
import React, { useState } from 'react';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from "react-hook-form";
import { GET_SEEN_LIKES_ACCESS, EDIT_SEEN_LIKES_PERMISSIONS } from '../../services/graphql';

const LikesSeenAccess = ({ school, setShowSaveConfirm, closeModal }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [likeEnabled, setLikeEnabled] = useState();
    const [seenEnabled, setSeenEnabled] = useState();

    const { data, error, loading } = useQuery(GET_SEEN_LIKES_ACCESS, {
        variables: {
            schoolId: school.id
        },
        onCompleted: (data) => {
            if (data.school?.id) {
                setLikeEnabled(data.school.allowLikes);
                setSeenEnabled(data.school.allowSeens);
            }
        }
    })

    const { register, handleSubmit } = useForm({
        mode: "onSubmit"
    });

    const [editSeenLikes, { loading: savingPermissions }] = useMutation(EDIT_SEEN_LIKES_PERMISSIONS, {
        update: (_, { data }) => {
            if (data.editSchoolSettingsForSeensAndLikes.errors && data.editSchoolSettingsForSeensAndLikes.errors.length !== 0) {
                setFormErrors(data.editSchoolSettingsForSeensAndLikes.errors);
                return;
            }
            if (data.editSchoolSettingsForSeensAndLikes.school.id) {
                setShowSaveConfirm && setShowSaveConfirm(true);
                closeModal();

            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SEEN_LIKES_ACCESS, variables: { schoolId: school.id } }]
    });



    const onSubmit = ({ allowSeens, allowLikes }) => {
        editSeenLikes({
            variables: {
                schoolId: school.id,
                allowLikes,
                allowSeens
            }
        });
    }

    if (loading) return <Loader />

    if (error) return <p>{error.message}</p>

    if (data && data.school) {


        return (
            <>
                <h2 className='heavy'>Seen by &amp; Likes</h2>
                <p className='heavy u-m-base-0'>Seen by: This will automatically show when a log has been seen (either in the activity feed or in the individual log history).</p>
                <p className='heavy u-m-base-2'>Like button: a heart that teachers and parents can use for any comments.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic-form__group basic-form__group--check">
                        <span className='u-m-right-3 u-p-right-3'>Seen by</span>
                        <input
                            type="checkbox"
                            ref={register}
                            className="switchToggle"
                            name='allowSeens'
                            defaultChecked={seenEnabled}
                            id='allowSeens'
                        />
                        <label className='switchLabel' htmlFor='allowSeens'>toggle</label>
                    </div>

                    <div className="basic-form__group basic-form__group--check">
                        <span className='u-m-right-3 u-p-right-1'>Like button</span>
                        <input
                            type="checkbox"
                            ref={register}
                            className="switchToggle"
                            name='allowLikes'
                            defaultChecked={likeEnabled}
                            id='allowLikes'
                        />
                        <label className='switchLabel' htmlFor='allowLikes'>toggle</label>
                    </div>
                    <Button className='u-m-top-2' disabled={savingPermissions}>Update Permissions</Button>
                    {formErrors && <FormErrors errors={formErrors} />}
                </form>
            </>
        )
    }
    return null;
}

export default LikesSeenAccess;