import React, { useState, useEffect, useRef } from 'react';
import ClassItem from '../../components/ClassItem';
import { ARCHIVE_CLASSES } from '../../scenes/NewAcademicYear/services/graphql'
import { GET_CLASSES_QUERY, UPDATE_CLASS_TYPE_ID, UPDATE_ACADEMIC_YEAR } from '../../services/graphql';
import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';
import SideModal from '../../../../components/SideModal';
import CreateClassForm from '../../components/CreateClassForm';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { AlertCircle, ChevronDown, PlusCircle } from 'react-feather';
import styles from '../../Classes.module.scss';
import { useClickAway } from 'react-use';
import SaveConfirm from '../../../../components/SaveConfirm';
import { CLASS_TYPE } from '../../../../services/constants';
import UploadClassesPupils from '../../components/UploadClassesPupils';
import { Link } from '@reach/router';

const ClassList = ({ classes, user, allTabs, selectedTab, setSelectedTab }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [addClass, setAddClass] = useState(false);
    const [uploadClasses, setUploadClasses] = useState(false);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [showUpdateAcademicYear, setShowUpdateAcademicYear] = useState(false)
    const [dropdown, setDropdown] = useState(false);
    const [addDropdown, setAddDropdown] = useState(false);
    const [confirmArchive, setConfirmArchive] = useState(false);

    const [showSaveConfirm, setShowSaveConfirm] = useState(null);

    const [getClasses] = useLazyQuery(GET_CLASSES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            includeAdminedClasses: user && user.isSchoolAdmin
        }
    });

    const selectRef = useRef("");

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));
    const ref2 = useRef(null);
    useClickAway(ref2, () => setAddDropdown(false));

    useEffect(() => {
        setSelectedClasses([]);
    }, [selectedTab]);

    const onUploadDone = () => {
        setUploadClasses(false);
        setAddDropdown(false);
        getClasses();
    }

    const renderTitle = () => {
        switch (selectedTab) {
            case allTabs.allClasses:
                return t('all_classes');
            case allTabs.registrationClasses:
                return t('registration_classes');
            case allTabs.myClasses:
                return t('my_classes');
            case allTabs.otherClasses:
                return t('other_classes_groups');
            case allTabs.archivedClasses:
                return t('archived_classes');
            default:
                return t('classes');
        }
    }

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(t => t !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const onSelectAllClasses = e => {
        const clsIds = classes.map(t => t.id);
        e.target.checked ? setSelectedClasses(clsIds) : setSelectedClasses([])
    }

    const [archiveClasses, { loading: archiveLoading }] = useMutation(ARCHIVE_CLASSES, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.archiveMultipleClasses.errors && data.archiveMultipleClasses.errors.length > 0) {
                setFormErrors(data.archiveMultipleClasses.errors);
            } else {
                setShowSaveConfirm(t('common:changes_saved'));
                setDropdown(false);
                setConfirmArchive(false);
                setSelectedClasses([]);
                setFormErrors([]);
                return;
            }
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY,
                variables: {
                    includeAdminedClasses: user.isSchoolAdmin
                },
            }
        ]
    });

    const [updateAcademicYear, { loading: updatingYear }] = useMutation(UPDATE_ACADEMIC_YEAR, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.editClassesAcademicYear.errors && data.editClassesAcademicYear.errors.length > 0) {
                setFormErrors(data.editClassesAcademicYear.errors);
            } else {
                setShowSaveConfirm(t('common:changes_saved'));
                setDropdown(false);
                setShowUpdateAcademicYear(false);
                setSelectedClasses([]);
                setFormErrors([]);
                return;
            }
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY,
                variables: {
                    includeAdminedClasses: user.isSchoolAdmin
                },
            }
        ]
    })

    const [updateClassesTypeId, { loading: updatingType }] = useMutation(UPDATE_CLASS_TYPE_ID, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.updateStudentsClassesTypeId.errors && data.updateStudentsClassesTypeId.errors.length > 0) {
                setFormErrors(data.updateStudentsClassesTypeId.errors);
            } else {
                setShowSaveConfirm(t('common:changes_saved'));
                setDropdown(false);
                setSelectedClasses([]);
                setFormErrors([]);
                return;
            }
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY,
                variables: {
                    includeAdminedClasses: user.isSchoolAdmin
                },
            }
        ]
    });

    const updateMultipleClasses = type => {
        updateClassesTypeId({
            variables: {
                studentsClassesIds: selectedClasses,
                typeId: type,
                schoolId: user.school.id
            }
        });
    }

    return (
        <>
            <h3 className='u-display-flex u-justify-between u-align-center'>
                {renderTitle()}
                {showSaveConfirm && <SaveConfirm message={showSaveConfirm} hideConfirm={setShowSaveConfirm} />}
            </h3>
            <hr className='separator' />
            {uploadClasses ? <UploadClassesPupils exit={() => { setUploadClasses(false); setAddDropdown(false) }} isOnboarding={false} onUploadDone={onUploadDone} school={user?.school} /> :
                <>
                    <div className={styles.actions}>
                        <div className={styles.actionsLeft}>
                            {classes.length > 0 && user.isSchoolAdmin && (
                                <>
                                    <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                        <input
                                            name="all"
                                            className="basic-form__check-box"
                                            type="checkbox"
                                            checked={classes.length > 0 && selectedClasses.length === classes.length}
                                            onChange={(e) => onSelectAllClasses(e)}
                                            id="selectAll"
                                        />
                                        <label className="basic-form__check-label" htmlFor='selectAll'>All / None</label>
                                    </div>
                                    <div ref={ref} className='dropdownHolder'>
                                        <button disabled={selectedClasses.length === 0} className='dropdownBtn' onClick={() => setDropdown(!dropdown)}>Actions <ChevronDown /> </button>
                                        {dropdown && (
                                            <ul className='dropdownMenu'>
                                                {selectedTab !== allTabs.registrationClasses &&
                                                    <li>
                                                        <button
                                                            disabled={updatingType}
                                                            className='dropdownMenuItem'
                                                            onClick={() => updateMultipleClasses(CLASS_TYPE.registration)}
                                                        >{updatingType ? t('common:saving') : t('set_as_registration_class')}</button>
                                                    </li>
                                                }
                                                {selectedTab !== allTabs.otherClasses &&
                                                    <li><button
                                                        disabled={updatingType}
                                                        className='dropdownMenuItem'
                                                        onClick={() => updateMultipleClasses(CLASS_TYPE.other)}
                                                    >{updatingType ? t('common:saving') : t('set_as_other_class')}</button></li>
                                                }
                                                {selectedTab !== allTabs.archivedClasses &&
                                                    <li><button
                                                        className='dropdownMenuItem'
                                                        onClick={() => setConfirmArchive(true)}
                                                    >Archive classes</button></li>
                                                }
                                                <li><button className="dropdownMenuItem" onClick={() => {
                                                    setDropdown(false);
                                                    setShowUpdateAcademicYear(true)
                                                }}>Edit Academic Year</button></li>
                                            </ul>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {user.isSchoolAdmin && !user.school?.wondeAccessApprovedAt &&
                            <div ref={ref2} className='dropdownHolder'>
                                <button className='dropdownBtn heavy' onClick={() => setAddDropdown(!addDropdown)}><PlusCircle size="18" className='u-m-right-1' /> Add classes <ChevronDown /> </button>
                                {addDropdown && (
                                    <ul className='dropdownMenu'>
                                        <li>
                                            <button className='dropdownMenuItem' onClick={() => setAddClass(true)}>{t('add_class')}</button>
                                        </li>
                                        <li>
                                            <button className='dropdownMenuItem' onClick={() => setUploadClasses(true)}>Upload multiple classes</button>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        }
                    </div>

                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                    {classes && classes.length > 0 ?

                        <ul className='list-group'>
                            {classes.sort((a, b) => a.name.localeCompare(b.name)).map(cls => (
                                <ClassItem
                                    onSelectClass={onSelectClass}
                                    selectedClasses={selectedClasses}
                                    key={cls.id}
                                    studentClass={cls}
                                    user={user}
                                />))}
                        </ul> :
                        <div>
                            <p className="u-bold u-m-base-2">No classes.</p>
                            {user.isSchoolAdmin && !user.school.wondeAccessApprovedAt &&
                                <p>Looking for your students? You can find them in the <Link className="u-bold" to="/students">Manage Students</Link> section</p>
                            }
                        </div>
                    }
                </>
            }
            {confirmArchive && (
                <Modal closeModal={() => setConfirmArchive(false)}>
                    <h1>Archive {`${selectedClasses.length === 1 ? '1 class' : selectedClasses.length + ' classes'}`} </h1>
                    <div className={styles.confirmArchive}>
                        <div className="u-display-flex u-align-center u-m-base-2">
                            <AlertCircle className='u-m-right-2' />
                            <p className='lead heavy'>Are you sure?</p>
                        </div>
                        <p>This will mark their students as inactive</p>
                        <p>Current academic year classes can be unarchived from the Archived classes tab.</p>
                    </div>
                    <Button
                        className='u-m-right-1'
                        disabled={archiveLoading}
                        onClick={() =>
                            archiveClasses(
                                { variables: { studentsClassIds: selectedClasses } }
                            )
                        }>{`${archiveLoading ? 'Saving...' : `Yes, archive ${selectedClasses.length === 1 ? 'class' : 'classes'}`}`}</Button>
                    <Button outline onClick={() => setConfirmArchive(false)}>Cancel</Button>
                </Modal>
            )}
            {showUpdateAcademicYear && (
                <SideModal closeModal={() => setShowUpdateAcademicYear(false)}>
                    <div>
                        <div>
                            <h1>Update Acadamic Year</h1>
                            <p className="u-m-base-1">Select academic year:</p>
                            <div className="u-display-flex">
                                <select ref={selectRef} className="u-m-base-2 basic-form__text-select">
                                    <option value="2025">2024/2025</option>
                                    <option value="2024">2023/2024</option>
                                    <option value="2023">2022/2023</option>
                                </select>
                            </div>
                        </div>
                        <Button
                            className='u-m-right-1'
                            onClick={() => {
                                updateAcademicYear({
                                    variables: {
                                        studentsClassIds: selectedClasses,
                                        academicYear: parseInt(selectRef.current.value)
                                    }
                                })
                            }}

                        >Confirm</Button>
                        <Button outline onClick={() => setShowUpdateAcademicYear(false)}>Cancel</Button>
                    </div>
                </SideModal>
            )
            }
            {addClass && (
                <SideModal closeModal={() => setAddClass(false)}>
                    <div className="row">
                        <div className="col-lg-8">
                            <CreateClassForm setShowSaveConfirm={setShowSaveConfirm} close={() => setAddClass(false)} classTabs={allTabs} setSelectedTab={setSelectedTab} />
                        </div>
                    </div>
                </SideModal>
            )}
        </>
    )
}

export default ClassList;
