import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import { useTranslation } from 'react-i18next';
import { useQuery } from "@apollo/react-hooks";
import { GET_WONDE_URL } from "../services/graphql";

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import ls from 'local-storage'

import styles from './CountrySelect.module.scss'
import { Link } from '@reach/router';
import { useLocation } from 'react-use';

import usa from '../../../images/usa.png'
import uk from '../../../images/uk.png'
import row from '../../../images/row.png'

const LoginForm = ({ formErrors, loggingIn, loginMethod, setModalForgotPass, setShowCountrySelect, showWondeBtn = false }) => {
    const location = useLocation();

    const { t } = useTranslation(['profile', 'common']);

    const schema = Yup.object().shape({
        email: Yup.string().email()
            .required(t('enter_your_email')),
        password: Yup.string()
            .required(t('please_enter_password'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const { data } = useQuery(GET_WONDE_URL, {
        fetchPolicy: 'network-only',
        variables: {
            from: 'Teacher'
        }
    });

    const onSubmit = values => {
        const { email, password } = values;
        loginMethod({
            variables: {
                email,
                password
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='basic-form__group'>
                <input
                    name='email'
                    placeholder={t('common:email')}
                    className='basic-form__text-box'
                    ref={register}
                    type='email'
                />
            </div>
            {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
            <div className='basic-form__group'>
                <input
                    name='password'
                    placeholder={t('password')}
                    className='basic-form__text-box'
                    autoComplete='off'
                    ref={register}
                    type='password'
                />
            </div>
            {errors.password && <p className='basic-form__hint'>{errors.password.message}</p>}
            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(fe => t(fe))} />}
            {formErrors[0]?.toLowerCase().replace(/\s/g, '').includes('invalidemail') && <p className="u-m-base-2 u-bold">Make sure you have the right country set.</p>}
            <div className='u-display-flex u-justify-center'>
                <Button type='submit' primary className='u-width-full'>{loggingIn ? 'Logging in...' : t('common:login')}</Button>
            </div>

            {location?.state?.wondeLoginErrors && (
                <div className="u-m-top-2">
                    <FormErrors errors={location.state.wondeLoginErrors} />
                </div>
            )}
            {data && data.wondeLoginUrl && showWondeBtn && (
                <a className='wondeLoginBtn' href={`${data.wondeLoginUrl}`}>Login with Wonde</a>

            )}

            <div className={styles.countrySelectLabel}>
                <p className={styles.countrySelectPara}>Your country is: <img className={styles.countrySelectImg} src={ls('country') === 'US' ? usa : ls('country') === 'GB' ? uk : row} /></p>
                <span className={styles.countrySelectBtn} onClick={() => setShowCountrySelect(true)}>Change</span>
            </div>
            <div className='u-display-flex u-justify-center u-m-top-3'>
                <p onClick={() => setModalForgotPass(true)} className='link-underline auth__link u-m-right-1'>{t('ive_forgotten_my_password')}</p>
                <Link className='link-underline auth__link u-m-left-1' to='/signup'>Create Account</Link>
            </div>
        </form>
    )
}

export default LoginForm;