import { parseOfferStatus, isCurrentAcademicYear } from '../../../services/utils';

// Classes: get available packs (for the current academic year)
export const getAvailablePacks = (purchasedPacks, classId) => {
    const classPacks = [];
    const currentPurchasedPacks = purchasedPacks.filter(pPack => isCurrentAcademicYear(pPack.academicYear));

    currentPurchasedPacks.forEach(pPack => {
        parseOfferStatus(pPack.status).forEach(item => {
            if (!item.students_class) {
                classPacks.push({
                    id: `${pPack.id}-${item.id}`,
                    packOfferName: pPack.packOffer.name,
                    packOfferId: pPack.packOffer.id,
                    packOfferIndex: item.id,
                    purchasedPackId: pPack.id,
                    purchasedAt: pPack.purchasedAt,
                    maxNumberOfStudents: item.students,
                    academicYear: pPack.academicYear,
                    cls: item.students_class
                })
            }
        })
    })
    return classPacks;
}