import { gql } from 'apollo-boost';
import { schoolQuery, renewalFragment } from '../../../services/constants';

export const PURCHASE_READ_ACCESS = gql`
mutation purchaseGoReadAccess($schoolId: ID!, $studentsCounter: Int!, $purchaseOrderReferenceNumber: String, $duration: Int!, $misAccess: Boolean!, $misUrn: String, $withDataDashboardAccess: Boolean!) {
    purchaseGoReadAccess(schoolId: $schoolId,studentsCounter: $studentsCounter, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber, duration: $duration, misAccess: $misAccess, misUrn: $misUrn, withDataDashboardAccess: $withDataDashboardAccess) {
        errors
        school {
            ${schoolQuery}
            schoolSubscriptions {
                id
                appName
                app {
                    id
                    name
                }
                expiresAt
                status
                isTrial
                duration
                createdAt
                subscribedSeats
                createdAt
                nextPaymentDate
                isStripe
                subscriptionCancelledAt
                payments {
                    id
                    amount
                }
                invoiceRequest {
                    id
                    orderedAt
                    studentsCounter
                    isTrial
                    discount
                    amount
                }
            }
        }
    }
}
`;

export const BOOMREAD_PRICING = gql`
query boomReadPricing {
    boomReadPricing {
        oneYearPricePerStudent
        renewalDiscount
        twoYearPricePerStudent
    }
}
`;

export const ADD_READ_TRIAL_ACCESS = gql`
mutation addBoomReaderTrialAccess($schoolId: ID!, $studentsCounter: Int!) {
    addBoomReaderTrialAccess(schoolId: $schoolId, studentsCounter: $studentsCounter) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

export const GET_READ_SUBSCRIPTIONS = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        ${schoolQuery}
        schoolSubscriptions {
            id
            appName
            app {
                id
                name
            }
            expiresAt
            status
            isTrial
            duration
            createdAt
            subscribedSeats
            createdAt
            nextPaymentDate
            isStripe
            subscriptionCancelledAt
            secondYearSubscribedSeats
            payments {
                id
                amount
                receiptUrl
                createdAt
            }
            invoiceRequest {
                id
                orderedAt
                studentsCounter
                isTrial
                discount
                amount
                VAT
            }
            createdBy {
                id
                displayName
            }
            details {
                VAT
                price
                quantity
                totalWithoutVat
            }
            renewal {
                ${renewalFragment}
            }
            secondYearRenewal {
                ${renewalFragment}
            }
        }
    }
}
`;

export const EDIT_RENEWAL = gql`
mutation editRenewal($renewalId: ID!, $duration: Int!, $studentsCounter: Int!, $purchaseOrderReferenceNumber: String, $dataDashboardAccess: Boolean, $urn: String, $wondeAccess: Boolean) {
    editRenewal(renewalId: $renewalId, duration: $duration, studentsCounter: $studentsCounter, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber, dataDashboardAccess: $dataDashboardAccess, urn: $urn, wondeAccess: $wondeAccess) {
        errors
        school {
            id
        }
    }
}
`;

export const EDIT_RENEWAL_SUBSCRITIONS = gql`
mutation editRenewalSubscriptions($renewalId: ID!, $dataDashboardAccess: Boolean, $urn: String, $wondeAccess: Boolean) {
    editRenewalSubscriptions(renewalId: $renewalId, dataDashboardAccess: $dataDashboardAccess, urn: $urn, wondeAccess: $wondeAccess) {
        errors
        school {
            id
            ${schoolQuery}
        }
    }
}
`;

export const CONFIRM_RENEWAL = gql`
mutation confirmRenewal($renewalId: ID!) {
    confirmRenewal(renewalId: $renewalId) {
        errors
        school {
            id
        }
    }
}
`;

export const RENEW_AND_PAY_NOW = gql`
mutation renewAndPayNow($renewalId: ID!) {
    renewAndPayNow(renewalId: $renewalId) {
        errors
        school {
            id
        }
    }
}
`;

export const GET_DOWNLOAD_DATA_PERMISSIONS = gql`
    query school($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            downloadDataSettings
        }
    }
`;

export const GET_NOTES_PERMISSIONS = gql`
    query school($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            manageStudentNotesSettings
        }
    }
`;

export const GET_SEEN_LIKES_ACCESS = gql`
    query school($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            allowSeens
            allowLikes
        }
    }
`;



export const EDIT_DOWNLOAD_PERMISSIONS = gql`
mutation editSchoolSettingsForDownloadData($schoolId: ID!, $permittedRoleIds: [Int!]!) {
    editSchoolSettingsForDownloadData(schoolId: $schoolId, permittedRoleIds: $permittedRoleIds) {
        errors
        school {
            ${schoolQuery}
            downloadDataSettings
        }
    }
}
`;
export const EDIT_NOTES_PERMISSIONS = gql`
mutation editSchoolSettingsForStudentNotes($schoolId: ID!, $permittedRoleIds: [Int!]!) {
    editSchoolSettingsForStudentNotes(schoolId: $schoolId, permittedRoleIds: $permittedRoleIds) {
        errors
        school {
            ${schoolQuery}
            downloadDataSettings
        }
    }
}
`;


export const EDIT_SEEN_LIKES_PERMISSIONS = gql`
mutation editSchoolSettingsForSeensAndLikes($schoolId: ID!, $allowSeens: Boolean!, $allowLikes: Boolean!) {
    editSchoolSettingsForSeensAndLikes(schoolId: $schoolId, allowSeens: $allowSeens, allowLikes: $allowLikes) {
        errors
        school {
            ${schoolQuery}
            allowSeens
            allowLikes
        }
    }
}
`;



export const BOOMREAD_COST = gql`
query boomreadCost($schoolId: ID!, $studentsCount: Int!, $years: Int!, $withDataDashboard: Boolean, $withWonde: Boolean) {
    boomreadCost(schoolId: $schoolId, studentsCount: $studentsCount, years: $years, withDataDashboard: $withDataDashboard, withWonde: $withWonde) {
        VAT
        totalCost
        standardCost
        discountValue
        wondeCost
        dataDashboardCost
        discountPercentage
    }
}
`;


export const START_STRIPE_SUB = gql`
mutation startBoomReadStripeSubscription($schoolId: ID!, $inputData: Int!, $studentsCounter: Int!, $duration: Int!) {
    startBoomReadStripeSubscription(schoolId: $schoolId, inputData: $inputData, studentsCounter: $studentsCounter, duration: $duration) {
        clientSecret
        errors
        subscriptionId
    }
}`

export const START_READ_ACCESS = gql`
mutation startBoomReadAccess($schoolId: ID!, $studentsCounter: Int!, $stripeSubscriptionId: String!, $duration: Int!) {
    startBoomReadAccess(schoolId: $schoolId, studentsCounter: $studentsCounter, stripeSubscriptionId: $stripeSubscriptionId, duration: $duration) {
        errors
        school {
            id
            schoolSubscriptions {
                id
                appName
                app {
                    id
                    name
                }
                expiresAt
                status
                isTrial
                duration
                createdAt
                subscribedSeats
                createdAt
                nextPaymentDate
                isStripe
                subscriptionCancelledAt
                payments {
                    id
                    amount
                }
                invoiceRequest {
                    id
                    orderedAt
                    studentsCounter
                    isTrial
                    discount
                    amount
                }
            }
        }
    }
}`

export const END_READ_ACCESS = gql`
mutation cancelBoomReadAccess($schoolId: ID!) {
    cancelBoomReadAccess(schoolId: $schoolId) {
        errors
        school {
            ${schoolQuery}
        }
    } 
}
`


export const PURCHASE_DATA_DASH_ACCESS = gql`
mutation purchaseSchoolDataDashboardAccess($schoolId: ID!, $allowAccess: Boolean!, $wondeAccess: Boolean!, $misUrn: String, $expiresAt: String, $purchaseOrderReferenceNumber: String) {
    purchaseSchoolDataDashboardAccess(schoolId: $schoolId, allowAccess: $allowAccess, wondeAccess: $wondeAccess, misUrn: $misUrn, expiresAt: $expiresAt, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

export const PURCHASE_WONDE_ACCESS_ONLY = gql`
mutation purchaseSchoolWondeAccess($schoolId: ID!, $allowAccess: Boolean!, $urn: String!, $expiresAt: String, $purchaseOrderReferenceNumber: String) {
    purchaseSchoolWondeAccess(schoolId: $schoolId, allowAccess: $allowAccess, urn: $urn, expiresAt: $expiresAt, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;


export const CHECK_WONDE_ACCESS_STATUS = gql`
query currentTeacher {
    currentTeacher {
        id
        school {
            id
            wondeId
            wondeAccessApprovedAt
            wondeSyncStep
        }
    }
}
`;