import React, { useContext, useState } from 'react';
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_WONDE_SCHOOL } from "../../services/graphql";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { WEEK_STARTS } from "../../../../services/constants";
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { getRoleLabel, usaOnlyRoles } from '../../../../services/utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import schoolDefault from '../../../../images/school-default.svg';
import ImageFadeIn from 'react-image-fade-in';
import { AuthContext } from '../../../../services/auth/AuthProvider';

const UpdateWondeSchool = () => {

    const {currentUser, setCurrentUser} = useContext(AuthContext)

    const [formErrors, setFormErrors] = useState([]);
    const { t } = useTranslation(['profile', 'common']);


    const [updateWondeSchool, { loading }] = useMutation(UPDATE_WONDE_SCHOOL, {
        update: (_, { data }) => {
            if (data.updateWondeSchool.errors && data.updateWondeSchool.errors.length !== 0) {
                setFormErrors(data.updateWondeSchool.errors);
                return;
            }
            if (data.updateWondeSchool?.school?.id) {
                const cu = { ...currentUser, school: data.updateWondeSchool.school }
                setCurrentUser(cu);
            }
        }
    });

    const schema = Yup.object().shape({
        weekStart: Yup.number().required().typeError('Please select week start'),
        roleId: Yup.string().required(t('common:please_select_role')),
        tos: Yup.boolean().oneOf([true], t('must_agree_to_tos')),
        authorised: Yup.boolean().oneOf([true], "You must confirm you are authorized"),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });


    const onSubmit = values => {
        const { weekStart, roleId } = values;
        updateWondeSchool({
            variables: {
                schoolId: currentUser?.school.id,
                weekStart,
                roleId: +roleId
            }
        })
    }

    return (
        <>
            <h1 className="heavy u-m-base-2">Continue data import from Wonde</h1>
            <div className="panel u-m-base-2">
                <div className='u-display-flex'>
                    <div>
                        <ImageFadeIn style={{maxWidth: '60px'}} src={currentUser.school.avatarUrl || schoolDefault} />
                    </div>
                    <div className='u-m-left-2'>
                        <h3 className='heavy'>{currentUser.school.name}</h3>
                        <p>{currentUser.school.adddress}, {currentUser.school.address2}</p>
                        <p>{currentUser.school.city}, {currentUser.school.zip}, {currentUser.school.country?.name}, {currentUser.school.phone}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="heavy lead">School Reporting Week</p>
                        <div className='basic-form__group'>
                            <select name='weekStart' className='basic-form__text-select' ref={register}>
                                <option value=''>Please select</option>
                                {Object.entries(WEEK_STARTS).map(week => {
                                    return (<option key={`week-${week[0]}`} value={week[0]}>{week[1]}</option>)
                                })}
                            </select>
                            {errors.weekStart && <p className='basic-form__hint u-m-top-1'>{errors.weekStart.message}</p>}
                        </div>
                        <p className="heavy lead"> Your role at the school</p>
                        <div className='basic-form__group u-m-base-2'>
                            <select name="roleId" ref={register} className="basic-form__text-select">
                                <option value=''>Select your role</option>
                                {Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                    return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                })}
                            </select>
                            {errors.roleId && <p className="basic-form__hint u-m-top-1">{errors.roleId.message}</p>}
                        </div>

                        <div className="basic-form__group basic-form__group--check u-text-left u-m-base-3">
                            <div>
                                <input
                                    name="authorised"
                                    className="basic-form__check-box"
                                    ref={register}
                                    type="checkbox"
                                    id="authorised"
                                />
                                <label className="basic-form__check-label" htmlFor="authorised">I'm authorised to create an account for this school</label>
                            </div>
                        </div>
                        {errors.authorised && <p className='basic-form__hint'>{errors.authorised.message}</p>}

                        <div className="basic-form__group basic-form__group--check u-text-left u-m-base-3">
                            <div>
                                <input
                                    name="tos"
                                    className="basic-form__check-box"
                                    ref={register}
                                    type="checkbox"
                                    id="tos"
                                />
                                <label className="basic-form__check-label" htmlFor="tos">I accept the Terms and Privacy Policies for this School account</label>
                            </div>
                        </div>
                        {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}
                        {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                        <Button disabled={loading} type='submit'>
                            {loading ? t('common:saving') : 'Next: import data'}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateWondeSchool;