import React from 'react';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

const StudentUpdateCable = ({ setReceivedData, schoolId }) => {

    return (
        <ActionCableConsumer
            channel={{
                channel: "SchoolChannel",
                id: `${schoolId}`
            }}
            onReceived={(message) => {
                setReceivedData(message)
            }}
            onRejected={(test) => {
                console.log(test)
            }}
        ></ActionCableConsumer>
    );
};

export default StudentUpdateCable;

