import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import GoReadLocked from './scenes/GoReadLocked';
import GoReadDashboard from './scenes/GoReadDashboard';
import { AuthContext } from '../../services/auth/AuthProvider';
import { GET_READ_SUBSCRIPTIONS } from './services/graphql';

const GoReadSettings = () => {

    const { currentUser } = useContext(AuthContext);

    const {data, loading, error} = useQuery(GET_READ_SUBSCRIPTIONS, {
        variables: {
            schoolId: currentUser.school.id
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.school) {
        const { schoolSubscriptions: subscriptions, goReadSubscriptionExpired} = data.school;
        const readSubscriptions = subscriptions.filter(s => s.app && s.app.name ==="BoomReader");
        const orderedSubs = [...readSubscriptions].sort((a,b) => a.expiresAt.localeCompare(b.expiresAt));
        return(
            <div>
                {
                    currentUser.isSchoolAdmin && readSubscriptions.length > 0 ? (
                        <GoReadDashboard user={currentUser} subExpired={goReadSubscriptionExpired} subscription={[...orderedSubs].pop()} />
                    ) :  <GoReadLocked />
                    }
            </div>
        )
    }
    return null
}

export default GoReadSettings;