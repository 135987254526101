import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import { getYearGroupLabel } from '../../../../../../services/utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { RefreshCw } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';

import { EDIT_STUDENT_MUTATION } from '../../services/graphql';

const EditStudent = ({ closeModal, schoolId, student, setShowStudentModal, setFromEditModal }) => {

    const [formErrors, setFormErrors] = useState([]);
    const { t } = useTranslation(['classes', 'common']);
    const isUS = ls('country') === 'US';


    const [editStudent, { loading: savingStudent }] = useMutation(EDIT_STUDENT_MUTATION, {
        update: (_, { data }) => {
            if (data.editStudent.errors && data.editStudent.errors.length !== 0) {
                setFormErrors(data.editStudent.errors);
                return;
            }

            if (data.editStudent.student.id) {
                closeModal();
            }
        }
    });

    const validYearGroup = YEAR_GROUPS.find(yg => yg.val === student.yearGroup);

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        yearGroup: Yup.number().typeError(isUS ? 'Please select grade' : 'Please select year group')
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        const { firstName, lastName, yearGroup, upn } = values;
        editStudent({
            variables: {
                firstName,
                lastName,
                displayName: `${firstName} ${lastName}`,
                email: student.email || '',
                studentId: student.id,
                schoolId,
                yearGroup,
                upn
            }
        });
    }

    return (
        <div className='site-admin__modal__content'>
            <h1 className='title u-m-base-2'>{t('edit_pupil')}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <label htmlFor='firstName' className='basic-form__text-label'>{t('common:first_name')}</label>
                    <input
                        name='firstName'
                        id='firstName'
                        placeholder={t('common:first_name_placeholder')}
                        className='basic-form__text-box'
                        ref={register}
                        defaultValue={student.firstName}
                        type='text'
                    />
                </div>
                {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                <div className='basic-form__group'>
                    <label htmlFor='lastName' className='basic-form__text-label'>{t('common:last_name')}</label>
                    <input
                        name='lastName'
                        id='lastName'
                        placeholder={t('common:last_name_placeholder')}
                        className='basic-form__text-box'
                        ref={register}
                        defaultValue={student.lastName}
                        type='text'
                    />
                </div>
                {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}


                <div className='basic-form__group'>
                    <label htmlFor='firstName' className='basic-form__text-label'>{isUS ? 'Grade' : 'Year Group'}</label>
                    <select defaultValue={validYearGroup ? validYearGroup.val : 'blank'} ref={register} name="yearGroup" className="basic-form__text-select">
                        <option value='blank'>{isUS ? 'Select grade' : 'Select year group'}</option>
                        {YEAR_GROUPS.map(yearGroup => (
                            <option key={yearGroup.val} value={yearGroup.val}>{getYearGroupLabel(yearGroup.val)}</option>
                        ))}
                    </select>
                </div>
                {errors.yearGroup && <p className='basic-form__hint'>{errors.yearGroup.message}</p>}
                {!isUS &&
                    <div className='basic-form__group'>
                        <label htmlFor='upn' className='basic-form__text-label'>UPN</label>
                        <input
                            name='upn'
                            id='upn'
                            placeholder='e.g. 12345'
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={student.upn}
                            type='text'
                        />
                    </div>
                }

                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}

                <p className='u-display-flex u-align-center u-m-base-2'>
                    {t('login_code')}: <strong className='u-m-left-1'>{student.loginCode}</strong>
                    <button
                        type='button'
                        onClick={() => {
                            closeModal();
                            setShowStudentModal(student);
                            setFromEditModal(true);
                        }}
                        className='btn-reset u-text-tertiary u-m-left-2 icon-title'
                    ><RefreshCw size='16' className='u-m-right-1' /> {t('refresh')}</button>
                </p>


                <div className='u-display-flex u-align-center'>
                    <Button type='submit' primary className='u-m-right-1'>{savingStudent ? t('common:saving') : t('common:button.save_changes')}</Button>
                    <Button type='button' outline onClick={() => closeModal()} >{t('common:button.cancel')}</Button>
                </div>
            </form>
        </div>

    )
}

export default EditStudent;
