import React, {useState, useContext, useEffect,} from "react";

import { Button  } from '@axeedge/go-teacher-components';
import styles from './NewYear.module.scss'
import PageHeader from '../../components/PageHeader';
import {Users} from 'react-feather'
import {navigate} from '@reach/router'
import { AuthContext } from '../../services/auth/AuthProvider';

import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Step4 from './components/Step4'
import Step5 from './components/Step5'
import Step6 from './components/Step6'
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_STEP, SET_NEXT_STEP } from "./services/graphql";
import {CURRENT_TEACHER_QUERY} from '../Home/services/graphql'
import cx from 'classnames'

const NewYear = () => {

    const { currentUser: user, setCurrentUser } = useContext(AuthContext);

    const [currentStep, setCurrentStep] = useState(null)
    const [formErrors, setFormErrors] = useState(null)

    const { data, error, loading } = useQuery(GET_STEP, {
        variables: {
            schoolId: user && user.school.id
        },
        fetchPolicy: 'network-only',
        onCompleted: ({school}) => {
            setCurrentStep(school.newYearStep)
        }
    });

    const [updateStep, { loading: updatingStep }] = useMutation(SET_NEXT_STEP, {
        onCompleted: ({ updateSchoolNewYearStep }) => {
            if (updateSchoolNewYearStep.errors && updateSchoolNewYearStep.errors.length !== 0) {
                setFormErrors(updateSchoolNewYearStep.errors);
                return;
            } else {
                if (currentStep === 99) {
                    setCurrentUser({...user, school: {...user.school, newYearStep: 99}})
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: CURRENT_TEACHER_QUERY},]
    })

    // below is used to reset step during dev
    // useEffect(() => {
    //     updateStep({variables:{
    //         schoolId: user && user.school.id,
    //         newYearStep: 1,
    //     }})
    // },[])

    useEffect(() => {
        if (currentStep && currentStep > data.school.newYearStep) {
            updateStep({variables:{
                schoolId: user && user.school.id,
                newYearStep: currentStep
            }})
        }
    },[currentStep])

    const Step1 = () => {
        return (
            <>
                 <PageHeader title={`2024/25 Setup`} icon={Users} />
                <div className={cx(styles.step1)}>
                    <div className={styles.step1Inner}>
                        <p>Follow this simple wizard to set your school up for the new academic year.</p>
                        <p className="u-bold u-m-base-2">This will guide you through:</p>
                        <h2>1. Review &amp; Update current Pupil Info</h2>
                        <p className="u-m-left-4 u-m-base-2">First help us resolve any duplicate pupil accounts, add any missing UPNs (optional) and fill in any missing data (such as current year group).</p>
                        <h2>2. Auto Archive &amp; Year Change</h2>
                        <p className="u-m-left-4 u-m-base-2">First we’ll archive your classes for 2023/24 (These are still available as read-only classes!). We’ll also move each student up by one year group.</p>
                        <h2>3. Create Registration Classes</h2>
                        <p className="u-m-left-4 u-m-base-2">One year group at a time, you'll create the Registration class and move pupils into them.</p>
                        <h2>4. Upload or Add New Cohort</h2>
                        <p className="u-m-left-4 u-m-base-2">Create the registration classes for your new cohort by uploading an XLSX file.</p>
                        <h2 className="u-m-base-4">5. Grab tea &amp; biscuits as a reward</h2>
                        <h2>Ready? Let's go!</h2>
                        <Button onClick={() => setCurrentStep(2)}>Get Started</Button>
                    </div>
                    <div>
                        <div className={styles.side}>
                            <h2>Already started this process?</h2>
                            <p className="u-m-base-2">If you've already started manually doing this process, then you can skip this and carry on as you were.</p>
                            <Button onClick={() => {setCurrentStep(99);navigate('/')}}>Skip This Process</Button>
                        </div>
                    </div>    
                </div>
            </>
        )
    }

    const renderSteps = () => {
        switch(currentStep) {
            case 1:
                return <Step1 />
            case 2:
                return <Step2 setCurrentStep={setCurrentStep} />
            case 3:
                return <Step3 setCurrentStep={setCurrentStep} schoolId={user && user.school.id} schoolStep={data && data.school.newYearStep} />
            case 4:
                return <Step4 setCurrentStep={setCurrentStep} schoolStep={data && data.school.newYearStep}/>
            case 5:
                return <Step5 setCurrentStep={setCurrentStep} school={user && user.school} />
            case 44: 
                return (
                <div className={styles.updateProgress}>
                    <p className="u-bold u-m-base-2">We are still updating your students.</p>
                    <Button onClick={() => window.location.reload()}>Check Again</Button>
                </div>
                )
            case 99:
                return <Step6 />        
            default:
              return <p>Loading...</p>
          }
    }

    return (
        <>{renderSteps()}</>
)
}

export default NewYear