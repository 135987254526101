import React, { useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from "@apollo/react-hooks";
import { STRIPE_DETAILS_QUERY } from "../../../services/graphql";
import { Loader } from "@axeedge/go-teacher-components";

const StripeWrapper = ({ children }) => {

    const [stripePromise, setStripePromise] = useState(null);

    const { data, error, loading } = useQuery(STRIPE_DETAILS_QUERY, {
        onCompleted: ({ stripeDetails }) => {
            setStripePromise(loadStripe(stripeDetails.publicKey));
        }
    });
   
    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && stripePromise) {
        return (
            <Elements stripe={stripePromise} >
               {children}
            </Elements>
        )
    }

    return null
}

export default StripeWrapper;