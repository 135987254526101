import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircle, Watch } from 'react-feather';
import AddClassPack from '../../components/AddClassPack';
import { useMutation } from '@apollo/react-hooks';
import { Modal } from '@axeedge/go-teacher-components';
import styles from './ClassView.module.scss';

const ClassViewPacks = ({ classData, currentUser }) => {

    const [showPacksModal, setShowPacksModal] = useState(false);
    const { t } = useTranslation(['classes', 'common']);


    return (
        <div className={styles.container}>
            <div className='row'>
                <div className="col-lg-8">

                    {currentUser.isSchoolAdmin ? (
                        <button className='btn-icon' onClick={() => setShowPacksModal(true)}>
                            <PlusCircle /> <span className='u-m-right-1'>{t('common:gowrite_book_packs')}</span>
                        </button>
                    ) : (
                        <div className='lead'>{t('common:gowrite_book_packs')}</div>
                    )}
                    <hr className='separator' />

                    <ul className='list-group'>
                        {
                            classData.classPacks && classData.classPacks.length ? classData.classPacks.map(cPack => {
                                return (
                                    <li className='list-item' key={`class-p-${cPack.id}`}>
                                        <p>{cPack.bookTemplateCategory ? cPack.bookTemplateCategory.name : t('common:one_pack')}</p>
                                        {!cPack.bookTemplate && <Watch className='u-text-muted u-m-left-1' size='16' />}
                                    </li>
                                )
                            }) : <li>{t('common:no_gowrite_book_packs')}</li>
                        }
                    </ul>

                    {
                        showPacksModal && (
                            <Modal closeModal={() => setShowPacksModal(false)}>
                                <AddClassPack schoolCode={currentUser.school.schoolCode} closeModal={() => setShowPacksModal(false)} studentClass={classData} />
                            </Modal>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ClassViewPacks;