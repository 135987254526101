import React, { useContext } from 'react';
import { PlusCircle } from 'react-feather';
import { navigate } from '@reach/router';
import styles from './GoWriteDashboard.module.scss';

import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import boomwriterImg from '../../../../images/boomwriter-logo.svg';
import ImageFadeIn from 'react-image-fade-in';
import ls from 'local-storage';
import { APP_NAME, APP_NAME_US } from '../../../../services/constants';

import { Loader, Button } from '@axeedge/go-teacher-components';
import PurchasedPacksList from './components/PurchasedPacksList';
import Activities from './components/Activities/'
import { useQuery } from '@apollo/react-hooks';
import { GET_PURCHASED_PACKS_QUERY } from '../../../../services/school/graphql';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { useTranslation } from 'react-i18next';

const GoWriteDashboard = ({ setViewPack }) => {

    const token = ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));
    const { currentUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const {data, loading, error} = useQuery(GET_PURCHASED_PACKS_QUERY, {
        variables: {
            schoolCode: currentUser.school.schoolCode
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.purchasedPacksBySchoolCode) {
        return(
            <>
                <div className='row'>
                    <div className="col-lg-5 col-md-6">
                        <a className={styles.appLink} href={`${getUrl(APPS.go_write_teacher)}?token=${token}`}>
                            <ImageFadeIn src={boomwriterImg} width='70px'  className={styles.appLinkImg} />
                            <div className={styles.appLinkText}>Launch <span>BoomWriter</span></div>
                        </a>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-lg-5 col-md-6">
                        <h2 className='heavy'>{t('prepurchase_book_packs')}</h2>
                        <Button onClick={() => navigate('/settings/boomwriter/purchase')} type='button' className='u-m-base-2'><span className='u-text-white u-display-flex u-align-center'><PlusCircle className='u-m-right-1' />Purchase Packs</span></Button>
                        <PurchasedPacksList currentUser={currentUser} purchasedPacks={data.purchasedPacksBySchoolCode} setViewPack={setViewPack} />
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <Activities setViewPack={setViewPack} />
                    </div>
                </div>
            </>
        )
    }
    return null
}

export default GoWriteDashboard;