import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { navigate } from '@reach/router';
import { useTranslation, Trans } from 'react-i18next';

import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { RESET_PASSWORD_MUTATION } from '../services/graphql';

const ForgotPassword = ({ closeModal, invalid_token }) => {

    const { t } = useTranslation(['profile', 'common']);

    const [formErrors, setFormErrors] = useState([]);
    const tokenError = invalid_token && invalid_token[0].toLowerCase().includes("token");
    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.resetPassword.errors && data.resetPassword.errors.length !== 0) {
                setFormErrors(data.resetPassword.errors);
                return;
            }
            if (data.resetPassword.reset) {
                navigate('/reset_sent')
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().required(t('enter_your_email'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        resetPassword({
            variables: {
                email: values.email
            }
        })
    };

    return(
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!tokenError && 
                    <>
                        <h1 className='light u-text-primary u-mb-2 auth-page__title'>{t('reset_my_password')}</h1>
                        <p className='u-text-center u-m-base-2'>{t('happens_to_all_enter_email_reset')}</p>
                    </>
                }              
                {(invalid_token && tokenError) ?
                <>
                    <h1 className='light u-text-primary u-mb-2 auth-page__title'>{t('oops')}</h1>
                    <p className="u-text-center u-smaller-text u-m-base-2">{t('click_most_recent_activation_link')}</p>
                    <p className="u-text-center u-smaller-text u-m-base-2">
                        <Trans i18nKey="profile:got_questions_contact_us_at_email_or_visit_fb">
                            If you have any questions contact us at <a href="mailto:support@boomhub.app">{{contactEmail: 'support@boomhub.app'}}</a> or visit {{pageName: 'UKGoApps'}} on Facebook.
                        </Trans>
                    </p>
                </>
                :
                <>
                    {invalid_token && <FormErrors errors={invalid_token} />}
                </>
                }
                <div className='basic-form__group'>
                    <input
                        name='email'
                        placeholder={t('common:email')}
                        className='basic-form__text-box'
                        type='email'
                        ref={register}
                    />
                    {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
                </div>
                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}

                <Button className='u-m-right-1 u-m-top-2' disabled={loading} type='submit'>{loading ? t('common:loading') : t('send_link')}</Button>
                <Button className='u-m-top-2' type='button' onClick={() => closeModal()} outline>{t('common:button.cancel')}</Button>
            </form>
        </Fragment>
    )
}

export default ForgotPassword;
