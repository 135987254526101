import React, { Fragment, useContext, useState } from 'react';
import { Link, navigate } from '@reach/router';
import ls from 'local-storage';
import { ArrowRight, Lock, Settings, XCircle } from 'react-feather';
import ImageFadeIn from 'react-image-fade-in';
import cx from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { GET_READ_SUBSCRIPTIONS } from '../GoReadSettings/services/graphql';
import ButtonLink from '../../components/ButtonLink';

import { APP_NAME, APP_NAME_US, MATH_SUB_STATUS, RENEWAL_STATUS } from '../../services/constants';
import bwLogo from '../../images/boomwriter_reversed.png'
import brLogo from '../../images/boomreader_reversed.png'
import helpDesk from '../../images/dashboard/helpdesk.png';
import dataDashImg from '../../images/dashboard/data-dashboard.png';
import { AuthContext } from '../../services/auth/AuthProvider';
import styles from './Dashboard.module.scss';
import SchoolAcceptPromotion from '../Onboarding/components/SchoolAcceptPromotion';
import surveyImg from '../../images/survey.png';
import SchoolProfileLink from './components/SchoolProfileLink';
import PendingStaffRequests from './components/PendingStaffRequests';
import SetupPrompts from '../../components/SetupPrompts';
import PromoBanners from './PromoBanners';
import BeeBanner from './components/BeeBanner';

import CheckReadRenewal from './components/CheckReadRenewal';
import changeYear from '../../images/dashboard/change-years.png';
import CheckWondeAccessStatus from '../GoReadSettings/components/PurchaseDataDashboardAccess/CheckWondeAccessStatus';

const Dashboard = () => {
    const token = ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [acceptPromo, setAcceptPromo] = useState(null);

    const [showChangeYear, setShowChangeYear] = useState(false);

    const { t } = useTranslation(['dashboard', 'common']);

    const { data } = useQuery(GET_READ_SUBSCRIPTIONS, {
        variables: {
            schoolId: currentUser.school.id
        }
    });


    const now = moment();
    const mathSub = currentUser?.school?.boomMathSubscription;

    const mathSubAccessEnds = mathSub && moment(mathSub.expiresAt.substring(0, 10)).diff(now, 'days');


    let dateUntilAccessEnds;
    let sub;
    let renewal;

    if (data && data.school) {
        const { schoolSubscriptions: subscriptions } = data.school;
        const readSubscriptions = subscriptions.filter(s => s.app && s.app.name === 'BoomReader');
        const orderedSubs = [...readSubscriptions].sort((a, b) => a.expiresAt.localeCompare(b.expiresAt));
        sub = ([...orderedSubs].pop())
        if (sub) {
            dateUntilAccessEnds = now.diff(moment(sub.expiresAt.substring(0, 10)), 'days');
        }
    }

    const renderReadBtnText = () => {
        if (!sub) {
            return 'Start Your Trial'
        }
        if (dateUntilAccessEnds > 0) {
            return sub.isTrial ? 'Start Subscription' : 'Restart Subscription'
        }
        return 'Settings'
    }

    const renderRenewalBanner = () => {
        if (sub) {
            renewal = (sub.renewal?.status === RENEWAL_STATUS.new && sub.renewal) || (sub.secondYearRenewal?.status === RENEWAL_STATUS.new && sub.secondYearRenewal);
            // console.log('renewal', renewal);
            const renewalDate = renewal && now.diff(moment(renewal.toBeProcessedAt, 'YYYY-MM-DD'), 'days');
            if (renewal && !renewal.editedBy && renewalDate >= -60 && renewalDate < 0) {
                return <CheckReadRenewal renewal={renewal} sub={sub} />
            }
        }
        return null;
    }

    const showSubscriptionBanner = () => {
        if (dateUntilAccessEnds > 14) {
            return (
                <div className={styles.subPanel}>
                    <p className="u-bold">{sub.isTrial ? 'Trial' : 'Subscription'} Expired.</p>
                    {sub.isTrial ?
                        <p className="u-m-base-1">To continue using BoomReader please start your subscription.</p>
                        :
                        <p className="u-m-base-1">Renew now to keep your existing renewal date with no loss of access.</p>
                    }
                    <ButtonLink to='/settings/boomreader/purchase'>{sub.isTrial ? 'Start Subscription' : 'Renew Now'}</ButtonLink>
                </div>
            )
        }
        if (dateUntilAccessEnds >= 0 && dateUntilAccessEnds < 14) {
            const accessEnds = now.diff(moment(sub.expiresAt.substring(0, 10)).add(14, 'days'), 'days');
            return (
                <div className={styles.subPanel}>
                    <p className="u-bold">Your {sub.isTrial ? 'trial' : 'subscription'} has expired.<br />{sub.isTrial ? 'Start subscription' : 'Renew Now'} within {accessEnds.toString().substring(1)} days to keep access.</p>
                    {sub.isTrial ?
                        <p className="u-m-base-1">To continue using BoomReader please start your subscription.</p>
                        :
                        <p className="u-m-base-1">Renew now to keep your existing renewal date with no loss of access.</p>
                    }
                    <div className={styles.subPanelAction}>
                        <div className={styles.subPanelBtn}>
                            <ButtonLink to='/settings/boomreader/purchase' state={{ renew: !sub.isTrial }}>{sub.isTrial ? 'Start Subscription' : 'Renew Now'}</ButtonLink>
                        </div>
                    </div>
                </div>
            )
        }

        if (dateUntilAccessEnds >= -14 && dateUntilAccessEnds < 0 && sub.isTrial) {
            return (
                <div className={styles.subPanel}>
                    <p className="u-bold">Your  trial ends in {dateUntilAccessEnds.toString().substring(1)} days</p>
                    <p className="u-m-base-1">To continue using BoomReader please start your subscription.</p>
                    <div className={styles.subPanelAction}>
                        <div className={styles.subPanelBtn}>
                            <ButtonLink to='/settings/boomreader/purchase' state={{ renew: !sub.isTrial }}>Start Subscription</ButtonLink>
                        </div>
                    </div>
                </div>
            )
        }
        // if (dateUntilAccessEnds >= -14 && dateUntilAccessEnds < 0) {
        //     return (
        //         <div className={styles.subPanel}>
        //             <p className="u-bold">Your {sub.isTrial ? 'trial' : 'subscription'} ends in {dateUntilAccessEnds.toString().substring(1)} days</p>
        //             {sub.isTrial &&
        //                 <p className="u-m-base-1">To continue using BoomReader please start your subscription.</p>
        //                 :
        //                 <p className="u-m-base-1">Renew now to keep your existing renewal date with no loss of access.</p>
        //             }
        //             <div className={styles.subPanelAction}>
        //                 <div className={styles.subPanelBtn}>
        //                     <ButtonLink to='/settings/boomreader/purchase' state={{ renew: !sub.isTrial }}>{sub.isTrial ? 'Start Subscription' : 'Renew Now'}</ButtonLink>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // }
        return null;
    }


    // const renderReadBanner = () => {
    //     const hasJoinedRGT = currentUser && currentUser.school && currentUser.school.acceptedPromotions && currentUser.school.acceptedPromotions.length > 0 && currentUser.school.acceptedPromotions.find(p => p === PROMO_CODES.goReadGrandTour);
    //     if (!currentUser.school.canAccessGoReadApp && currentUser.isSchoolAdmin) {
    //         return (
    //             <div className={styles.appBannerRead}>
    //                 <div className={styles.appBannerReadMain}>
    //                     <h3 className='heavy u-m-base-0'>Join the Grand Tour for a free guided trial</h3>
    //                 </div>
    //                 <ImageFadeIn className={styles.appBannerReadImg} src={grandTourLogo} alt='Grand tour image' />
    //             </div>
    //         )
    //     }
    //     if (currentUser.school.canAccessGoReadApp && hasJoinedRGT && sub?.isTrial) {
    //         return (
    //             <a href={getHelpLink() + '/grand-tour'} target='blank' rel='noopener noreferrer' className={styles.appBannerRead}>
    //                 <div className={styles.appBannerReadMain}>
    //                     <h3 className='heavy u-m-base-0'>Find all the Grand Tour information here</h3>
    //                 </div>
    //                 <ImageFadeIn className={styles.appBannerReadImg} src={grandTourLogo} alt='Grand tour image' />
    //             </a>
    //         )
    //     }
    //     return null
    // }

    const DashboardContent = () => {

        return (
            <Fragment>
                {currentUser.isSchoolAdmin && renderRenewalBanner()}
                {currentUser.isSchoolAdmin && currentUser.school.newYearStep < 99 && !currentUser.school.canAccessWonde &&
                    <div className={styles.changeYearBanner} onClick={() => navigate('/newAcademicYear')}>
                        <div className={styles.changeYearBannerMain}>
                            <h2 className='u-m-base-0 heavy'>Set up your 2024/25 classes and students.</h2>
                        </div>
                        <div className={styles.changeYearBannerSide}>
                            <ArrowRight color="#FFF" />
                        </div>
                    </div>
                }
                {currentUser.school?.wondeAccessApprovedAt && (
                    <div className={styles.wondeWarning}>
                        <p>Wonde integration means that all teacher and pupil management takes place within your MIS. This means that you won't see the Manage Pupils tab any more as this is handled by Wonde. Wonde will sync any updates to the MIS overnight</p>
                    </div>
                )}

                {currentUser.isSchoolAdmin && currentUser.school?.canAccessGoReadApp && currentUser.school?.wondePreapproved && !currentUser.school?.wondeAccessApprovedAt && (
                    <div className={styles.wondePreApprovedBanner} onClick={() => navigate('/settings/boomreader')}>
                        <div className={styles.wondePreApprovedBannerMain}>
                            <h2 className='u-m-base-0 heavy'>Click here to continue your Wonde setup</h2>
                        </div>
                        <div className={styles.wondePreApprovedBannerSide}>
                            <ArrowRight color="#FFF" />
                        </div>
                    </div>
                )}
                <div className={styles.dashboardWrapper}>
                    <div className={styles.dashboardMain}>
                        {ls('country') === 'US' ? <>
                            <div className={styles.app}>
                                {currentUser.school.canAccessBoomMathApp ? (
                                    <a className={cx(styles.appLink, styles.appLinkWrite)} href={`${getUrl(APPS.boom_math_teacher)}${ls('go_impersonating') ? '/impersonate' : ''}?token=${token}`}>
                                        BoomMath
                                        <span className={styles.appLinkIcon}><ArrowRight /></span>
                                    </a>) : (
                                    <span className={cx(styles.appLink, styles.appLinkDisabled)}>
                                        BoomMath
                                        <span className={styles.appLinkIcon}><Lock /></span>
                                    </span>
                                )}
                                <div className={styles.appExtra}>
                                    {mathSub.isTrial && mathSub.status !== MATH_SUB_STATUS.cancelled && mathSub.status !== MATH_SUB_STATUS.cancelled_renewal &&
                                        <div className={cx(styles.appInfo)}>
                                            {mathSubAccessEnds >= 0 ? <>  {mathSubAccessEnds} days free trial remaining</> :
                                                'Trial Expired'
                                            }
                                        </div>
                                    }
                                    {currentUser.isSchoolAdmin &&
                                        <Link to='/settings/boommath' className={cx(styles.appSettings)}>
                                            <Settings size="28" className='u-m-right-2' /> {t('settings')}
                                        </Link>
                                    }
                                </div>
                            </div>
                        </>
                            :
                            <>
                                <div className={styles.app}>
                                    {
                                        currentUser.school.canAccessGoReadApp ? (
                                            <a className={styles.appLink} href={`${getUrl(APPS.go_read_teacher)}${ls('go_impersonating') ? '/impersonate' : ''}?token=${token}`}>
                                                <ImageFadeIn src={brLogo} className={styles.appLinkImg} />
                                                <span className={styles.appLinkIcon}><ArrowRight /></span>
                                            </a>
                                        ) : (
                                            <span className={cx(styles.appLink, styles.appLinkDisabled)}>
                                                <ImageFadeIn src={brLogo} className={cx(styles.appLinkImg)} />
                                                <span className={styles.appLinkIcon}><Lock /></span>
                                            </span>
                                        )
                                    }

                                    <div className={styles.appExtra}>
                                        <a href="https://boomhub.freshdesk.com/support/home" target="blank" rel='noopener noreferrer' className={cx(styles.appHelp)}>
                                            <div>
                                                <p>New to BoomReader?</p>
                                                <p className='small'>Take a tour</p>
                                            </div>
                                            <ImageFadeIn src={helpDesk} className={styles.appHelpImg} />
                                        </a>
                                        {currentUser.isSchoolAdmin &&
                                            <Link to='/settings/boomreader' className={cx(styles.appSettings)}>
                                                <Settings size="28" className='u-m-right-2' />{data && data.school && renderReadBtnText()}
                                            </Link>
                                        }
                                    </div>



                                    <CheckWondeAccessStatus dashBanner={true} currentUser={currentUser} setCurrentUser={setCurrentUser} />

                                </div>

                                <div className={styles.app}>
                                    <a className={cx(styles.appLink, styles.appLinkWrite)} href={`${getUrl(APPS.go_write_teacher)}${ls('go_impersonating') ? '/impersonate' : ''}?token=${token}`}>
                                        <ImageFadeIn src={bwLogo} className={styles.appLinkImg} />
                                        <span className={styles.appLinkIcon}><ArrowRight /></span>
                                    </a>

                                    {currentUser.school.country?.code === 'IND' ?
                                        <BeeBanner currentUser={currentUser} />
                                        :
                                        <PromoBanners />
                                    }

                                    <div className={styles.appExtra}>
                                        <a href="https://boomhub.freshdesk.com/support/home" target="blank" rel='noopener noreferrer' className={cx(styles.appHelp, styles.appHelpWrite)}>
                                            <div>
                                                <p>New to BoomWriter?</p>
                                                <p className='small'>Take a tour</p>
                                            </div>
                                            <ImageFadeIn src={helpDesk} className={styles.appHelpImg} />
                                        </a>
                                        {currentUser.isSchoolAdmin &&
                                            <Link to='/settings/boomwriter' className={cx(styles.appSettings, styles.appSettingsWrite)}>
                                                <Settings size="28" className='u-m-right-2' /> {t('settings')}
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>


                    <div className={styles.dashboardSide}>
                        <SchoolProfileLink />
                        {currentUser.isSchoolAdmin && <PendingStaffRequests school={currentUser?.school} />}

                        {data && data.school && currentUser.isSchoolAdmin && showSubscriptionBanner()}

                        <>
                            {currentUser.isSchoolAdmin ?
                                <Link className={styles.subPanel} to='/settings/boomreader'>
                                    {currentUser.school.canAccessDataDashboard ?
                                        <h3>Data dashboard</h3> :
                                        <h3>Click here to setup your Data dashboard</h3>
                                    }
                                    <ImageFadeIn src={dataDashImg} alt='' />
                                    <p className='small u-m-top-1'>A graphical representation of whole-school reading, enabling year group and class breakdown with the ability to track specific groups and individual students</p>
                                </Link>
                                :
                                <a href="https://www.boomreader.co.uk/features" target='blank' className={styles.subPanel} rel='noopener noreferrer'>
                                    <h3>Data dashboard</h3>
                                    <ImageFadeIn src={dataDashImg} alt='' />
                                    <p className='small u-m-top-1'>A graphical representation of whole-school reading, enabling year group and class breakdown with the ability to track specific groups and individual students</p>
                                </a>
                            }
                        </>

                        {currentUser.school.canAccessGoReadApp && (
                            <div className={styles.surveyAdd}>

                                <div className={styles.surveyAddMain}>
                                    <p className='lead heavy'>Reading for Pleasure
                                        Survey</p>
                                    <p className='small'>Our Reading for Pleasure Survey is now complete, thank you for taking part. We're currently working on the data and will present the findings here for you soon.</p>
                                </div>

                                <div className={styles.surveyAddIcon}>
                                    <ImageFadeIn src={surveyImg} alt='Survey icon' />
                                </div>
                            </div>
                        )}


                        {
                            currentUser.isSchoolAdmin && ((currentUser.school.teachers && currentUser.school.teachers.length <= 1) || currentUser.school.classes.length === 0) && (
                                <div className='card card-mobile u-width-full'>
                                    <SetupPrompts school={currentUser.school} />
                                </div>
                            )
                        }

                    </div>


                </div>
            </Fragment>
        )
    }


    if (acceptPromo) {
        return <SchoolAcceptPromotion currentUser={currentUser} setCurrentUser={setCurrentUser} close={() => setAcceptPromo(null)} promo={acceptPromo} afterOnboarding={true} />
    }

    if (showChangeYear) {
        return (
            <div className='card card-mobile'>
                <div className={styles.changeYearHeader}>
                    <div>
                        <p className='heavy'>Coming soon</p>
                        <h2 className='u-m-base-0 heavy'>New school year setup wizard will launch soon!</h2>
                    </div>
                    <button onClick={() => setShowChangeYear(false)} className='btn-reset'><XCircle size={30} /></button>
                </div>
                <div className={styles.changeYearInfo}>
                    <div className={styles.changeYearInfoMain}>
                        <p className='heavy u-m-base-3'>It’s getting to that time of year again!</p>
                        <p className='u-m-base-2'>We'll soon be launching an easy-to-follow wizard that will help you move your children up a year group and set up their new registration classes.</p>
                        <p className='u-m-base-2'>We'll be making it easier than ever - all you'll need is a spreadsheet of your new cohort and if you mix up your classes each year the list of classes and pupils.</p>
                        <p>Keep an eye out here for more details!</p>
                    </div>
                    <div>
                        <ImageFadeIn src={changeYear} alt='Change year' />
                    </div>
                </div>

            </div>
        )
    }

    return (
        <Fragment>
            <div className={styles.dashboard}>
                <DashboardContent />
            </div>
        </Fragment>
    )
}

export default Dashboard;