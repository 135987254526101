import React, { Fragment} from 'react';
import { PlusCircle, Users, Book } from 'react-feather';
import cx from 'classnames';
import styles from './PackOffers.module.scss';
import { PACK_EXTRA_COPY_PRICE } from '../../../../../../services/constants';
import { packStudentsRange } from '../../../../services/utils'

const PackOffers = ({ packOffers, setPackOffers, selectPack }) => {

    const updatePackOfferStudents = (value, i, classIdx) => {
        let offers = [...packOffers];
        const newStudents = value - offers[i].classes[classIdx].students;
        const newPrice = offers[i].price + newStudents * PACK_EXTRA_COPY_PRICE;

        offers[i].classes[classIdx].students = +value;
        offers[i].price = newPrice;
        setPackOffers(offers);
    }


    return(
        <Fragment>
            <h1>Add pack</h1>
            <p className='u-m-base-2'><b>Extra pupils can be added at just £{PACK_EXTRA_COPY_PRICE}/each</b></p>
            <ul className={styles.packList}>
                {
                    packOffers.map((packOffer, i) => {
                        return (
                            <li key={`po-${packOffer.id}`} className={styles.pack}>
                                <div className={styles.packHead}>
                                    <p>{packOffer.name}</p>
                                    <div className={styles.packAdd}>
                                        £{packOffer.price.toFixed(2)} 
                                        <button
                                            className={cx('btn-reset', styles.packAddBtn)}
                                            type="button"
                                            onClick={() => selectPack(packOffer)}
                                        ><PlusCircle size="20"/></button>
                                    </div>
                                </div>
                                {
                                    packOffer.instances === 1 && (
                                        <ul className={styles.packMeta}>
                                            <li>
                                                <Users size="20" />
                                                <input 
                                                    onChange={e => updatePackOfferStudents(e.target.value, i, 0)}
                                                    onKeyDown={e => e.preventDefault(e)}
                                                    type="number"
                                                    defaultValue={packOffer.classes[0].students}
                                                    max={packStudentsRange(packOffer, false)}
                                                    min={packStudentsRange(packOffer, true)}
                                                    key={`${packOffer.id}`}
                                                    className={cx('basic-form__text-box', styles.packMetaInput)}
                                                />
                                            </li>
                                            <li>
                                                <Book size="20" />{ +packOffer.classes[0].students + 1}
                                            </li>
                                        </ul>
                                    )
                                } 
                            </li>
                        )
                    })
                }
            </ul>
        </Fragment>
    )
}

export default PackOffers;