import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, FormErrors, Loader } from "@axeedge/go-teacher-components";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { AuthContext } from "../../../../services/auth/AuthProvider";

import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { SEND_STAFF_INVITES } from "../../services/graphql";
import { getRoleLabel } from '../../../../services/utils';
import { Delete, XCircle } from "react-feather";
import { UPDATE_SCHOOL_SETUP_STEP, GET_TEACHERS_QUERY } from "../../../../services/school/graphql";
import { GET_CLASSES_LIST_QUERY } from "../../../Classes/services/graphql";
import { SCHOOL_SETUP_STEPS } from "../../../../services/constants";
import { usaOnlyRoles } from "../../../../services/utils";
import cx from 'classnames';
import styles from './StaffAccess.module.scss';

const StaffAccess = ({ isOnboarding = false, close }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [staff, setStaff] = useState([]);
    const [staffRoleError, setStaffRoleError] = useState(null);

    const staffInput = useRef(null);
    const checkEmail = email => /^\S+@\S+\.\S+$/.test(email);

    const { data, loading, error } = useQuery(GET_CLASSES_LIST_QUERY, {
        variables: {
            schoolId: currentUser?.school.id
        }
    });

    useEffect(() => {
        if (!staff.find(el => !el.roleId) && staffRoleError) {
            setStaffRoleError(null);
        }
    }, [staff, staffRoleError])


    const [updateSchoolSetupStep, { loading: updatingStep }] = useMutation(UPDATE_SCHOOL_SETUP_STEP, {
        update: (_, { data }) => {
            if ((data && data.updateSchoolSetupStep.errors && data.updateSchoolSetupStep.errors.length > 0)) {
                setFormErrors(data.updateSchoolSetupStep.errors);
                return;
            }
            if (data?.updateSchoolSetupStep?.school?.id) {
                const cu = { ...currentUser, school: data.updateSchoolSetupStep.school }
                setCurrentUser(cu);
            }
        }
    });

    const staffInvitesQueries = () => {
        if (!isOnboarding) {
            return [{
                query: GET_TEACHERS_QUERY,
                variables: {
                    schoolCode: currentUser?.school.schoolCode,
                    includeInvited: true
                }
            }];
        }
        return [];
    }

    const [sendStaffInvites, { loading: sendingInvites }] = useMutation(SEND_STAFF_INVITES, {
        update: (_, { data }) => {
            if (data && data.sendStaffInvites.errors && data.sendStaffInvites.errors.length > 0) {
                setFormErrors(data.sendStaffInvites.errors);
                return;
            }
            if (data && data.sendStaffInvites.teachers && data.sendStaffInvites.teachers.length > 0) {
                if (isOnboarding) {
                    updateSchoolSetupStep({
                        variables: {
                            schoolId: currentUser?.school.id,
                            step: SCHOOL_SETUP_STEPS.finalStepDone
                        }
                    });
                } else {
                    close();
                }
            }
        },
        refetchQueries: () => staffInvitesQueries()
    })

    const onSelectStaff = e => {
        if (selectedStaff.indexOf(e.target.value) !== -1) {
            setSelectedStaff(selectedStaff.filter(t => t !== e.target.value));
        } else {
            setSelectedStaff([...selectedStaff, e.target.value]);
        }
    }

    const onSelectAllStaff = e => {
        const selectedIds = staff.map(t => t.id);
        e.target.checked ? setSelectedStaff(selectedIds) : setSelectedStaff([])
    }


    const onChangeField = (field, val, i) => {
        const updatedStaff = [...staff];
        updatedStaff[i][field] = field === 'roleId' ? +val : val;
        setStaff(updatedStaff);
    }

    const onChangeFieldForAll = (field, val) => {
        const value = field === 'roleId' ? +val : field === 'isAdmin' ? Boolean(+val) : val;
        const updatedStaff = staff.map(el => {
            return selectedStaff.find(t => t === el.id) ? { ...el, [field]: value } : el
        });
        setStaff([...updatedStaff]);
    }

    const removeTeacher = i => {
        const updatedStaff = [...staff];
        updatedStaff.splice(i, 1);
        setStaff(updatedStaff)
    }


    const addStaffEmail = () => {
        const val = staffInput.current.value.toLowerCase();
        if (!val || val.indexOf('@') === -1 || val.indexOf('.') === -1) {
            return;
        }
        //replace newlines with commas & trim whitespace
        const arr = val.replace(/\r?\n/g, ",").split(',').map(el => el.replace(/\s/g, ''));
        const uniqArr = [...new Set(arr)];
        const newStaff = [];
        uniqArr.forEach((el, i) => {
            if (checkEmail(el)) {
                newStaff.push({
                    id: i + '' + Date.now(),
                    email: el,
                    roleId: null,
                    isAdmin: false,
                    leadClass: null
                });
            }
        });
        setStaff([...staff, ...newStaff]);
        staffInput.current.value = null;
    };


    const sendInvites = () => {
        if (staff.find(el => !el.roleId)) {
            setStaffRoleError(true);
            return;
        }
        const staffDetails = [];
        staff.forEach(el => {
            staffDetails.push({
                email: el.email,
                roleId: el.roleId,
                isSchoolAdmin: el.isAdmin,
                studentsClassId: el.leadClass
            });
        });
        sendStaffInvites({
            variables: {
                schoolId: currentUser?.school?.id,
                staffDetails: staffDetails
            }
        });
    }

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.school) {

        const { school: { classes } } = data;

        return (
            <>
                {isOnboarding ? (
                    <>
                        <h1>Staff Access and Invites</h1>
                        <p>Send invites to your staff - you will be able to add volunteers and others at a later date.</p>
                        <p>For each choose their job role, admin access and, where appropriate, the main class they manage or teach. You’ll be able to connect teachers to other classes later.</p>
                        <p className="u-m-base-2">Paste in a list of email addresses (or enter them one at a time)</p>
                    </>
                ) : (
                    <>
                        <div className="u-display-flex u-align-center u-m-base-2">
                            <h3>Send  invites to staff your staff</h3>
                            <button className="btn-reset u-m-left-auto" onClick={close}><XCircle /></button>
                        </div>
                        <p>For each choose their job role, admin access and, where appropriate, the main class they manage or teach.</p>
                        <p className="u-m-base-2">Paste in a list of email addresses (or enter them one at a time)</p>
                    </>
                )}

                {staff.length > 0 && (
                    <>
                        <ul className='u-m-base-2'>
                            <li className={cx(styles.staffItem, styles.staffItemHeader)}>
                                <div className={styles.staffItemMain}>
                                    <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                        <input
                                            name="all"
                                            className="basic-form__check-box"
                                            type="checkbox"
                                            checked={selectedStaff.length === staff.length}
                                            onChange={(e) => onSelectAllStaff(e)}
                                            id="selectAll"
                                        />
                                        <label className="basic-form__check-label" htmlFor='selectAll'>All / None</label>
                                    </div>
                                </div>
                                <div className={styles.staffItemActions}>
                                    <div className={styles.staffItemActionsItem}>
                                        <select value='' disabled={selectedStaff.length === 0} onChange={(e) => onChangeFieldForAll('isAdmin', e.target.value)} name="adminAll" className={cx("basic-form__text-select", styles.select)}>
                                            <option value=''>Admin</option>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </select>
                                    </div>

                                    <div className={styles.staffItemActionsItem}>
                                        <select value='' disabled={selectedStaff.length === 0} onChange={(e) => onChangeFieldForAll('roleId', e.target.value)} name="roleAll" className={cx("basic-form__text-select", styles.select)}>
                                            <option value=''>Role</option>
                                            {Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                                return (<option key={`roleall-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                            })}
                                        </select>
                                    </div>
                                    {classes.length > 0 &&
                                        <div className={styles.staffItemActionsItem}>
                                            <select value='' disabled={selectedStaff.length === 0} onChange={(e) => onChangeFieldForAll('leadClass', e.target.value)} name="classAll" className={cx("basic-form__text-select", styles.select)}>
                                                <option value=''>Lead Class</option>
                                                {classes.sort((a, b) => a.name.localeCompare(b.name)).map(kls => (
                                                    <option key={`kls-${kls.id}`} value={kls.id}>{kls.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                </div>
                            </li>

                            {staff.map((teacher, i) => (
                                <li key={`staff-${teacher.id}`} className={styles.staffItem}>
                                    <div className={styles.staffItemMain}>
                                        <div className="basic-form__group basic-form__group--check u-m-base-0">
                                            <input
                                                type='checkbox'
                                                className="basic-form__check-box"
                                                value={teacher.id}
                                                onChange={(e) => onSelectStaff(e)}
                                                id={`t-${teacher.id}`}
                                                checked={selectedStaff.indexOf(teacher.id) > -1}
                                            />
                                            <label className="basic-form__check-label" htmlFor={`t-${teacher.id}`}>&nbsp;</label>
                                        </div>
                                        {teacher.email}
                                    </div>
                                    <div className={styles.staffItemActions}>
                                        <div className={cx(styles.staffItemActionsItem, "basic-form__group basic-form__group--check u-m-base-0")}>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => onChangeField('isAdmin', e.target.checked, i)}
                                                className={cx("switchToggle", styles.switchAdminToggle)}
                                                name='admin'
                                                id={`admin-${i}`}
                                                checked={teacher.isAdmin}
                                            />
                                            <label className={cx("switchLabel", styles.switchAdminLabel)} htmlFor={`admin-${i}`}><span>Admin</span></label>
                                        </div>
                                        <div className={styles.staffItemActionsItem}>
                                            <select value={teacher.roleId || ''} onChange={(e) => onChangeField('roleId', e.target.value, i)} name="roleId" className={cx("basic-form__text-select", styles.select)}>
                                                <option value=''>Role</option>
                                                {Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                                    return (<option key={`role-${role[1]}`} value={+role[1]}>{getRoleLabel(role[1])}</option>)
                                                })}
                                            </select>
                                        </div>

                                        {classes.length > 0 &&
                                            <div className={styles.staffItemActionsItem}>
                                                <select value={teacher.leadClass || ''} onChange={(e) => onChangeField('leadClass', e.target.value, i)} name="classId" className={cx("basic-form__text-select", styles.select)}>
                                                    <option value=''>Lead Class</option>
                                                    {classes.sort((a, b) => a.name.localeCompare(b.name)).map(kls => (
                                                        <option key={`kls-${kls.id}`} value={kls.id}>{kls.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        <button onClick={() => removeTeacher(i)} className={cx("btn-reset", styles.removeStaffBtn)}><Delete /> </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {staffRoleError && <p className="basic-form__hint u-m-top-2">Please select a role for each member of the staff!</p>}

                <p className="small">Email addresses</p>
                <div className={styles.addStaffEmails}>
                    <textarea
                        ref={staffInput}
                        type='text'
                        className="basic-form__text-box"
                    ></textarea>
                    <Button onClick={() => addStaffEmail()}>Add</Button>
                </div>

                <div className="u-m-top-2 u-m-base-2">
                    <Button disabled={staff.length === 0 || sendingInvites || updatingStep} onClick={() => sendInvites()}>Send invites & finish</Button>
                    {isOnboarding &&
                        <button disabled={updatingStep} onClick={() => updateSchoolSetupStep({
                            variables: {
                                schoolId: currentUser?.school.id,
                                step: SCHOOL_SETUP_STEPS.finalStepDone
                            }
                        })} className="btn-reset link-underline u-m-left-2">{updatingStep ? 'Saving...' : 'I\'ll do this later'}</button>
                    }
                </div>
                {formErrors.length > 0 && <FormErrors errors={formErrors} />}
            </>
        )
    }

    return null;
}

export default StaffAccess;