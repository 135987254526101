import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { REGENERATE_LOGIN_CODE } from '../../services/graphql';
import { RefreshCw } from 'react-feather';
import { useTranslation } from 'react-i18next';

const LoginCode = ({ student, closeModal, setShowEditStudentModal, fromEditModal }) => {

    const [formErrors, setFormErrors] = useState([]);

    const { t } = useTranslation(['classes', 'common']);

    const [showRefresh, setShowRefresh] = useState(fromEditModal);
    const [codeRefreshed, setCodeRefreshed] = useState(null);

    const [regenerateStudentLoginCode, { loading }] = useMutation(REGENERATE_LOGIN_CODE, {
        update: (_, { data }) => {
            if (data.regenerateStudentLoginCode.errors && data.regenerateStudentLoginCode.errors.length !== 0) {
                setFormErrors(data.regenerateStudentLoginCode.errors);
                return;
            }
            if (data.regenerateStudentLoginCode.student.id) {
                setShowRefresh(false);
                setCodeRefreshed(data.regenerateStudentLoginCode.student)
            }  
        }      
    });

    if (showRefresh) {
        return(
            <Fragment>
                <h1>{t('refresh_login_code')}</h1>
                <p className='u-m-base-3'>{t('well_generate_new_login_code_for_pupil', {name: student.name})}</p>
                <Button
                    onClick={() => regenerateStudentLoginCode({
                        variables: {
                            studentId: student.id
                        }
                    })}
                >{loading ? t('common:please_wait') : t('refresh_login_code')}</Button>
                <Button
                    outline
                    className='u-m-left-2'
                    onClick={() => {
                        closeModal();
                        setShowRefresh(false);
                        setCodeRefreshed(null);
                        fromEditModal && setShowEditStudentModal(student);
                    }}
                >{t('common:button.cancel')}</Button>
                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
            </Fragment>
        )
    }

    if (codeRefreshed) {
        return(
            <Fragment>
                <h1 className='u-m-base-2'>{t('login_code_refreshed')}</h1>
                <p className='u-m-base-2'>{t('pass_on_new_code_reprint_login_sheet', {name: codeRefreshed.name})}:</p>
                <h2 className='u-m-base-3'>{t('new_login_code')}: <b className='heavy'>{codeRefreshed.loginCode}</b></h2>
                <Button
                    outline
                    onClick={() => {
                        closeModal();
                        fromEditModal && setShowEditStudentModal({...student, loginCode: codeRefreshed.loginCode});
                        setCodeRefreshed(null);
                        setShowRefresh(false);
                    }}
                >{t('common:button.done')}</Button>
            </Fragment>
        )
    }

    return(
        <Fragment>
            <h1 className='title u-m-base-2'>{student.name}</h1>
            <p className='u-display-flex u-align-center u-m-base-2'>
                {t('login_code')}: <strong className='u-m-left-1'>{student.loginCode}</strong>
                <button
                    onClick={() => setShowRefresh(true)}
                    className='btn-reset u-text-tertiary u-m-left-2 icon-title'
                ><RefreshCw size='16' className='u-m-right-1' /> {t('refresh')}</button>
            </p>
        </Fragment>
    )
}

export default LoginCode;