import React, { useEffect } from "react";
import { CHECK_WONDE_ACCESS_STATUS } from "../../services/graphql";
import { useLazyQuery } from "@apollo/react-hooks";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import ButtonLink from "../../../../components/ButtonLink";
import { Link } from "@reach/router";
import {
    Tooltip,
} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import { RefreshCw } from "react-feather";
import ImageFadeIn from "react-image-fade-in";
import wondeLogo from '../../../../images/dashboard/wonde-logo.png';
import styles from './CheckWondeStatus.module.scss';
import cx from 'classnames';

const CheckWondeAccessStatus = ({ currentUser, setCurrentUser, dashBanner }) => {

    const [checkWondeStatus, { loading }] = useLazyQuery(CHECK_WONDE_ACCESS_STATUS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data?.currentTeacher?.school?.wondeAccessApprovedAt) {
                const cu = { ...currentUser, school: { ...currentUser.school, wondeAccessApprovedAt: data.currentTeacher.school.wondeAccessApprovedAt } }
                setCurrentUser(cu);
            }
        }
    });


    useEffect(() => {
        if (!currentUser.school.wondeAccessApprovedAt && currentUser.school.wondeId) {
            checkWondeStatus();
        }
    }, []);

    return (
        <>

            {currentUser.isSchoolAdmin && currentUser.school.wondeAccessApprovedAt && currentUser.school.wondeSyncStep < WONDE_SYNC_STEPS.syncCompleted &&
                <div className={cx({ [styles.wondeBanner]: dashBanner })}>
                    {dashBanner ?
                        <div className="u-flex-center">
                            <ImageFadeIn width={100} src={wondeLogo} alt='Wonde logo' />
                            <Link className='heavy u-text-white u-m-left-2' to='/misUpdate'>Wonde request approved - {currentUser.school.wondeSyncStep === 0 ? 'Start sync' : 'Complete sync'}</Link>
                        </div>
                        : <>
                            <p className="heavy u-m-base-2">Your Wonde request has been approved.</p>
                            <ButtonLink className='heavy' to='/misUpdate'>{currentUser.school.wondeSyncStep === 0 ? 'Sync now' : 'Complete sync'}</ButtonLink>
                        </>
                    }
                </div>
            }

            {currentUser.isSchoolAdmin && !currentUser.school.wondeAccessApprovedAt && currentUser.school.wondeId &&
                <div className={cx({ [styles.wondeBanner]: dashBanner })}>
                    <div className='heavy u-flex-center u-justify-between'>
                        <div className="u-flex-center">
                            {dashBanner && <ImageFadeIn width={124} src={wondeLogo} alt='Wonde logo' />}
                            <span>Waiting for approval from Wonde </span>
                        </div>
                        <Tooltip
                            title="Check Status"
                            size='big'
                        >
                            <button disabled={loading} onClick={() => checkWondeStatus()} className="btn-reset u-flex-center"><RefreshCw /></button>
                        </Tooltip>
                    </div>
                </div>
            }

        </>
    )
}

export default CheckWondeAccessStatus;