import React, { useContext } from 'react';
import { Link } from '@reach/router';
import ImageFadeIn from 'react-image-fade-in';
import cx from 'classnames';

import { AuthContext } from '../../../../services/auth/AuthProvider';

import styles from './SchoolProfileLink.module.scss';
import schoolDefault from '../../../../images/school-default.svg';

const SchoolProfileLink = () => {
    const { currentUser } = useContext(AuthContext);

    return (
        <>
        <Link to='/settings/schoolProfile' className={cx(styles.schoolPanel, { [styles.schoolPanelDisabled]: !currentUser.isSchoolAdmin })}>
            <ImageFadeIn className={styles.schoolPanelImg} src={currentUser.school.avatarUrl || schoolDefault} />
            <div className={styles.schoolPanelText}>
                <p className='u-m-base-0'>{currentUser.school.name}</p>
            </div>
        </Link>
        <p className={styles.schoolPanelCode}>School code: {currentUser.school.schoolCode}</p>
        </>
    )
}

export default SchoolProfileLink;