import React from 'react';
import emailImg from '../../SignUp/images/confirm_email.png';
import logo from '../../../images/boomer.svg';
import ImageFadeIn from "react-image-fade-in";

import { Button } from '@axeedge/go-teacher-components';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

const ResetSent = () => {

    const { t } = useTranslation();

    return(
        <div className='auth-page__container'>
            <div className='auth-page__panel u-text-center'>
                <ImageFadeIn className='auth-page__logo' src={logo} />
                <h3 className='heavy u-m-y-3 u-text-center'>{t('we_sent_a_link')}</h3>
                <div className='auth-page__confirm-email'><img src={emailImg} alt='' /></div>
                <p className='heavy'>{t('didnt_get_the_link')}</p>
                <p className='u-m-base-2'>{t('check_spam_make_sure_you_typed_email_correctly')}</p>
                <Link to='/'><Button>{t('login')}</Button></Link>
            </div>
        </div>
    )
}

export default ResetSent;