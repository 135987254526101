import React, { useContext } from 'react';
import { AuthContext } from '../../../../services/auth/AuthProvider';
// import { useQuery } from '@apollo/react-hooks';
// import SchoolAcceptPromotion from '../../components/SchoolAcceptPromotion';
import { SCHOOL_SETUP_STEPS, SCHOOL_MATH_SETUP_STEPS } from '../../../../services/constants';
import StaffAccess from '../../../Teachers/components/StaffAccess';
import UploadClassesPupils from '../../../Classes/components/UploadClassesPupils';
// import { ACTIVE_PROMOTIONS } from '../../services/graphql';
// import { Loader } from '@axeedge/go-teacher-components';
import ProgressIndicator from '../../components/ProgressIndicator';
import ls from 'local-storage';
import AddMathTrialWithoutCard from '../../../Settings/scenes/AddMathTrialWithoutCard';

const PostOnboardingFlow = () => {

    const { currentUser } = useContext(AuthContext);
    const isUS = ls('country') === 'US';

    // const [promo, setPromo] = useState(null);
    // const [activePromos, setActivePromos] = useState([]);

    // const { data, loading, error } = useQuery(ACTIVE_PROMOTIONS);

    // useEffect(() => {
    //     if (data && data.activePromotions.length > 0) {
    //         setActivePromos(data.activePromotions);
    //     }
    // }, [data]);

    // useEffect(() => {
    //     if (activePromos.length > 0 && currentUser?.subscriptionIntent) {
    //         if (currentUser.subscriptionIntent === SUBSCRIPTION_INTENT.write && activePromos.find(p => p.code === PROMO_CODES.writeToRaise)) {
    //             !currentUser.school?.acceptedPromotions.find(p => p === PROMO_CODES.writeToRaise) && setPromo(activePromos.find(p => p.code === PROMO_CODES.writeToRaise));
    //         } else if (currentUser.subscriptionIntent === SUBSCRIPTION_INTENT.read && data.activePromotions.find(p => p.code === PROMO_CODES.goReadGrandTour)) {
    //             !currentUser.school?.acceptedPromotions.find(p => p === PROMO_CODES.goReadGrandTour) && setPromo(activePromos.find(p => p.code === PROMO_CODES.goReadGrandTour))
    //         }
    //     }
    // }, [activePromos, currentUser]);

    const renderUsSetupSteps = (school) => {
        if (school.setupStep < SCHOOL_MATH_SETUP_STEPS.classesStepDone) {
            return <UploadClassesPupils school={school} isOnboarding={true} />
        } else if (school.setupStep >= SCHOOL_MATH_SETUP_STEPS.classesStepDone && school.setupStep < SCHOOL_MATH_SETUP_STEPS.staffAccessDone) {
            return <StaffAccess school={school} isOnboarding={true} />
        } else {
            return <AddMathTrialWithoutCard school={school} isOnboarding={true} />
        }
    }



    if (currentUser) {
        const { school } = currentUser;
        return (
            <>
                <ProgressIndicator user={currentUser} />
                {
                    isUS ? renderUsSetupSteps(school) :
                        school.setupStep < SCHOOL_SETUP_STEPS.classesStepDone ?
                            <UploadClassesPupils school={school} isOnboarding={true} /> : <StaffAccess school={school} isOnboarding={true} />
                }

            </>
        )
    }
    return null;
}

export default PostOnboardingFlow;