import React, { useState } from "react";
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { useMutation } from "@apollo/react-hooks";
import { MERGE_STUDENTS, GET_YEAR_STATS, GET_FILTERED_STUDENTS, GET_UNASSIGNED_STUDENTS_QUERY } from '../services/graphql';
import { getYearGroupLabel } from "../../../services/utils";
import { CheckCircle, Circle } from "react-feather";

const MergeStudents = ({ stToMerge, close, clearStudents, currentYear, schoolId }) => {

    const [mergeInto, setMergeInto] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const getQueries = () => {
        if (currentYear === 'UPN') {
            return {
                schoolId,
                withoutUpn: true,
            }
        } else {
            return {
                schoolId,
                yearGroup: currentYear && currentYear.toString(),
            }
        }
    }

    const [mergeStudents, { loading }] = useMutation(MERGE_STUDENTS, {
        onCompleted: (data) => {
            if (data && data.mergeStudentAccounts.errors && data.mergeStudentAccounts.errors.length > 0) {
                setFormErrors(data.mergeStudentAccounts.errors)
                return;
            }
            if (data && data.mergeStudentAccounts && data.mergeStudentAccounts.merged) {
                clearStudents();
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId,
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId
                }
            }
        ],
    });

    return (
        <>
            <h2>Are you sure?</h2>
            <p>Select the account into which you want to merge the students:</p>
            {stToMerge.map(s => (
                <div onClick={() => setMergeInto(s.id)} key={`merge-${s.id}`} className="panel u-m-base-2 u-display-flex">
                    {s.id === mergeInto ? <CheckCircle size={20} className="u-text-primary u-m-top-1" /> : <Circle size={20} className="u-m-top-1" />}
                    <div className="u-m-left-2">
                        <p className="u-m-base-0">{s.firstName} {s.lastName} - Login code: {s.loginCode}</p>
                        {s.yearGroup && <p className="u-m-base-0">{getYearGroupLabel(s.yearGroup)}</p>}
                    </div>

                </div>
            ))}
            {formErrors && <FormErrors errors={formErrors} />}
            <Button disabled={!mergeInto || loading} onClick={() =>
                mergeStudents({
                    variables: {
                        student1Id: mergeInto,
                        student2Id: stToMerge.filter(s => s.id !== mergeInto)[0]?.id,
                    }
                })} className='u-m-right-2'>Merge</Button>
            <Button outline onClick={close} className='u-m-right-2'>Cancel</Button>
        </>
    )
}

export default MergeStudents;