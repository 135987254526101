import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import PageHeader from "../../../../components/PageHeader";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { WONDE_CLASSES_DATA } from "../../services/graphql";
import WondeClassesList from "./WondeClassesList";
import loader from '../../../../images/loader.svg';
import { useEffectOnce } from "react-use";

const WondeClassImport = ({ duringAdditionalImport = false, closeAdditionalImport }) => {

    const { currentUser } = useContext(AuthContext);

    const [wondeClasses, setWondeClasses] = useState([]);
    const [page, setPage] = useState(1);
    const [finishLoad, setFinishLoad] = useState(false);

    const [loadInitial, { data, error, loading: loadingInitial }] = useLazyQuery(WONDE_CLASSES_DATA, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setWondeClasses(data.school.wondeSchool.classes);
            if (data.school.wondeSchool.classes.length > 0) {
                setPage(page + 1);
            } else {
                setFinishLoad(true);
            }
        }
    });

    useEffectOnce(() => {
        loadInitial({
            variables: {
                schoolId: currentUser?.school?.id,
                page: page,
                additionalOnly: duringAdditionalImport
            }
        })
    })

    const [loadMoreWondeClasses] = useLazyQuery(WONDE_CLASSES_DATA, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setWondeClasses(wondeClasses.concat(data.school.wondeSchool.classes));
            if (data.school.wondeSchool.classes.length > 0) {
                setPage(page + 1);
            } else {
                setFinishLoad(true);
            }
        }
    });

    useEffect(() => {
        if (page > 1) {
            loadMoreWondeClasses({
                variables: {
                    schoolId: currentUser?.school?.id,
                    page: page,
                    additionalOnly: duringAdditionalImport
                }
            })
        }
    }, [page]);


    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <>
            <PageHeader showBackLink={false} title={currentUser.school.name} subtitle={`URN ${currentUser.school.urn}`} />
            {loadingInitial ?
                <p className="u-display-flex u-align-center heavy">Please wait, loading classes... <img className="u-m-left-2" src={loader} /></p>
                :
                wondeClasses.length > 0 ?
                    <>
                        {
                            finishLoad ?
                                <WondeClassesList
                                    allWondeClasses={[...wondeClasses]}
                                    closeAdditionalImport={closeAdditionalImport}
                                    duringAdditionalImport={duringAdditionalImport}
                                /> :
                                <p className="u-display-flex u-align-center heavy">Please wait, loading classes ({wondeClasses.length})... <img className="u-m-left-2" src={loader} /></p>
                        }
                    </>
                    : <p>No classes found</p>
            }
        </>
    );
}

export default WondeClassImport;