import React, { useState, useContext } from 'react';
import StripeWrapper from '../../../components/StripeWrapper';

import CardFormUpdate from './CardFormUpdate';
import { AuthContext } from '../../../../../services/auth/AuthProvider';

const UpdateCardDetails = ({ close }) => {

    const [confirmSuccess, setConfirmSuccess] = useState(false);

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const onDoneUpdate = (schoolData) => {
        setConfirmSuccess(false);
        const cu = { ...currentUser, school: { ...currentUser.school, stripeCustomerPaymentMethod: schoolData.stripeCustomerPaymentMethod } }
        setCurrentUser(cu);
        close();
    }

    return (
        <>
            <StripeWrapper>
                <CardFormUpdate
                    school={currentUser?.school}
                    user={currentUser}
                    onDone={onDoneUpdate}
                    confirmSuccess={confirmSuccess}
                    setConfirmSuccess={setConfirmSuccess}
                    close={close}
                />
            </StripeWrapper>
        </>
    )
}

export default UpdateCardDetails