import { gql } from 'apollo-boost';
import { TERMS_VERSION } from '@axeedge/go-shared-utils';
import { teacherQuery } from '../../../services/constants';


var version = ((TERMS_VERSION).toString())

export const TEACHER_SIGNUP = gql`
mutation teacherSignUp($email: String!, $termsVersionApproved: String!){
    teacherSignUp(email: $email, termsVersionApproved: $termsVersionApproved) {
        errors
        teacher {
            id
            token
            email
        }
    }
}`;

export const RESEND_WELCOME_EMAIL = gql`
query resendWelcomeEmail($email: String!) {
    resendWelcomeEmail(email: $email)
}
`;

export const PROMO_TEACHER_SIGNUP = gql`
mutation promoTeacherSignUp($email: String!, $firstName: String!, $lastName: String!, $displayName: String!, $password: String!, $passwordConfirmation: String!, $termsVersionApproved: String!, $subscriptionIntent: Int, $signupReason: Int) {
    promoTeacherSignUp(email: $email, firstName: $firstName, lastName: $lastName, displayName: $displayName, password: $password, passwordConfirmation: $passwordConfirmation, termsVersionApproved: $termsVersionApproved, subscriptionIntent: $subscriptionIntent, signupReason: $signupReason){
        errors
        user {
          id
          token
          email
        }
    }
}
`;