import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';

// import * as serviceWorker from './serviceWorker';
import toBlob from 'canvas-to-blob';

import Home from './scenes/Home';

import './styles/main.scss';

require('es7-object-polyfill');

toBlob.init();

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={(<div>Loading</div>)}>
            <Home />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
