import { Button } from '@axeedge/go-teacher-components';
import React from 'react';
import { ArrowLeft, CheckSquare, Clock } from 'react-feather';
import styles from './SchoolSetup.module.scss';

const SchoolSetupInfo = ({ back, showSchoolForm }) => {

   return (
      <div className={styles.setupInfo}>
         <div className={styles.setupInfoHeader}>
            <div className={styles.setupInfoHeaderMain}>
               <h1 className=' u-display-flex u-align-center'><button onClick={back} className='btn-reset'><ArrowLeft className='u-m-right-2' /></button>Create your school account</h1>

               <p className='u-m-base-2'>BoomHub gives your school access to both BoomWriter and BoomReader. From one central place you can manage subscriptions, classes, pupils and more.</p>
               <p>We recomend this step is done by a member of the Senior Leadership Team or office staff. There's a few pieces of information that you'll need to hand:</p>

            </div>
            <div className={styles.setupInfoTime}>
               <Clock />
               <p>15 Mins</p>
            </div>
         </div>

         <ul className={styles.setupList}>
            <li className={styles.setupListItem}>
               <span className={styles.setupListNo}>1</span>
               <div className={styles.setupListRow}>
                  <div>
                     <p>Tell us a little about your school and create the classes that you would like to use BoomReader or BoomWriter for...</p>
                  </div>
                  <div>
                     <h3>You'll need:</h3>
                     <p className='u-display-flex u-align-center'><CheckSquare className='u-m-right-1' />School Address</p>
                  </div>
               </div>
            </li>

            <li className={styles.setupListItem}>
               <span className={styles.setupListNo}>2</span>
               <div className={styles.setupListRow}>
                  <div>
                     <h3>Create Registration Classes</h3>
                     <p>Which registration classes will potentially be writing? You can create your classes and pupil accounts by uploading a single xlsx file.</p>
                  </div>
                  <div>
                     <h3>You'll need:</h3>
                     <p className='u-display-flex u-align-center'><CheckSquare className='u-m-right-1' /> XLSX of classes and pupils</p>
                     <a href="https://goapps.s3.eu-west-2.amazonaws.com/sample-pupils-class-upload.xlsx" className={styles.setupDownloadBtn} target="_blank" rel="noopener noreferrer">Download Template</a>

                  </div>
               </div>
            </li>
            <li className={styles.setupListItem}>
               <span className={styles.setupListNo}>3</span>
               <div className={styles.setupListRow}>
                  <div>
                     <h3>Add administrators & Staff</h3>
                     <p>We recommend you have at least 2 admins (including yourself). Staff will be able to self sign up or you can invite them via email.</p>
                  </div>
                  <div>
                     <h3>You'll need:</h3>
                     <p className='u-display-flex u-align-center'><CheckSquare className='u-m-right-1' /> Who needs admin access</p>
                     <p className='u-display-flex u-align-center'><CheckSquare className='u-m-right-1' /> Staff email addresses (optional)</p>
                  </div>
               </div>
            </li>
         </ul>

         <Button onClick={showSchoolForm}>I'm ready... let's go</Button>
         <div className="u-m-top-3 u-m-base-3">
            <h3>I'm not the right person!</h3>
            <p>No problem! Simply ask the appropriate person to sign up and create the school account. Once done you’ll be able to join the school using this account.</p>
         </div>
      </div>
   )
}

export default SchoolSetupInfo;