import React, { useState, useContext, useEffect } from 'react';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { GET_STATES, GET_COUNTRIES } from '../../../../../services/school/graphql';
import ls from 'local-storage'
import { EDIT_BILLING_PROFILE } from '../../../services/graphql';
import { AuthContext } from '../../../../../services/auth/AuthProvider';

const BillingProfileForm = ({ school, close }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [uk, setUk] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const isUS = ls('country') === 'US';

    const { data, loading, error } = useQuery(GET_STATES, {
        onCompleted: (data) => {
            setStates(data.statesByCountry);
        },
        variables: { country: 'USA' }
    });

    const { data2, loading2, error2 } = useQuery(GET_COUNTRIES, {
        onCompleted: (data) => {
            setCountries(data.countriesList);
        }
    });

    useEffect(() => {
        if (countries.length > 0) {
            setUk(countries.find(c => c.code === 'GBR'));
        }
    }, [countries]);


    const schema = Yup.object().shape({
        address: Yup.string().required('Please enter an address'),
        city: Yup.string().required('Please enter the city'),
        zip: Yup.string().required('Please enter post code'),
        contactPhone: Yup.string().required('Please enter telephone'),
        contactPerson: Yup.string().required('Please enter a name'),
        contactEmail: Yup.string().required('Please enter an email'),
        ...isUS && {
            state: Yup.string().required('Please select state'),
        },
        ...!isUS && {
            country: Yup.string().required('Please select country'),
        },
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });


    const [editSchoolBillingProfile, { loading: editing }] = useMutation(EDIT_BILLING_PROFILE, {
        update: (_, { data }) => {
            if (data?.editSchoolBillingProfile?.errors && data.editSchoolBillingProfile.errors.length !== 0) {
                setFormErrors(data.editSchoolBillingProfile.errors);
                return;
            }
            if (data?.editSchoolBillingProfile?.school?.id) {
                const cu = { ...currentUser, school: data.editSchoolBillingProfile.school }
                setCurrentUser(cu);
                close();
            }
        },
    });

    const onSubmit = values => {
        const { companyName, address, address2, city, zip, state, country, contactPhone, contactPerson, contactEmail } = values;
        editSchoolBillingProfile({
            variables: {
                schoolId: school.id,
                companyName,
                address,
                address2,
                city,
                zip,
                country: isUS ? 'USA' : country,
                state,
                contactPhone,
                contactEmail,
                contactPerson
            }
        });
    }

    if (loading || loading2) {
        return <Loader />
    }
    if (error || error2) {
        return <p>{error.message}</p>
    }

    if (data) {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className='heavy'>Company/district name (optional)</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className='basic-form__group'>
                            <input
                                name='companyName'
                                ref={register}
                                className='basic-form__text-box'
                                type='text'
                                defaultValue={school.billingProfile?.companyName}
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-6">
                        <h3 className='heavy'>Full address</h3>
                        <div className='basic-form__group'>
                            <label htmlFor='address' className='basic-form__text-label'>Street address</label>
                            <input
                                name='address'
                                id='address'
                                ref={register}
                                className='basic-form__text-box'
                                type='text'
                                defaultValue={school.billingProfile?.address}

                            />
                            {errors.address && <p className='basic-form__hint u-m-top-1'>{errors.address.message}</p>}
                        </div>

                        <div className='basic-form__group'>
                            <label htmlFor='address2' className='basic-form__text-label'>Street address 2</label>
                            <input
                                name='address2'
                                id='address2'
                                ref={register}
                                className='basic-form__text-box'
                                type='text'
                                defaultValue={school.billingProfile?.address2}

                            />
                        </div>
                        <div className='basic-form__group'>
                            <label htmlFor='city' className='basic-form__text-label'>City</label>
                            <input
                                name='city'
                                id='city'
                                ref={register}
                                className='basic-form__text-box'
                                defaultValue={school.billingProfile?.city}
                                type='text'
                            />
                            {errors.city && <p className='basic-form__hint u-m-top-1'>{errors.city.message}</p>}
                        </div>

                        {isUS && states && states.length > 0 ?
                            <div className='basic-form__group'>
                                <label htmlFor='state'>State</label>
                                <select defaultValue={school.billingProfile?.state?.code} name="state" ref={register} className="basic-form__text-select">
                                    <option value=''>Please select state</option>
                                    {states.map(state => (
                                        <option key={`c-${state.code}`} value={state.code}>{state.name}</option>
                                    ))}
                                </select>
                                {errors.state && <p className='basic-form__hint u-m-top-1'>{errors.state.message}</p>}
                            </div> : countries.length > 0 &&
                            <>
                                <div className='basic-form__group'>
                                    <label htmlFor='zip'>Country:</label>
                                    <select
                                        defaultValue={school.billingProfile?.country?.code}
                                        name="country"
                                        ref={register}
                                        className="basic-form__text-select"
                                    >
                                        <option value=''>Please select country</option>
                                        {uk && (
                                            <option value={uk.code}>{uk.name}</option>
                                        )}
                                        <option disabled>──────────</option>
                                        {countries.map((country, i) => (
                                            <option key={`c-${country.code}`} value={country.code}>{country.name}</option>
                                        ))}
                                    </select>
                                </div>

                                {errors.country && <p className='basic-form__hint'>{errors.country.message}</p>}
                            </>
                        }

                        <div className='basic-form__group'>
                            <label htmlFor='zip' className='basic-form__text-label'>{isUS ? 'Zip' : 'Post code'}</label>
                            <input
                                name='zip'
                                id='zip'
                                placeholder={isUS ? 'ZIP' : 'Postcode'}
                                ref={register}
                                className='basic-form__text-box'
                                type='text'
                                defaultValue={school.billingProfile?.zip}

                            />
                            {errors.zip && <p className='basic-form__hint u-m-top-1'>{errors.zip.message}</p>}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h3 className='heavy'>Contact details</h3>
                        <div className='basic-form__group'>
                            <label htmlFor='contactPerson'>Contact name</label>
                            <input
                                name='contactPerson'
                                id='contactPerson'
                                className='basic-form__text-box'
                                type='text'
                                ref={register}
                                defaultValue={school.billingProfile?.contactPerson}
                            />
                        </div>
                        {errors.contactPerson && <p className='basic-form__hint'>{errors.contactPerson.message}</p>}

                        <div className='basic-form__group'>
                            <label htmlFor='contactEmail'>Contact email</label>
                            <input
                                name='contactEmail'
                                id='contactEmail'
                                className='basic-form__text-box'
                                type='email'
                                ref={register}
                                defaultValue={school.billingProfile?.contactEmail}
                            />
                        </div>
                        {errors.contactEmail && <p className='basic-form__hint'>{errors.contactEmail.message}</p>}
                        <div className='basic-form__group'>
                            <label htmlFor='contactPhone'>Contact phone</label>
                            <input
                                name='contactPhone'
                                id='contactPhone'
                                className='basic-form__text-box'
                                type='text'
                                ref={register}
                                defaultValue={school.billingProfile?.contactPhone}
                            />
                        </div>
                        {errors.contactPhone && <p className='basic-form__hint'>{errors.contactPhone.message}</p>}
                    </div>
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <Button disabled={editing}>{editing ? 'Saving...' : 'Save'}</Button>
                <Button outline className='u-m-left-2' type='button' onClick={() => close()}>Cancel</Button>
            </form>
        )
    }
    return null;
}

export default BillingProfileForm;