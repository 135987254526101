import React, {useState, useEffect, Fragment} from 'react';
import PageHeader from '../../../../components/PageHeader';
import { Users, Download } from 'react-feather';
import styles from './NewAcademicYear.module.scss';
import { GET_CLASSES_QUERY, ARCHIVE_CLASSES } from './services/graphql';
import { useQuery, useMutation} from '@apollo/react-hooks';
import { Loader, Button } from '@axeedge/go-teacher-components';
import _ from 'lodash';
import ButtonLink from '../../../../components/ButtonLink';
import {ArrowLeft} from 'react-feather'
import { Link } from '@reach/router';

const NewAcademicYear = () => {

    const [classes, setClasses] = useState([])
    const [selectedClasses, setSelectedClasses] = useState([]) 

    const { data, error, loading } = useQuery(GET_CLASSES_QUERY, {
        variables: {
            includeAdminedClasses: true
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setClasses(data.myClasses.filter(c => c.academicYear === 2021))
        }
    });

    const [archiveClasses, { loading: archiveLoading, error: archiveError }] = useMutation(ARCHIVE_CLASSES, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.archiveMultipleClasses.errors && data.archiveMultipleClasses.errors.length > 0) {
                console.log("error")
            } else {
                console.log("success")
            }
            return;
        }
        ,
        onError: () => {
            console.log("error")
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY, variables: {
                    includeAdminedClasses: true
                }
            }
        ]
    });

    useEffect(() => {
        data && setClasses(data.myClasses.filter(c => c.academicYear === 2021))
    }, [data])
    

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(s => s !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const onToggleAll = e => {
        const cIds = classes.map(c => c.id);
        e.target.checked ? setSelectedClasses(cIds) : setSelectedClasses([])
    }


    return (
        <Fragment>
            <div className={styles.pageHeader}>
                <div>
                <Link to="/classes/newAcademicYearStart" className={styles.backButton}>
                    <ArrowLeft size="30"/>
                </Link>
                </div>
                <h1 className={styles.pageHeading}>Start Academic Year 2021/2022</h1>
            </div>
            <h2 className="u-bold">Step 1/2: Archive any non-register classes.</h2>
            <p className="u-m-base-20 u-bold u-large-text">Changes you make now will be instant.</p>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.controls}>
                        <input
                            name="class"
                            type="checkbox"
                            onChange={(e) => onToggleAll(e)}
                            id="SelectAll"
                            style={{
                                marginRight: '10px'
                            }}
                        />
                        <label htmlFor="SelectAll" style={{marginRight: '50px'}}>All/None</label>
                        <Button disabled={selectedClasses.length < 1} onClick={() => archiveClasses({variables: {studentsClassIds: selectedClasses}})}>Archive</Button>
                        <div style={{marginLeft:'auto'}}>
                            <ButtonLink to='/classes/moveClasses'>Done</ButtonLink>
                        </div>
                    </div>
                    {data &&
                        <div>
                            {
                                _.map(classes, c => (
                                    <div key={`class${c.id}`} className={styles.classRow}>
                                        <input
                                            name="class"
                                            type="checkbox"
                                            value={c.id}
                                            onChange={(e) => onSelectClass(e)}
                                            checked={selectedClasses.indexOf(c.id) > -1}
                                            className={styles.checkbox}
                                            id={`st_${c.id}`}
                                        />
                                        {c.name}
                                        <div style={{marginLeft: 'auto'}}>{c.archived && 'archived'} {c.description}</div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
                <div className={styles.right}>
                    <div className={styles.greySide}>
                        <h2 className="u-bold">Step 1 help</h2>
                        <p className="u-m-base-2">Please start by archiving any <span className="u-bold">non-registration</span> or form classes that you have created. These might be break-out, accelerated reading or simple groups within the main class.</p>
                        <p>Archiving classes won't affect any of your Reading Logs data and you'll still be able to view these classes for reference.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NewAcademicYear;
