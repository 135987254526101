import React, { useState, useContext } from "react";
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_SCHOOL_BY_URN, CONFIRM_WONDE_SCHOOL } from '../../services/graphql';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import JoinExistingSchool from "../PostOnboardingWondeFlow/JoinExistingSchool";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import cx from 'classnames';
import styles from '../../Onboarding.module.scss';


const FindByUrn = ({ setShowManualSetup, exit }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [wondeSchool, setWondeSchool] = useState(null);
    const [existingSchool, setExistingSchool] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [schoolError, setSchoolError] = useState(null);

    const [getSchoolByUrn, { loading }] = useLazyQuery(GET_SCHOOL_BY_URN, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.getSchoolByUrn.errors?.length > 0) {
                setFormErrors(data.getSchoolByUrn.errors);
            }
            if (data && data.getSchoolByUrn.school?.id) {
                setExistingSchool(data.getSchoolByUrn.school);
                return
            }
            if (data && data.getSchoolByUrn.wondeSchool) {
                setWondeSchool(data.getSchoolByUrn.wondeSchool);
                setSchoolError(null);
            }
            if (data && !data.getSchoolByUrn.wondeSchool && !data.getSchoolByUrn.school) {
                setSchoolError('No School found');
            }

        }
    });

    const [confirmWondeSchool, { loading: confirming }] = useMutation(CONFIRM_WONDE_SCHOOL, {
        update: (_, { data }) => {
            if (data.confirmWondeSchool.errors && data.confirmWondeSchool.errors.length !== 0) {
                setFormErrors(data.confirmWondeSchool.errors);
                return;
            }
            if (data.confirmWondeSchool?.school?.id) {
                const cu = { ...currentUser, isSchoolAdmin: true, roleId: 1, school: data.confirmWondeSchool.school }
                setCurrentUser(cu);
                exit()
            }
        }
    })

    const schema = Yup.object().shape({
        urn: Yup.string().required('Please enter school URN')
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });
    const onSubmit = ({ urn }) => {
        const regex = /^[0-9\b]+$/;
        if (regex.test(urn)) {
            getSchoolByUrn({ variables: { urn } })
        } else {
            setSchoolError('Please type a correct URN')
        }

    }

    return (
        <>
            {wondeSchool ?
                <>
                    <p>We found the following school based on the URN provided:</p>
                    <div className="panel u-m-base-2">
                        <h2 className="heavy">{wondeSchool.name} <span className="light">- URN {wondeSchool.urn}</span></h2>
                        <p>{wondeSchool.address}</p>
                        <p>{wondeSchool.address2}</p>
                        <p>{wondeSchool.city}, {wondeSchool.zip}</p>
                        <p>{wondeSchool.country}</p>
                    </div>
                    <p className="u-m-base-2">Please review the access permissions we'll request:</p>
                    <div className={styles.wondePermissions}>
                        <div>
                            <p className="u-m-base-1">Pupils:</p>
                            <ul>
                                <li>Name (Required)</li>
                                <li>URN Number (Required)</li>
                                <li>Year group (Required)</li>
                                <li>SEND status</li>
                                <li>Pupil premium status</li>
                                <li>Gender</li>
                            </ul>
                        </div>
                        <div>
                            <p className="u-m-base-1">Staff:</p>
                            <ul>
                                <li>Name (Required)</li>
                                <li>Display name (Required)</li>
                                <li>Email (Required)</li>
                            </ul>
                        </div>
                        <div>
                            <p className="u-m-base-1">Classes:</p>
                            <ul>
                                <li>Name (Required)</li>
                            </ul>
                        </div>
                    </div>
                    <p>An admin on your school's Wonde account will need to approve our access request.</p>
                    <p className="u-m-base-2">Your 30 day trial will automatically start once the Wonde request is authorised.</p>
                    <div className="u-display-flex u-align-center u-m-base-2">
                        <Button
                            disabled={confirming}
                            onClick={() => confirmWondeSchool({
                                variables: {
                                    urn: (wondeSchool.urn).toString()
                                }
                            })}
                            className='u-m-right-2'
                        >Request Wonde access</Button>
                        <button onClick={() => setWondeSchool(null)} className="btn-reset link-underline">This is not my school</button>
                    </div>
                </> : existingSchool ?
                    <>
                        {formErrors && <FormErrors errors={formErrors} />}
                        <JoinExistingSchool clearExistingSchool={() => {setExistingSchool(null); setSchoolError(null)}} exit={exit} school={existingSchool} />

                    </> :
                    <>
                        <form className='panel u-m-base-3' onSubmit={handleSubmit(onSubmit)}>
                            <h3 className="heavy">My school uses Wonde</h3>
                            <p className="u-m-base-2">Your free trial includes our MIS Integration package to easily sync your classes, students and staff. (After trial, MIS Integration is £40/year) </p>
                            <label htmlFor="schoolCode" className="basic-form__text-label heavy">Enter the school URN:</label>
                            <div className="u-display-flex u-align-center u-m-top2">
                                <div className="basic-form__group u-m-base-0">
                                    <input
                                        className="basic-form__text-box"
                                        name="urn"
                                        ref={register}
                                        type="text"
                                    />
                                </div>
                                <Button type="submit" primary className='u-m-left-2'>{loading ? 'Loading...' : 'Go!'}</Button>
                            </div>
                            {schoolError && <p className={cx(styles.joinSchoolError, 'u-m-base-2')}>{schoolError}</p>}
                            {errors.urn && <p className="basic-form__hint">{errors.urn.message}</p>}
                        </form>
                        <h2 className='heavy'>Or create your school manually</h2>
                        <p className='u-m-base-2'>If your school doesn't use Wonde you can set-up your school manually</p>
                        <Button outline onClick={() => setShowManualSetup(true)}>Create school</Button>
                    </>
            }
        </>
    )
}

export default FindByUrn;