import React, { useState, useEffect } from 'react';
import SignUpForm from './components/SignUpForm';
import AuthHeader from '../Login/components/AuthHeader';
import AuthLinks from '../Login/components/AuthLinks';
import CountrySelect from '../Login/components/CountrySelect';
import boomreaderImg from '../../images/boomreader-logo.svg';
import boomwriterImg from '../../images/boomwriter-logo.svg';
import writeImg from '../../images/signup/write.png';
import readImg from '../../images/signup/reading-boomer.png';

import { Link } from '@reach/router';
import { useLocation } from 'react-use';
import { SUBSCRIPTION_INTENT } from '../../services/constants';
import ImageFadeIn from 'react-image-fade-in';
import styles from './SignUp.module.scss';

import ls from 'local-storage';

const SignUp = () => {

    const location = useLocation();

    const [intent, setIntent] = useState(ls('country') === 'US' ? SUBSCRIPTION_INTENT.math : null);

    const [showCountrySelect, setShowCountrySelect] = useState(false);

 

    useEffect(() => {
        if (ls('country') !== 'US' && (location.pathname.includes('write') || location.pathname.includes('read') || location.pathname.includes('leavers'))) {
          if(location.pathname === ('/signup/write')) {
            setIntent(SUBSCRIPTION_INTENT.write)
          } else if (location.pathname.includes('read')) {
            setIntent(SUBSCRIPTION_INTENT.read)
          } else if(location.pathname.includes('leavers')) {
            setIntent(SUBSCRIPTION_INTENT.leavers);
          }
        }

    }, [location])


    return (
        <div className='auth'>
            <AuthHeader showLogin={true} setShowCountrySelect={setShowCountrySelect} />
            <div className="auth__content">
                <div className='auth__panel'>
                    {showCountrySelect ?
                        <>
                            <CountrySelect setShowCountrySelect={setShowCountrySelect} />
                        </>
                        :
                        <>
                            <AuthLinks signup={true} country={ls('country')} />
                            {!intent ?
                                <>
                                    <h1>Which app are you signing up for</h1>
                                    <div className={styles.interestLinks}>
                                        <Link to='/signup/write' className={styles.interestLinksItem}>
                                            <ImageFadeIn className={styles.interestLinksItemImg} src={writeImg} alt='BoomWriter' />
                                            <ImageFadeIn className={styles.interestLinksItemLogo} src={boomwriterImg} alt='BoomWriter' />
                                        </Link>
                                        <Link to='/signup/read' className={styles.interestLinksItem}>
                                            <ImageFadeIn className={styles.interestLinksItemImg} src={readImg} alt='BoomWriter' />
                                            <ImageFadeIn className={styles.interestLinksItemLogo} src={boomreaderImg} alt='BoomReader' />
                                        </Link>
                                    </div>
                                </>
                                : <SignUpForm subscriptionIntent={intent} setShowCountrySelect={setShowCountrySelect} />
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignUp;