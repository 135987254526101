
import { gql } from 'apollo-boost';



export const CURRENT_PROMOS_QUERY = gql`
query currentPromos {
    currentPromos {
        id
        name
        promoOrderId
        isWriteToRaise
    }
}
`;

export const ACTIVE_CONTEST_QUERY = gql`
query activeContest {
    activeContest {
        id
        name
        status
        
    }
}
`;

export const GET_SCHOOL_CONTESTS = gql`
query currentTeacher($schoolId: ID!)  {
    currentTeacher {
        school {
            id
            contests {
                id
                bookForSchool(schoolId: $schoolId) {
                    id
                    status
                }
            }
        }
    }
}
`;

export const PENDING_JOIN_REQUESTS = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        joinRequests {
            id
            approved
            teacher {
                id
                displayName
            }
        }
    }
}
`;
