import React, { useState } from "react";
import SideModal from "../../../../components/SideModal";
import MatchAccount from "./MatchAccount";
import styles from '../../WondeSync.module.scss';
import { AlertCircle, CheckCircle, PauseCircle, Trash2 } from "react-feather";
import cx from 'classnames';
import { getRoleLabel } from '../../../../services/utils';

const StaffMatchItem = ({ teacher, setStaff, staff }) => {

    const [showEl, setShowEl] = useState(null);

    return (
        <>
            <li className={cx('u-flex-center', styles.staffMatch)}>
                <div className={styles.staffMatchMain}>
                    <div className={cx(styles.staffMatchName, 'u-flex-center')}>
                        {teacher.wondeTeacherId === '-99' ? <PauseCircle size={18} className="u-m-right-1" /> :
                            <>
                                {teacher.wondeTeacherId === '-1' ? <Trash2 size={18} className="u-m-right-1" /> : (!teacher.wondeId && !teacher.matchTo) ? <AlertCircle size={18} className="u-m-right-1 u-text-danger" /> : <CheckCircle size={18} className="u-m-right-1" />}
                            </>
                        }

                        {teacher.matchTo ? teacher.matchTo.firstName + ' ' + teacher.matchTo.lastName : teacher.name}
                    </div>
                    <div className={styles.staffMatchEmail}>
                        {teacher.matchTo ? teacher.matchTo.email : teacher.email}
                    </div>
                    <div className="u-text-right">
                        {teacher.isSchoolAdmin && <span className="label label-muted u-m-right-2">Admin </span>}
                        <span className="label label-muted">{getRoleLabel(teacher.roleId)}</span>
                    </div>
                </div>
                <button onClick={() => setShowEl(teacher)} className="btn-reset u-flex-center"><AlertCircle size={20} className="u-m-right-1" />details</button>
            </li>

            {showEl &&
                <SideModal closeModal={() => setShowEl(null)} >
                    <MatchAccount staff={staff} setStaff={setStaff} teacher={teacher} />
                </SideModal>
            }
        </>
    )
}

export default StaffMatchItem;