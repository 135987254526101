import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { WONDE_STAFF_MATCH } from "../../services/graphql";
import loader from '../../../../images/loader.svg';
import { Button } from "@axeedge/go-teacher-components";
import StaffList from "./StaffList";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import PageHeader from "../../../../components/PageHeader";
import styles from '../../WondeSync.module.scss';

const SyncStaff = ({ setCurrentStep }) => {

    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(WONDE_STAFF_MATCH, {
        variables: {
            schoolId: currentUser?.school?.id
        }
    });

    const [showIntro, setShowIntro] = useState(true);


    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <>
            <PageHeader showBackLink={false} title={'MIS Update'} />
            {loading ?
                <>
                    <p className="u-display-flex u-align-center heavy">Please wait, loading staff... <img className="u-m-left-2" src={loader} /></p>
                </> :
                data?.school?.wondeAutoMatchTeachers &&
                <>
                    {
                        showIntro ? <>

                            <div className={styles.syncMain}>
                                <h3 className="heavy">Sync Existing Staff</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="u-m-base-2">We found:</p>
                                        <p>{data?.school?.wondeAutoMatchTeachers.length} staff on your MIS</p>
                                        <p>{data?.school?.wondeAutoMatchTeachers.filter(t => t.wondeId).length} are currently in BoomHub and will be synched</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p>You'll add in new staff members in a later step.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.syncFooter}>
                                <Button className='u-m-left-auto' onClick={() => setShowIntro(false)}>Continue</Button>
                            </div>
                        </> :
                            <StaffList
                                setCurrentStep={setCurrentStep}
                                matchTeachers={data.school.wondeAutoMatchTeachers}
                            />
                    }
                </>
            }
        </>
    )
}

export default SyncStaff;