import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PageHeader from '../../../../components/PageHeader';
import { Button, FormErrors, Modal, Loader } from '@axeedge/go-teacher-components';
import { GET_TEACHER, REMOVE_TEACHER_FROM_SCHOOL, GET_OTHER_TEACHERS } from '../../services/graphql';
import { GET_TEACHERS_QUERY } from '../../../../services/school/graphql';

import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styles from '../../Teachers.module.scss';
import AddClassTeacher from '../../../Classes/components/AddClassTeacher';
import SaveConfirm from '../../../../components/SaveConfirm';

const RemoveTeacher = ({ selectedTeacher, setRemoveTeacher, setRemoveSuccess, school }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [formErrors, setFormErrors] = useState([]);
    const [addTeacherToClass, setAddTeacherToClass] = useState(null);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [teacherAdded, setTeacherAdded] = useState(false);

    const { data, error, loading } = useQuery(GET_TEACHER, {
        variables: {
            teacherId: selectedTeacher.id
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (data && data.teacher && data.teacher.studentsClasses) {
            const filtered = data.teacher.studentsClasses.filter(stClass => stClass.teachers.length === 1 && stClass.teachers.find(t => t.id === selectedTeacher.id))
            setFilteredClasses(filtered);
        }
    }, [data]);

    const [removeTeacherFromSchool] = useMutation(REMOVE_TEACHER_FROM_SCHOOL, {
        update: (_, { data }) => {
            if (data.removeTeacherFromSchool.errors && data.removeTeacherFromSchool.errors.length) {
                setFormErrors(data.removeTeacherFromSchool.errors);
                return;
            }
            if (data.removeTeacherFromSchool && data.removeTeacherFromSchool.teacher && data.removeTeacherFromSchool.teacher.id) {
                setRemoveSuccess(true);
                setRemoveTeacher(null);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GET_TEACHERS_QUERY,
            variables: {
                schoolCode: school.schoolCode,
                includeInvited: true
            },
        }, {
            query: GET_OTHER_TEACHERS, variables: { schoolId: school.id }
        }]
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.teacher) {
        const { teacher } = data;
        return (
            <div className={styles.container}>
                <div className="card-mobile">
                    <div className='row'>
                        <div className='col-md-7 col-lg-6'>
                            <PageHeader showBackLink={false} close={() => setRemoveTeacher(null)} title={`Remove Teachers`} />
                            <h1 className='heavy'>{t('common:are_you_sure')}</h1>
                            <p>{t('removing_teacher_will_suspend_account')}</p>
                            <h3 className='heavy u-m-top-3'>{teacher.displayName}</h3>
                            <p>{`${teacher.firstName} ${teacher.lastName}`} | {teacher.email}</p>

                            {filteredClasses.length > 0 && (
                                <Fragment>
                                    <h3 className='heavy u-m-top-3'>{t('classes_requiring_new_teacher')}</h3>
                                    <p>{t('classes_requiring_new_teacher_info', { teacherName: teacher.displayName })}</p>
                                    <ul className='list-group u-m-top-2'>
                                        {filteredClasses.map(stClass => {
                                            return (
                                                <li key={`cls-${stClass.id}`} className='list-item u-m-top-1 u-m-base-1'>
                                                    <span className='list-item--child'>{stClass.name}</span>
                                                    <div className='u-m-left-auto list-item--child'>
                                                        <button
                                                            onClick={() => setAddTeacherToClass(stClass)}
                                                            className='btn-reset link-reverse u-display-flex u-align-center'
                                                        >Assign new teacher <PlusCircle size="20" className='u-m-left-1' /></button>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Fragment>
                            )}

                            <div className='u-display-flex u-align-center u-m-top-3'>
                                <Button className='u-m-right-2' disabled={filteredClasses.length > 0} onClick={() => removeTeacherFromSchool({
                                    variables: {
                                        schoolId: school.id,
                                        teacherId: teacher.id
                                    }
                                })}>{t('remove_teacher')}</Button>
                                {teacherAdded && <SaveConfirm message={t('teacher_added')} hideConfirm={setTeacherAdded} />}
                            </div>

                            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                        </div>
                    </div>
                    {addTeacherToClass && (
                        <Modal closeModal={() => setAddTeacherToClass(null)}>
                            <AddClassTeacher
                                removingTeacher={teacher}
                                closeAddTeacher={() => { setAddTeacherToClass(null); setTeacherAdded(true) }}
                                schoolCode={school.schoolCode}
                                studentClass={addTeacherToClass}
                            />
                        </Modal>
                    )}
                </div>
            </div>
        );
    }
    return null;
}

export default RemoveTeacher;

