import React, { useState, useEffect, useRef, useContext } from "react";
import { AlertCircle, CheckCircle, PauseCircle, Trash2 } from "react-feather";
import { useLazyQuery } from "@apollo/react-hooks";
import { SEARCH_WONDE_TEACHERS } from "../../services/graphql";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import styles from '../../WondeSync.module.scss';
import { Loader } from "@axeedge/go-teacher-components";
import { getRoleLabel } from '../../../../services/utils';


const MatchAccount = ({ teacher, setStaff, staff }) => {

    const { currentUser } = useContext(AuthContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);

    const intervalRef = useRef(null);

    const [searchWondeTeachers, { loading, error }] = useLazyQuery(SEARCH_WONDE_TEACHERS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getWondeTeachersByNameOrEmail.length > 0) {
                setResults(data.getWondeTeachersByNameOrEmail);
                setNoResults(false);
            } else {
                setResults([]);
                setNoResults(true);
            }
        }
    });


    const onSelectMatch = result => {
        setResults([]);
        setNoResults(false);
        setSearchTerm('');
        const updatedTeachers = staff.map(el => el.id === teacher.id ? { ...el, wondeTeacherId: result.wondeId, matchTo: result } : el)
        setStaff([...updatedTeachers]);

    }

    const onEditMatch = () => {
        const updatedTeachers = staff.map(el => el.id === teacher.id ? { ...el, matchTo: null, wondeTeacherId: null } : el)
        setStaff([...updatedTeachers]);
    }

    useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            intervalRef.current = setTimeout(() => {
                searchWondeTeachers({
                    variables: {
                        nameOrEmail: searchTerm,
                        wondeSchoolId: currentUser?.school?.wondeId
                    }
                })
            }, 1000);
        } else {
            setResults([]);
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [searchTerm]);

    const deleteStaff = (teacher) => {
        const updatedTeachers = staff.map(el => el.id === teacher.id ? { ...el, wondeTeacherId: '-1' } : el)
        setStaff(updatedTeachers);
    }

    const skippStaff = (teacher) => {
        const updatedTeachers = staff.map(el => el.id === teacher.id ? { ...el, wondeTeacherId: '-99' } : el)
        setStaff(updatedTeachers);
    }

    return (
        <>
            {teacher.wondeId ? <p className="u-m-base-2">Auto matched by email</p> : teacher.matchTo ? <p>manually matched</p> : <p className="u-m-base-2">No match found by email</p>}

            <h3 className="heavy u-m-base-1">Account in BoomHub</h3>
            <p>{teacher.name}</p>
            <p className="u-m-base-1">{teacher.email}</p>
            <p className="u-m-base-2">
                {teacher.isSchoolAdmin && <span className="label label-muted u-m-right-2">Admin </span>}
                <span className="label label-muted">{getRoleLabel(teacher.roleId)}</span>
            </p>

            {teacher.wondeId || teacher.matchTo ?
                <>
                    <h3 className="heavy">Mapped to MIS Account</h3>
                    <p>This accounts profile will be updated to match the following:</p>
                    {teacher.matchTo ?
                        <>
                            <p>{teacher.matchTo.firstName + ' ' + teacher.matchTo.lastName}</p>
                            <p className="u-m-base-3">{teacher.matchTo.email}</p>
                        </>
                        : <>
                            <p> {teacher.wondeName}</p>
                            <p className="u-m-base-3">{teacher.email}</p>
                        </>}

                    <p>Role: {getRoleLabel(teacher.roleId)}</p>
                    <p className="u-m-base-3">Admin access: {teacher.isSchoolAdmin ? 'Yes' : 'No'}</p>
                    <p>Their account password will be unchanged</p>
                    {currentUser.id === teacher.id && teacher.matchTo && teacher.matchTo.email !== currentUser.email && (
                        <div className="u-flex-center u-m-base-2">
                            <AlertCircle className="u-m-right-2" />
                            <p className="heavy u-text danger">After completing this step, you will need to log in again using your new email address</p> 
                        </div>
                    )}

                    {teacher.matchTo && <button className="btn-outline-danger btn-icon u-m-top-2" onClick={() => onEditMatch()}><Trash2 size={18} /> Edit Match</button>}
                </> :
                <>

                    {teacher.wondeTeacherId !== '-1' &&
                        <>                   
                         <h3 className="heavy">Match Options</h3>
                            <div style={{ position: 'relative' }}>
                                <div className="u-flex-center">
                                    <label className="u-m-right-1">Select Match:</label>
                                    <input
                                        type="text"
                                        className="basic-form__text-box"
                                        placeholder="Email or last name"
                                        autoComplete='off'
                                        value={searchTerm}
                                        onChange={(e) => { setSearchTerm(e.target.value); setNoResults(false) }}
                                    />
                                </div>
                                {error && <p>{error.message}</p>}
                                <div className={styles.resultsWrapper}>
                                    {loading && <Loader />}
                                    {results.length > 0 ? (
                                        <ul className={styles.resultsWrapperList}>
                                            {results.map(result => (
                                                <li key={result.id} onClick={() => onSelectMatch(result)} className="u-flex-center u-justify-between">
                                                    <div>
                                                        <p>{result.email}</p>
                                                        <p>{result.firstName} {result.lastName}</p>
                                                    </div>
                                                    <CheckCircle />
                                                </li>
                                            ))}
                                        </ul>
                                    ) : noResults && <p>No teachers found</p>}
                                </div>
                            </div>
                        </>
                    }
                    <hr className="separator" />

                
                    {teacher.wondeTeacherId === '-1' || teacher.wondeTeacherId === '-99' ? (
                        <>
                            <h3 className="heavy">{teacher.wondeTeacherId === '-1' ? 'Account will be removed' :'Sync will be skipped'}</h3>
                            <button className="btn-outline-danger btn-icon u-m-top-2" onClick={() => onEditMatch()}><Trash2 size={18} /> Edit Match</button>
                        </>
                    ) :
                        <div className="u-display-flex u-align-center">
                            {currentUser.id !== teacher.id && <button className="u-m-right-2 btn-outline-danger btn-icon" onClick={() => deleteStaff(teacher)}><Trash2 size={18} /> Delete</button>}
                            <button className="btn-outline-danger btn-icon" onClick={() => skippStaff(teacher)}><PauseCircle size={18} /> Skip Sync</button>
                        </div>
                    }

                </>
            }
        </>
    )
}
export default MatchAccount;