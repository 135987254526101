import React, { useState } from "react";
import InterestForm from "./InterestForm";
import Share from "../../../components/Share";

import styles from '../RegisterInterest.module.scss';
import cx from 'classnames';
import gtLogo from '../../../images/interest/grand-tour-logo.png'
import ImageFadeIn from "react-image-fade-in";
import gtBg from '../../../images/interest/grand-tour-bg.png'
const GrandTourInterest = () => {


    const [done, setDone] = useState(false);

    const hostname = window && window.location && window.location.hostname;

    const getShareUrl = () => {
        if (hostname.includes('staging')) {
            return hostname.includes('boom') ? 'https://staging.boomreader.co.uk/grandtour' : 'https://staging.go-read.co.uk/grandtour'
        }
        return hostname.includes('boom') ? 'https://boomreader.co.uk/grandtour' : 'https://go-read.co.uk/grandtour'
    }


    return (
        <>
            {
                done ? (
                    <div className={styles.interest}>
                        <div className={cx(styles.interestHero, styles.interestHeroRead)}>
                            <div className={cx(styles.interestHeroContent)}>
                                <ImageFadeIn className={styles.interestWriteLogo} src={gtLogo} alt='Grand Tour' />
                                <h1 className={styles.interestHeading}>Thanks</h1>
                                <p>Your no-obligation place on the Grand Tour has been reserved</p>
                            </div>
                        </div>
                        <div className={styles.interestShareRead}>
                            <div className={cx(styles.interestDoneContent)}>
                                <h2 className={styles.interestSubHeading}>Share With Others</h2>
                                <p>Don't keep this to yourself! Please share this with other schools and help them make the switch to digital reading records.</p>
                                <p>Together we can make sure there are no more lost reading records</p>
                                <Share url={getShareUrl()} title="We’re signed up for the BoomReader Grand Tour" />
                            </div>
                        </div>
                        <div className={cx(styles.interestDoneContent)}>
                            <h2 className={styles.interestSubHeading}>What Happens Next?</h2>
                            <p>Please check your inbox as well send you a welcome email (please check your spam folder if you don't see it!).</p>
                            <p className="u-m-base-3"><b>We'll send you further updates and information including when you should create your school's trial account.</b></p>
                        </div>
                    </div>
                ) : (
                    <div className={styles.interest}>
                            <div className={cx(styles.interestHero, styles.interestHeroRead)}>
                                <div className={styles.interestHeroContainer}>
                                    <div className={cx(styles.interestHeroContent)}>
                                        <ImageFadeIn className={styles.interestWriteLogo} src={gtLogo} alt='Grand Tour' />
                                        <p className={cx(styles.interestMainTitle, 'u-m-base-2')}>Reserve your school a place today.</p>
                                        <p className={styles.interestMainTitle}>We’ll reserve your school a no-obligation place and send you information on how to prepare and take part.</p>
                                    </div>
                                    <div className={styles.interestHeroSide}>
                                        <img src={gtBg} className={styles.interestHeroSideImg} />
                                    </div>
                                </div>
                            </div>
                            <InterestForm close={() => setDone(true)} />
                    </div>
                )
            }
        </>

    )

}

export default GrandTourInterest;