import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Users, Download, HelpCircle } from 'react-feather';
import { GET_CLASSES_QUERY, GET_SCHOOL_STUDENTS_LIST, CHECK_STUDENTS } from './services/graphql';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import axios from 'axios';
import { Loader, Button } from '@axeedge/go-teacher-components';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import PageHeader from '../../components/PageHeader';
import ClassList from './scenes/ClassList';
import UnassignedPupils from './components/UnassignedPupils';
import { AuthContext } from '../../services/auth/AuthProvider';
import { parse } from "query-string";
import { useLocation, useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import styles from './Classes.module.scss';
import cx from 'classnames';

import ArchivedClasses from './components/ArchivedClasses';
import SideClassNav from './components/SideClassNav';
import { CLASS_TYPE, WONDE_SYNC_STEPS } from '../../services/constants';
import NewSchoolYearWarning from '../../components/NewSchoolYearWarning';
import WondeClassImport from '../Onboarding/components/WondeClassImport';

const VIEW_TABS = {
    classes: 1,
    unassignedPupils: 2,
}
const TABS = {
    myClasses: 1,
    registrationClasses: 2,
    otherClasses: 3,
    allClasses: 4,
    archivedClasses: 5,
}

const Classes = () => {

    const location = useLocation();

    const { t } = useTranslation(['classes', 'common']);

    const { currentUser: user } = useContext(AuthContext);
    const [showMisCheck, setShowMisCheck] = useState(false);
    const [showHelp, setShowHelp] = useState(false);

    const [selectedView, setSelectedView] = useState(VIEW_TABS.classes);
    const [selectedTab, setSelectedTab] = useState(TABS.myClasses);

    const [baseClasses, setBaseClasses] = useState([]);
    const [visibleClasses, setVisibleClasses] = useState(baseClasses);

    const [classWithPupils, setClassWithPupils] = useState(false);

    const { data, error, loading } = useQuery(GET_CLASSES_QUERY, {
        variables: {
            includeAdminedClasses: user && user.isSchoolAdmin
        },
    });

    useEffect(() => {
        if (data && data.myClasses) {
            setBaseClasses(data.myClasses)
        }
    }, [data]);

    const [fetchClasses] = useLazyQuery(GET_CLASSES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            includeAdminedClasses: user && user.isSchoolAdmin
        },
    })


    const { data: studentData, error: studentError, loading: studentLoading } = useQuery(CHECK_STUDENTS, {
        variables: {
            schoolId: user && user.school.id
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (studentData && studentData.school.classes) {
            setClassWithPupils(false)
            for (var i = 0; i < studentData.school.classes.length; i++) {
                if (studentData.school.classes[i].students.length > 0) {
                    setClassWithPupils(true)
                    break;
                }
            }
        }
    }, [studentData]);

    useEffect(() => {
        switch (selectedTab) {
            case TABS.myClasses:
                setVisibleClasses([...baseClasses].filter(c => c.teachers.find(t => t.id === user.id)));
                break;
            case TABS.otherClasses:
                setVisibleClasses([...baseClasses].filter(c => c.typeId === CLASS_TYPE.other));
                break;
            case TABS.registrationClasses:
                setVisibleClasses([...baseClasses].filter(c => c.typeId === CLASS_TYPE.registration));
                break;
            case TABS.allClasses:
                setVisibleClasses([...baseClasses]);
                break;
            default:
                setVisibleClasses(baseClasses);
                break;
        }
    }, [selectedTab, baseClasses, setVisibleClasses]);


    const [getSchoolStudentList, { loading: csvLoading }] = useLazyQuery(GET_SCHOOL_STUDENTS_LIST, {
        variables: {
            schoolId: user.school.id
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.schoolStudentsList,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `School_login_details`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    });



    useEffectOnce(() => {
        if (data && data.myClasses && user.isSchoolAdmin && parse(location.search).showAll) {
            setSelectedTab(TABS.allClasses);
        }
    });

    const misCheckBtnVisible = user?.school?.wondeAccessApprovedAt && user?.school?.wondeSyncStep === WONDE_SYNC_STEPS.syncCompleted && user.isSchoolAdmin;


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (showMisCheck) {
        return <WondeClassImport duringAdditionalImport={true} closeAdditionalImport={() => { setShowMisCheck(false); fetchClasses() }} />
    }

    if (visibleClasses) {
        return (
            <Fragment>
                <PageHeader title={`Classes`} icon={Users} />
                {user && user.school.newYearStep < 99 && !user.school.canAccessWonde ?
                    <NewSchoolYearWarning />
                    :
                    <>
                        <Tabs withPanel className={cx(styles.container, 'u-m-base-3')}>
                            <Tab onClick={() => setSelectedView(VIEW_TABS.classes)} selected={selectedView === VIEW_TABS.classes}>{t('classes')}</Tab>
                            <Tab onClick={() => setSelectedView(VIEW_TABS.unassignedPupils)} selected={selectedView === VIEW_TABS.unassignedPupils}>{t('unassigned_pupils')}</Tab>
                            {
                                classWithPupils && selectedTab !== TABS.archivedClasses &&
                                <button type='button' className='btn-icon u-m-left-md-auto u-m-top-2 u-m-top-md-0' disabled={csvLoading} onClick={() => getSchoolStudentList()}>
                                    <Download size='18' />{csvLoading ? t('downloading_csv_please_wait') : t('download_school_login_details_csv')}
                                </button>
                            }
                        </Tabs>

                        <div className={cx(styles.container, 'card card-mobile')}>
                            {selectedView === VIEW_TABS.classes ?
                                <div className='row'>
                                    <div className={cx('col-md', styles.containerSide)}>
                                        <SideClassNav
                                            tabs={TABS}
                                            selectedTab={selectedTab}
                                            setSelectedTab={setSelectedTab}
                                            classList={baseClasses}
                                            user={user}
                                        />
                                        {misCheckBtnVisible &&

                                            <>
                                                <div className="u-display-flex u-align-center">
                                                    <Button outline onClick={() => setShowMisCheck(true)}>Check MIS</Button>
                                                    <button onClick={() => setShowHelp(!showHelp)} className='btn-reset u-m-left-2'><HelpCircle /></button>
                                                </div>

                                                {showHelp &&
                                                    <p className='small u-m-top-2'>The Check MIS button is used to add classes from your MIS to the existing list of Boomreader classes you have already imported by comparing BoomReader to your MIS. This will <b>not</b> overwrite pupil data or replace classes, it will only give you the option to include further class structures from your MIS that are not <b>already</b> in your BoomReader class structure (for example, if you create Phonics groups as MIS classes during the year)
                                                    <button onClick={() => setShowHelp(false)} className='u-m-left-2 btn-reset link-underline'>Hide</button></p>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className='col-md'>
                                        {
                                            selectedTab === TABS.archivedClasses ?
                                                <ArchivedClasses /> :
                                                <ClassList
                                                    user={user}
                                                    classes={visibleClasses}
                                                    allTabs={TABS}
                                                    selectedTab={selectedTab}
                                                    setSelectedTab={setSelectedTab}
                                                />
                                        }

                                    </div>
                                </div> : <UnassignedPupils schoolId={user.school.id} />}
                        </div>
                    </>
                }
            </Fragment>
        );
    }

    return null;

}

export default Classes;
