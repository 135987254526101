import React, { Fragment } from 'react';
import { HelpCircle } from 'react-feather';
import PageHeader from '../../../components/PageHeader';
import FaqItem from '../components/FaqItem';
import ImageFadeIn from "react-image-fade-in";
import gemImg from '../../../images/faq/gem.png';
import codeImg from '../../../images/faq/code.png';

import { useTranslation, Trans } from 'react-i18next';

const FaqRead = () => {

    const { t } = useTranslation();

    return (
        <Fragment>
            <PageHeader title={t('go_read_faq')} icon={HelpCircle} />
            <div className='card card-mobile'>
                <ul className='u-m-base-3'>
                    <FaqItem title={t('read_faq_question_subscription')}>
                        <p>{t('read_faq_ans_subscription')}</p>
                    </FaqItem>
                    <FaqItem title={t('read_faq_question_gems')}>
                        <p>{t('read_faq_ans_gems')}</p>
                        <ImageFadeIn width="240" src={gemImg} />
                    </FaqItem>
                    <FaqItem title={t('read_faq_question_midyear')}>
                        <p>{t('read_faq_ans_midyear')}</p>
                    </FaqItem>
                    <FaqItem title={t('read_faq_question_children_login')}>
                        <p>{t('read_faq_ans_children_login')}</p>
                    </FaqItem>
                    <FaqItem title={t('read_faq_question_training')}>
                        <p>{t('read_faq_ans_training')}</p>
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_filtering')}>
                        <p>
                            <Trans i18nKey="shared_faq_ans_filtering">
                                We have a list of all the websites that need to be whitelisted by your schools internet provider. Please email <a className='u-text-tertiary link-underline' href="mailto:support@boomhub.app">{{ contactEmail: 'support@boomhub.app' }}</a> for more information
                            </Trans>
                        </p>
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_pupils_multiple_classes')}>
                        <p>{t('shared_faq_ans_pupils_multiple_classes')}</p>
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_teacher_multiple_classes')}>
                        <p>{t('read_faq_ans_teacher_multiple_classes')}</p>
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_change_pupil_code')}>
                        <p className='u-m-base-2'>{t('shared_faq_ans_change_pupil_code')}</p>
                        <ImageFadeIn src={codeImg} />
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_social_media')}>
                        <p>{t('shared_faq_ans_social_media')}</p>
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_something_wrong')}>
                        <Trans i18nKey="shared_faq_ans_something_wrong">
                            Sorry! Get in touch <a className='u-text-tertiary link-underline' href="mailto:support@boomhub.app">{{ contactEmail: 'support@boomhub.app' }}</a>
                        </Trans>
                    </FaqItem>
                    <FaqItem title={t('shared_faq_question_have_idea')}>
                        <Trans i18nKey="shared_faq_ans_have_idea">
                            Awesome! Get in touch <a className='u-text-tertiary link-underline' href="mailto:support@boomhub.app">{{ contactEmail: 'support@boomhub.app' }}</a>
                        </Trans>
                    </FaqItem>
                </ul>
            </div>
        </Fragment>
    )
}

export default FaqRead;