import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_BOOK_TEMPLATE_CATEGORIES } from '../../services/graphql';
import { Loader, Button, FormErrors } from '@axeedge/go-teacher-components';
import { ArrowLeft } from 'react-feather';

const BookTemplateCategories = ({ formErrors, setSelectedPack, selectedPack, onAssignPack }) => {

    const {data, loading, error} = useQuery(GET_BOOK_TEMPLATE_CATEGORIES);

    if (error) {
        return (<p>{error.message}</p>)
    }

    if (loading) {
        return <Loader/>
    }

    if (data && data.bookTemplateCategories) {
       return(
            <Fragment>
                <h2>Select type</h2>
                <button onClick={() => setSelectedPack(null)} className='btn-reset'><ArrowLeft /></button>
                <p className='u-m-y-2'>Select which type of book you would like this class to do:</p>
                {formErrors && <FormErrors errors={formErrors} />}
                <ul className='list-group u-m-base-2'>
                    {
                        data.bookTemplateCategories.map(bookTemplateCateg => (
                            <li className='list-item' key={`bt-${bookTemplateCateg.id}`}>
                                <button type='button' className='btn-reset link-reverse' onClick={() => onAssignPack(selectedPack, bookTemplateCateg)}>
                                    {bookTemplateCateg.name}
                                </button> 
                            </li>
                        ))
                    } 
                </ul>
                <Button type='button' onClick={() => onAssignPack(selectedPack)}>Let teacher choose</Button>
            </Fragment>
       )
    }

    return null
}


export default BookTemplateCategories
