// Queries and mutations for the Staff scene

import { gql } from 'apollo-boost';

export const ADD_TEACHER_MUTATION = gql`
mutation addTeacher($schoolId: ID!, $firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $isSchoolAdmin: Boolean!, $roleId: Int!) {
    addTeacher(schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, isSchoolAdmin: $isSchoolAdmin, roleId: $roleId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            roleId
            school {
                id
            }
        }
        errors
    }
}
`;

export const EDIT_TEACHER_MUTATION = gql`
mutation editTeacher($teacherId: ID!, $schoolId: ID!, $firstName: String, $lastName: String, $displayName: String, $email: String, $isSchoolAdmin: Boolean!, $roleId: Int!) {
    editTeacher(teacherId: $teacherId, schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, isSchoolAdmin: $isSchoolAdmin, roleId: $roleId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            roleId
            school {
                id
            }
        }
        errors
    }
}
`;

export const REMOVE_TEACHER_FROM_SCHOOL = gql`
mutation removeTeacherFromSchool($teacherId: ID!, $schoolId: ID!) {
    removeTeacherFromSchool(teacherId: $teacherId, schoolId: $schoolId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            school {
                id
            }
        }
        errors
    }
}
`;

export const READD_TEACHER_TO_SCHOOL = gql`
mutation reAddTeacherToSchool($teacherId: ID!, $schoolId: ID!) {
    reAddTeacherToSchool(teacherId: $teacherId, schoolId: $schoolId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            roleId
            school {
                id
            }
        }
        errors
    }
}
`;

export const UPDATE_SCHOOL_MEMBERSHIPS = gql`
mutation updateSchoolMemberships($teacherIds: [ID!]!, $schoolId: ID!, $isSchoolAdmin: Boolean!) {
    updateSchoolMemberships(teacherIds: $teacherIds, schoolId: $schoolId, isSchoolAdmin: $isSchoolAdmin) {
        school {
            id
        }
        errors
    }
}
`;

export const GET_TEACHER = gql`
query teacher($teacherId: ID!) {
    teacher(teacherId: $teacherId) {
        id
        active
        firstName
        lastName
        displayName
        email
        isSchoolAdmin
        roleId
        school {
            id 
            classes {
                id
                name
            }
        }
        studentsClasses {
            id
            name
            teachers {
                id
                displayName
            }
        }
    }
}
`;

export const GET_OTHER_TEACHERS = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        removedTeachers{
            id
            firstName
            lastName
            displayName
            inactiveSchoolMemberships {
                id
                school {
                    id
                }
                removedAt
                removedBy {
                    id
                    displayName
                }
            }
        }
        joinRequests {
            id
            approved
            roleId
            teacher {
                id
                displayName
                firstName
                lastName
                name
                email
                roleId
            }
        }
    }
}
`;



export const RESEND_INVITE_EMAIL = gql`
query resendInviteEmail($teacherId: ID!) {
    resendInviteEmail(teacherId: $teacherId)
}
`;

export const SEND_STAFF_INVITES = gql`
mutation sendStaffInvites($schoolId: ID!, $staffDetails: [SendStaffInvitesAttributes!]!) {
    sendStaffInvites(schoolId: $schoolId, staffDetails: $staffDetails) {
        errors
        teachers {
            id
            active
            displayName
            email
            firstName
            lastName
            isSchoolAdmin
            token
            expiredToken
            roleId
        }
    }
}
`;


export const APPROVE_TEACHER_JOIN_REQUEST = gql`
mutation approveTeacherJoinRequest($joinRequestId: ID!, $roleId: Int!, $approved: Boolean!, $isSchoolAdmin: Boolean!) {
    approveTeacherJoinRequest(joinRequestId: $joinRequestId, roleId: $roleId, approved: $approved, isSchoolAdmin: $isSchoolAdmin) {
        errors
        teacher {
            id
        }
    }
}
`;


export const ADD_TEACHER_TO_CLASSES = gql`
mutation addTeacherToMultipleClasses($teacherId: ID!, $studentsClassesId: [ID!]!) {
    addTeacherToMultipleClasses(teacherId: $teacherId, studentsClassesId: $studentsClassesId) {
        errors
        studentsClasses {
            id
            name
        }
        teacher {
            id
        }
    }
}
`;

