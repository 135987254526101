import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useEffectOnce } from 'react-use';
import ls from 'local-storage';
import { Redirect, Router } from '@reach/router';

import { Loader } from '@axeedge/go-teacher-components';
import { getAppTokenName, TERMS_VERSION } from '@axeedge/go-shared-utils';
import { ErrorPage, Ssl } from '@axeedge/go-shared-components';

import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGGED_IN } from '../services/graphql';
import { APP_NAME, APP_NAME_US, SCHOOL_MATH_SETUP_STEPS, SCHOOL_SETUP_STEPS } from '../../../services/constants';

import Logout from '../../Logout';
import RedirectAfterLogin from '../../Login/scenes/RedirectAfterLogin';
import Dashboard from '../../Dashboard';
import TeacherProfile from '../../TeacherProfile';
import SchoolProfile from '../../SchoolProfile';
import Teachers from '../../Teachers';
import Classes from '../../Classes';
import Students from '../../Students'
import ClassView from '../../Classes/scenes/ClassView';
import ArchivedClassView from '../../Classes/scenes/ClassView/ArchivedClassView';

import NewAcademicYear from '../../Classes/scenes/NewAcademicYear';
import GetReady from '../../Classes/scenes/GetReady'
import NewYear from '../../NewYear'
import HelpVideo from '../../Classes/scenes/HelpVideo';
import MoveClasses from '../../Classes/scenes/MoveClasses';
import MoveClass from '../../Classes/scenes/MoveClass';
import MoveGroup from '../../Classes/scenes/MoveGroup';
import NewIntake from '../../Classes/scenes/NewIntake';
import LoggedInRouter from './LoggedInRouter';
import GoWriteSettings from '../../GoWriteSettings';
import PurchasePacks from '../../GoWriteSettings/scenes/PurchasePacks';
import PurchaseActivities from '../../GoWriteSettings/scenes/PurchaseActivities';
import GoReadSettings from '../../GoReadSettings';
import PurchaseSubscription from '../../GoReadSettings/scenes/PurchaseSubscription';
import Onboarding from '../../Onboarding';

import RouteCapture from './RouteCapture';
import Login from '../../Login';
import SetPassword from '../../Login/scenes/SetPassword';
import ResetSent from '../../Login/scenes/ResetSent';
import ConfirmEmail from '../../Login/scenes/ConfirmEmail';
import AcceptInvite from '../../Login/scenes/AcceptInvite';
import Impersonate from '../../Impersonate';
import FaqRead from '../../Faq/FaqRead';
import FaqWrite from '../../Faq/FaqWrite';
import TermsUpdate from '../../TermsUpdate'
import Settings from '../../Settings';
import Billing from '../../Settings/scenes/Billing';
import BoomMathSettings from '../../Settings/scenes/BoomMathSettings'
import WondeLogin from '../../Login/scenes/WondeLogin';
import BetaWondeLogin from '../../Login/BetaWondeLogin';

import SignUp from '../../SignUp';
import SignUpConfirm from '../../SignUp/scenes/SignUpConfirm';
import RegisterInterest from '../../RegisterInterest';
import WondeSync from '../../WondeSync';
const RouterAuthQuery = () => {
    const { data, error, loading } = useQuery(LOGGED_IN);
    const [ready, setReady] = useState(false);

    const [school, setSchool] = useState();


    const auth = useContext(AuthContext);

    useEffect(() => {
        if (ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME)) && !auth.currentUser && ls('go_teacher_user') && ls('go_teacher_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_teacher_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME)) && !auth.currentUser && ls('go_teacher_user') && ls('go_teacher_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_teacher_user')));
        }
        setReady(true);
    });

    const isOnboardingDone = (user, school) => {
        if (ls('country') == 'US') {
            return user.isSchoolAdmin && school && school.setupStep !== null && school.setupStep < SCHOOL_MATH_SETUP_STEPS.finalStepDone
        }
        return user.isSchoolAdmin && school && school.setupStep !== null && school.setupStep < SCHOOL_SETUP_STEPS.finalStepDone
    }


    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return <Loader />
        }

        if (data && ready) {
            return (
                <Router>
                    <RouteCapture path='/'>
                        <RegisterInterest path='/interest/grandTour' />
                        <RegisterInterest path='/interest/writeToRaise' isWriteToRaise={true} />
                        {
                            auth.currentUser ? (
                                <LoggedInRouter school={school} setSchool={setSchool} path='/' default>
                                    <RedirectAfterLogin path='/redirectAfterLogin/:app' />
                                    <Logout path='/logout/*' />

                                    {(!auth.currentUser.lastTermsVersionApproved || parseInt(auth.currentUser.lastTermsVersionApproved) < TERMS_VERSION) ?
                                        <TermsUpdate path='/termsUpdate' default /> :
                                        school === null || isOnboardingDone(auth.currentUser, school) ? (

                                            // school === null || (auth.currentUser.isSchoolAdmin && school && school.setupStep !== null && school.setupStep < SCHOOL_SETUP_STEPS.finalStepDone) ? (
                                            <Onboarding path='/' default />
                                        ) : school && (
                                            <Fragment>
                                                <Dashboard path='/' default />
                                                <TeacherProfile path='/profile' duringSetup={false} />
                                                <Settings path='/settings'>
                                                    <SchoolProfile path='schoolProfile' default />
                                                    <Billing path='billing' />
                                                    <GoWriteSettings path='boomwriter' />
                                                    <PurchasePacks path='boomwriter/purchase' />
                                                    <PurchaseActivities path='boomwriter/purchaseActivities' />
                                                    <GoReadSettings path='boomreader' />
                                                    <BoomMathSettings path='boommath' />
                                                    <PurchaseSubscription path='boomreader/purchase' />
                                                </Settings>
                                                <Redirect from='/gowrite/purchase' to='/settings/gowrite/purchase' />
                                                <Students path='/students' />
                                                <Classes path='/classes' />
                                                <ClassView path='/classes/:classid' />
                                                <ArchivedClassView path='/classes/archived/:classid' />
                                                <NewAcademicYear path='/classes/newAcademicYear' />
                                                <WondeSync path='/misUpdate' />
                                                <NewYear path='/newAcademicYear' />
                                                <GetReady path='/classes/getReady' />
                                                <HelpVideo path='/classes/newAcademicYearStart' />
                                                <MoveClasses path='/classes/moveClasses' />
                                                <MoveClasses path='/classes/moveGroups' groups={true} />
                                                <MoveClass path='/classes/moveClass/:classid' />
                                                <MoveGroup path='/classes/moveGroup/:classid' />
                                                <NewIntake path='/classes/newIntake' />
                                                <Teachers path='/teachers/' />
                                                <FaqRead path='/faqRead' />
                                                <FaqWrite path='/faqWrite' />
                                            </Fragment>
                                        )
                                    }
                                </LoggedInRouter>
                            ) : (
                                <Fragment>
                                    <Login path='/' default />
                                    <BetaWondeLogin path="/beta" />
                                    <Redirect from='/logout' to='/' noThrow />
                                    {/* <SignUp path='/signup' /> */}
                                    {["/signup", "/signup/write", "signup/leavers", "/signup/read"].map(page => <SignUp key={page} path={page} />)}
                                    <SignUpConfirm path='/signup_confirm' />
                                    <AcceptInvite path='/accept_invite/:token' />
                                    <RedirectAfterLogin path='/redirectAfterLogin/:app' />
                                    <SetPassword path='/setpassword/:token/*' />
                                    <SetPassword path='/invite/:token' />
                                    <ConfirmEmail path='/confirm_email/:token/*' />
                                    <ResetSent path='/reset_sent' />
                                    <ErrorPage path='/error' />
                                    <Impersonate path='/impersonate/:id/:token' />
                                    <WondeLogin path='/wondeLogin' />
                                    {/* <Redirect from='/interest/grandTour' to='/signup/read' />
                                    <Redirect from='/interest/writeToRaise' to='/signup/write' /> */}
                                </Fragment>
                            )
                        }
                    </RouteCapture>
                </Router >
            )
        }
    }

    return (
        <Fragment>
            <Ssl />
            {renderContent()}
        </Fragment>
    );

}

export default RouterAuthQuery;
