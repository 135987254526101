import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useLazyQuery } from "@apollo/react-hooks";
import { SCHOOL_BY_CODE } from '../../services/graphql';
import { Button } from '@axeedge/go-teacher-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import cx from 'classnames';
import styles from '../../Onboarding.module.scss';
import ls from 'local-storage';

const SearchSchool = ({ close, setSchool }) => {

    const [schoolError, setSchoolError] = useState(null);

    const [getSchool, { loading }] = useLazyQuery(SCHOOL_BY_CODE, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.schoolByCode) {
                setSchool(data.schoolByCode);
                setSchoolError(null);
            }
            else {
                setSchoolError('No School with this code found!');
            }
        }
    });

    const schema = Yup.object().shape({
        schoolCode: Yup.string().required('Please enter school code')
    });
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });
    const onSubmit = ({ schoolCode }) => {
        getSchool({ variables: { schoolCode } })
    }


    return (
        <div className={styles.onboarding}>
            <div>
                {
                    ls('country') === 'US' ?
                        <> <h2>Welcome to BoomMath!</h2>
                            <p>Please choose from the following options:</p>
                        </>
                        :
                        <>
                            <h2>Welcome to BoomHub!</h2>
                            <p>The home of BoomReader & BoomWriter</p>
                            <p>Let's connect you to your school, or setup a new school account</p>
                        </>
                }
                <form className={styles.joinSchool} onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="heavy">Enter your school's code</h3>
                    <div className="basic-form__group">
                        <label htmlFor="schoolCode" className="basic-form__text-label">Enter school code</label>
                        <input
                            className="basic-form__text-box"
                            name="schoolCode"
                            ref={register}
                            type="text"
                        />
                    </div>
                    {schoolError && <p className={cx(styles.joinSchoolError, 'u-m-base-2')}>{schoolError}</p>}
                    {errors.schoolCode && <p className="basic-form__hint">{errors.schoolCode.message}</p>}
                    <div className="u-display-flex u-align-center">
                        <Button type="submit" primary className='u-m-right-2'>{loading ? 'Loading...' : 'Join My School'}</Button>
                        <Button onClick={() => close()} type="button" outline>Cancel</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SearchSchool;