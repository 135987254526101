import React from 'react';
import { Button } from '@axeedge/go-teacher-components';
import moment from 'moment';
import styles from './ActiveTrialSubscription.module.scss';
import ButtonLink from '../../../../components/ButtonLink';

const ActiveTrialSubscription = ({ subscription, setShowPricing }) => {

    const now = moment();
    const daysUntilTrialEnds = now.diff(moment(subscription.expiresAt.substring(0, 10)), 'days');

    return (
        <>
            <div className={styles.header}>
                <h2 className='heavy u-m-base-0'>Trial ends in:  {daysUntilTrialEnds.toString().substring(1)} days </h2>
                <span className={styles.headerLabel}>Trial</span>
            </div>
            <p className='u-m-base-1'>Trial started: {moment(subscription.createdAt.substring(0, 10)).format('MMMM DD, YYYY')}</p>
             <p className='u-m-base-2'>Trial ends: {moment(subscription.expiresAt.substring(0, 10)).format('MMMM DD, YYYY')}</p> 
            <hr className='separator' />
            <p className='heavy u-m-base-2'>You’ll be able to start your subscription near the end of your trial.</p>
            <ButtonLink to='/settings/boomreader/purchase'>Start subscription</ButtonLink>
            <hr className='separator u-m-top-3' />
            <p className='heavy u-m-base-3'>You trial will automatically cancel if you don't continue with a paid subscription</p>
            <div className='panel'>
                <h4 className='heavy'>Pricing</h4>
                <p className='u-m-base-2'>BoomReader uses a simple per student pricing model. To find out how much BoomReader will cost you can use our simple pricing calculator.</p>

                <p className='heavy u-m-base-2'>Additional Features</p>
                <p className='u-m-base-1'><b>MIS Integration - BoomReader</b> fully integrates with your school MIS to allow easy management of classes, students and staff.</p>
                <p className='u-m-base-2'>£40 admin fee per year</p>

                <p className='u-m-base-1'><b>Data Dashboard</b> - Monitor levels of reading engagement, compare reading trends and parental engagement.</p>
                <p className='u-m-base-2'>£1/pupil</p>

                <Button outline onClick={() => setShowPricing(true)}>Get a quote</Button>
            </div>
        </>
    )
}

export default ActiveTrialSubscription