import React, { useState, useEffect } from "react";
import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';
import { CLASS_TYPE } from "../../../../services/constants";
import { FINALIZE_CLASSES_IMPORT } from "../../services/graphql";
import { useMutation } from "@apollo/react-hooks";
import PreviewClassPupils from "./PreviewClassPupils";
import { ArrowLeft } from 'react-feather';
import styles from './UploadClassesPupils.module.scss';

import styles2 from '../../../Teachers/components/StaffAccess/StaffAccess.module.scss';
import cx from 'classnames';
import ImportSummaryErrors from "./ImportErrorsSummary";
import ls from 'local-storage';

const ManageClassesImportData = ({ setManageDone, manageUploadData, close, progress, isOnboarding, onUpdateSetupStep, updatingStep, finalizeErrors }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [classTypeError, setClassTypeError] = useState(null);

    const [showPupilsPreview, setShowPupilsPreview] = useState(false);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [classes, setClasses] = useState([]);

    const getTotalStudentsToImport = (stClasses) => {
        return stClasses.map(cls => cls.studentsCounter).reduce((a, b) => a + b, 0)
    }

    useEffect(() => {
        if (manageUploadData.importTicket.importStudentsClasses.length > 0) {
            setClasses(manageUploadData.importTicket.importStudentsClasses)
        }

    }, [manageUploadData]);

    useEffect(() => {
        if (!classes.find(el => !el.typeId) && classTypeError) {
            setClassTypeError(null);
        }
    }, [classes, classTypeError])



    const [finalizeClassesImport, { loading }] = useMutation(FINALIZE_CLASSES_IMPORT, {
        update: (_, { data }) => {
            if (data.finalizeClassesImport.errors && data.finalizeClassesImport.errors.length !== 0) {
                setFormErrors(data.finalizeClassesImport.errors);
                return;
            }
            if (data?.finalizeClassesImport?.importTicket?.id) {
                //just done, info comes via cable;
            }
        }
    });

    const finalizeImport = () => {
        if (classes.find(el => !el.typeId)) {
            setClassTypeError(true);
            return;
        }

        const classDetails = [];
        classes.forEach(el => {
            classDetails.push({
                importStudentsClassId: el.id,
                visibleToAllTeachers: el.visibleToAllTeachers ? true : false,
                typeId: el.typeId,
            })
        });

        finalizeClassesImport({
            variables: {
                classDetails: classDetails,
                importTicketId: manageUploadData.importTicket.id
            }
        });
    }



    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(c => c !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const onSelectAllClasses = e => {
        const selectedIds = classes.map(c => c.id);
        e.target.checked ? setSelectedClasses(selectedIds) : setSelectedClasses([])
    }


    const onChangeField = (field, val, i) => {
        const updatedClasses = [...classes];
        const value = field === 'visibleToAllTeachers' || field === 'isMyClass' ? Boolean(+val) : +val;
        updatedClasses[i][field] = value;
        setClasses(updatedClasses);
    }

    const onChangeFieldForAll = (field, val) => {
        const value = field === 'visibleToAllTeachers' ? Boolean(+val) : +val;
        const updatedClasses = classes.map(el => {
            return selectedClasses.find(c => c === el.id) ? { ...el, [field]: value } : el
        });

        setClasses([...updatedClasses]);
    }

    return (
        <>

            <h2 className='u-m-base-2  u-display-flex u-align-center'>
                <button className='btn-reset u-display-flex u-align-center' onClick={close}><ArrowLeft className='u-m-right-1' /></button>
                Results preview
            </h2>

            {manageUploadData.importTicket.notValidStudents.length > 0 ? (
                <ImportSummaryErrors
                    close={close}
                    notValidStudents={manageUploadData.importTicket.notValidStudents}
                    updatingStep={updatingStep}
                    isOnboarding={isOnboarding}
                    onUpdateSetupStep={onUpdateSetupStep}
                    ticketId={manageUploadData.importTicket.id}
                    setManageDone={setManageDone}
                />
            ) : (
                <>
                    <h3>We found {classes.length} classes and {getTotalStudentsToImport(classes)} pupils</h3>
                    {progress ?
                        <div className={cx(styles.panel, 'u-m-base-3')}>
                            <h2>Importing data, please wait</h2>
                            <p className='heavy'>Progress: {progress}%</p>
                        </div> :
                        <ul className='u-m-base-2'>
                            <li className={cx(styles2.staffItem, styles2.staffItemHeader)}>
                                <div className={styles2.staffItemMain}>
                                    <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                        <input
                                            name="all"
                                            className="basic-form__check-box"
                                            type="checkbox"
                                            checked={selectedClasses.length === classes.length}
                                            onChange={(e) => onSelectAllClasses(e)}
                                            id="selectAll"
                                        />
                                        <label className="basic-form__check-label" htmlFor='selectAll'>All / None</label>
                                    </div>
                                </div>
                                <div className={styles2.staffItemActions}>
                                    <div className={styles2.staffItemActionsItem}>
                                        <select value='' disabled={selectedClasses.length === 0} onChange={(e) => onChangeFieldForAll('visibleToAllTeachers', e.target.value)} name="visibleAll" className={cx("basic-form__text-select", styles2.select)}>
                                            <option value=''>Visibility</option>
                                            <option value={1}>Visible To All</option>
                                            <option value={0}>Named Teachers Only</option>
                                        </select>
                                    </div>

                                    <div className={styles2.staffItemActionsItem}>
                                        <select value='' disabled={selectedClasses.length === 0} onChange={(e) => onChangeFieldForAll('typeId', e.target.value)} name="typeAll" className={cx("basic-form__text-select", styles2.select)}>
                                            <option value=''>Class Type</option>
                                            <option value={CLASS_TYPE.registration}>Registration</option>
                                            <option value={CLASS_TYPE.other}>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                            {classes.map((c, i) => (
                                <li key={`kls-${c.id}`} className={styles2.staffItem}>
                                    <div className={styles2.staffItemMain}>
                                        <div className="basic-form__group basic-form__group--check u-m-base-0">
                                            <input
                                                type='checkbox'
                                                className="basic-form__check-box"
                                                value={c.id}
                                                onChange={(e) => onSelectClass(e)}
                                                id={`c-${c.id}`}
                                                checked={selectedClasses.indexOf(c.id) > -1}
                                            />
                                            <label className="basic-form__check-label" htmlFor={`c-${c.id}`}>&nbsp;</label>
                                        </div>
                                        {c.name} ({c.studentsCounter} pupils) <button onClick={() => setShowPupilsPreview(c)} className='btn-reset link-underline u-m-left-2'>Preview</button>
                                    </div>
                                    <div className={styles2.staffItemActions}>

                                

                                        <div className={cx(styles2.staffItemActionsItem, "basic-form__group basic-form__group--check u-m-base-0")}>
                                            <span className="u-m-right-1">Visible To All</span>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => onChangeField('visibleToAllTeachers', e.target.checked, i)}
                                                className="switchToggle"
                                                name='clsVisible'
                                                id={`cls-${i}`}
                                                checked={c.visibleToAllTeachers || false}
                                            />
                                            <label className="switchLabel" htmlFor={`cls-${i}`}>&nbsp;</label>
                                        </div>
                                        <div className={styles2.staffItemActionsItem}>
                                            <select value={c.typeId || ''} onChange={(e) => onChangeField('typeId', e.target.value, i)} name="classTyle" className={cx("basic-form__text-select", styles2.select)}>
                                                <option value=''>Select class type</option>
                                                <option value={CLASS_TYPE.registration}>Registration</option>
                                                <option value={CLASS_TYPE.other}>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    }
                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}

                    {classTypeError && <p className="basic-form__hint u-m-top-2">Please select a type for each class</p>}

                    {
                        finalizeErrors.length > 0 ?
                            <>
                                <FormErrors errors={finalizeErrors} />
                                <Button onClick={onUpdateSetupStep} disabled={updatingStep}>{updatingStep ? 'Saving...' : 'Continue'}</Button>
                            </>
                            : !progress && <Button onClick={() => finalizeImport()} disabled={updatingStep || loading}>{updatingStep || loading ? 'Saving...' : 'Create classes & pupils'}</Button>
                    }

                </>
            )}

            {
                showPupilsPreview &&
                <Modal closeModal={() => setShowPupilsPreview(null)}>
                    <PreviewClassPupils stClass={showPupilsPreview} closePreview={() => setShowPupilsPreview(null)} />
                </Modal>
            }
        </>
    )
}

export default ManageClassesImportData;