import React from 'react'
import PageHeader from '../../../components/PageHeader';
import { Button  } from '@axeedge/go-teacher-components';
import {Users} from 'react-feather'
import Students from '../../Students'
import styles from '../NewYear.module.scss'

const Step2 = ({setCurrentStep}) => {

    return (
        <>
            <div className={styles.stepContainer}>
                <PageHeader title={`1 Update current pupil info`} icon={Users} close={() => setCurrentStep(1)} showBackLink={false} />
                <p className="u-m-base-2">Please carefully review your current students and update or enter any missing information:</p>
                <p className="u-m-base-2">Use the filtering on the left to identify: Students with no year group, students without a UPN and any duplicates.</p>
                <p className="u-bold u-m-base-2">Please note: This is missing information for the current <span className="u-underline">2023/24</span> academic year.</p>
                <Students source={'NewYear'} />
            </div>
            <div className={styles.stepFooter}>
                <Button onClick={() => setCurrentStep(3)}>Next</Button>
            </div>
        </>
    )
}

export default Step2