import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { AuthContext } from '../../../services/auth/AuthProvider';

import { EDIT_SCHOOL_MUTATION } from '../services/graphql';
import { GET_COUNTRIES, GET_STATES } from '../../../services/school/graphql';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';


const EditAddress = ({ close, setShowSaveConfirm }) => {

    const { t } = useTranslation(['profile', 'common']);
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [countries, setCountries] = useState([]);
    const [uk, setUk] = useState(null);
    const [states, setStates] = useState([]);

    const [formErrors, setFormErrors] = useState([]);

    const isUS = ls('country') === 'US';

    const { data: statesData } = useQuery(GET_STATES, {
        onCompleted: (data) => {
            setStates(data.statesByCountry);
        },
        variables: { country: 'USA' }
    });

    const { data, loading, error } = useQuery(GET_COUNTRIES, {
        onCompleted: (data) => {
            setCountries(data.countriesList);
        }
    });

    useEffect(() => {
        if (countries.length > 0) {
            setUk(countries.find(c => c.code === 'GBR'));
        }
    }, [countries]);

    const [editSchool, { loading: savingSchool }] = useMutation(EDIT_SCHOOL_MUTATION, {
        update: (_, { data }) => {
            if (data.editSchool.errors && data.editSchool.errors.length !== 0) {
                setFormErrors(data.editSchool.errors);
                return;
            }
            if (data.editSchool.school.id) {
                const cu = { ...currentUser, school: data.editSchool.school }
                setCurrentUser(cu);
                setShowSaveConfirm(true);
                close();
            }
        },
    });

    const schema = Yup.object().shape({
        name: Yup.string().required(t('please_enter_a_school_name')),
        address: Yup.string().required(t('please_enter_school_address')),
        city: Yup.string().required(t('please_enter_city')),
        zip: Yup.string().required(t('please_enter_postcode')),
        schoolPhoneNumber: Yup.string().required('Please enter telephone'),
        ...isUS && {
            state: Yup.string().required('Please select state'),
        },
        ...!isUS && {
            countryCode: Yup.string().required('Please select country'),
        },
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const onSubmit = values => {
        const { name, address, address2, city, zip, countryCode, schoolPhoneNumber, state } = values;
        editSchool({
            variables: {
                schoolId: currentUser.school.id,
                name,
                address,
                address2,
                city,
                zip,
                schoolPhoneNumber,
                financeOfficerName: currentUser.school.financeOfficerName,
                financeOfficerEmail: currentUser.school.financeOfficerEmail,
                financeOfficerPhone: currentUser.school.financeOfficerPhone,
                localAuthority: currentUser.school.localAuthority,
                country: isUS ? 'USA' : countryCode,
                state,
            }
        });
    }

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.countriesList && countries.length > 0) {
        return (
            <>
                <h2>{t('update_address')}</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='basic-form__group'>
                        <label htmlFor='name'>{t('school_name')}</label>
                        <input
                            name='name'
                            placeholder={t('school_name')}
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={currentUser.school && currentUser.school.name}
                            type='text'
                        />
                    </div>
                    {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                    <div className='basic-form__group'>
                        <label htmlFor='address'>{t('address_1')}</label>
                        <input
                            name='address'
                            placeholder={t('address_1')}
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={currentUser.school && currentUser.school.address}
                            type='text'
                        />
                    </div>
                    {errors.address && <p className='basic-form__hint'>{errors.address.message}</p>}
                    <div className='basic-form__group'>
                        <label htmlFor='address2'>{t('address_2')}</label>
                        <input
                            name='address2'
                            placeholder={t('address_2')}
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={currentUser.school && currentUser.school.address2}
                            type='text'
                        />
                    </div>
                    <div className='basic-form__group'>
                        <label htmlFor='city'>{t('city')}</label>
                        <input
                            name='city'
                            placeholder={t('city')}
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={currentUser.school && currentUser.school.city}
                            type='text'
                        />
                    </div>
                    {errors.city && <p className='basic-form__hint'>{errors.city.message}</p>}
                    <div className='basic-form__group'>
                        <label htmlFor='zip'>{t('postcode')}</label>
                        <input
                            name='zip'
                            placeholder={t('postcode')}
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={currentUser.school && currentUser.school.zip}
                            type='text'
                        />
                    </div>
                    {errors.zip && <p className='basic-form__hint'>{errors.zip.message}</p>}

                    {isUS && states && states.length > 0 ?
                        <div className='basic-form__group'>
                            <label htmlFor='state' className='basic-form__text-label'>State</label>
                            <select defaultValue={currentUser.school.state && currentUser.school.state.code} name="state" ref={register} className="basic-form__text-select">
                                <option value=''>Please select state</option>
                                {states.map(state => (
                                    <option key={`c-${state.code}`} value={state.code}>{state.name}</option>
                                ))}
                            </select>
                            {errors.state && <p className='basic-form__hint u-m-top-1'>{errors.state.message}</p>}
                        </div>
                        :
                        <>
                            <div className='basic-form__group'>
                                <label htmlFor='zip'>Country</label>
                                <select
                                    defaultValue={currentUser.school.country && currentUser.school.country.code}
                                    name="countryCode"
                                    ref={register}
                                    className="basic-form__text-select"
                                >
                                    <option value=''>Please select country</option>
                                    {uk && (
                                        <option value={uk.code}>{uk.name}</option>
                                    )}
                                    <option disabled>──────────</option>
                                    {countries.map((country, i) => (
                                        <option key={`c-${country.code}`} value={country.code}>{country.name}</option>
                                    ))}
                                </select>
                            </div>

                            {errors.countryCode && <p className='basic-form__hint'>{errors.countryCode.message}</p>}
                        </>
                    }


                    <div className='basic-form__group'>
                        <label htmlFor='schoolPhoneNumber'>Telephone</label>
                        <input
                            name='schoolPhoneNumber'
                            placeholder='School phone number'
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={currentUser.school && currentUser.school.schoolPhoneNumber}
                            type='text'
                        />
                    </div>
                    {errors.schoolPhoneNumber && <p className='basic-form__hint'>{errors.schoolPhoneNumber.message}</p>}

                    {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                    <div className='u-display-flex u-align-center'>
                        <Button type='submit' primary className='u-m-right-1'>{savingSchool ? t('common:saving') : t('common:button.save_changes')}</Button>
                        <Button
                            outline
                            onClick={() => close()}
                            type='button'
                        >
                            {t('common:button.cancel')}
                        </Button>

                    </div>
                </form>
            </>
        )
    }
    return null;
}
export default EditAddress;