import React, { useState, useContext, useEffect } from 'react';
import { BOOMREAD_PRICING } from '../../services/graphql';
import { READ_SUB_DURATION } from '../../../../services/constants';
import { useQuery } from '@apollo/react-hooks';
import { ArrowLeft, CheckSquare, Square } from 'react-feather';
import SelectStudents from '../../scenes/PurchaseSubscription/components/SelectStudents';
import styles from '../../scenes/PurchaseSubscription/components/Payment/Payment.module.scss';
import cx from 'classnames';
import moment from 'moment';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { useTranslation } from 'react-i18next';

const PriceQuote = ({ close }) => {

    const { t } = useTranslation(['apps']);
    const { currentUser: user } = useContext(AuthContext)

    const { data } = useQuery(BOOMREAD_PRICING);

    if (data && data.boomReadPricing) {
        const { boomReadPricing } = data;
        return (
            <>
                <div className="u-display-flex u-align-center u-m-base-3">
                    <button onClick={close} className='btn-reset u-display-flex u-align-center'><ArrowLeft className='u-m-right-1' /></button>
                    <h3 className='u-m-base-0'>BoomReader Quote</h3>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <p className='u-m-base-2'>Please use this form to calculate the pricing for your school. This information can be used to generate any Purchase Orders that your school needs</p>
                        <QuoteSummary boomReadPricing={boomReadPricing} />

                        <p className='u-m-top-2 u-m-base-2 heavy'>Info for Purchase Orders</p>
                        <p className='u-m-base-2'>Please use or pass on the following information to create/issue purchase orders:</p>

                        {user?.school?.billingProfile && (
                            <>
                                <p><b>{user.school.billingProfile.companyName}</b></p>
                                <div className="u-m-base-1">
                                    <p>{user.school.billingProfile.address}</p>
                                    <p>{user.school.billingProfile.address2}</p>
                                    <p>{user.school.billingProfile.city}</p>
                                    <p>{user.school.billingProfile.state}</p>
                                    <p>{user.school.billingProfile.zip}</p>
                                    <p>{user.school.billingProfile.country?.name}</p>
                                </div>
                                <div className="u-m-base-2">
                                    <p>Contact: {user.school.billingProfile.contactPerson}</p>
                                    <p>Email: {user.school.billingProfile.contactEmail}</p>
                                    <p>Phone: {user.school.billingProfile.contactPhone}</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
    return null;
}

const QuoteSummary = ({ boomReadPricing }) => {

    const [totalStudents, setTotalStudents] = useState(0);
    const { t } = useTranslation(['apps']);

    const [subDuration, setSubDuration] = useState(READ_SUB_DURATION.oneYear);
    const now = moment();

    const [additionalFeatures, setAdditionalFeatures] = useState({
        wondeAndDataDash: true,
        wondeOnly: false
    })

    const price = subDuration === READ_SUB_DURATION.oneYear ? boomReadPricing.oneYearPricePerStudent : boomReadPricing.twoYearPricePerStudent;
    const subscriptionAmount = parseFloat(totalStudents * price).toFixed(2);
    const wondePrice = 40;

    const wondeAmount = totalStudents > 0 ? (additionalFeatures.wondeOnly || additionalFeatures.wondeAndDataDash ? wondePrice : 0) : 0;

    const dataDashAmount = additionalFeatures.wondeAndDataDash ? parseFloat(totalStudents * 1) : 0;

    const vatAmount = parseFloat((+subscriptionAmount + +wondeAmount + +dataDashAmount) * 0.2).toFixed(2);
    const totalAmount = parseFloat(+subscriptionAmount + +wondeAmount + +dataDashAmount + +vatAmount).toFixed(2);
 

    const renderPaymentSummary = () => {
        return (
            <>
                {subDuration === READ_SUB_DURATION.twoYear && <p><b>Year 1: Invoiced now</b></p>}
                <ul className={styles.paymentSummary}>
                    <li className={styles.paymentSummaryItem}>
                        {totalStudents} students at £{subDuration === READ_SUB_DURATION.oneYear ? boomReadPricing.oneYearPricePerStudent : boomReadPricing.twoYearPricePerStudent}/student
                        <div className={styles.paymentSummaryData}>
                            <span className={styles.paymentSummaryDataValue}>£{subscriptionAmount}</span>
                        </div>
                    </li>

                    {additionalFeatures.wondeAndDataDash && (
                        <li className={styles.paymentSummaryItem}>
                            Data dashboard & MIS Integration
                            <div className={styles.paymentSummaryData}>
                                <span className={styles.paymentSummaryDataValue}>£{dataDashAmount + wondeAmount}</span>
                            </div>
                        </li>
                    )}

                    {additionalFeatures.wondeOnly && (
                        <li className={styles.paymentSummaryItem}>
                           BoomReader Administration fee
                            <div className={styles.paymentSummaryData}>
                                <span className={styles.paymentSummaryDataValue}>£{wondeAmount}</span>
                            </div>
                        </li>
                    )}

                    <li className={styles.paymentSummaryItem}>
                        {t('vat_value')}
                        <div className={styles.paymentSummaryData}>
                            <span className={styles.paymentSummaryDataValue}>£{vatAmount}</span>
                        </div>
                    </li>
                    <li className={cx(styles.paymentSummaryItem, styles.paymentSummaryItemTotal)}>
                        {t('total')}
                        <div className={styles.paymentSummaryData}>
                            <span className={styles.paymentSummaryDataValue}>£{totalAmount}</span>
                        </div>
                    </li>
                    {subDuration === READ_SUB_DURATION.twoYear && (
                        <>
                            <p><b>Year 2: Invoiced {moment(now).add(1, 'year').format('DD MMM YYYY')} </b></p>
                            <p>£{boomReadPricing.twoYearPricePerStudent}/student</p>
                            {additionalFeatures.wondeAndDataDash && (
                                <p>Data Dashboard: £{dataDashAmount + wondeAmount.toFixed(2)}</p>
                            )}
                            {additionalFeatures.wondeOnly && (
                                <p>BoomReader Administration fee: £{wondeAmount}</p>
                            )}
                            <p>You'll be able to update/confirm your student numbers.</p>
                        </>
                    )}
                </ul>
            </>
        )
    }

    return (
        <>
            <SelectStudents setTotalStudents={setTotalStudents} />
            <ul className={styles.subType}>
                <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: subDuration === READ_SUB_DURATION.oneYear })}>
                    <div className={styles.subTypeMain}>
                        <p><b>1 Year at £{boomReadPricing.oneYearPricePerStudent}/student</b></p>
                    </div>

                </li>
                {/* <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: subDuration === READ_SUB_DURATION.twoYear })} onClick={() => setSubDuration(READ_SUB_DURATION.twoYear)}>
                    <div className={styles.subTypeMain}>
                        <div>
                            <p><b>2 Year at £{boomReadPricing.twoYearPricePerStudent}/student</b></p>
                            <p>Save and lock in your costs with a two year deal.</p>
                        </div>
                    </div>
                </li> */}
            </ul>

            <p className='heavy u-m-base-2'>Select additional features</p>
            <ul className={styles.subType}>
                <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: additionalFeatures.wondeAndDataDash })}>
                    <div className={styles.subTypeMain} onClick={() => setAdditionalFeatures({ ...additionalFeatures, wondeOnly: false, wondeAndDataDash: !additionalFeatures.wondeAndDataDash })}>
                        <span className={cx(styles.subTypeCheck, { [styles.subTypeCheckActive]: additionalFeatures.wondeAndDataDash })}>{additionalFeatures.wondeAndDataDash ? <CheckSquare /> : <Square />}</span>
                        <p><b>Data Dashboard & MIS Integration - £{1}/student</b></p>
                    </div>
                    <div className={styles.subTypeContent}>
                        <p className='small'>Monitor levels of reading engagement, compare reading trends and parental engagement.</p>
                    </div>
                </li>

                <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: additionalFeatures.wondeOnly })}>
                    <div className={styles.subTypeMain} onClick={() => setAdditionalFeatures({ ...additionalFeatures, wondeAndDataDash: false, wondeOnly: !additionalFeatures.wondeOnly })}>
                        <span className={cx(styles.subTypeCheck, { [styles.subTypeCheckActive]: additionalFeatures.wondeOnly })}>{additionalFeatures.wondeOnly ? <CheckSquare /> : <Square />}</span>
                        <p><b> MIS Integration </b></p>
                    </div>
                    <div className={styles.subTypeContent}>
                        <p className='small'>BoomReader fully integrates with your school MIS to allow easy management of classes, students and staff.</p>
                    </div>
                </li>
            </ul>

            {renderPaymentSummary()}
        </>
    )

}

export default PriceQuote;