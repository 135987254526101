import React, {useEffect} from 'react';
import { ActionCableConsumer, ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import { getCableEndpoint, getBoomMathCableEndpoint } from '@axeedge/go-shared-utils';
import ls from 'local-storage';

const ws = new WebSocket(ls('country') === 'US' ? getBoomMathCableEndpoint() : getCableEndpoint());


export const ActionCableWrapper = ({ children }) => {
    useEffect(() => {
        if (ws.readyState === 3) {
            ls('noSocket', true)
        } else {
            ls('noSocket', false)
        }
    }, [ws])
    return (
        <ActionCableProvider url={ls('country') === 'US' ? getBoomMathCableEndpoint() : getCableEndpoint()}>
            {children}
        </ActionCableProvider>
    );
};




export const NotifCableConsumer = ({ id, onForcedLogout, onLogout }) => 
{
    return (
        <ActionCableConsumer
            channel={{
                channel: 'NotificationsChannel',
                id
            }}
            onReceived={(message) => {
                if (message.logged_out) {
                    onLogout();
                }

                if (message.forced_logged_out && onForcedLogout) {
                    onForcedLogout();
                }
            }}
        ></ActionCableConsumer>
    )
}