import React from 'react';
import { Delete, Watch, Settings, Move, AlertCircle } from 'react-feather';
import { Link } from '@reach/router';
import cx from 'classnames';

import { PupilAvatarViewer } from '@axeedge/go-shared-components';
import ReactTooltip from "react-tooltip";

import styles from './ListItem.module.scss';
import boomer from '../../images/loader-boomer.png'

const ListItem = ({ avatarJson, inactive, linkPath, mainText, short, onClick, onDelete, onSettings, onMove, withCard, onWarning, isStudent = false, showTip = true, yearGroup=null, boomerAvatarUrl = null, updatedAt = null }) => {
    
    return (
        <div className={cx(styles.listItem, {[styles.short]: short}, {[styles.listItemWithCard]: withCard})}>
            {showTip && <ReactTooltip /> }
            <div className={styles.listItemLeft}>
                {/* {
                    avatarJson && <PupilAvatarViewer avatar={JSON.parse(avatarJson)} styleName={styles.listItemAvatar} />
                } */}
                {boomerAvatarUrl ?
                    <img src={`${boomerAvatarUrl}?v=${updatedAt.replace(/\s/g, '')}`} className={styles.listItemAvatar} /> :
                    isStudent && <img src={boomer} className={styles.listItemAvatar} />
                }
                {
                    inactive && <Watch className="u-m-right-1 u-text-muted" />
                }
                {
                    linkPath && (
                        <Link className={styles.listItemMainTextLink} to={linkPath}>
                            {mainText}
                        </Link>
                    )
                }
                
                <p className={cx(styles.listItemMainText, {[styles.listItemMainTextLink]: onClick})} {...(onClick && {onClick: () => onClick()} )}>
                    {mainText}
                </p>
              

            </div>
            <div className={styles.listItemRight}>
                {yearGroup && <span>{yearGroup}</span>}
                {onWarning && <AlertCircle data-tip="Invalid Year Group" className={styles.listItemRightButton} onClick={onWarning} />}
                {onSettings && <Settings data-tip={isStudent ? "Edit pupil" : 'Edit'} className={styles.listItemRightButton} onClick={onSettings} />}
                {onDelete && <Delete data-tip="Delete/Remove" className={styles.listItemRightButton} onClick={onDelete} />}
                {onMove && <Move data-tip="Move" className={styles.listItemRightButton} onClick={onMove} />}
            </div>
        </div>
    );
}

export default ListItem;
