import React, { Fragment, useState } from 'react';

import GoWriteDashboard from './scenes/GoWriteDashboard';
import ViewPackItem from './scenes/ViewPackItem';


const GoWriteSettings = () => {

    const [viewPack, setViewPack] = useState();

    return (
        <Fragment>
               {
                    viewPack ? <ViewPackItem setViewPack={setViewPack} pack={viewPack} /> : <GoWriteDashboard setViewPack={setViewPack} />
               }
        </Fragment>
    )
}

export default GoWriteSettings;