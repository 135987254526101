import React, { useEffect, useState, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useLocation } from 'react-use';
import { navigate } from '@reach/router';

import { getUrl, APPS } from '@axeedge/go-shared-utils';
import { ErrorPage } from '@axeedge/go-shared-components';

import { ERROR, FORCED_LOGOUT } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';

import { APP_NAME, APP_NAME_US } from '../../../services/constants';

const RouteCapture = ({ children }) => {
    const [targetRoute, setTargetRoute] = useState('');
    const client = useApolloClient();
    const { data: fl } = useQuery(FORCED_LOGOUT);
    const { data: clientError } = useQuery(ERROR);
    const { currentUser: user, setCurrentUser } = useContext(AuthContext);

    const locationState = useLocation();

    const checkRouteForLogout = () => {
        const { pathname } = locationState;
        if (!pathname) {
            return false;
        }
        if (pathname.includes('logout')) {
            return false;
        }
        if (pathname.includes('invite')) {
            return true;
        }
        if (pathname.includes('setpassword')) {
            return true;
        }
        if (pathname.includes('impersonate')) {
            const params = locationState.pathname.split('/');
            if (ls('go_impersonating') && params[2] === user.id) {
                return false;
            }
            return true;
        }
        return false;
    }

    useEffect(() => {
        setTargetRoute(locationState.pathname);
        if (user && !user.reset && checkRouteForLogout()) {
            navigate(`/logout/${locationState.pathname}`);
        }
    }, [setTargetRoute, locationState]);

    const forceLogout = redirect => {
        ls.clear();
        client.clearStore();
        setCurrentUser(null);
        if (redirect) {
            window.location.href = `${getUrl(APPS.go_portal_teacher)}/redirectAfterLogin/${ls('country') === 'US' ? APP_NAME_US : APP_NAME}?targetUrl=${targetRoute}`;
        } else {
            window.location.href = `${getUrl(APPS.go_portal_teacher)}`;
        }
    }

    useEffect(() => {
        if ((ls('go_teacher_user') && ls('go_teacher_user') === 'undefined') || (locationState && locationState.pathname && locationState.pathname.includes('forceLogout')) ) {
            forceLogout(false);
        }
    });

    if (fl && fl.forcedLogout) {
        forceLogout(true);
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    return children || null;
}

export default RouteCapture;