
import React from 'react';
import ImageFadeIn from "react-image-fade-in";
import { ArrowLeft } from 'react-feather';
import logo from '../../../images/boomer.svg';

const Header = ({ isWriteToRaise }) => {


    const hostname = window && window.location && window.location.hostname;

    const boomWriterUrl = () => {
        if (hostname.includes('staging')) {
           return 'https://staging.boomwriter.co.uk' 
        }
        return 'https://boomwriter.co.uk'
    }

    const boomReaderUrl = () => {
        if (hostname.includes('staging')) {
            return 'https://staging.boomreader.co.uk'
        }
        return 'https://boomreader.co.uk'
    }


    const renderLinks = () => {
        return (
            <>
                {isWriteToRaise ? (
                    <li><a href={boomWriterUrl()} rel='noopener noreferrer' className='link-reverse u-display-flex u-align-center'><ArrowLeft /> BoomWriter</a></li>
                ) : (
                    <li><a href={boomReaderUrl()} rel='noopener noreferrer' className='link-reverse u-display-flex u-align-center'><ArrowLeft /> BoomReader</a></li>
                )}
            </>
        )
    }


    return (
        <div className='auth__header'>
            <ImageFadeIn src={logo} alt="Go Apps Logo" className="auth__applogo" />

            <ul className='u-display-flex'>
                {renderLinks()}
            </ul>
        </div>
    )
};

export default Header;