import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Loader } from 'react-feather';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Modal } from '@axeedge/go-teacher-components';
import BillingProfileForm from '../../Billing/components/BillingProfileForm';
import { START_MATH_ACCESS, START_MATH_STRIPE_SUBSCRIPTION, GET_MATH_SUBSCRIPTION } from '../../../services/graphql';

import styles from '../../BoomMathSettings/BoomMathSettings.module.scss';
import cx from 'classnames';

const paymentErrorMessage = "We're sorry, there was an error processing your payment. Please check the card details you have provided, or try again with a different payment method.";

const RECEIPT_SETTING = {
    personal: 1,
    other: 3
}

const CardFormRestart = ({ totalStudents, school, user, setCurrentUser, onDone }) => {

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [confirmSuccess, setConfirmSuccess] = useState(false);
    const [card, setCard] = useState(null);

    const [receiptSetting, setReceiptSetting] = useState(null);
    const [editBillingProfile, setEditBillingProfile] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const { handleSubmit } = useForm({
        mode: 'onSubmit'
    });


    const [startBoomMathAccess, { loading }] = useMutation(START_MATH_ACCESS, {
        onCompleted: ({ startBoomMathAccess }) => {
            if (startBoomMathAccess.errors && startBoomMathAccess.errors.length !== 0) {
                alert(startBoomMathAccess.errors[0]);
                setProcessing(false);
                return;
            }
            if (startBoomMathAccess && startBoomMathAccess.school) {
                setProcessing(false);
                setConfirmSuccess(true);
                const cu = { ...user, school: startBoomMathAccess.school }
                setCurrentUser(cu);
                setTimeout(() => {
                    onDone();
                }, 500)
            }
        },
        refetchQueries: [{ query: GET_MATH_SUBSCRIPTION, variables: { schoolId: school.id } }]
    });


    const onPaymentIntent = async (data) => {
        const { clientSecret, subscriptionId } = data;
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                type: 'card',
                card,
                billing_details: null
            }
        })
        if (payload.error) {
            setProcessing(false);
            setShowErrorModal(paymentErrorMessage);
        } else {
            if (payload.paymentIntent.status === 'succeeded') {
                startBoomMathAccess({
                    variables: {
                        stripeSubscriptionId: subscriptionId,
                        studentsCounter: +totalStudents,
                        schoolId: school.id
                    }
                });
            }
        }
        if (payload.paymentIntent.status === 'requires_payment_method') {
            setProcessing(false);
            setShowErrorModal(paymentErrorMessage);
        }
    }

    const [startBoomMathStripeSubscription] = useMutation(START_MATH_STRIPE_SUBSCRIPTION, {
        onCompleted: ({ startBoomMathStripeSubscription }) => {
            if (startBoomMathStripeSubscription.errors && startBoomMathStripeSubscription.errors.length !== 0) {
                alert(startBoomMathStripeSubscription.errors[0]);
                setProcessing(false);
                return;
            } else {
                onPaymentIntent({ clientSecret: startBoomMathStripeSubscription.clientSecret, subscriptionId: startBoomMathStripeSubscription.subscriptionId });
            }
        }
    });



    const onSubmit = () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        setProcessing(true);
        setCard(elements.getElement(CardElement));
        startBoomMathStripeSubscription({
            variables: {
                schoolId: school.id,
                studentsCounter: +totalStudents,
                inputData: receiptSetting
            }
        })
    }


    return (
        <div className='row'>
            <div className='col-md-8'>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='u-m-base-3 u-m-top-2'>
                        <h4>Enter card details</h4>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        color: '#4A4A4A',
                                        fontSize: '16px'
                                    }
                                }
                            }}
                        />
                    </div>

                    <p className='heavy'>Receipt Settings</p>
                    <p>Please confirm the details for your receipt</p>
                    <ul className={styles.receiptOpts}>
                        <li onClick={() => setReceiptSetting(RECEIPT_SETTING.personal)}>
                            <div className={styles.receiptOptsMain}><span className={cx(styles.receiptOptsBullet, { [styles.receiptOptsBulletActive]: receiptSetting === RECEIPT_SETTING.personal })}></span> Use my details</div>
                            {receiptSetting === RECEIPT_SETTING.personal && (
                                <div className='u-m-base-2'>
                                    <p>Name: <span className='u-m-left-1'>{user.firstName} {user.lastName}</span></p>
                                    <p>Email: <span className='u-m-left-1'>{user.email}</span></p>
                                </div>
                            )}
                        </li>
                        <li onClick={() => setReceiptSetting(RECEIPT_SETTING.other)}>
                            <div className={styles.receiptOptsMain}><span className={cx(styles.receiptOptsBullet, { [styles.receiptOptsBulletActive]: receiptSetting === RECEIPT_SETTING.other })}></span> Use School or other details</div>
                            {receiptSetting === RECEIPT_SETTING.other &&
                                <>
                                    <p><b>{school.billingProfile.companyName}</b></p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>{school.billingProfile.address}</p>
                                            <p>{school.billingProfile.address2}</p>
                                            <p>{school.billingProfile.city}</p>
                                            <p>{school.billingProfile.state.code}</p>
                                            <p>{school.billingProfile.zip}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>Contact: {school.billingProfile.contactPerson}</p>
                                            <p>Email: {school.billingProfile.contactEmail}</p>
                                            <p>Phone: {school.billingProfile.contactPhone}</p>
                                        </div>
                                    </div>
                                    <button
                                        type='button'
                                        onClick={() => setEditBillingProfile(true)}
                                        className='btn-reset link-underline u-m-base-2'>
                                        Edit billing profile</button>
                                </>
                            }
                        </li>
                    </ul>

                    <p >Your subscription will automatically renew each year unless you cancel.</p>
                    <p className='u-m-base-2'>Your card will be charged immediately</p>

                    <Button
                        className='u-m-top-2'
                        disabled={!(stripe && elements) || loading || +totalStudents === 0 || !receiptSetting}
                        type='submit'
                    >Start subscription</Button>
                </form>

                {(processing || confirmSuccess) &&
                    <div className={styles.processing}>
                        {processing && <Loader className={styles.processingLoader} />}
                        <p className={styles.processingText}>{processing ? 'Processing, please wait' : 'Subscription started successfully, redirecting...!'}</p>
                    </div>
                }

                {showErrorModal &&
                    <Modal closeModal={() => setShowErrorModal(false)}>
                        <h1>Payment failed</h1>
                        <p className='u-m-base-2'>
                            {showErrorModal}
                        </p>
                    </Modal>
                }

                {editBillingProfile &&
                    <Modal wide closeModal={() => setEditBillingProfile(false)}>
                        <BillingProfileForm isEdit={true} school={school} close={() => setEditBillingProfile(false)} />
                    </Modal>
                }
            </div>
        </div>
    )
}

export default CardFormRestart;