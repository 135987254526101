import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { WONDE_SYNC_STUDENTS } from "../../services/graphql";
import { AlertCircle } from "react-feather";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import PageHeader from "../../../../components/PageHeader";
import { getYearGroupShortName } from "../../../../services/utils";
import styles from '../../WondeSync.module.scss';
import cx from 'classnames';

const SuggestedMatchContent = ({ school, suggestedStudents, setCurrentStep }) => {

    const [suggested, setSuggested] = useState(suggestedStudents);

    const [formErrors, setFormErrors] = useState([]);

    const [wondeSyncStudents, { loading }] = useMutation(WONDE_SYNC_STUDENTS, {
        update: (_, { data }) => {
            if (data && data.wondeSyncStudents.errors && data.wondeSyncStudents.errors.length > 0) {
                setFormErrors(data.wondeSyncStudents.errors);
                return;
            }
            if (data && data.wondeSyncStudents?.students) {
                setCurrentStep(WONDE_SYNC_STEPS.syncStudentsManual);
            }
        }
    });


    const onToggleStudent = (checked, studentId) => {
        const updatedStudents = suggested.map(el => el.id === studentId ? { ...el, wondeStudentId: checked ? null : '-1' } : el);
        setSuggested([...updatedStudents]);
    }

    const finalizeSuggestedSync = () => {
        const studentDetails = [];
        suggested.forEach(st => {
            studentDetails.push({
                wondeStudentId: st.wondeStudentId ? st.wondeStudentId : st.wondeId,
                studentId: st.id
            })
        })
        wondeSyncStudents({
            variables: {
                schoolId: school.id,
                wondeStudentDetails: studentDetails
            }
        });
    }

    return (
        <>
            <div className={styles.syncMain}>
                <PageHeader showBackLink={false} title={'MIS Update: Existing Students - 2/3 Suggested Match'} />
                {formErrors && <FormErrors errors={formErrors} />}

                <p className="u-m-base-2">Please review and adjust the matches we've made based on name and year group.</p>
                <div className={styles.suggestedMatch}>
                    <div>
                        <p>Currently in <b>BoomHub</b>:</p>
                        <div className={cx(styles.suggestedMatchBoom)}>
                            <div>Sync</div>
                            <div>Name</div>
                            <div className="u-text-right">Year</div>
                        </div>
                    </div>
                    <div>
                        <p>From your <b>MIS</b>:</p>
                        <div className={cx(styles.suggestedMatchWonde)}>
                            <div>UPN</div>
                            <div>Name</div>
                            <div className="u-text-right">Year</div>
                        </div>
                    </div>
                </div>
                <ul className="u-m-base-3">
                    {suggested?.map(student =>
                        <li key={`suggested-${student.id}`} className={cx(styles.suggestedMatch, styles.altRow)}>
                            <div className={cx(styles.suggestedMatchBoom, styles.altRowBoom)}>
                                <div className="u-flex-center">
                                    <input 
                                        className="switchToggleSmall"
                                        checked={student.wondeStudentId !== '-1'} 
                                        onChange={(e) => onToggleStudent(e.target.checked, student.id)} 
                                        type="checkbox"
                                        id={`check-${student.id}`}
                                     />
                                    <label className={cx("switchLabelSmall")} htmlFor={`check-${student.id}`}>&nbsp;</label>
                                </div>
                                <div>{student.name}</div>
                                <div className="u-text-right">{getYearGroupShortName(student.yearGroup)}</div>
                            </div>
                            <div className={cx(styles.suggestedMatchWonde, styles.altRowWonde)}>
                                <div>{student.wondeUpn}</div>
                                <div>{student.wondeName}</div>
                                <div className="u-text-right">{student.wondeYearGroup}</div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            <div className={styles.syncFooter}>
                {/* <button onClick={() => ''} className="btn-reset u-flex-center heavy"><AlertCircle className="u-m-right-1" size={18} /> Help</button> */}
                <Button disabled={loading} className='u-m-left-auto' onClick={() => finalizeSuggestedSync()}>{loading ? 'Saving...' : 'Confirm and continue'}</Button>
            </div>
        </>
    )
}

export default SuggestedMatchContent;