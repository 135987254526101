import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useMutation } from "@apollo/react-hooks";
import { TEACHER_REQUEST_TO_JOIN, CHECK_JOIN_REQUESTS } from '../../services/graphql';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { getRoleLabel, usaOnlyRoles } from '../../../../services/utils';

const JoinExistingSchool = ({ school, exit, clearExistingSchool }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [showJoinForm, setShowJoinForm] = useState(false);

    const schema = Yup.object().shape({
        roleId: Yup.string().required('Please select role')
    })
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const [teacherRequestToJoinSchool, { loading }] = useMutation(TEACHER_REQUEST_TO_JOIN, {
        update: (_, { data }) => {
            if (data?.teacherRequestToJoinSchool?.errors && data?.teacherRequestToJoinSchool?.errors.length > 0) {
                setFormErrors(data.teacherRequestToJoinSchool.errors);
                return
            }
            if (data.teacherRequestToJoinSchool.teacher && data.teacherRequestToJoinSchool.teacher.id) {
                exit()
            }
        },
        refetchQueries: [{ query: CHECK_JOIN_REQUESTS }]
    })

    const onSubmit = values => {
        const { roleId } = values;
        teacherRequestToJoinSchool({
            variables: {
                roleId: +roleId,
                schoolCode: school.schoolCode
            }
        });
    }

    return (
        <>

            <p>We found the following school based on the URN provided:</p>
            <div className="panel u-m-base-2">
                <div className="row">
                    <div className="col-md-8">
                        <h2 className="heavy">{school.name} <span className="light">- URN {school.urn}</span></h2>
                        <p>{school.address}</p>
                        <p>{school.address2}</p>
                        <p>{school.city}, {school.zip}</p>
                        {school.country && <p>{school.country.hasOwnProperty('name') ? school.country.name : school.country}</p>}
                    </div>
                    <div className="col-md u-text-right">
                        <button onClick={clearExistingSchool} className="btn-reset link-underline">This isn't my school</button>
                    </div>
                </div>

            </div>
            {showJoinForm ?
                <div className="row">
                    <form className='u-m-top-2 col-md-6' onSubmit={handleSubmit(onSubmit)}>
                        <p>What is your role at this school?</p>
                        <div className="basic-form__group">
                            <select id="roleId" name="roleId" ref={register} className="basic-form__text-select">
                                <option value=''>Please select</option>
                                {Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                    return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                })}
                            </select>
                        </div>
                        {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}
                        {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                        <div className="u-display-flex u-align-center u-m-top-3">
                            <Button disabled={loading} type="submit" primary className="u-m-right-1">{loading ? 'Saving' : 'Request Access'}</Button>
                        </div>
                    </form>
                </div> :
                <>
                    {/* LP: Hide Join Requests */}
                    <p className="u-m-base-2">It looks like your school already has an account with us.</p>

                    {/* <p className="u-m-base-2">It looks like your school already has an account with us. Please ask your school's BoomHub administrator to invite you to join.</p> */}
                    {/* <p className="u-m-base-2">Or you can request access:</p>
                    <div className="u-display-flex u-align-center u-m-base-2">
                        <Button outline onClick={() => setShowJoinForm(true)} className='u-m-right-2'>Request access</Button>
                    </div> */}
                </>
            }
        </>
    )
}

export default JoinExistingSchool;