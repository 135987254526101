import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { WONDE_SYNC_STUDENTS } from "../../services/graphql";
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { YEAR_GROUPS } from "@axeedge/go-shared-utils";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import { AlertCircle } from "react-feather";
import { getYearGroupLabel, getYearGroupShortName } from "../../../../services/utils";
import PageHeader from "../../../../components/PageHeader";
import styles from '../../WondeSync.module.scss';
import cx from 'classnames';

const UpnMatchContent = ({ school, allStudents, setCurrentStep }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [selectedYear, setSelectedYear] = useState(null);

    const [visibleStudents, setVisibleStudents] = useState(allStudents);


    useEffect(() => {
        if (selectedYear) {
            setVisibleStudents([...allStudents].filter(st => st.yearGroup === selectedYear))
        } else {
            setVisibleStudents([...allStudents]);
        }

    }, [selectedYear]);

    const [wondeSyncStudents, { loading }] = useMutation(WONDE_SYNC_STUDENTS, {
        update: (_, { data }) => {
            if (data && data.wondeSyncStudents.errors && data.wondeSyncStudents.errors.length > 0) {
                setFormErrors(data.wondeSyncStudents.errors);
                return;
            }
            if (data && data.wondeSyncStudents?.students) {
                setCurrentStep(WONDE_SYNC_STEPS.syncStudentsSuggested);
            }
        },
    });

    const finalizeSync = () => {
        const studentDetails = [];
        allStudents.forEach(st => {
            studentDetails.push({
                wondeStudentId: st.wondeId,
                studentId: st.id
            })
        })
        wondeSyncStudents({
            variables: {
                schoolId: school.id,
                wondeStudentDetails: studentDetails
            }    
        });
    }

    return (
        <>
            <div className={styles.syncMain}>
                <PageHeader showBackLink={false} title={'MIS Update: Existing Students - 1/3 UPN Match'} />

                <p>Please review the matches we've made by UPN: {allStudents.length} students.</p>

                <div className="u-flex-center u-m-base-2">
                    <span>Filters:</span>
                    <select
                        value={selectedYear || ''}
                        onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                        className="basic-form__text-select u-m-left-2"
                        style={{ maxWidth: '150px' }}
                    >
                        <option value=''>Year Group</option>
                        {YEAR_GROUPS.map(yearGroup => (
                            <option key={yearGroup.val} value={yearGroup.val}>{getYearGroupLabel(yearGroup.val)}</option>
                        ))}
                    </select>
                </div>

                <div className={styles.upnMatch}>
                    <div>
                        <p className="u-m-base-1 u-m-top-2">Currently in <b>BoomHub</b>:</p>
                        <div className={cx(styles.upnMatchBoom)}>
                            <div>UPN</div>
                            <div>Name</div>
                            <div className="u-text-right">Year</div>
                        </div>
                    </div>
                    <div>
                        <p className="u-m-base-1 u-m-top-2">From your <b>MIS</b>:</p>
                        <div className={cx(styles.upnMatchWonde)}>
                            <div>Name</div>
                            <div className="u-text-right">Year</div>
                        </div>
                    </div>
                </div>

                {formErrors && <FormErrors errors={formErrors} />}

                <ul className="u-m-base-3">
                    {
                        visibleStudents.length > 0 ? visibleStudents.map(match => {
                            return <li key={match.id} className={cx(styles.upnMatch, styles.altRow)}>
                                <div className={cx(styles.upnMatchBoom, styles.altRowBoom)}>
                                    <div>{match.upn}</div>
                                    <div>{match.name}</div>
                                    <div className="u-text-right">{getYearGroupShortName(match.yearGroup)}</div>
                                </div>
                                <div className={cx(styles.upnMatchWonde, styles.altRowWonde)}>
                                    <div>{match.wondeName}</div>
                                    <div className="u-text-right">{match.wondeYearGroup}</div>
                                </div>
                            </li>
                        }) : <p>No students</p>

                    }
                </ul>
            </div>
            <div className={styles.syncFooter}>
                {/* <button onClick={() => ''} className="btn-reset u-flex-center heavy"><AlertCircle className="u-m-right-1" size={18} /> Help</button> */}
                <Button disabled={loading} className='u-m-left-auto' onClick={() => finalizeSync()}>{loading ? 'Saving...' : 'Confirm and continue'}</Button>
            </div>
        </>
    )

}

export default UpnMatchContent;