// Queries and mutations for the Home module
import { gql } from 'apollo-boost';
import { teacherQuery } from '../../../services/constants';

export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;

export const CURRENT_TEACHER_QUERY = gql`
query currentTeacher {
    currentTeacher {
        ${teacherQuery}
    }
}
`;
