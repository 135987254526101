import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import ProfileImage from '../../../../components/ProfileImage';
import SchoolSetupInfo from './SchoolSetupInfo';
import { CREATE_PROMO_SCHOOL_MUTATION } from '../../../SchoolProfile/services/graphql';
import { GET_COUNTRIES } from '../../../../services/school/graphql';
import { getRoleLabel, usaOnlyRoles } from '../../../../services/utils';
import { WEEK_STARTS } from '../../../../services/constants';
import styles from './SchoolSetup.module.scss';
import ProgressIndicator from '../../components/ProgressIndicator';


const ManualSchoolSetup = ({ close }) => {

    const { t } = useTranslation(['profile', 'common']);
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [tempData, setTempData] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [showCropModal, setShowCropModal] = useState(false);

    const [countries, setCountries] = useState([]);
    const [uk, setUk] = useState(null);
    const [showIntro, setShowIntro] = useState(true);


    const [getCountries, { loading: countriesLoading, error: countriesError }] = useLazyQuery(GET_COUNTRIES, {
        onCompleted: (data) => {
            setCountries(data.countriesList);
        }
    });


    useEffect(() => {
        if (!showIntro) {
            getCountries();
        }
    }, [showIntro])

    useEffect(() => {
        if (countries.length > 0) {
            setUk(countries.find(c => c.code === 'GBR'));
        }
    }, [countries]);

    const schema = Yup.object().shape({
        name: Yup.string().required(t('please_enter_a_school_name')),
        address: Yup.string().required(t('please_enter_school_address')),
        city: Yup.string().required(t('please_enter_city')),
        zip: Yup.string().required(t('please_enter_postcode')),
        schoolPhoneNumber: Yup.string().required('Please enter telephone'),
        roleId: Yup.string().required(t('common:please_select_role')),
        tos: Yup.boolean().oneOf([true], "You must agree to terms & conditions"),
        weekStart: Yup.number().required().typeError('Please select week start'),
        country: Yup.string().required('Please select country'),
        authorised: Yup.boolean().oneOf([true], "You must confirm you are authorized"),

    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [createPromoSchool, { loading: creatingSchool }] = useMutation(CREATE_PROMO_SCHOOL_MUTATION, {
        update: (_, { data }) => {
            if (data?.createPromoSchool?.errors && data.createPromoSchool.errors.length !== 0) {
                setFormErrors(data.createPromoSchool.errors);
                return;
            }
            if (data?.createPromoSchool?.school?.id) {
                const teacherInSchool = data.createPromoSchool.school.teachers && data.createPromoSchool.school.teachers.find(t => t.id == currentUser.id)
                const cu = { ...currentUser, isSchoolAdmin: true, roleId: teacherInSchool && teacherInSchool.roleId, school: data.createPromoSchool.school }
                setCurrentUser(cu);
                setShowCropModal(false);
                close();
            }
        },
    });

    const onSubmit = values => {
        const { name, address, address2, city, zip, roleId, country, schoolPhoneNumber, weekStart } = values;
        createPromoSchool({
            variables: {
                name,
                address,
                address2,
                city,
                zip,
                country,
                roleId: +roleId,
                schoolPhoneNumber,
                weekStart,
                ...(tempData && tempData.variables)
            }
        });

    }

    const onSaveNewAvatar = (avatarObj) => {
        setTempData(avatarObj);
        setShowCropModal(false);
    }

    if (countriesLoading) {
        return <Loader />
    }

    if (countriesError) {
        return <p>{countriesError.message}</p>
    }

    return (
        <>
            <ProgressIndicator user={currentUser} />
            {showIntro ?
                <SchoolSetupInfo back={close} showSchoolForm={() => setShowIntro(false)} /> :
                countries.length > 0 &&
                <div className={styles.schoolSetup}>
                    <h1>Create your school account</h1>
                    <form className={styles.schoolSetupForm} onSubmit={handleSubmit(onSubmit)}>
                        <div className='mainCol'>
                            <div className='basic-form__group'>
                                <label htmlFor='name' className='basic-form__text-label '>Official School Name</label>
                                <input
                                    name='name'
                                    id='name'
                                    placeholder={t('school_name')}
                                    ref={register}
                                    type='text'
                                    className='basic-form__text-box'
                                />
                                {errors.name && <p className='basic-form__hint u-m-top-1'>{errors.name.message}</p>}
                            </div>

                            <h2 className='u-m-top-3'>School Address</h2>
                            <p className='u-m-base-2'>This is used to verify your school for account billing.</p>
                            <div className='basic-form__group'>
                                <label htmlFor='address' className='basic-form__text-label'>Street address</label>
                                <input
                                    name='address'
                                    id='address'
                                    placeholder={t('address_1')}
                                    ref={register}
                                    className='basic-form__text-box'
                                    type='text'
                                />
                                {errors.address && <p className='basic-form__hint u-m-top-1'>{errors.address.message}</p>}
                            </div>
                            <div className='basic-form__group'>
                                <label htmlFor='address2' className='basic-form__text-label'>Address 2</label>
                                <input
                                    name='address2'
                                    id='address2'
                                    placeholder={t('address_2')}
                                    ref={register}
                                    className='basic-form__text-box'
                                    type='text'
                                />
                                {errors.address2 && <p className='basic-form__hint u-m-top-1'>{errors.address2.message}</p>}
                            </div>

                            <div className='basic-form__group'>
                                <label htmlFor='city' className='basic-form__text-label'>Town/City</label>
                                <input
                                    name='city'
                                    id='city'
                                    placeholder={t('city')}
                                    ref={register}
                                    className='basic-form__text-box'
                                    type='text'
                                />
                                {errors.city && <p className='basic-form__hint u-m-top-1'>{errors.city.message}</p>}
                            </div>


                            <div className='basic-form__group'>
                                <select name="country" ref={register} className="basic-form__text-select">
                                    <option value=''>Please select country</option>
                                    {uk && <option value={uk.code}>{uk.name}</option>}
                                    <option disabled>──────────</option>
                                    {countries.map(country => (
                                        <option key={`c-${country.code}`} value={country.code}>{country.name}</option>
                                    ))}
                                </select>
                                {errors.country && <p className='basic-form__hint u-m-top-1'>{errors.country.message}</p>}
                            </div>


                            <div className='basic-form__group'>
                                <label htmlFor='zip' className='basic-form__text-label'>Post code</label>
                                <input
                                    name='zip'
                                    id='zip'
                                    placeholder={t('postcode')}
                                    ref={register}
                                    className='basic-form__text-box'
                                    type='text'
                                />
                                {errors.zip && <p className='basic-form__hint u-m-top-1'>{errors.zip.message}</p>}
                            </div>

                            <div className='basic-form__group'>
                                <label htmlFor='schoolPhoneNumber' className='basic-form__text-label'>Telephone</label>
                                <input
                                    name='schoolPhoneNumber'
                                    id='schoolPhoneNumber'
                                    ref={register}
                                    className='basic-form__text-box'
                                    type='text'
                                />
                                {errors.schoolPhoneNumber && <p className='basic-form__hint u-m-top-1'>{errors.schoolPhoneNumber.message}</p>}
                            </div>
                            <p>We may use this to confirm your school's identity</p>

                            <hr className='separator' />
                            <h3 className='u-m-base-1 u-m-top-2'>Your Role at the School</h3>
                            <p>Select the role that best describes your role at the school</p>
                            <div className='basic-form__group u-m-base-2'>
                                <select name="roleId" ref={register} className="basic-form__text-select">
                                    <option value=''>Select your role</option>
                                    {
                                        Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                            return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                        })
                                    }
                                </select>
                                {errors.roleId && <p className="basic-form__hint u-m-top-1">{errors.roleId.message}</p>}
                            </div>



                            <div className="basic-form__group basic-form__group--check u-text-left u-m-base-3">
                                <div>
                                    <input
                                        name="authorised"
                                        className="basic-form__check-box"
                                        ref={register}
                                        type="checkbox"
                                        id="authorised"
                                    />
                                    <label className="basic-form__check-label" htmlFor="authorised">I'm authorised to create an account for this school</label>
                                </div>
                            </div>
                            {errors.authorised && <p className='basic-form__hint'>{errors.authorised.message}</p>}


                            <div className="basic-form__group basic-form__group--check u-text-left u-m-base-3">
                                <div>
                                    <input
                                        name="tos"
                                        className="basic-form__check-box"
                                        ref={register}
                                        type="checkbox"
                                        id="tos"
                                    />
                                    <label className="basic-form__check-label" htmlFor="tos">I accept the Terms and Privacy Policies for this School account</label>
                                </div>
                            </div>

                            {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}

                            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                            <Button fullWidth disabled={creatingSchool} type='submit'>
                                {creatingSchool ? t('common:saving') : 'Create School Account'}
                            </Button>

                        </div>

                        <div>
                            <ProfileImage
                                buttonText={'Select Image'}
                                defaultImg={(tempData && tempData.imgUrl)}
                                heading='Upload School Logo '
                                imgName={`schools/profile/schoolof${currentUser.id}`}
                                onSave={onSaveNewAvatar}
                                savingImage={creatingSchool}
                                setShowCropModal={setShowCropModal}
                                showCropModal={showCropModal}
                                width={140}
                                imgStyles={styles.setupLogo}
                            />
                            <p className='u-m-base-1 small'>Optional: This is displayed in the app to pupils, parents and your staff.</p>
                            <p className='u-m-base-3 small'>Image should be at least 200x200 pixels as a png, jpg or gif file.</p>
                            <div className='basic-form__group'>
                                <label htmlFor='weekStart' className='basic-form__text-label heavy'>School Reporting Week</label>
                                <select name='weekStart' className='basic-form__text-select' ref={register}>
                                    <option value=''>Please select</option>
                                    {Object.entries(WEEK_STARTS).map(week => {
                                        return (<option key={`week-${week[0]}`} value={week[0]}>{week[1]}</option>)
                                    })}
                                </select>
                                {errors.weekStart && <p className='basic-form__hint u-m-top-1'>{errors.weekStart.message}</p>}
                            </div>

                        </div>
                    </form>
                </div>
            }
        </>

    )

}

export default ManualSchoolSetup;
