import { parseOfferStatus } from '../../../services/utils';

// GoWrite dashboard: list all purchased pack class instances
export const listPurchasedPackInstances = purchasedPacks => {
    const pPacks = [];
    purchasedPacks.forEach(pPack => {
        parseOfferStatus(pPack.status).forEach(item => {
            pPacks.push({
                id: `${pPack.id}-${item.id}`,
                packOffer: pPack.packOffer.name,
                purchasedAt: pPack.purchasedAt,
                maxNumberOfStudents: item.students,
                academicYear: pPack.academicYear,
                students: item.students,
                students_class: item.students_class
            })
        })            
    })
    return pPacks;
}


// Purchase packs: display pack offers with classes
const setOfferClasses = (offer) => {
    return [...Array(offer.instances)].map((_, i)=>({
        id: i + 1,
        students: offer.defaultNumberOfStudentsPerInstance
    }))
}
export const displayPackOffers = (packOffers) => {
    const offers = [];
    packOffers.forEach(offer => {
        offers.push({
                id: offer.id,
                price: offer.price,
                initialPrice: offer.price,
                name: offer.name,
                description: offer.description,
                instances: offer.instances,
                defaultNumberOfStudentsPerInstance: offer.defaultNumberOfStudentsPerInstance,
                classes: setOfferClasses(offer)
            })
    });
    return offers;
}

// Purchase packs: set pack min/max no. of students (small packs only)
const SMALL_PACK_STUDENTS = {
    default: 15,
    min: 6,
    max: 29
}
export const packStudentsRange = (pack, isMinAttribute) => {
    let val;
    if (isMinAttribute) {
        val = pack.defaultNumberOfStudentsPerInstance === SMALL_PACK_STUDENTS.default ? SMALL_PACK_STUDENTS.min : pack.defaultNumberOfStudentsPerInstance
    } else {
        val = pack.defaultNumberOfStudentsPerInstance === SMALL_PACK_STUDENTS.default ? SMALL_PACK_STUDENTS.max : 'false'
    }
    return val
}


// Purchase packs: get packs array data for muation
export const getPacksPurchaseData = selectedPacks => {
    const packs = [];
    selectedPacks.forEach(pack => {
        let offerDetails = {};
        pack.classes.forEach((_, key) =>  offerDetails[pack.classes[key].id] = pack.classes[key].students);
        packs.push({
            packOfferId:  pack.id,
            price: parseFloat(pack.price.toFixed(2)),
            offerDetails: JSON.stringify(offerDetails),
            maxNumberOfStudents: pack.classes.map(cls => cls.students).reduce((a, b) => a + b, 0)
        })
    });
    return packs
}

// Purchase packs: set selectable academic years (Sept 1 to end of July)
export const setPurchaseAcademicYears = () => {
    const END_MONTH = 6;

    const currYear = new Date().getFullYear();
    const currMonth = new Date().getMonth();
    const academicYears = [];
    const years = [];
    const testYear = currMonth < END_MONTH  ? currYear - 1 : currYear;

    for (var i = 0; i < 2; i++) {
        years.push(testYear + i);
        academicYears.push((+years[i]) + '/' + (+years[i] + 1))
    }
    return academicYears
}