import { gql } from 'apollo-boost';
import { schoolQuery } from '../constants';

// query used in both /teachers page & /classes page
export const GET_TEACHERS_QUERY = gql`
query teachersBySchoolCode($schoolCode: ID!, $includeInvited: Boolean) {
    teachersBySchoolCode(schoolCode: $schoolCode, includeInvited: $includeInvited) {
        id
        active
        displayName
        name
        email
        firstName
        lastName
        isSchoolAdmin
        token
        expiredToken
        roleId
        isAdditional
    }
}
`;

export const GET_COUNTRIES = gql`
query countriesList {
    countriesList {
        id
        code
        name
    }
}
`;

export const GET_STATES = gql`
query statesByCountry($country: String!) {
    statesByCountry(country: $country) {
        id
        code
        name
    }
}
`;

export const GET_CURRICULUMNS = gql`
query curriculums {
    curriculums {
        id
        name
        state
    }
}
`;

//used in /classes & /gowrite settings
export const GET_PURCHASED_PACKS_QUERY = gql`
query purchasedPacksBySchoolCode($schoolCode: String!) {
    purchasedPacksBySchoolCode(schoolCode: $schoolCode) {
        id
        instances
        academicYear
        maxNumberOfStudents
        offerDetails
        status
        price
        purchasedAt
        packOffer {
            id
            defaultNumberOfStudentsPerInstance
            name
            instances
            price
        }
        studentsClasses {
            id
            name
        }
    }
}
`;

export const UPDATE_SCHOOL_SETUP_STEP = gql`
mutation updateSchoolSetupStep($schoolId: ID!, $step: Int!) {
    updateSchoolSetupStep(schoolId: $schoolId, step: $step) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;