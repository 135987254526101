import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { WONDE_STUDENTS_MANUAL_MATCH } from "../../services/graphql";
import ManualMatchContent from "./ManualMatchContent";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import loader from '../../../../images/loader.svg';
import { WONDE_SYNC_STEPS } from "../../../../services/constants";

const ManualMatchQuery = ({ setCurrentStep }) => {

    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(WONDE_STUDENTS_MANUAL_MATCH, {
        variables: {
            schoolId: currentUser?.school?.id
        },
        onCompleted: ({ school }) => {
            if (school?.unmatchedStudentsInWonde?.length === 0) {
                setCurrentStep(WONDE_SYNC_STEPS.syncClasses)
            }
        }
    });


    if (error) {
        return <p>{error.message}</p>
    }


    return (
        <>
            {loading ?
                <>
                    <p className="u-display-flex u-align-center heavy">Please wait, loading manual matches for students... <img className="u-m-left-2" src={loader} /></p>
                </> :
                data?.school?.unmatchedStudentsInWonde?.length > 0 &&
                <ManualMatchContent
                    setCurrentStep={setCurrentStep}
                    school={currentUser?.school}
                    manualStudents={data.school?.unmatchedStudentsInWonde}
                />
            }
        </>
    )

}

export default ManualMatchQuery;