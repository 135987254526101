import React from "react";
import UpnMatchQuery from "./UpnMatchQuery";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import SuggestedMatchQuery from "./SuggestedMatchQuery";
import ManualMatchQuery from "./ManualMatchQuery";

const SyncStudents = ({ currentStep, setCurrentStep }) => {

    const renderSteps = () => {
        if (currentStep === WONDE_SYNC_STEPS.syncStudentsUpn) {
            return <UpnMatchQuery setCurrentStep={setCurrentStep} />
        } else if (currentStep === WONDE_SYNC_STEPS.syncStudentsSuggested) {
            return <SuggestedMatchQuery setCurrentStep={setCurrentStep} />
        }
        else if (currentStep === WONDE_SYNC_STEPS.syncStudentsManual) {
            return <ManualMatchQuery setCurrentStep={setCurrentStep} />
        }
    }
    return (
        <>
            {renderSteps()}
        </>
    )
}

export default SyncStudents;