import React, {useState, useEffect} from 'react'
import { Button, Modal  } from '@axeedge/go-teacher-components';
import styles from '../Students.module.scss'
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import {MERGE_STUDENTS, GET_YEAR_STATS,} from '../services/graphql'
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
const Duplicate = ({s, setStudent, compare, setProcessing, processing, schoolId}) => {

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }

    const [studentsToMerge, setStudentsToMerge] = useState(s.students.map(s => {return s.id}))

    const [refetchStudents, { data, error: unassignedError, loading: unassignedLoading }] = useLazyQuery(GET_YEAR_STATS, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: {
            schoolId: schoolId
        },
        // onCompleted: () => {
        //     console.log('hello')
        //     setProcessing(false)
        // }
    });

    useEffect(() => {
        if (data && data.school) {
            setProcessing(false)
        }
    }, [data])

    useEffect(() => {
        if (processing) {
            if (studentsToMerge.length > 1) {
                mergeStudents({
                    variables: {
                        student1Id: studentsToMerge[0],
                        student2Id: studentsToMerge[1],
                    },
                })
            }
            else {
                refetchStudents()
            }
        }
    },[studentsToMerge])


    const dupeStudentItem = (s) => {
        return (
            <li className={styles.dupeStudentItem} key={`s${s.id}`}>
                <div className={styles.studentItemMeta}>
                    <div>
                        <span onClick={() => setStudent(s)} className={styles.studentItemName}>{s.firstName} {s.lastName}</span>
                        <p>{s.yearGroup ? getYearGroupName(s.yearGroup) : 'Year : NA'} </p>
                        <p>Login Code: {s.loginCode} {s.upn && <>|  UPN: {s.upn}</>}</p>
                        {/* {s.classes.filter(x => x.typeId === 1).length > 0 &&
                            <Link to={`/classes/${s.classes.filter(x => x.typeId === 1)[0].id}`} className={styles.className}>{s.classes.filter(x => x.typeId === 1)[0].name}</Link>
                        } */}
                    </div>
                </div>
            </li>
        )   
    }

    const [mergeStudents, { loading: refreshLoading }] = useMutation(MERGE_STUDENTS, {
        onCompleted: (data) => {
            if (data && data.mergeStudentAccounts && data.mergeStudentAccounts.merged) {
                setStudentsToMerge([...studentsToMerge.filter(s => s !== studentsToMerge[1])])
                // if (studentsToMerge.length > 1) {
                //     handleMerge()
                // } else {
                //     setProcessing(false)
                // }
            }
        }
    });


    const handleMerge = () => {
        mergeStudents({
            variables: {
                student1Id: studentsToMerge[0],
                student2Id: studentsToMerge[1],
            },
        })
    }

    return (
        <li className={styles.dupeItem} key={`d${s.id}`}>
        <ul>
            {s.students.sort(compare).map(s => {
                return (
                    dupeStudentItem(s)
                )
            })}
        </ul>
        <Button onClick={() => {setProcessing(true);handleMerge()}}>Merge Students</Button>
        </li>
    )
}

export default Duplicate