import React from "react";
import Header from "./components/Header";

import GrandTourInterest from './components/GrandTourInterest';
import WriteToRaiseInterest from "./components/WriteToRaiseInterest";
import cx from 'classnames';
import styles from './RegisterInterest.module.scss';

const RegisterInterest = ({ isWriteToRaise = false}) => {

    return (
        <div className={cx('app__layout', styles.appLayoutInterest)}>
            <Header isWriteToRaise={isWriteToRaise} />
            {
                isWriteToRaise ?
                    <WriteToRaiseInterest isWriteToRaise={isWriteToRaise} /> :
                    <GrandTourInterest />
            }
        </div>
    )

}

export default RegisterInterest;