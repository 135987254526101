
import React, { Fragment } from 'react';
import ImageFadeIn from "react-image-fade-in";
import { Link } from '@reach/router';
import { Dropdown } from '@axeedge/go-teacher-components';

import logo from '../../../images/boomer.svg';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import usa from '../../../images/usa.png'
import uk from '../../../images/uk.png'
import row from '../../../images/row.png'

import styles from './CountrySelect.module.scss'

const AuthHeader = ({setShowCountrySelect, hideCountrySelect = false}) => {
    const { t } = useTranslation(['common', 'profile']);

    const renderLinks = () => {
        return (
            <Fragment>
                {ls('country') === 'US' ?
                <>
                    <li><a href="https://boommath.com/" rel='noopener noreferrer' className='link-reverse'>BoomMath</a></li>
                    <li><a href="https://boomhub.app/terms" rel='noopener noreferrer' className='link-reverse'>{t('profile:terms_and_conditions')}</a></li>
                    <li><a href="https://boomhub.app/privacy" rel='noopener noreferrer' className='link-reverse'>{t('profile:privacy_policy')}</a></li>
                </>
                :
                <>
                    <li><a href="https://boomreader.co.uk/" rel='noopener noreferrer' className='link-reverse'>BoomReader</a></li>
                    <li><a href="https://boomwriter.co.uk/" rel='noopener noreferrer' className='link-reverse'>BoomWriter</a></li>
                    <li><a href="https://boomhub.app/terms" rel='noopener noreferrer' className='link-reverse'>{t('profile:terms_and_conditions')}</a></li>
                    <li><a href="https://boomhub.app/privacy" rel='noopener noreferrer' className='link-reverse'>{t('profile:privacy_policy')}</a></li>
                </>
                }
            </Fragment>
        )
    }

    return (
        <div className='auth__header'>
            <ImageFadeIn src={logo} alt="Go Apps Logo" className="auth__applogo" />
            <Dropdown classNames='u-display-sm-block u-display-md-none'>
                <li><Link className='link-reverse' to='/signup'>{t('create_account')}</Link></li>
                <li><Link className='link-reverse' to='/login'>{t('login')}</Link></li>
                {renderLinks()}
            </Dropdown>

            <ul className='u-display-none u-display-md-flex'>
                {!hideCountrySelect &&
                    <li className='u-m-right-2' onClick={() => setShowCountrySelect(true)}><span className={styles.headerFlag}><img className={styles.headerFlagImg} src={ls('country') === 'US' ? usa : ls('country') === 'GB' ? uk : row} /></span></li>
                }
                <li>
                    <Dropdown>
                        {renderLinks()}
                    </Dropdown>
                </li>
            </ul>
        </div>
    )
};

export default AuthHeader;