import React from 'react'
import PageHeader from '../../../components/PageHeader';
import { Button  } from '@axeedge/go-teacher-components';
import {Users} from 'react-feather'
import Students from '../../Students'
import styles from '../NewYear.module.scss'
import {navigate} from '@reach/router'
import newYearSuccess from '../../../images/newYearSetupSuccess.png'
const Step6 = ({setCurrentStep}) => {

    return (
        <>
            <div className={styles.stepContainer}>
                <PageHeader title={`Exit`} icon={Users} close={() => navigate('/')} showBackLink={false} />
                <div className={styles.endContainer}>
                    <div>
                        <h1>5 Relax! Put the kettle on. You're all done! </h1>
                        <p className="u-m-base-2">All your pupils and registration classes have been set-up.</p>
                        <p className="u-m-base-2">You can create an other classes & groups from the Manage Classes section.</p>
                        <p className="u-m-base-2">You can also add any new pupil accounts or update any of their details from the Manage Students section.</p>
                        <p className="u-m-base-2">All your pupil logins are unchanged and parents can continue to log reads without needing to make any changes.</p>
                        <p className="u-m-base-2">For your new cohort & joiners, don’t forget to send home the parent letters and pupil login sheets where appropriate!</p>
                        <Button onClick={() => navigate('/')}>Exit</Button>
                    </div>
                    <div>
                        <img src={newYearSuccess} className={styles.endImg} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step6