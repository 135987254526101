import React, { useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { GET_SCHOOL_BY_URN } from "../../../Onboarding/services/graphql";
import { useForm } from 'react-hook-form';
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { useLazyQuery } from "@apollo/react-hooks";

const MisFindByUrn = ({ onConfirmWondeSchool, setShowUrnForm, purchasing = false, formErrors = [], withDataDashboard, subWithAdditional = null }) => {


    const [wondeSchool, setWondeSchool] = useState(null);
    const [existingSchool, setExistingSchool] = useState(null);
    const [schoolError, setSchoolError] = useState(null);


    const [getSchoolByUrn, { loading: searching }] = useLazyQuery(GET_SCHOOL_BY_URN, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.getSchoolByUrn.errors?.length > 0) {
                setSchoolError(data.getSchoolByUrn.errors[0]);
            }
            if (data && data.getSchoolByUrn.school?.id) {
                setExistingSchool(data.getSchoolByUrn.school);
                return
            }
            if (data && data.getSchoolByUrn.wondeSchool) {
                setWondeSchool(data.getSchoolByUrn.wondeSchool);
                setSchoolError(null);
            }
            if (data && !data.getSchoolByUrn.wondeSchool && !data.getSchoolByUrn.school) {
                setSchoolError('No School found');
            }

        }
    });

    const schema = Yup.object().shape({
        urn: Yup.string().required('Please enter school URN')
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });
    const onSubmit = ({ urn }) => {
        const regex = /^[0-9\b]+$/;
        if (regex.test(urn)) {
            getSchoolByUrn({ variables: { urn } })
        } else {
            setSchoolError('Please type a correct URN')
        }

    }


    return (
        <>

            <p>To sync BoomReader and Wonde, we require your school's URN (Unique Reference Number).</p>
            {schoolError && <p className={'u-text-danger u-m-top-2'}>{schoolError}</p>}
            {wondeSchool ?
                <>
                    <div className="u-m-base-2 u-m-top-2">
                        <p className="heavy">{wondeSchool.name} <span className="light">- URN {wondeSchool.urn}</span></p>
                        <p>{wondeSchool.address}</p>
                        <p>{wondeSchool.address2}</p>
                        <p>{wondeSchool.city}, {wondeSchool.zip}</p>
                        <p>{wondeSchool.country}</p>
                    </div>
                    {formErrors && <FormErrors errors={formErrors} />}
                    {!subWithAdditional?.urn &&
                        <Button disabled={purchasing} onClick={() => onConfirmWondeSchool({ urn: wondeSchool.urn, withDataDashboard: withDataDashboard })}>This is my school</Button>
                    }
                    <button type="button" className="btn-reset u-m-left-2 link-underline" onClick={() => setWondeSchool(null)}>This is not my school</button>
                </> : existingSchool ?
                    <>
                        <p className="heavy u-m-top-2">It looks like your school already has an account with us.</p>
                        <button type="button" className="btn-reset  link-underline" onClick={() => { setExistingSchool(null); setSchoolError(null) }}>This is not my school</button>
                    </>
                    :
                    <form className='u-m-base-3 u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                        <div className="u-display-flex u-align-center">
                            <div className="basic-form__group u-m-base-0">
                                <input
                                    className="basic-form__text-box u-m-base-0 "
                                    name="urn"
                                    style={{ minWidth: '280px' }}
                                    ref={register}
                                    type="text"
                                    placeholder="Unique Reference Number"
                                />
                            </div>
                            <Button type="submit" primary className='u-m-left-2'>{searching ? 'Loading...' : 'Search'}</Button>
                            {setShowUrnForm && <button type="button" className="btn-reset u-m-left-2 link-underline" onClick={() => setShowUrnForm('')}>Cancel</button>}
                        </div>
                        {errors.urn && <p className="basic-form__hint">{errors.urn.message}</p>}
                    </form>
            }
        </>
    )
}

export default MisFindByUrn;