import React, { Fragment, useState } from 'react';
import { Upload, AlertTriangle } from 'react-feather';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import styles from './UploadStudentsList.module.scss';
import { GET_S3_UPLOAD_URL_QUERY } from '../../../../services/graphql';
import { UPLOAD_STUDENTS_LIST_MUTATION } from '../../services/graphql';
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-feather'
import preview from '../../../../images/csv-preview.jpg'
import previewUs from '../../../../images/csv-preview-us.png';
import ls from 'local-storage';

const UploadStudentsList = ({ studentsClassId, setManageUploadData, close }) => {
    const { t } = useTranslation(['classes', 'common']);

    const [uploadError, setUploadError] = useState(null);
    const client = useApolloClient();

    const [onSave, { loading }] = useMutation(UPLOAD_STUDENTS_LIST_MUTATION, {
        update: (_, { data }) => {
            if (data.importStudents.errors && data.importStudents.errors.length !== 0) {
                setUploadError(data.importStudents.errors[0]);
                return;
            }
            if (data.importStudents.importFile.errors && data.importStudents.importFile.errors.length !== 0) {
                setUploadError(data.importStudents.importFile.errors[0]);
                return;
            }
            if (data.importStudents.importFile && data.importStudents.importFile.importStudents) {
                setManageUploadData(data.importStudents);
                close(true);
            }
        }
    });

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const fileName = `class-students-list-${studentsClassId}-${new Date().getTime()}.xlsx`;
            const file = e.target.files[0];
            client.query({
                query: GET_S3_UPLOAD_URL_QUERY,
                variables: {
                    name: fileName
                }
            }).then(r => {
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                axios.put(r.data.s3UploadUrl, file, options).then(s3r => {
                    onSave({
                        variables: {
                            fileName,
                            studentsClassId
                        }
                    });
                })
            })
        }
    }

    if (loading) {
        return (
            <p>{t('common:uploading_file')}</p>
        )
    }

    return (
        <Fragment>
            <h1>Upload Pupil List</h1>
            {
                uploadError &&
                (
                    <div className='basic-form__errors'>
                        <AlertTriangle className='basic-form__errors__icon' />
                        <p>{uploadError}</p>
                    </div>
                )
            }
            <div className={styles.warning}>
                <Icon.AlertCircle size={50} />
                <div>
                    <p className='heavy'>PLEASE NOTE:</p>
                    <p className="u-m-base-2">Upload an xlsx file, please ensure it only contains pupils from a single class, not multiple classes.</p>
                    <p className="u-m-base-2">UPN Numbers are optional</p>
                    <p>Do not add existing students into the xlsc file.</p>
                </div>
            </div>
            <p className='u-m-top-1 u-m-base-1'>Set up your spreadsheet like this or download our template.</p>
            <div className="u-m-base-2">
                
                {ls('country') === 'US' ?
                    <a href="https://goapps.s3.eu-west-2.amazonaws.com/sample-class-upload-usa.xlsx" className={styles.dlButton} target="_blank" rel="noopener noreferrer">Download Template<Icon.Download className={styles.dlIcon} /></a> :
                    <a href="https://goapps.s3.eu-west-2.amazonaws.com/resources/sample-class-upload.xlsx" className={styles.dlButton} target="_blank" rel="noopener noreferrer">Download Template<Icon.Download className={styles.dlIcon} /></a>
                }
            </div>
            {ls('country') === 'US' ?
                <img src={previewUs} alt='' className={styles.img} /> :
                <img src={preview} alt='' className={styles.img} />
            }
            <div className={styles.buttonContainer}>
                <input className={styles.inputFile} id='list' name='list' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={onSelectFile} />
                <label htmlFor='list'><Upload size='20' className={styles.buttonIcon} />Upload Excel File</label>
            </div>
        </Fragment>
    )
}

export default UploadStudentsList;