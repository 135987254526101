import React, { useEffect, useState, useContext } from "react";
import { FormErrors, Button } from "@axeedge/go-teacher-components";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import { LOGIN_WITH_WONDE } from "../services/graphql";
import { AuthContext } from "../../../services/auth/AuthProvider";
import { getAppTokenName, APPS, getUrl } from '@axeedge/go-shared-utils';
import ls from 'local-storage';
import { APP_NAME } from '../../../services/constants';
import { parse } from 'query-string';
import { useLocation } from "react-use";
import { navigate } from "@reach/router";

const WondeLogin = () => {

    const location = useLocation();
    const [formErrors, setFormErrors] = useState([]);
    const client = useApolloClient();
    const auth = useContext(AuthContext);

    const wondeLoginSource = parse(location.search).state;

    useEffect(() => {
        if (parse(location.search).code)
            loginWithWonde({
                variables: {
                    code: parse(location.search).code
                }
            })
    }, [location]);

    const [loginWithWonde, { loading }] = useMutation(LOGIN_WITH_WONDE, {
        onCompleted: data => {
            if (data.loginWithWonde.errors && data.loginWithWonde.errors.length !== 0) {
                if (wondeLoginSource && wondeLoginSource === 'Student') {
                    window.location.href = `${getUrl(APPS.go_portal_pupil)}?wondeError=${data.loginWithWonde.errors[0]}`;
                } else {
                    navigate('/', { state: { wondeLoginErrors: data.loginWithWonde.errors } })
                }
            }
            if (data.loginWithWonde.user && data.loginWithWonde.user.id && data.loginWithWonde.token) {
                if (data.loginWithWonde.userType === 'Teacher') {
                    ls(getAppTokenName(APP_NAME), data.loginWithWonde.token);
                    ls('go_teacher_user', JSON.stringify(data.loginWithWonde.user));
                    auth.setCurrentUser(data.loginWithWonde.user);
                    client.writeData({
                        data: {
                            loggedIn: true,
                            forcedLogout: false
                        }
                    });
                    navigate('/');
                } else if (data.loginWithWonde.userType === 'Student') {
                    window.location.href = `${getUrl(APPS.go_portal_pupil)}/wondeLogin?token=${data.loginWithWonde.token}`;
                }
            }
        }
    });



    return (
        <>
            <div className='auth'>
                <div className="auth__content auth__login-wrapper">
                    <div className='auth__panel auth__panel-main'>
                        <h1 className='u-m-base-2 u-m-top-3 heavy h2'>Login</h1>
                        <div className="auth__form">
                            {loading && <p>Logging in</p>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WondeLogin;