import React from 'react'
import PageHeader from '../../../components/PageHeader';
import { Button  } from '@axeedge/go-teacher-components';
import {Users} from 'react-feather'
import Students from '../../Students'
import styles from '../NewYear.module.scss'
import {navigate} from '@reach/router'
const Step4 = ({setCurrentStep}) => {

    return (
        <>
            <div className={styles.stepContainer}>
                <PageHeader title={`3 New Registration Classes`} icon={Users} close={() => navigate('/')} showBackLink={false} />
                <p className="u-m-base-1">Create 2024/25 registration classes and assign <strong>current</strong> pupils.</p>

                <p className="u-m-base-2"><em>Now, you <strong>must</strong> create a new set of classes for your students moving up a year for 2024-25 as your previous classes have been archived as part of the rollover process.</em></p>
                
                <p className="u-m-base-2"><em>You can create any new classes for a new cohort to BoomReader in the following step.</em></p>
                <Students source={'NewClasses'} />
            </div>
            <div className={styles.stepFooter}>
                <Button onClick={() => setCurrentStep(5)}>Next</Button>
            </div>
        </>
    )
}

export default Step4