import { gql } from 'apollo-boost';

export const ADD_STUDENT_TO_CLASS_MUTATION = gql`
mutation addStudentToClass($studentsClassId: ID!, $firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $yearGroup: Int!, $upn: String) {
    addStudentToClass(studentsClassId: $studentsClassId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, yearGroup: $yearGroup, upn: $upn) {
        student {
            id
        }
        errors
    }
}`;

export const EDIT_STUDENT_MUTATION = gql`
mutation editStudent($studentId: ID!, $schoolId: ID!, $firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $yearGroup: Int!, $upn: String) {
    editStudent(studentId: $studentId, schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, yearGroup: $yearGroup, upn: $upn) {
        student {
            id
            name
            firstName
            lastName
            loginCode
            yearGroup
            upn
        }
        errors
    }
}
`;

export const REMOVE_STUDENT_FROM_CLASS = gql`
mutation removeStudentFromClass($studentId: ID!, $studentsClassId: ID!) {
    removeStudentFromClass(studentId: $studentId, studentsClassId: $studentsClassId) {
        deleted
        errors
    }
}
`;

export const REMOVE_STUDENT_FROM_SCHOOL = gql`
mutation removeStudentFromSchool($studentId: ID!, $schoolId: ID!) {
    removeStudentFromSchool(studentId: $studentId, schoolId: $schoolId) {
        deleted
        errors
    }
}
`;

export const MOVE_STUDENT_TO_CLASS = gql`
mutation moveStudentToClass($studentId: ID!, $studentsClassId: ID!, $fromStudentsClassId: ID) {
    moveStudentToClass(studentId: $studentId, studentsClassId: $studentsClassId, fromStudentsClassId: $fromStudentsClassId) {
        student {
            id
        }
        errors
    }
}
`;



export const REGENERATE_LOGIN_CODE = gql`
mutation regenerateStudentLoginCode($studentId: ID!) {
    regenerateStudentLoginCode(studentId: $studentId) {
        student {
            id
            name
            loginCode
        }
        errors
    }
}
`;