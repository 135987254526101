import React, { useContext } from "react";
import { Button } from "@axeedge/go-teacher-components";
import { useMutation } from "@apollo/react-hooks";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { UPDATE_SCHOOL_SETUP_STEP } from "../../../../services/school/graphql";
import { ADD_BOOM_MATH_TRIAL_ACCESS } from "../../services/graphql";
import { SCHOOL_MATH_SETUP_STEPS } from "../../../../services/constants";

const AddMathTrialWithoutCard = ({ school, isOnboarding = false }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [updateSchoolSetupStep, { loading: updatingStep }] = useMutation(UPDATE_SCHOOL_SETUP_STEP, {
        update: (_, { data }) => {
            if ((data && data.updateSchoolSetupStep.errors && data.updateSchoolSetupStep.errors.length > 0)) {
                alert(data.updateSchoolSetupStep.errors[0]);
                return;
            }
            if (data?.updateSchoolSetupStep?.school?.id) {
                const cu = { ...currentUser, school: data.updateSchoolSetupStep.school }
                setCurrentUser(cu);
            }
        }
    });

    const [addBoomMathTrialAccess, { loading }] = useMutation(ADD_BOOM_MATH_TRIAL_ACCESS, {
        update: (_, { data }) => {
            if ((data && data.addBoomMathTrialAccess.errors && data.addBoomMathTrialAccess.errors.length > 0)) {
                alert(data.addBoomMathTrialAccess.errors[0]);
                return;
            }
            if (data?.addBoomMathTrialAccess?.school?.id) {
                const cu = { ...currentUser, school: data.addBoomMathTrialAccess.school }
                setCurrentUser(cu);
                if (isOnboarding) {
                    updateSchoolSetupStep({
                        variables: {
                            schoolId: currentUser?.school.id,
                            step: SCHOOL_MATH_SETUP_STEPS.finalStepDone
                        }
                    })
                }
            }
        }
    });

    return (
        <>
            <h1>You're all setup!</h1>
            <p className="u-m-base-2">Enjoy BoomMath, the collaborative tool that allows students to explore the application of mathematical theory through writing and sharing.</p>
            <Button disabled={loading || updatingStep} onClick={() => addBoomMathTrialAccess({
                variables: {
                    schoolId: school.id
                }
            })}>{loading || updatingStep ? 'Saving...' : 'Start my free trial'}</Button>
        </>
    )
}

export default AddMathTrialWithoutCard