import React, {useContext} from 'react'
import { AuthContext } from '../../services/auth/AuthProvider';
import styles from './NewSchoolYearWarning.module.scss'
import loader from '../../images/loader.svg';
import { Button  } from '@axeedge/go-teacher-components';
import {navigate} from '@reach/router'
import boomers from '../../images/create-assignment.png'
import { useQuery } from '@apollo/react-hooks';
import {GET_TEACHERS} from './services/graphql'

const NewSchoolYearWarning = () => {
    const { currentUser: user } = useContext(AuthContext);

    const { data, error, loading } = useQuery(GET_TEACHERS, {
        variables: {
            schoolId: user && user.school.id
        },
        fetchPolicy: 'network-only'
    });

    if (error) {
        return <p>Something went wrong</p>
    }

    if (loading) {
        return <img src={loader} />
    }

    return (
        <div className={styles.container}>
            <div>
                <h2>This feature is unavailable until your school is set up for the new academic year.</h2>
                {user.isSchoolAdmin ?
                    <>
                        <h2>Ready? Let's go!</h2>
                        <Button onClick={() => navigate('/newAcademicYear')}>Get Started</Button>
                    </>
                    :
                    <>
                        <h2>Please ask your administrator to do this.</h2>
                        <p>Your BoomReader administrators are:</p>
                        <ul>
                            {data && data.school && data.school.teachers.filter(t => t.isSchoolAdmin).map(t => {
                                return <li key={`teacher${t.id}`}>{t.displayName}</li>
                            })}
                        </ul>
                    </>
                }
            </div>
            <div>
                <img src={boomers}/>
            </div>
        </div>
    )
}

export default NewSchoolYearWarning