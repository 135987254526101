import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../../../../services/auth/AuthProvider';
import { useLazyQuery } from '@apollo/react-hooks';
import { READ_SUB_DURATION } from '../../../../../../services/constants';
import { BOOMREAD_COST } from '../../../../services/graphql';
import { useTranslation } from 'react-i18next';
import { CheckCircle, CheckSquare, Square } from 'react-feather';
import styles from './Payment.module.scss';
import SelectStudents from '../SelectStudents';
import cx from 'classnames';
import moment from 'moment';
// import CardSubscription from '../CardSubscription';
import InvoiceSubscription from '../InvoiceSubscription';
import MisFindByUrn from '../../../../components/PurchaseDataDashboardAccess/MisFindByUrn';

const Payment = ({ setSubDuration, subDuration, boomReadPricing }) => {

    const { t } = useTranslation(['apps']);
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const now = moment();

    const [totalStudents, setTotalStudents] = useState(0);
    const [costs, setCosts] = useState(null);

    const [paymentType, setPaymentType] = useState('invoice');

    const [additionalFeatures, setAdditionalFeatures] = useState({
        wondeAndDataDash: true,
        wondeOnly: false,
        urn: null
    });

    const [getCost] = useLazyQuery(BOOMREAD_COST, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => setCosts(data.boomreadCost)
    });

    useEffect(() => {
        if (totalStudents) {
            getCost({
                variables: {
                    schoolId: currentUser.school?.id,
                    years: subDuration,
                    studentsCount: +totalStudents,
                    withDataDashboard: additionalFeatures.wondeAndDataDash,
                    withWonde: additionalFeatures.wondeAndDataDash || additionalFeatures.wondeOnly
                }
            })
        }
    }, [totalStudents, subDuration, additionalFeatures])


    const renderPaymentSummary = () => {
        return (
            <>
                <ul className={styles.paymentSummary}>
                    <li className={styles.paymentSummaryItem}>
                        {totalStudents} students at £{subDuration === READ_SUB_DURATION.oneYear ? boomReadPricing.oneYearPricePerStudent : boomReadPricing.twoYearPricePerStudent}/student
                        <div className={styles.paymentSummaryData}>
                            <span className={styles.paymentSummaryDataValue}>£{costs?.standardCost.toFixed(2) || "0.00"}</span>
                        </div>
                    </li>
                    {Boolean(costs?.discountValue) && (
                        <li className={cx(styles.paymentSummaryItem, 'heavy')}>
                            {boomReadPricing.renewalDiscount}% discount
                            <div className={styles.paymentSummaryData}>
                                <span className={styles.paymentSummaryDataValue}>-£{costs?.discountValue.toFixed(2) || "0.00"}</span>
                            </div>
                        </li>
                    )}

                    {
                        (additionalFeatures.wondeAndDataDash || additionalFeatures.wondeOnly) &&
                        <li className={styles.paymentSummaryItem}>
                            BoomReader Administration Fee
                            <div className={styles.paymentSummaryData}>
                                <span className={styles.paymentSummaryDataValue}>£{(costs?.wondeCost)?.toFixed(2) || '0.00'}</span>
                            </div>
                        </li>
                    }

                    {
                        additionalFeatures.wondeAndDataDash &&
                        <li className={styles.paymentSummaryItem}>
                            Data Dashboard
                            <div className={styles.paymentSummaryData}>
                                <span className={styles.paymentSummaryDataValue}>£{(costs?.dataDashboardCost)?.toFixed(2) || '0.00'}</span>
                            </div>
                        </li>
                    }

                    <li className={styles.paymentSummaryItem}>
                        {t('vat_value')}
                        <div className={styles.paymentSummaryData}>
                            <span className={styles.paymentSummaryDataValue}>£{costs?.VAT.toFixed(2) || "0.00"}</span>
                        </div>
                    </li>
                    <li className={cx(styles.paymentSummaryItem, styles.paymentSummaryItemTotal)}>
                        {t('total')}
                        <div className={styles.paymentSummaryData}>
                            <span className={styles.paymentSummaryDataValue}>£{costs ? (costs.VAT + costs.totalCost).toFixed(2) : "0.00"}</span>
                        </div>
                    </li>
                </ul>
            </>
        )
    }

    return (
        <div className='row'>
            <div className="col-md-8">
                <div className={styles.paymentFormTop}>
                    <SelectStudents setTotalStudents={setTotalStudents} />
                    <h3 className='heavy'>2: Subscription term</h3>
                    <ul className={styles.subType}>
                        <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: subDuration === READ_SUB_DURATION.oneYear })}>
                            <div className={styles.subTypeMain}>
                                <span className={cx(styles.subTypeCheck, { [styles.subTypeCheckActive]: subDuration === READ_SUB_DURATION.oneYear })}><CheckCircle /></span>
                                <p><b>1 Year - £{boomReadPricing.oneYearPricePerStudent}/student</b></p>
                            </div>
                        </li>
                        {/* <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: subDuration === READ_SUB_DURATION.twoYear })} onClick={() => setSubDuration(READ_SUB_DURATION.twoYear)}>
                            <div className={styles.subTypeMain}>
                                <span className={cx(styles.subTypeCheck, { [styles.subTypeCheckActive]: subDuration === READ_SUB_DURATION.twoYear })}><CheckCircle /></span>
                                <div>
                                    <p><b>2 Year - £{boomReadPricing.twoYearPricePerStudent}/student</b></p>
                                    <p>Save and lock in your costs with a two year deal.</p>
                                </div>
                            </div>
                            {subDuration === READ_SUB_DURATION.twoYear &&
                                <div className={styles.subTypeContent}>
                                    <p><b>Year 1: Invoiced now</b></p>
                                    <p><b>Year 2: Invoiced  {moment(now).add(1, 'year').format('DD MMM YYYY')}</b></p>
                                    <p>You'll be able to update/confirm pupil numbers</p>
                                </div>
                            }
                        </li> */}
                    </ul>
                </div>


                <h3 className='heavy'>3: Additional Features</h3>
                <ul className={styles.subType}>
                    <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: additionalFeatures.wondeAndDataDash })}>
                        <div className={styles.subTypeMain} onClick={() => setAdditionalFeatures({ ...additionalFeatures, wondeOnly: false, wondeAndDataDash: !additionalFeatures.wondeAndDataDash })}>
                            <span className={cx(styles.subTypeCheck, { [styles.subTypeCheckActive]: additionalFeatures.wondeAndDataDash })}>{additionalFeatures.wondeAndDataDash ? <CheckSquare /> : <Square />}</span>
                            <p><b>Data Dashboard & MIS Integration - £{1}/student</b></p>
                        </div>
                        <div className={styles.subTypeContent}>
                            <p className='small'>Monitor levels of reading engagement, compare reading trends and parental engagement.</p>
                            {additionalFeatures.wondeAndDataDash && !additionalFeatures?.urn && !currentUser.school?.urn &&
                                <div className='u-m-top-2'>
                                    <MisFindByUrn subWithAdditional={additionalFeatures} onConfirmWondeSchool={(data) => { setAdditionalFeatures({ ...additionalFeatures, urn: data.urn }) }} withDataDashboard={true} />
                                </div>
                            }
                        </div>
                    </li>

                    <li className={cx(styles.subTypeLink, { [styles.subTypeLinkActive]: additionalFeatures.wondeOnly })}>
                        <div className={styles.subTypeMain} onClick={() => setAdditionalFeatures({ ...additionalFeatures, wondeAndDataDash: false, wondeOnly: !additionalFeatures.wondeOnly })}>
                            <span className={cx(styles.subTypeCheck, { [styles.subTypeCheckActive]: additionalFeatures.wondeOnly })}>{additionalFeatures.wondeOnly ? <CheckSquare /> : <Square />}</span>
                            <p><b>MIS Integration </b></p>
                        </div>
                        <div className={styles.subTypeContent}>
                            <p className='small'>Monitor levels of reading engagement, compare reading trends and parental engagement.</p>
                            {additionalFeatures.wondeOnly && !additionalFeatures.urn && !currentUser.school?.urn &&
                                <div className='u-m-top-2'> <MisFindByUrn subWithAdditional={additionalFeatures} onConfirmWondeSchool={(data) => { setAdditionalFeatures({ ...additionalFeatures, urn: data.urn }) }} withDataDashboard={false} /></div>
                            }
                        </div>
                    </li>
                </ul>


                <h3 className='heavy'>3: Invoice Details</h3>
                {/* {paymentType === 'card' ?
                    <CardSubscription
                        totalStudents={totalStudents}
                        user={currentUser}
                        setCurrentUser={setCurrentUser}
                        school={currentUser.school}
                        setPaymentType={setPaymentType}
                        subDuration={subDuration}
                    /> :
                    paymentType === 'invoice' &&
                    <InvoiceSubscription
                        totalStudents={totalStudents}
                        user={currentUser}
                        setCurrentUser={setCurrentUser}
                        school={currentUser.school}
                        setPaymentType={setPaymentType}
                        subDuration={subDuration}
                    />
                } */}
                <InvoiceSubscription
                    totalStudents={totalStudents}
                    user={currentUser}
                    setCurrentUser={setCurrentUser}
                    school={currentUser.school}
                    setPaymentType={setPaymentType}
                    subDuration={subDuration}
                    additionalFeatures={additionalFeatures}
                />
            </div>
            <div className="col-md-3">
                {Boolean(costs?.discountValue) &&
                    <div className={styles.infoPanel}>
                        <h3 className='heavy'>10% loyalty discount</h3>
                        <p>As an existing BoomReader school we'll automatically apply a 10% discount to your renewal.</p>
                    </div>
                }
                {subDuration && renderPaymentSummary()}
            </div>
        </div >
    )
}

export default Payment;