import { gql } from 'apollo-boost';

export const WONDE_STUDENTS_UPN_MATCH = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        wondeAutoMatchStudentsByUpn {
            id
            wondeId
            name
            upn
            yearGroup
            wondeName
            wondeYearGroup
        }
    }
}
`;

export const WONDE_STUDENTS_SUGGESTED_MATCH = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        wondeSuggestedMatchStudents {
            id
            wondeId
            name
            yearGroup
            wondeName
            wondeYearGroup
            wondeUpn
        }
    }
}
`;

export const WONDE_STUDENTS_MANUAL_MATCH = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        unmatchedStudentsInWonde {
            id
            name
            upn
            yearGroup
        }
    }
}
`;

export const UPDATE_WONDE_SYNC_STEP = gql`
mutation updateWondeSyncStep($schoolId: ID!, $wondeSyncStep: Int!) {
    updateWondeSyncStep(schoolId: $schoolId, wondeSyncStep: $wondeSyncStep) {
        errors
        school {
            id
            wondeSyncStep
        }
    }
}
`;

export const GET_WONDE_SYNC_STEP = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        wondeSyncStep
    }
}
`;

export const WONDE_STAFF_MATCH = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        wondeAutoMatchTeachers {
            id
            wondeId
            name
            email
            roleId
            isSchoolAdmin
            wondeName
        }
    }
}
`;

export const SEARCH_WONDE_TEACHERS = gql`
query getWondeTeachersByNameOrEmail($wondeSchoolId: String!, $nameOrEmail: String!) {
    getWondeTeachersByNameOrEmail(wondeSchoolId: $wondeSchoolId, nameOrEmail: $nameOrEmail) {
        id
        email
        firstName
        lastName
        wondeId
    }
}
`;

export const SEARCH_WONDE_STUDENTS = gql`
query getWondeStudentsByNameOrUpn($wondeSchoolId: String!, $nameOrUpn: String!) {
    getWondeStudentsByNameOrUpn(wondeSchoolId: $wondeSchoolId, nameOrUpn: $nameOrUpn) {
        id
        firstName
        lastName
        wondeId
        wondeYearGroup
        wondeUpn
    }
}
`;

export const WONDE_SYNC_TEACHERS = gql`
mutation wondeSyncTeachers($schoolId: ID!, $wondeTeacherDetails: [SyncWondeTeacherAttributes!]!) {
    wondeSyncTeachers(schoolId: $schoolId, wondeTeacherDetails: $wondeTeacherDetails) {
        errors
        teachers {
            id
        }
    }
}
`;

export const WONDE_SYNC_STUDENTS = gql`
mutation wondeSyncStudents($schoolId: ID!, $wondeStudentDetails: [SyncWondeStudentAttributes!]!) {
    wondeSyncStudents(schoolId: $schoolId, wondeStudentDetails: $wondeStudentDetails) {
        errors
        students {
            id
        }
    }
}
`;

export const WONDE_SYNC_CLASSES = gql`
mutation wondeSyncClasses($schoolId: ID!, $wondeClassDetails: [ImportWondeClassAttributes!]!) {
    wondeSyncClasses(schoolId: $schoolId, wondeClassDetails: $wondeClassDetails) {
        errors
        school {
            id
        }
    }
}
`;