import React from 'react';
import styles from './SelectStudents.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const SelectStudents = ({ setTotalStudents, totalStudents = 0 }) => {

    const { t } = useTranslation(['apps']);

    return (
        <div className={styles.selectStudents}>
            <h3 className={styles.selectStudentsTitle}>1: Number of students</h3>
            <input
                type='number'
                className={cx('basic-form__text-box', styles.selectStudentsInput)}
                min='0'
                defaultValue={totalStudents}
                onChange={(e) => setTotalStudents(e.target.value)}
            />
        </div>
    )
}

export default SelectStudents;