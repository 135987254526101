import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Watch } from 'react-feather';
import { Loader } from '@axeedge/go-teacher-components';
import Tab from '../../../../components/Tab';
import Tabs from '../../../../components/Tabs';
import PageHeader from '../../../../components/PageHeader';
import ListItem from '../../../../components/ListItem';
import { GET_ARCHIVED_CLASS } from '../../services/graphql';
import styles from './ClassView.module.scss';
import { useTranslation } from 'react-i18next';

const TABS = {
    pupils: 1,
    teachers: 2
}

const ArchivedClassView = ({ classid }) => {

    const { t } = useTranslation(['classes', 'common']);
    const [selectedTab, setSelectedTab] = useState(TABS.pupils)

    const { data, error, loading } = useQuery(GET_ARCHIVED_CLASS, {
        variables: {
            id: classid
        },
        fetchPolicy: 'cache-and-network'
    });


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass) {
        return (
            <>
                <PageHeader title={data.studentsClass.name} backTo={'/classes'} />
                <Tabs withPanel className='u-m-base-3'>
                    <Tab onClick={() => setSelectedTab(TABS.pupils)} selected={selectedTab === TABS.pupils}>{t('common:pupils')}</Tab>
                    <Tab onClick={() => setSelectedTab(TABS.teachers)} selected={selectedTab === TABS.teachers}>{t('common:teachers')}</Tab>
                </Tabs>
                <div className={styles.container}>
                    <div className='row'>
                        <div className='col-md-6 u-m-base-2'>
                            <div className='card-mobile'>
                                {selectedTab === TABS.pupils ?
                                    <>
                                        {
                                            data.studentsClass.inactiveStudents.length === 0 ?
                                                <p>No pupils in this class</p> :
                                                data.studentsClass.inactiveStudents.map(student =>
                                                    <ListItem
                                                        key={`archived-${student.id}`}
                                                        avatarJson={student.avatarJson}
                                                        mainText={student.name}
                                                        showTip={false}
                                                    />
                                                )
                                        }
                                    </> :
                                    <ul>
                                        {
                                            data.studentsClass.inactiveTeachers && data.studentsClass.inactiveTeachers.length >0 ? data.studentsClass.inactiveTeachers.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(teacher => {
                                                return (
                                                    <li className='icon-title u-m-base-1' key={`class-t-${teacher.id}`}>
                                                        <p>{teacher.displayName}</p>
                                                        {!teacher.active && <Watch className='u-text-muted u-m-left-1' size='16' />}
                                                    </li>
                                                )
                                            }) : <li>{t('common:no_teachers')}</li>}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return null;
}

export default ArchivedClassView;
