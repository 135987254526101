import React, { useEffect, useState, useContext } from 'react'
import PageHeader from '../../../../components/PageHeader';
import { Users, ChevronDown} from 'react-feather';
import styles from '../NewAcademicYear/NewAcademicYear.module.scss';
import { Loader, Button } from '@axeedge/go-teacher-components';
import { GET_CLASS_QUERY } from '../MoveClass/components/services/graphql';
import { ARCHIVE_CLASSES, GET_CLASSES_QUERY } from '../NewAcademicYear/services/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import cx from 'classnames'
import ClassOptions from '../MoveClass/components/classOptions/index'
import YearOptions from '../MoveClass/components/yearOptions/index'
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { Link, navigate } from '@reach/router';
import {ArrowLeft} from 'react-feather'
import { CLASS_TYPE } from '../../../../services/constants';
const MoveGroup = ({ classid, groups=true }) => {

    const { currentUser: user } = useContext(AuthContext);

    const [students, setStudents] = useState([]);
    const [individualStudent, setIndividualStudent] = useState([]);
    const [individualObject, setIndividualObject] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectedStudentsObjects, setSelectedStudentsObjects] = useState([])
    const [showClassOptions, setShowClassOptions] = useState(false)
    const [showIndividualClassOptions, setShowIndividualClassOptions] = useState(false);
    const [showYearOptions, setShowYearOptions] = useState(false)
    const [showIndividualYearOptions, setShowIndividualYearOptions] = useState(false);

    const [studentsDone, setStudentsDone] = useState(0);

    const[studentsYearUpdated, setStudentsYearUpdated] = useState([])

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: classid
        },
        fetchPolicy: 'network-only',
        /*onCompleted: () => {
            setStudents([...data.studentsClass.students, ...data.studentsClass.inactiveStudents])
        }*/
    });

    const [archiveCLass, { loading: archiveLoading, error: archiveError }] = useMutation(ARCHIVE_CLASSES, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.archiveMultipleClasses.errors && data.archiveMultipleClasses.errors.length > 0) {
            } else {
                navigate('/classes/moveGroups');
                return;
            }
            return;
        }
        ,
        onError: (error) => {

            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY
            }
        ]
    });

    useEffect(() => {
        if (data) {
        setStudents([...data.studentsClass.students, ...data.studentsClass.inactiveStudents])
        }
    },[data]);

    useEffect(() => {
        if (students.length > 0) {
            const updatedStudents = students.filter(s => (s.classes.filter(c => c.academicYear === user.school.currentAcademicYear).length > 0 ));
            setStudentsDone(updatedStudents.length);
        }
    }, [students]);

    const onSelectStudent = e => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(s => s !== e.target.value));
            setSelectedStudentsObjects(selectedStudentsObjects.filter(s => s.studentId !== e.target.value))
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
            const student = students.filter(c => c.id === e.target.value)[0];
            let studentClasses
            if (groups) {
                studentClasses = student.classes.filter(c => c.academicYear === 2025 && c.typeId !== CLASS_TYPE.registration);
            } else {
                studentClasses = student.classes.filter(c => c.academicYear === 2025 && c.typeId === CLASS_TYPE.registration);
            }
            setSelectedStudentsObjects([...selectedStudentsObjects, {
                studentId: student.id,
                removeFromClassId: studentClasses.length > 0 ? studentClasses[studentClasses.length - 1].id : null,
                ...(!student.school && {activateSchoolMembership: true})
            }])
        }
    }

    const onToggleAll = e => {
        const cIds = students.map(c => c.id);
        if (e.target.checked) {
            setSelectedStudents(cIds)
            let newArray = []
            _.map(students, student => {
                let studentClasses
                if (groups) {
                    studentClasses = student.classes.filter(c => c.academicYear === 2025 && c.typeId !== CLASS_TYPE.registration)
                } else {
                    studentClasses = student.classes.filter(c => c.academicYear === 2025 && c.typeId === CLASS_TYPE.registration);
                }
                newArray.push({
                    studentId: student.id,
                    removeFromClassId: studentClasses.length > 0 ? studentClasses[studentClasses.length - 1].id : null,
                    ...(!student.school && {activateSchoolMembership: true})
                })
            })
            setSelectedStudentsObjects(newArray)
        } else {
            setSelectedStudents([])
            setSelectedStudentsObjects([])
        }
    }


    const setShowIndividualClassOption = (id) => {
        setIndividualStudent([id]);
        const student = students.filter(c => c.id === id)[0]
        let studentClasses;
        if (groups) {
            studentClasses = student.classes.filter(c => c.academicYear === 2025  && c.typeId !== CLASS_TYPE.registration);
        }
        else {
            studentClasses = student.classes.filter(c => c.academicYear === 2025  && c.typeId === CLASS_TYPE.registration);
        }
        setIndividualObject([{
            studentId: student.id,
            removeFromClassId: studentClasses.length > 0 ? studentClasses[studentClasses.length - 1].id : null,
            ...(!student.school && {activateSchoolMembership: true})
        }]);
        setShowIndividualClassOptions(true)
    }

    const setShowIndividualYearOption = (id) => {
        setIndividualStudent([id]);
        setShowIndividualYearOptions(true)
    }

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }

    return (
        <>
            {loading && <Loader />}
            {data &&
            <>
                <div className={styles.pageHeader}>
                    <div>
                        <Link to="/classes/moveGroups" className={styles.backButton}>
                            <ArrowLeft size="30"/>
                        </Link>
                    </div>
                    <h1 className={styles.pageHeading}>{data.studentsClass.name}</h1>
                    <h1 className={styles.pageHeading} style={{marginLeft: 'auto'}}>2021/22</h1>
                </div>
                <div className="card card-mobile">
                    <div className={styles.container}>
                        <div className={styles.left}>
                        <h1 className="u-m-base-2 u-bold">Select target classes &amp; year groups</h1>
                        <div className={styles.controls}>
                            <input
                                name="class"
                                type="checkbox"
                                onChange={(e) => onToggleAll(e)}
                                id="SelectAll"
                                style={{
                                    marginRight: '10px'
                                }}
                            />
                            <label htmlFor="SelectAll" style={{marginRight: '50px'}}>All/None</label>
                            <div
                                className={cx(styles.actionDrop,selectedStudents.length === 0 && styles.actionDropDisabled)}
                                style={{marginRight: '10px'}}
                                onClick={() => selectedStudents.length > 0 && setShowClassOptions(!showClassOptions)}    
                            >
                                Select Class <ChevronDown />
                            </div>
                        </div>
                            {
                                _.map(students, s => {
                                    let studentClasses
                                    if (groups) {
                                        studentClasses = s.classes.filter(c => c.academicYear === 2025 && c.typeId !== CLASS_TYPE.registration);
                                    } else {
                                        studentClasses = s.classes.filter(c => c.academicYear === 2025 && c.typeId === CLASS_TYPE.registration);   
                                    }
                                    return (
                                    <div key={`class${s.id}`} className={styles.studentRow}>
                                        <input
                                            name="class"
                                            type="checkbox"
                                            value={s.id}
                                            onChange={(e) => onSelectStudent(e)}
                                            checked={selectedStudents.indexOf(s.id) > -1}
                                            className={styles.checkbox}
                                            id={`st_${s.id}`}
                                        />
                                        {s.name}
                                        <div
                                            className={cx(styles.actionDrop, styles.actionDropStudent)}
                                            style={{marginRight: '10px', marginLeft: 'auto'}}
                                            onClick={() => setShowIndividualClassOption(s.id)}    
                                        >
                                            <div className={styles.actionDropTitle}>{!s.school ? "Leaving School" : studentClasses.length > 0 ? studentClasses[studentClasses.length - 1].name : 'Select Class'}</div><ChevronDown />
                                        </div>
                                    </div>
                                    )
                                })
                                }
                        </div>
                        <div className={styles.right}>
                            <h1 className="u-bold">{studentsDone}/{students.length} Completed</h1>
                            <Button disabled={studentsDone !== students.length} onClick={() => {
                                archiveCLass({variables: {studentsClassIds: [classid]}})
                            }} fullWidth>
                                Done
                            </Button>
                            <div className={cx(styles.greySide,"u-m-top-2")}>
                                <h2 className="u-bold">Class Help</h2>
                                <p className="u-m-base-2">For each pupil select their target class and year group.</p>
                                <p className="u-m-base-2">You can create your new classes as you go or select from those already created.</p>
                                <p className="u-m-base-2">Select 'Year 6 Leaver' to automatically set them to be leaving the school and their target year-group to Year 7.</p>
                                <p>Select 'Leaving this school' if the pupil has or will be leaving. To help with historical data you'll also need to set their target year group.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {showClassOptions &&
                    <ClassOptions close={() => setShowClassOptions(false)} schoolId={user.school.id} stClass={data.studentsClass} students={selectedStudents} studentObjects={selectedStudentsObjects} groups={groups}/>
                }
                { showIndividualClassOptions &&
                    <ClassOptions close={() => setShowIndividualClassOptions(false)} schoolId={user.school.id} stClass={data.studentsClass} students={individualStudent} studentObjects={individualObject} groups={groups} />
                }
                {showYearOptions &&
                    <YearOptions close={() => setShowYearOptions(false)} students={selectedStudents} classId={classid} setStudentsYearUpdated={setStudentsYearUpdated} updatedStudents={studentsYearUpdated}  />
                }
                { showIndividualYearOptions &&
                    <YearOptions close={() => setShowIndividualYearOptions(false)} students={individualStudent} classId={classid} setStudentsYearUpdated={setStudentsYearUpdated} updatedStudents={studentsYearUpdated} />
                }
            </>
            }
        </>
    )
}

export default MoveGroup;