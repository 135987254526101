import React from 'react';
import { Link } from '@reach/router';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CLASS_TYPE } from '../../../../services/constants';

const ClassItem = ({ studentClass, onSelectClass, selectedClasses = [], user }) => {

    const { t } = useTranslation(['classes']);

    return (
        <li className='list-item'>
            {user.isSchoolAdmin &&
                <div className="basic-form__group basic-form__group--check u-m-base-0 u-m-top-1">
                    <input
                        type='checkbox'
                        className="basic-form__check-box"
                        value={studentClass.id}
                        onChange={(e) => onSelectClass(e)}
                        id={`t-${studentClass.id}`}
                        checked={selectedClasses.indexOf(studentClass.id) > -1}
                    />
                    <label className="basic-form__check-label" htmlFor={`t-${studentClass.id}`}>&nbsp;</label>
                </div>
            }
            <Link className='link-reverse' to={`/classes/${studentClass.id}`}>{studentClass.name}</Link>
            <span className='u-m-left-auto small'>
                {studentClass.typeId === CLASS_TYPE.registration && t('registration')}
                <span className='u-m-left-2'>{studentClass.description}</span>
            </span>
        </li>
    )
}

export default ClassItem;
