import React, { useState, Fragment } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { RESEND_WELCOME_EMAIL } from '../../services/graphql';
import SaveConfirm from '../../../../components/SaveConfirm';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../../../Login/components/AuthHeader';
import styles from '../../SignUp.module.scss';
import writeToRaiseLogo from '../../../../images/dashboard/write_to_raise_logo.png';
import brianImg from '../../../../images/signup/brian_blessed_cutout.png';
import bwLogo from '../../../../images/boomwriter_reversed.png';
//import grandTourLogo from '../../../../images/onboarding/grand-tour-logo-rev.png';
import boomReaderLogo from '../../../../images/boomreader_reversed.png';
import boomMathLogo from '../../../../images/boom-math.png';

import ImageFadeIn from 'react-image-fade-in';
import { SUBSCRIPTION_INTENT } from '../../../../services/constants';

const SignUpConfirm = ({ location }) => {

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [showResend, setShowResend] = useState(false);

    const { t } = useTranslation(['profile', 'common']);

    const [resendEmail, { loading }] = useLazyQuery(RESEND_WELCOME_EMAIL, {
        onCompleted: _ => {
            setShowSaveConfirm(true)
        }
    });

    return (
        <div className='auth'>
            <AuthHeader showLogin={true} hideCountrySelect={true}/>
            <div className="auth__content">
                <div className='auth__panelx'>
                    <div className={styles.signupConfirm}>
                        {location?.state?.subscriptionIntent && (
                            <>
                                {location.state.subscriptionIntent === SUBSCRIPTION_INTENT.write || location.state.subscriptionIntent === SUBSCRIPTION_INTENT.leavers ? (
                                    <div className={styles.signupConfirmBanner}>
                                        <ImageFadeIn src={bwLogo} alt='BoomWriter' />
                                    </div>
                                ) : location.state.subscriptionIntent === SUBSCRIPTION_INTENT.math ? (
                                    <div className={styles.signupConfirmBannerMath}>
                                        <ImageFadeIn src={boomMathLogo} alt='BoomMath' />
                                        <h2>Activate your Teacher account</h2>
                                        </div>) : location.state.subscriptionIntent === SUBSCRIPTION_INTENT.read &&
                                    <div className={styles.signupConfirmBannerRead}>
                                        <ImageFadeIn src={boomReaderLogo} alt='BoomReader' />
                                    </div>
                                }
                            </>
                        )}

                        <div className={styles.signupConfirmContent}>
                            <h1>Almost there... Check your email!</h1>
                            {location && location.state && location.state.confirm_email && (
                                <>
                                    <p className='u-m-base-2'>We've sent an activation email to: <b className='u-text-primary'>{location.state.confirm_email}</b></p>
                                    {showResend ? (
                                        <>
                                            <p>Please check your spam or junk folder as sometimes it can end up there by mistake.</p>
                                            <p>You may need to ask your IT team to have our email address added to your approved sender list: support@boomhub.app</p>
                                            <p className='u-m-base-2'>If you are having trouble activating your account, please contact our friendly team direct: schools@boomhub.app</p>
                                            <button
                                                onClick={() => resendEmail({
                                                    variables: { email: location.state.confirm_email }
                                                })}
                                                className='btn-reset u-m-top-2 u-m-base-2 link-underline'
                                            >
                                                {loading ? t('resending') : t('send_me_another_confirm_email')}
                                            </button>
                                            {showSaveConfirm && <SaveConfirm message={t('email_sent')} hideConfirm={setShowSaveConfirm} />}
                                        </>
                                    ) : <button onClick={() => setShowResend(true)} className='btn-reset link-underline'>{t('i_didnt_get_my_email')}</button>}
                                </>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SignUpConfirm;

