import React, { useState, useContext } from "react";
import { AuthContext } from '../../../services/auth/AuthProvider';

import InterestForm from "./InterestForm";
import Share from "../../../components/Share";
import { X } from "react-feather";
import ImageFadeIn from "react-image-fade-in";
import { Button } from "@axeedge/go-teacher-components";
import circlesImg from '../../../images/interest/write2raise_circles.png';
import write2raiseLogo from '../../../images/interest/write2raise-logo.svg';
import cx from 'classnames'
import styles from '../RegisterInterest.module.scss';


const WriteToRaiseInterest = ({ isWriteToRaise, onHide }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [done, setDone] = useState(false);

    const hostname = window && window.location && window.location.hostname;

    const getShareUrl = () => {
        if (hostname.includes('staging')) {
            return hostname.includes('boom') ? 'https://staging.boomwriter.co.uk/write-to-raise' : 'https://staging.go-write.co.uk/write-to-raise'
        }
        return hostname.includes('boom') ? 'https://boomwriter.co.uk/write-to-raise' : 'https://go-write.co.uk/write-to-raise'
    }

    return (
        <>
            {onHide && <button className={cx('btn-reset', styles.closeRegisterBtn)} onClick={onHide}><X size="30" /></button>}
            {
                done ? (
                    <div className={styles.interest}>
                        <div className={cx(styles.interestHero, styles.interestHeroWrite)}>
                            <div className={cx(styles.interestHeroContent)}>
                                <ImageFadeIn className={styles.interestWriteCircles} src={circlesImg} alt='' />
                                <ImageFadeIn className={styles.interestWriteLogo} src={write2raiseLogo} alt='Write To Raise' />
                                <h1 className={styles.interestHeading}>You're in</h1>
                                <p>We've reserved your school a no-obligation place.</p>
                            </div>
                        </div>
                        <div className={styles.interestShare}>
                            <div className={cx(styles.interestDoneContent)}>
                                <h2 className={styles.interestSubHeading}>Share With Others</h2>
                                <p>Don't keep this amazing opportunity to yourself. Share this project with others - together we can help fund schools like yours all through the power of the written word.</p>
                                <Share url={getShareUrl()} title="Write with Brian Blessed and raise money for your school." />
                            </div>
                        </div>
                        <div className={cx(styles.interestDoneContent)}>
                            <h2 className={styles.interestSubHeading}>What Happens Next?</h2>
                            <p>Please check your inbox as we'll send you a welcome email (please check your spam folder if you don't see it!).</p>
                            <p className="u-m-base-3"><b>We'll send you further updates on how to take part, how the program works and what your school needs to do.</b></p>
                            {onHide && <Button onClick={onHide}>Back to dashboard</Button>}
                        </div>

                    </div>
                ) : (
                    <div className={styles.interest}>
                        <div className={cx(styles.interestHero, styles.interestHeroWrite)}>
                            <div className={cx(styles.interestHeroContent)}>
                                <ImageFadeIn className={styles.interestWriteCircles} src={circlesImg} alt='' />
                                <ImageFadeIn className={styles.interestWriteLogo} src={write2raiseLogo} alt='Write To Raise' />
                                <p className={styles.interestWriteLimited}>Places are limited</p>
                                <p className={cx("u-m-base-2 ", styles.interestMainTitle)}>Don't miss this amazing opportunity to raise both funds and the profile of writing in your school.</p>
                                <p className={styles.interestSubtitle}>Enter your details below and we'll reserve your school a no-obligation place.</p>
                                <p className={styles.interestSubtitle}>We'll send you regular updates with more information on how Write to Raise works, and what you need to know to be able to take part.</p>
                            </div>
                        </div>
                        <InterestForm
                            currentUser={currentUser}
                            setCurrentUser={setCurrentUser}
                            close={() => setDone(true)}
                            isWriteToRaise={isWriteToRaise} />
                    </div>
                )
            }
        </>
    )

}

export default WriteToRaiseInterest;