// Queries and mutations for the Login module
import { gql } from 'apollo-boost';

export const LOGIN = gql`
mutation teacherLogIn($email: String!, $password: String!) {
    teacherLogIn(email: $email, password: $password) {
        teacher {
            id
        }
        token
        errors
    }
}
`;

export const UPDATE_PASSWORD_MUTATION = gql`
mutation updatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        user {
            id
        }
        errors
        token
    }
}
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
        reset
        errors
    }
}
`;

export const TEACHER_CONFIRM_EMAIL = gql`
mutation teacherConfirmEmail($token: String!){
    teacherConfirmEmail(token: $token) {
        errors
        token
        user {
            id
            firstName
            lastName
            displayName
            token
            email

        }
    }
}
`;

export const TEACHER_BY_TOKEN = gql`
query teacherByToken($token: String!) {
    teacherByToken(token: $token) {
        id
        firstName
        lastName
        displayName
        email
        roleId
        lastTermsVersionApproved
        invitedSchools {
            id
            name
            city
            avatarUrl
            country {
                id
                name
            }
        }
    }
}
`;

export const TEACHER_INVITE_ACCEPT = gql`
mutation teacherInviteAccept($schoolId: ID!, $resetToken: String!, $firstName: String!, $lastName: String!, $displayName: String!, $password: String!, $passwordConfirmation: String!, $version: String!){
    teacherInviteAccept(schoolId: $schoolId, resetToken: $resetToken, firstName: $firstName, lastName: $lastName, displayName: $displayName, password: $password, passwordConfirmation: $passwordConfirmation, version: $version) {
        errors
        token
        teacher {
            id
            firstName
            lastName
            displayName
            token
            email
            roleId

        }
    }
}
`;

export const TEACHER_INVITE_DECLINE = gql`
mutation teacherInviteDecline($schoolId: ID!, $teacherId: ID!){
    teacherInviteDecline(schoolId: $schoolId, teacherId: $teacherId) {
        errors
        deleted
    }
}
`;


export const LOGIN_WITH_WONDE = gql`
mutation loginWithWonde($code: String!) {
    loginWithWonde(code: $code) {
        user {
            id
        }
        token
        errors
        userType
    }
}
`;

export const GET_WONDE_URL = gql`
query wondeLoginUrl($from: String!) {
    wondeLoginUrl(from: $from)
}
`;