import React, { useState, useEffect, useContext } from "react";
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { SCHOOL_SETUP_STEPS, CLASS_TYPE, WONDE_SYNC_STEPS } from "../../../../services/constants";
import { useMutation } from "@apollo/react-hooks";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { START_WONDE_IMPORT } from "../../services/graphql";
import { AlertTriangle } from "react-feather";
import { WONDE_SYNC_CLASSES } from "../../../WondeSync/services/graphql";
import { UPDATE_SCHOOL_SETUP_STEP } from "../../../../services/school/graphql";
import UploadActionCable from '../../../Classes/components/UploadClassesPupils/UploadActionCable';
import StudentUpdateCable from "../../../NewYear/components/studentUpdateCable";
import loader from '../../../../images/loader.svg';
import styles2 from '../../../Teachers/components/StaffAccess/StaffAccess.module.scss';
import styles from '../../Onboarding.module.scss';
import cx from 'classnames';

const IMPORT_STATUS = {
    ongoing: 0,
    preprocessed: 1,
    done: 2,
    failed: 9,
    ongoingFinalize: 99
}

const ManageWondeClassSettings = ({ selectedToImport, school, duringSync, setCurrentSyncStep, duringAdditionalImport, closeAdditionalImport }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [formErrors, setFormErrors] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [classTypeError, setClassTypeError] = useState(null);
    const [progress, setProgress] = useState(null);

    const [selectedClasses, setSelectedClasses] = useState([]);
    const [classes, setClasses] = useState(selectedToImport);
    const [importDone, setImportDone] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [ticketId, setTickedId] = useState(null);
    const [receivedData, setReceivedData] = useState(null);


    const [schoolChannelId, setSchoolChannelId] = useState(null);
    const [receivedDataSync, setReceivedDataSync] = useState(null);


    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(el => el !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const onSelectAllClasses = e => {
        const selectedIds = selectedToImport.map(t => t.id);
        e.target.checked ? setSelectedClasses(selectedIds) : setSelectedClasses([])
    }

    const onChangeField = (field, val, i) => {
        const updatedClasses = [...classes];
        const value = field === 'visibleToAllTeachers' || field === 'isMyClass' ? Boolean(+val) : +val;
        updatedClasses[i][field] = value;
        setClasses(updatedClasses);
    }

    const onChangeFieldForAll = (field, val) => {
        const value = field === 'visibleToAllTeachers' ? Boolean(+val) : +val;
        const updatedClasses = classes.map(el => {
            return selectedClasses.find(c => c === el.id) ? { ...el, [field]: value } : el
        });
        setClasses([...updatedClasses]);
    }

    useEffect(() => {
        if (!classes.find(el => !el.typeId) && classTypeError) {
            setClassTypeError(null);
        }
    }, [classes, classTypeError])


    const [updateSchoolSetupStep] = useMutation(UPDATE_SCHOOL_SETUP_STEP, {
        update: (_, { data }) => {
            if (data && data.updateSchoolSetupStep.errors && data.updateSchoolSetupStep.errors.length > 0) {
                setFormErrors(data.updateSchoolSetupStep.errors);
                return;
            }
            if (data?.updateSchoolSetupStep?.school?.id) {
                const cu = { ...currentUser, school: data.updateSchoolSetupStep.school }
                setCurrentUser(cu);
                setShowLoader(false);
            }
        }
    });


    //during Sync (migration) OR Additional Import Only
    const [wondeSyncClasses] = useMutation(WONDE_SYNC_CLASSES, {
        update: (_, { data }) => {
            if (data.wondeSyncClasses.errors && data.wondeSyncClasses.errors.length !== 0) {
                setFormErrors(data.wondeSyncClasses.errors);
                return;
            }
            if (data?.wondeSyncClasses?.school?.id) {
               //via Action Cable, too
                setSchoolChannelId(data?.wondeSyncClasses?.school?.id);
            }
        }
    });


    //during onboarding only
    const [startWondeImport, { loading }] = useMutation(START_WONDE_IMPORT, {
        update: (_, { data }) => {
            if (data.startWondeImport.errors && data.startWondeImport.errors.length !== 0) {
                setFormErrors(data.startWondeImport.errors);
                return;
            }
            if (data.startWondeImport.importFile.errors && data.startWondeImport.importFile.errors.length !== 0) {
                setUploadError(data.startWondeImport.importFile.errors[0]);
                return;
            }
            if (data?.startWondeImport?.importFile?.id) {
                setTickedId(data.startWondeImport.importFile.id);
                setShowLoader(true);
            }
        }
    });

    useEffect(() => {
        if (receivedData) {
            receivedData.progress && setProgress(receivedData.progress)
            if (receivedData.status === IMPORT_STATUS.failed) {
                setUploadError(receivedData.import_errors);
            } else if (receivedData.status === IMPORT_STATUS.done) {
                setImportDone(true);
            }
        }
    }, [receivedData]);


    useEffect(() => {
        if (receivedDataSync) {
            receivedDataSync.mis_sync_classes_progress && setProgress(receivedDataSync.mis_sync_classes_progress);
            if (receivedDataSync.mis_sync_classes_progress === 100) {
                setImportDone(true)
            }
        }
    }, [receivedDataSync]);

    const updateStep = () => {
        updateSchoolSetupStep({
            variables: {
                schoolId: school.id,
                step: SCHOOL_SETUP_STEPS.classesStepDone
            }
        });
    }


    useEffect(() => {
        if (importDone) {
            if (duringSync) {
                setCurrentSyncStep(WONDE_SYNC_STEPS.syncExtraStaff);
                setShowLoader(false);
            } else if (duringAdditionalImport) {
                closeAdditionalImport();
                setShowLoader(false);
            } else updateStep()
        }
    }, [importDone])

    const finalizeImport = () => {
        if (classes.find(el => !el.typeId)) {
            setClassTypeError(true);
            return;
        }
        const classDetails = [];
        classes.forEach(el => {
            classDetails.push({
                wondeClassId: el.id,
                visibility: el.visibleToAllTeachers ? true : false,
                classTypeId: el.typeId
            })
        });
        if (duringSync || duringAdditionalImport) {
            setShowLoader(true);
            wondeSyncClasses({
                variables: {
                    wondeClassDetails: classDetails,
                    schoolId: school.id
                }
            })
        } else {
            startWondeImport({
                variables: {
                    wondeClassesData: classDetails,
                    schoolId: school.id
                }
            });
        }
    }

    return (
        <>
            {schoolChannelId && <StudentUpdateCable schoolId={school.id} setReceivedData={setReceivedDataSync} />}
            {ticketId && <UploadActionCable ticketId={ticketId} setReceivedData={setReceivedData} />}
            <div className={styles.wondeMain}>
                {progress || showLoader ?
                    <div className={cx(styles.panel, 'u-m-base-3')}>
                        <h2>Importing data, please wait</h2>
                        <p className='heavy u-display-flex u-align-center'>Progress: {progress ? <>{progress.toFixed(2)}%</> : <img width={30} height={30} className="u-m-left-2" src={loader} />}</p>
                    </div> :
                    uploadError ? <div className='basic-form__errors'>
                        <AlertTriangle className='basic-form__errors__icon' />
                        <p>{uploadError}</p>
                    </div> :
                        <>
                            <h1>Class settings</h1>
                            <p>Please take a few moments to confirm the app settings for these classes:</p>
                            <div className="u-m-top-2">
                                <ul className="list-group u-m-right-3">
                                    <li className={cx(styles2.staffItem, styles2.staffItemHeader)}>
                                        <div className={styles2.staffItemMain}>
                                            <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                                <input
                                                    name="all"
                                                    className="basic-form__check-box"
                                                    type="checkbox"
                                                    checked={selectedClasses.length === selectedToImport.length}
                                                    onChange={(e) => onSelectAllClasses(e)}
                                                    id="selectAll"
                                                />
                                                <label className="basic-form__check-label" htmlFor='selectAll'>All</label>
                                            </div>
                                        </div>
                                        <div className={styles2.staffItemActions}>
                                            <div className={styles2.staffItemActionsItem}>
                                                <select value='' disabled={selectedClasses.length === 0} onChange={(e) => onChangeFieldForAll('visibleToAllTeachers', e.target.value)} name="visibleAll" className={cx("basic-form__text-select", styles2.select)}>
                                                    <option value=''>Visibility</option>
                                                    <option value={1}>Visible To All</option>
                                                    <option value={0}>Named Teachers Only</option>
                                                </select>
                                            </div>

                                            <div className={styles2.staffItemActionsItem}>
                                                <select value='' disabled={selectedClasses.length === 0} onChange={(e) => onChangeFieldForAll('typeId', e.target.value)} name="typeAll" className={cx("basic-form__text-select", styles2.select, styles.classTypeSelect)}>
                                                    <option value=''>Class Type</option>
                                                    <option value={CLASS_TYPE.registration}>Registration</option>
                                                    <option value={CLASS_TYPE.other}>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </li>

                                    {classes.map((kls, i) => (
                                        <li key={`kls-${kls.id}`} className={styles2.staffItem}>
                                            <div className={styles2.staffItemMain}>
                                                <div className="basic-form__group basic-form__group--check u-m-base-0">
                                                    <input
                                                        type='checkbox'
                                                        className="basic-form__check-box"
                                                        value={kls.id}
                                                        onChange={(e) => onSelectClass(e)}
                                                        id={`kls-${kls.id}`}
                                                        checked={selectedClasses.indexOf(kls.id) > -1}
                                                    />
                                                    <label className="basic-form__check-label" htmlFor={`kls-${kls.id}`}>&nbsp;</label>
                                                </div>
                                                {kls.name}
                                            </div>
                                            <div className={styles2.staffItemActions}>
                                                <div className={cx(styles2.staffItemActionsItem, "basic-form__group basic-form__group--check u-m-base-0")}>
                                                    <span className="u-m-right-1">Visible To All</span>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => onChangeField('visibleToAllTeachers', e.target.checked, i)}
                                                        className="switchToggle"
                                                        name='clsVisible'
                                                        id={`cls-${i}`}
                                                        checked={kls.visibleToAllTeachers || false}
                                                    />
                                                    <label className="switchLabel" htmlFor={`cls-${i}`}>&nbsp;</label>
                                                </div>
                                                <div className={styles2.staffItemActionsItem}>
                                                    <select value={kls.typeId || ''} onChange={(e) => onChangeField('typeId', e.target.value, i)} name="classType" className={cx("basic-form__text-select", styles2.select, styles.classTypeSelect)} >
                                                        <option value=''>Select class type</option>
                                                        <option value={CLASS_TYPE.registration}>Registration</option>
                                                        <option value={CLASS_TYPE.other}>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                {classTypeError && <p className="basic-form__hint u-m-top-2">Please select a type for each class</p>}
                                {formErrors && <FormErrors errors={formErrors} />}
                            </div>
                        </>
                }
            </div>
            <div className={styles.wondeFooter}>
                {!progress && <Button disabled={showLoader} className='u-m-left-auto' onClick={() => finalizeImport()}>{showLoader ? 'Saving... ' : 'Next'}</Button>}
            </div>
        </>
    )
}

export default ManageWondeClassSettings;