import React, { useState, useEffect, useContext, useRef } from "react";
import ManageWondeClassSettings from "./ManageWondeClassSettings";
import { Button } from '@axeedge/go-teacher-components';
import { CheckSquare, ChevronDown, Square } from "react-feather";
import { useClickAway } from "react-use";
import styles from '../../Onboarding.module.scss';
import { AuthContext } from "../../../../services/auth/AuthProvider";
import _ from 'lodash';

const WondeClassesList = ({ allWondeClasses, duringSync = false, setCurrentSyncStep, duringAdditionalImport, closeAdditionalImport }) => {

    const { currentUser } = useContext(AuthContext);

    const [dropdown, setDropdown] = useState(false);

    const [selectedClasses, setSelectedClasses] = useState([]);
    const [showClassSettings, setShowClassSettings] = useState(false);
    //const [searchTerm, setSearchTerm] = useState('');

    const classSubjects = [...new Set(allWondeClasses.map(obj => obj.subject))];
    const [selectedSubjects, setSelectedSubjects] = useState(classSubjects);

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));

    const [visibleClasses, setVisibleClasses] = useState(allWondeClasses);

    // useEffect(() => {
    //     const results = [...allWondeClasses].filter(el => el.name.toLowerCase().includes(searchTerm.toLowerCase()));
    //     setVisibleClasses(results);
    //     //TODO: limit search to exclude unselectedSubjects?
    // }, [searchTerm, setVisibleClasses]);

    useEffect(() => {
        const results = [...allWondeClasses].filter(el => selectedSubjects.includes(el.subject));
        setVisibleClasses(results);
        ///also update selected classes if it matches the subject
        setSelectedClasses(results.filter(el => selectedClasses.includes(el.id)).map(el => el.id))
    }, [selectedSubjects, setVisibleClasses]);

    const onSelectSubject = subject => {
        if (selectedSubjects.indexOf(subject) !== -1) {
            setSelectedSubjects(selectedSubjects.filter(s => s !== subject))
        } else {
            setSelectedSubjects([...selectedSubjects, subject])
        }
    }

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(el => el !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const getUniqueTeachers = (selectedClasses) => {
        const allTeachers = [];
        selectedClasses.forEach(el => {
            const selected = visibleClasses.find(item => item.id === el);
            selected && allTeachers.push(...selected.teachers);
        });
        return [...new Set(allTeachers.map(obj => `${obj.firstName} ${obj.lastName}`))];
    }

    const onSelectAllClasses = e => {
        const selectedIds = visibleClasses.map(t => t.id);
        e.target.checked ? setSelectedClasses(selectedIds) : setSelectedClasses([])
    }


    return (
        <>
            {showClassSettings ? <ManageWondeClassSettings
                duringSync={duringSync}
                school={currentUser.school}
                closeAdditionalImport={closeAdditionalImport}
                setCurrentSyncStep={setCurrentSyncStep}
                duringAdditionalImport={duringAdditionalImport}
                selectedToImport={visibleClasses.filter(el => selectedClasses.includes(el.id)).map(el => el)}
            /> :
                <>
                    <div className={styles.wondeMain}>
                        <h1>Select classes</h1>
                        <p>Which classes would you like to import?</p>
                        <div className="row u-m-top-2">
                            <div className='col-md-6'>
                                <ul className="list-group u-m-right-3 u-m-base-2">
                                    <li className='list-item u-m-base-2'>
                                        <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                            <input
                                                name="all"
                                                className="basic-form__check-box"
                                                type="checkbox"
                                                checked={selectedClasses.length > 0 && selectedClasses.length === visibleClasses.length}
                                                onChange={(e) => onSelectAllClasses(e)}
                                                id="selectAll"
                                            />
                                            <label className="basic-form__check-label" htmlFor='selectAll'>All</label>
                                        </div>
                                        <div className="u-m-left-auto u-display-flex u-align-center">
                                            <div ref={ref} className='dropdownHolder u-m-right-1'>
                                                <button className='dropdownBtn' onClick={() => setDropdown(!dropdown)}>{selectedSubjects.length === classSubjects.length ? 'All' : selectedSubjects.length} Subjects <ChevronDown /> </button>
                                                {dropdown && (
                                                    <ul className='dropdownMenu' style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                        {classSubjects.map(subject => (
                                                            <li key={subject}>
                                                                <button className="dropdownMenuItem" onClick={() => onSelectSubject(subject)}>
                                                                    {selectedSubjects.indexOf(subject) > -1 ? <CheckSquare size={14} /> : <Square size={14} />}   {_.unescape(subject)}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                            {/* <input
                                                type="text"
                                                className='basic-form__text-box u-m-base-0'
                                                value={searchTerm}
                                                placeholder='Type to find class'
                                                onChange={e => setSearchTerm(e.target.value)}
                                            /> */}
                                        </div>
                                    </li>
                                    {visibleClasses.map(kls => (
                                        <li key={`kls-${kls.id}`} className='list-item'>
                                            <div className="u-display-flex u-align-center">
                                                <div className="basic-form__group basic-form__group--check u-m-base-0">
                                                    <input
                                                        type='checkbox'
                                                        className="basic-form__check-box"
                                                        value={kls.id}
                                                        onChange={(e) => onSelectClass(e)}
                                                        id={`kls-${kls.id}`}
                                                        checked={selectedClasses.indexOf(kls.id) > -1}
                                                    />
                                                    <label className="basic-form__check-label" htmlFor={`kls-${kls.id}`}>&nbsp;</label>
                                                </div>
                                                {kls.name}
                                            </div>
                                            <div className="u-m-left-auto">
                                                <span className="u-m-right-3">Pupils: {kls.students.length}</span>
                                                <span className=''>Staff: {kls.teachers.length}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <div className={styles.staffToImport}>
                                    <h3>Staff:</h3>
                                    {getUniqueTeachers(selectedClasses).length === 0 ? <p>No teachers included</p> :
                                        <ul>
                                            {getUniqueTeachers(selectedClasses).sort((a, b) => a.localeCompare(b)).map(teacher => (
                                                <li key={teacher}>
                                                    {teacher}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.wondeFooter}>
                        <div>
                            <h2 className="heavy">We'll import {`${selectedClasses.length === 1 ? '1 class' : selectedClasses.length + ' classes'}`}</h2>
                            <p>You'll be able to import additional staff members in the next step</p>
                        </div>
                        <Button onClick={() => setShowClassSettings(true)} disabled={selectedClasses.length === 0}>Import</Button>
                    </div>
                </>
            }
        </>
    )
}

export default WondeClassesList;