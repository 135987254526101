import React, { useState, useContext } from 'react';
import styles from './Payment.module.scss';
import cx from 'classnames';

import { AuthContext } from '../../../../../../services/auth/AuthProvider';

import { PURCHASE_PACK_OFFER } from '../../../../services/graphql';
import { useMutation } from '@apollo/react-hooks';

import { getPacksPurchaseData } from '../../../../services/utils';
import { GET_PURCHASED_PACKS_QUERY } from '../../../../../../services/school/graphql';
import { ASSIGN_PACK_TO_CLASS_MUTATION } from '../../../../../../scenes/Classes/services/graphql';

import InvoiceDetailsForm from '../../../../../../components/Purchase/InvoiceDetailsForm';

const Payment = ({ totalPrice, setPurchaseDone, classId = null, academicYear, selectedPacks }) => {
    const [formErrors, setFormErrors] = useState([]);

    const { currentUser } = useContext(AuthContext);
    const { school } = currentUser;


    // buy from gowrite (promo books)
    const [assignPack, { loading: assigningToClass }] = useMutation(ASSIGN_PACK_TO_CLASS_MUTATION, {
        update: (_, { data }) => {
            if (data.assignPackToClass.errors && data.assignPackToClass.errors.length) {
                setFormErrors(data.assignPackToClass.errors);
                return
            } else {
                setPurchaseDone(true);
            }
        }
    })

    const [purchasePackOffer, { loading }] = useMutation(PURCHASE_PACK_OFFER, {
        update: (_, { data }) => {
            if (data.purchasePackOffer.errors && data.purchasePackOffer.errors.length) {
                setFormErrors(data.purchasePackOffer.errors)
                return
            }
            if (data.purchasePackOffer.school.id) {
                // buy from gowrite (promo books)
                if (classId) {
                    assignPack({
                        variables: {
                            studentsClassId: classId,
                            purchasedPackId: data.purchasePackOffer.purchasedPacks[0].id,
                            packOfferIndex: +data.purchasePackOffer.purchasedPacks[0].instances
                        }
                    })
                } else {
                    setPurchaseDone(true);
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_PURCHASED_PACKS_QUERY, variables: { schoolCode: school.schoolCode } }]
    });

    const onSubmit = values => {
        const { contactPerson, contactEmail, contactPhone, contactIsFinancialManager, purchaseOrderReferenceNumber } = values;
        // console.log('cart', getPacksPurchaseData(selectedPacks));
        purchasePackOffer({
            variables: {
                schoolId: school.id,
                academicYear: academicYear,
                packs: getPacksPurchaseData(selectedPacks),
                contactPerson,
                contactPhone,
                contactEmail,
                contactIsFinancialManager,
                purchaseOrderReferenceNumber
            }
        });
    }

    return (
        <div className='card card-mobile u-m-top-3'>

            <h3 className='heavy'>Payment</h3>

            <ul className={styles.paymentSummary}>
                <li className={styles.paymentSummaryItem}>
                    Books
                    <div className={styles.paymentSummaryData}>
                        <span>£</span>
                        <span className={styles.paymentSummaryDataValue}>{totalPrice.toFixed(2)}</span>
                    </div>
                </li>
                <li className={styles.paymentSummaryItem}>
                    Shipping
                    <div className={styles.paymentSummaryData}>
                        <span>£</span>
                        <span className={styles.paymentSummaryDataValue}>0</span>
                    </div>
                </li>
                <li className={styles.paymentSummaryItem}>
                    VAT
                    <div className={styles.paymentSummaryData}>
                        <span>£</span>
                        <span className={styles.paymentSummaryDataValue}>0</span>
                    </div>
                </li>
                <li className={cx(styles.paymentSummaryItem, 'heavy')}>
                    Total
                    <div className={styles.paymentSummaryData}>
                        <span>£</span>
                        <span className={styles.paymentSummaryDataValue}>{totalPrice.toFixed(2)}</span>
                    </div>
                </li>
            </ul>

            <InvoiceDetailsForm
                onSubmit={onSubmit}
                formErrors={formErrors}
                user={currentUser}
                totalValue={totalPrice}
                purchaseType='packs'
                loading={loading || assigningToClass}
            />
        </div>
    )
}

export default Payment;