import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FormErrors } from '@axeedge/go-teacher-components';
import { ADD_EXISTING_STUDENT_TO_CLASS, GET_CLASS_QUERY } from '../../../services/graphql';

import { PlusCircle, CheckCircle, Loader } from 'react-feather';

import cx from 'classnames';
import styles from '../AddExistingStudents.module.scss';

import { useTranslation } from 'react-i18next';

const Result = ({ student, classId }) => {

    const { t } = useTranslation(['classes']);

    const [formErrors, setFormErrors] = useState([]);

    const [addExistingStudentToClass, { loading: addingStudent }] = useMutation(ADD_EXISTING_STUDENT_TO_CLASS, {
        update: (_, {data}) => {
            if (data.addExistingStudentToClass.errors && data.addExistingStudentToClass.errors.length !== 0) {
                setFormErrors(data.addExistingStudentToClass.errors);
                return;
            }
            if (data.addExistingStudentToClass.student.id) {
                return isClassStudent(data.addExistingStudentToClass.student);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId }}]
    });


    const isClassStudent = student => {
        return student.classes.find(c => c.id === classId);
    }

    return(
        <li className={cx('list-item', styles.student)}>
            <div className={styles.studentMain}>
                <h2 className='u-m-base-0'>{student.name}</h2>
                <p>{t('classes')}: {student.classes.map(cls => cls.name).join(', ')}</p>
                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
            </div>
            {
                isClassStudent(student) ? <CheckCircle /> : (
                    <button
                        onClick={() => addExistingStudentToClass({
                            variables: {
                                studentsClassId: classId,
                                studentId: student.id
                            }
                        })}
                        disabled={addingStudent}
                        className='btn-reset'
                    >
                        {addingStudent ? <Loader /> : <PlusCircle /> } 
                    </button>
                )
            }
        </li>
    )
}

export default Result;