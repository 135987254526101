import { useEffect } from 'react';
import { useLocation } from 'react-use';

const FreshdeskWidget = ({ currentUser }) => {

    const location = useLocation();
    useEffect(() => {
        window.fwSettings = {
            widget_id: 151000005271,
        };
        const pagesWithWidget = ['/'];
        if (window.FreshworksWidget) {
            pagesWithWidget.includes(location.pathname) && currentUser ? window.FreshworksWidget("show") : window.FreshworksWidget("hide");
        }
        const loadFreshdeskScript = () => {
            if (!document.querySelector(`script[src='https://widget.freshworks.com/widgets/151000005271.js']`)) {
                const script = document.createElement('script');
                script.src = 'https://widget.freshworks.com/widgets/151000005271.js';
                script.async = true;
                script.defer = true;
                script.onerror = () => console.error("Failed to load Freshdesk widget script."); // Error handling
                document.body.appendChild(script);
            }
        };

        if (typeof window.FreshworksWidget !== 'function') {
            window.FreshworksWidget = function () {
                window.FreshworksWidget.q.push(arguments);
            };
            window.FreshworksWidget.q = [];
        }
        loadFreshdeskScript();
    }, [location.pathname]);
    return null;
};
export default FreshdeskWidget;