// Queries and mutations specifically for the Classes Scene
import { gql } from 'apollo-boost';

const teacherQuery = `
id
isSchoolAdmin
active
displayName
firstName
lastName
`

export const GET_CLASSES_QUERY = gql`
query myClasses($includeAdminedClasses: Boolean) {
    myClasses(includeAdminedClasses: $includeAdminedClasses) {
        id
        name
        description
        academicYear
        school {
            id
            newYearStep
        }
        visibleToAllTeachers
        archived
        typeId
        teachers {
           ${teacherQuery}
        }
    }
}
`

export const GET_ARCHIVED_CLASSES = gql`
query myClasses($includeAdminedClasses: Boolean, $onlyArchived: Boolean) {
    myClasses(includeAdminedClasses: $includeAdminedClasses, onlyArchived: $onlyArchived) {
        id
        name
        description
        academicYear
        visibleToAllTeachers
        archived
        inactiveTeachers {
           ${teacherQuery}
        }
    }
}
`
export const GET_CLASSES_LIST_QUERY = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        classes {
            id
            name
        }
    }
}
`;

export const CHECK_STUDENTS = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        currentAcademicYear
        classes {
            id
            name
            students{
                id
            }
        }
    }
}
`;


export const GET_UNASSIGNED_STUDENTS_QUERY = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        unassignedStudents {
            id
            name
            firstName
            lastName
            loginCode
        }
    }
}
`;

export const GET_CLASS_QUERY = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        academicYear
        visibleToAllTeachers
        archived
        typeId
        lastSyncedFromWondeAt
        teachers {
           ${teacherQuery}
        }
        students {
            id
            avatarJson
            avatarUrl
            firstName
            lastName
            name
            token
            loginCode
            expiredToken
            yearGroup
            boomerAvatarUrl
            updatedAt
            upn
        }
        classPacks {
            id
            bookTemplateCategory {
                id
                name
                description
            }
            bookTemplate {
                id
                name
            }
            packOfferIndex
            purchasedPack {
                id
                instances
            }
            book {
                id
                status
            }
        }
    }
}
`;

export const GET_ARCHIVED_CLASS = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        academicYear
        archived
        inactiveTeachers {
           ${teacherQuery}
        }
        inactiveStudents {
            id
            avatarJson
            avatarUrl
            firstName
            lastName
            name
            yearGroup
        }
    }
}
`;

export const FIND_STUDENTS = gql`
query studentsByName($name: String!, $schoolId: ID!) {
    studentsByName(name: $name, schoolId: $schoolId) {
        id
        name
        classes {
            id
            name
        }
    }
}
`;

export const ADD_EXISTING_STUDENT_TO_CLASS = gql`
mutation addExistingStudentToClass($studentId: ID!, $studentsClassId: ID!) {
    addExistingStudentToClass(studentId: $studentId, studentsClassId: $studentsClassId) {
        student {
            id
            name
            classes {
                id
                name
            }
        }
        errors
    }
}
`;

export const GET_BOOK_TEMPLATE_CATEGORIES = gql`
query bookTemplateCategories {
    bookTemplateCategories {
        id
        description
        name
    }
}
`;

export const GET_SCHOOL_STUDENTS_LIST = gql`
query schoolStudentsList($schoolId: ID!) {
    schoolStudentsList(schoolId: $schoolId)
}
`;

export const GET_CLASS_STUDENTS_LIST = gql`
query classStudentsList($studentsClassId: ID!) {
    classStudentsList(studentsClassId: $studentsClassId)
}
`;

export const ADD_CLASS_MUTATION = gql`
mutation createStudentsClass($name: String!, $typeId: Int, $description: String, $schoolId: ID!, $teacherIds: [ID!]!, $visibleToAllTeachers: Boolean!) {
    createStudentsClass(name: $name, typeId: $typeId, description: $description, schoolId: $schoolId, teacherIds: $teacherIds, visibleToAllTeachers: $visibleToAllTeachers) {
        studentsClass {
            id
        }
        errors
    }
}
`;

export const UPDATE_CLASS_TYPE_ID = gql`
mutation updateStudentsClassesTypeId($studentsClassesIds: [ID!]!, $typeId: Int!, $schoolId: ID!) {
    updateStudentsClassesTypeId(studentsClassesIds: $studentsClassesIds, typeId: $typeId, schoolId: $schoolId) {
        school {
            id
        }
        errors
    }
}
`;


export const ADD_CLASS_ASSIGN_STUDENTS_MUTATION = gql`
mutation createStudentsClassAndAssignStudents($name: String!, $typeId: Int!, $description: String, $schoolId: ID!, $studentsData: [StudentToClassAttributes!]!,  $teacherIds: [ID!]!, $visibleToAllTeachers: Boolean!) {
    createStudentsClassAndAssignStudents(name: $name, typeId: $typeId, description: $description, schoolId: $schoolId, studentsData: $studentsData, teacherIds: $teacherIds, visibleToAllTeachers: $visibleToAllTeachers) {
        studentsClass {
            id
        }
        errors
    }
}
`;

export const EDIT_CLASS_MUTATION = gql`
mutation editStudentsClass($name: String, $typeId: Int, $description: String, $schoolId: ID!, $studentsClassId: ID!, $visibleToAllTeachers: Boolean!, $academicYear: Int) {
    editStudentsClass(name: $name, typeId: $typeId, description: $description, schoolId: $schoolId, studentsClassId: $studentsClassId, visibleToAllTeachers: $visibleToAllTeachers, academicYear: $academicYear) {
        studentsClass {
            id
        }
        errors
    }
}
`;

export const UPDATE_ACADEMIC_YEAR = gql`
mutation editClassesAcademicYear($studentsClassIds: [ID!]!, $academicYear: Int!) {
    editClassesAcademicYear(studentsClassIds: $studentsClassIds, academicYear: $academicYear) {
        errors
    }
}
`;

export const ADD_CLASS_TEACHER_MUTATION = gql`
mutation addClassTeacher($studentsClassId: ID!, $teacherId: ID!) {
    addClassTeacher(studentsClassId: $studentsClassId, teacherId: $teacherId) {
        studentsClass {
            id
            name
            description
            teachers {
                ${teacherQuery}
            }
        }
        errors
    }
}
`;

export const REMOVE_TEACHER_FROM_CLASS_MUTATION = gql`
mutation removeTeacherFromClass($studentsClassId: ID!, $teacherId: ID!) {
    removeTeacherFromClass(studentsClassId: $studentsClassId, teacherId: $teacherId) {
        deleted
        errors
    }
}
`;


export const ASSIGN_PACK_TO_CLASS_MUTATION = gql`
mutation assignPackToClass($purchasedPackId: ID!, $studentsClassId: ID!, $bookTemplateCategoryId: ID, $packOfferIndex: Int!) {
    assignPackToClass(purchasedPackId: $purchasedPackId, studentsClassId: $studentsClassId, bookTemplateCategoryId: $bookTemplateCategoryId, packOfferIndex: $packOfferIndex) {
        studentsClass {
            id
        }
        errors
    }
}`;

export const UPLOAD_STUDENTS_LIST_MUTATION = gql`
mutation importStudents($studentsClassId: ID!, $fileName: String!) {
    importStudents(studentsClassId: $studentsClassId, fileName: $fileName) {
        importFile {
            errors
            id
            status
            notValidStudents {
                id
                firstName
                lastName
                line
                missingFirstName
                missingLastName
                missingStudentsClass
                missingYearGroup
                duplicated
                duplicatedInFile
                duplicatedUpn
            }
            importStudents {
                id
                firstName
                lastName
                duplicated
                duplicatedInFile
                yearGroup
                missingYearGroup
                upn
            }
        }
        errors
    }
}`;

export const MANAGE_IMPORT_STUDENTS = gql`
mutation manageImportStudentsFromXlsx($importTicketId: ID!, $importedStudentIds: [ID!]!) {
    manageImportStudentsFromXlsx(importTicketId: $importTicketId, importedStudentIds: $importedStudentIds) {
        importTicket {
            errors
            id
            status
            importStudents {
                id
                firstName
                lastName
                yearGroup
                missingYearGroup
                upn
            }
        }
        errors
    }
}`;

export const MANAGE_IMPORT_CLASSES_STUDENTS = gql`
mutation manageImportStudentsFromXlsx($importTicketId: ID!, $importedStudentIds: [ID!]!) {
    manageImportStudentsFromXlsx(importTicketId: $importTicketId, importedStudentIds: $importedStudentIds) {
        importTicket {
            errors
            id
            status
            imported
            total
            importStudentsClasses {
                id
                name
                studentsCounter
                visibleToAllTeachers
                typeId
            }
        }
        errors
    }
}`;

export const FINALIZE_IMPORT_STUDENTS = gql`
mutation finalizeImportStudentsFromXlsx($importTicketId: ID!) {
    finalizeImportStudentsFromXlsx(importTicketId: $importTicketId) {
        importFile {
            errors
            id
            imported
            total
            status
            importStudents {
                id
                firstName
                lastName
                yearGroup
                missingYearGroup
            }
        }
        errors
    }
}`;

export const IMPORT_STUDENTS_FROM_EXCEL_TO_SCHOOL = gql`
mutation importStudentsFromXlsxToSchool($schoolId: ID!, $fileName: String!) {
    importStudentsFromXlsxToSchool(schoolId: $schoolId, fileName: $fileName) {
        errors
        importFile {
            id
            errors
        }
    }
}
`;

export const IMPORT_TICKET_QUERY = gql`
query importTicket($importTicketId: ID!, $limit: Int!) {
    importTicket(importTicketId: $importTicketId) {
        errors
        id
        importStudentsClasses {
            id
            name
            studentsCounter
            visibleToAllTeachers
            typeId
        }
        imported
        total
        status
        notValidStudents {
            id
            firstName
            lastName
            line
            missingFirstName
            missingLastName
            missingStudentsClass
            missingYearGroup
            duplicated
            duplicatedInFile
            duplicatedUpn
        }
        sampleImportedStudents(limit: $limit) {
            id
            firstName
            lastName
            yearGroup
            importedStudentsClass {
                id
                name
            }
        }

    }
}
`;

export const IMPORT_STUDENTS_CLASS_QUERY = gql`
query importStudentsClass($id: ID!) {
    importStudentsClass(id: $id) {
        id
        name
        importedStudents {
            id
            firstName
            lastName
            yearGroup
        }
    }
}
`;


export const FINALIZE_CLASSES_IMPORT = gql`
mutation finalizeClassesImport($classDetails: [ImportClassesAttributes!]!, $importTicketId: ID!) {
    finalizeClassesImport(classDetails: $classDetails, importTicketId: $importTicketId) {
        errors
        importTicket {
            id
            errors
        }
    }
}
`;

export const WONDE_SYNC_CLASS = gql`
mutation wondeSyncStudentsClass($studentsClassId: ID!) {
    wondeSyncStudentsClass(studentsClassId: $studentsClassId) {
        errors
        school {
            id
        }
    }
}
`;