import React, { useState } from 'react'
import { GET_CLASSES_QUERY } from '../../../NewAcademicYear/services/graphql';
import styles from './ClassOptions.module.scss'
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { Button } from '@axeedge/go-teacher-components';
import { MOVE_STUDENTS, REMOVE_STUDENTS_FROM_SCHOOL, GET_CLASS_QUERY } from '../services/graphql';
import CreateClassForm from '../../../../components/CreateClassForm';
import cx from 'classnames'
import { CLASS_TYPE } from '../../../../../../services/constants';
const ClassOptions = ({ close, students, schoolId, stClass, studentObjects, groups }) => {

    const [classes, setClasses] = useState([]);

    const [showClassForm, setShowClassForm] = useState(false);

    const classType = groups ? CLASS_TYPE.other : CLASS_TYPE.registration;

    const { data, error, loading } = useQuery(GET_CLASSES_QUERY, {
        variables: {
            includeAdminedClasses: true,

        },
        onCompleted: () => {
            setClasses(data.myClasses)
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleClose = (e) => {
        close()
    }

    const [moveStudents, { loading: moveLoading, error: errorLoading }] = useMutation(MOVE_STUDENTS, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.addStudentsToClass.errors && data.addStudentsToClass.errors.length > 0) {
                console.log("error from backend?")
            } else {
                close()
            }
            return;
        }
        ,
        onError: (error) => {
            console.log(error)
            console.log("error")
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASS_QUERY, variables: {
                    id: stClass.id
                }
            }
        ]
    });

    const [removeStudentsFromSchool, { loading: removeLoading, error: removeError }] = useMutation(REMOVE_STUDENTS_FROM_SCHOOL, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.removeStudentsFromSchool.errors && data.removeStudentsFromSchool.errors.length > 0) {
                console.log("error from backend?");
            } else {
                close();
            }
            return;
        }
        ,
        onError: (error) => {
            console.log(error)
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASS_QUERY, variables: {
                    id: stClass.id
                }
            }
        ]
    });

    const moveStudentsToClass = (c) => {
        let newStudents = []
        studentObjects.forEach(s => {
            if (c === s.removeFromClassId) {
                newStudents.push({studentId: s.studentId, removeFromClassId: null})
            } else {
                newStudents.push(s)
            }
        })
        moveStudents({
            variables: {
                studentsClassId: c,
                studentsData: newStudents
            }
        })
    }

    return (
        <>
            {
                showClassForm ? (
                    <div className={styles.optionsContainer} onClick={() => handleClose()}>
                        <div className={styles.panel} onClick={(e) => e.stopPropagation()}>
                            <div className={styles.panelLeft}>
                                <CreateClassForm studentsClass={stClass} studentObjects={studentObjects} closeClassOptions={() => close()} close={() => setShowClassForm(false)} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.optionsContainer} onClick={() => handleClose()}>
                        <div className={styles.panel} onClick={(e) => e.stopPropagation()}>
                            <div className={styles.panelLeft} >
                                <h2>Target Class - or <Button onClick={() => setShowClassForm(true)}>Create class</Button></h2>
                                {loading ? <p>Fetching Classes...</p> :
                                <ul style={{ maxHeight: '600px', overflow: 'auto' }} >
                                    {
                                        _.map(classes.filter(c => c.academicYear === 2025 && c.typeId === classType ), c => (
                                            <li key={`class${c.id}`} className={styles.moveOptLink} onClick={() => moveStudentsToClass(c.id)}>
                                                {c.name}
                                            </li>
                                        ))
                                    }
                                </ul>}
                            </div>
                            <div className={styles.panelRight}>
                                <h2>Other Options</h2>
                                <ul>
                                    <li><button type="button" disabled={removeLoading} className={cx(styles.moveOptLink, 'btn-reset')} onClick={() => removeStudentsFromSchool({
                                        variables: {
                                            studentIds: students,
                                            schoolId
                                        }
                                    })}>Leaving this school</button></li>
                                    
                                    <li><button type="button" disabled={removeLoading} className={cx(styles.moveOptLink, 'btn-reset')} onClick={() => removeStudentsFromSchool({
                                        variables: {
                                            studentIds: students,
                                            schoolId,
                                            is6YearLeaver: true
                                        }
                                    })}>Year 6 Leaver</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}

export default ClassOptions