import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import SchoolSetup from './scenes/SchoolSetup';
import { useQuery } from '@apollo/react-hooks';
import { CHECK_JOIN_REQUESTS } from './services/graphql';
import JoinSchoolForm from "./components/JoinSchoolForm";
import AccessRequestSent from './components/JoinSchoolForm/AccessRequestSent';
import ImageFadeIn from "react-image-fade-in";
import { Loader } from '@axeedge/go-teacher-components';
import boomerImg from '../../images/onboarding/boomer-group.png';
import flagImg from '../../images/onboarding/start-project.png';
import styles from './Onboarding.module.scss';
import { SIGNUP_REASON } from '../../services/constants';
import PostOnboardingFlow from './scenes/PostOnboardingFlow';
import PostOnboardingWondeFlow from './scenes/PostOnboardingWondeFlow';

const Onboarding = () => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [showJoin, setShowJoin] = useState(currentUser?.signupReason === SIGNUP_REASON.requestAccess);
    const [showCreateSchool, setShowCreateSchool] = useState(currentUser?.signupReason === SIGNUP_REASON.createSchool && !currentUser.school);
   // const [showCreateSchool, setShowCreateSchool] = useState(currentUser?.signupReason === SIGNUP_REASON.createSchool);
    //const [done, setDone] = useState(false);

   const [joinRequests, setJoinRequests] = useState([]);

    const { data, loading } = useQuery(CHECK_JOIN_REQUESTS);

    useEffect(() => {
        if (data && data.currentTeacher && data.currentTeacher.joinRequests) {
            setJoinRequests(data.currentTeacher.joinRequests);
        }
    }, [data]);

    if (loading) {
        return <Loader />
    }

    if (showCreateSchool && joinRequests.length === 0) {
        return <SchoolSetup currentUser={currentUser} close={() => setShowCreateSchool(false)} />
    }

    if (currentUser.isSchoolAdmin && currentUser.school) {
        return currentUser.school.wondeId ? <PostOnboardingWondeFlow /> : <PostOnboardingFlow />
    }

    if (currentUser && !currentUser.school && joinRequests.length > 0) {
        return <AccessRequestSent user={currentUser} joinRequests={joinRequests} />
    }

    if (data) {
        return (
            <div className='card-mobile'>
                {showJoin ? <JoinSchoolForm close={() => setShowJoin(false)} /> :
                    <div className={styles.onboarding}>
                        <div>
                            <h2>Welcome to BoomHub!</h2>
                            <p>The home of BoomReader & BoomWriter</p>
                            <p>Let's connect you to your school, or setup a new school account</p>
                            <div className={styles.schoolLinks}>
                                <button onClick={() => setShowJoin(true)} className={styles.schoolLinksItem}>
                                    <p>My School has an account</p>
                                    <ImageFadeIn src={boomerImg} alt='Join School' />
                                </button>
                                <button onClick={() => setShowCreateSchool(true)} className={styles.schoolLinksItem}>
                                    <p>I need to setup a new School</p>
                                    <ImageFadeIn src={flagImg} alt='Join School' />
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
    return null;
}

export default Onboarding;