import React from "react";
import { Link } from "@reach/router";
import moment from "moment";
import styles from '../../Dashboard.module.scss';

const CheckReadRenewal = ({ renewal }) => {

    const now = moment().startOf('day');
    const renewalInDays = now.diff(moment(renewal.toBeProcessedAt, 'YYYY-MM-DD'), 'days');

    return (
        <>
            <Link to="/settings/boomreader" className={styles.renewalBanner}>
                {renewal.isSecondYearRenewal ? (
                    <>
                        <p className="lead heavy">Your BomReader year 2 invoice will be auto-issued in {renewalInDays.toString().substring(1)} days</p>
                        <p>Please review & update your pupil numbers and billing details</p>
                    </>
                ) :
                    <p><b>{renewalInDays.toString().substring(1)} days until your BoomReader subscription renews </b> - Check your renewal details</p>
                }
            </Link>
        </>
    )
}

export default CheckReadRenewal;