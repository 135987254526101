import { gql } from 'apollo-boost';

export const GET_PACK_OFFERS = gql`
query packOffers {
    packOffers {
        id
        defaultNumberOfStudentsPerInstance
        description
        instances
        name
        price
    }
}
`;

export const GET_SUBS_QUERY = gql`
    query school($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            studentsUsingGoWriteActivities
            schoolSubscriptions {
                id
                academicYear
                expiresAt
                id
                isTrial
                status
                subscriptionType
                invoiceRequest {
                    id
                    studentsCounter
                    issuedAt
                    orderedAt
                }
            }
        }
    }
`;

export const PURCHASE_PACK_OFFER = gql`
mutation purchasePackOffer($schoolId: ID!, $academicYear: String!, $contactPerson: String!, $contactEmail: String!, $contactPhone: String!, $contactIsFinancialManager: Boolean!, $packs: [PackOfferAttributes!]!, $purchaseOrderReferenceNumber: String) {
    purchasePackOffer(schoolId: $schoolId, academicYear: $academicYear, contactPerson: $contactPerson, contactEmail: $contactEmail, contactPhone: $contactPhone, contactIsFinancialManager: $contactIsFinancialManager, packs: $packs, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber) {
        errors
        school {
            id
        }
        purchasedPacks {
            id
            instances
        }
    }
}
`;

export const PURCHASE_ACTIVITY_TRIAL = gql`
mutation purchaseGoWriteActivitiesTrialAccess($schoolId: ID!) {
    purchaseGoWriteActivitiesTrialAccess(schoolId: $schoolId) {
        errors
        school {
            id
            schoolSubscriptions {
                id
                isTrial
                subscriptionType
            }
        }
    }
}
`;

export const PURCHASE_ACTIVITY_ACCESS = gql`
mutation purchaseGoWriteActivitiesAccess($schoolId: ID!, $contactPerson: String!, $contactEmail: String!, $contactPhone: String!, $contactIsFinancialManager: Boolean!, $studentsCounter: Int!, $amount: Float!, $purchaseOrderReferenceNumber: String) {
    purchaseGoWriteActivitiesAccess(schoolId: $schoolId, contactPerson: $contactPerson, contactEmail: $contactEmail, contactPhone: $contactPhone, contactIsFinancialManager: $contactIsFinancialManager, studentsCounter: $studentsCounter, amount: $amount, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber) {
        errors
        school {
            id
            schoolSubscriptions {
                id
                isTrial
                subscriptionType
            }
        }
    }
}
`;
