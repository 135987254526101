import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GET_YEAR_STATS, GET_FILTERED_STUDENTS, GET_UNASSIGNED_STUDENTS_QUERY } from '../services/graphql';
import { REMOVE_STUDENTS_FROM_SCHOOL } from "../../Classes/scenes/MoveClass/components/services/graphql";

const ArchiveStudents = ({ currentYear, schoolId, close, students, clearStudents }) => {

    const [formErrors, setFormErrors] = useState([]);

    const getQueries = () => {
        if (currentYear === 'UPN') {
            return {
                schoolId,
                withoutUpn: true,
            }
        } else {
            return {
                schoolId,
                yearGroup: currentYear && currentYear.toString(),
            }
        }
    }

    const [removeStudentsFromSchool, { loading: removeLoading }] = useMutation(REMOVE_STUDENTS_FROM_SCHOOL, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.removeStudentsFromSchool.errors && data.removeStudentsFromSchool.errors.length > 0) {
                console.log("error from backend?");
                setFormErrors(data.removeStudentsFromSchool.errors)

            } else {
                close();
                clearStudents();
            }
            return;
        },
        refetchQueries: [
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId
                }
            }
        ],
    });

    return (
        <>
            <h2>Are you sure?</h2>
            <p className='u-m-base-2'>You are about to archive {students.length} {students.length === 1 ? 'student' : 'students'}.  This will move them to the "Archived" section.</p>
            {formErrors && <FormErrors errors={formErrors} />}

            <Button disabled={removeLoading} onClick={() => removeStudentsFromSchool({
                variables: {
                    studentIds: students,
                    schoolId
                }
            })}>Archive</Button>
            <Button className='u-m-left-1' outline onClick={close}>Cancel</Button>
        </>
    )
}

export default ArchiveStudents;