import React from 'react'

import styles from './MoveClasses.module.scss'
import { MOVE_YEAR } from '../../Classes/scenes/MoveClass/components/services/graphql.js';
import { useMutation } from '@apollo/react-hooks';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import { ADD_STUDENT_MUTATION, GET_YEAR_STATS, GET_FILTERED_STUDENTS,GET_UNASSIGNED_STUDENTS_QUERY  } from '../services/graphql';
import _ from 'lodash';

const YearOptions = ({close, students, schoolId, currentYear, clearStudents}) => {

    const getQueries = () => {
        if (currentYear === 'UPN') {
            return {
                schoolId: schoolId,
                withoutUpn: true,
            }
        } else {
            return {
                schoolId: schoolId,
                yearGroup: currentYear && currentYear.toString(),
            }
        }
    }

    const [moveYear, { loading, error}] = useMutation(MOVE_YEAR, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.updateStudentsYearGroup.errors && data.updateStudentsYearGroup.errors.length > 0) {
                console.log("error from backend?")
            } else {
                clearStudents()
                close()
            }
            return;
        }
        ,
        onError: (error) => {
            console.log("error")
            return;
        },
        refetchQueries: [
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: schoolId,
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId: schoolId,
                }
            }
        ],
    });

    const handleClose = (e) => {
        close()
    }

    return (
        <div className={styles.optionsContainer} onClick={() => handleClose()}>
            <div className={styles.panel} onClick={(e) => e.stopPropagation()}>
                <div className={styles.panelLeft}>
                    <h2>Target Years</h2>
                    <ul>
                    {
                        _.map(YEAR_GROUPS, y => {
                            return <li key={`yg${y.val}`} className={styles.moveOptLink}  onClick={() =>
                                { 
                                moveYear({
                                    variables: {
                                        yearGroup: y.val,
                                        studentIds: students
                                    }
                                })
                            }
                            }
                        >{y.name}</li>
                        })
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default YearOptions