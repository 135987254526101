import React, { useState, useEffect } from "react";
import { Button } from '@axeedge/go-teacher-components';
import ManageStaffSettings from "./ManageStaffSettings";
import styles from '../../Onboarding.module.scss';
import { WONDE_SYNC_STEPS } from "../../../../services/constants";

const WondeStaffList = ({ extraStaff, school, duringSync = false, setCurrentSyncStep, duringAdditionalImport, closeAdditionalImport }) => {


    const [showStaffSettings, setShowStaffSettings] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);

    const onSelectStaff = e => {
        if (selectedStaff.indexOf(e.target.value) !== -1) {
            setSelectedStaff(selectedStaff.filter(el => el !== e.target.value));
        } else {
            setSelectedStaff([...selectedStaff, e.target.value]);
        }
    }

    const onSelectAllStaff = e => {
        const selectedIds = visibleStaff.map(t => t.id);
        e.target.checked ? setSelectedStaff(selectedIds) : setSelectedStaff([])
    }

  //  const [searchTerm, setSearchTerm] = useState('');
    const [visibleStaff, setVisibleStaff] = useState(extraStaff);

    // useEffect(() => {
    //     const results = [...extraStaff].filter(t => (t.firstName + ' ' + t.lastName).toLowerCase().includes(searchTerm.toLowerCase()));
    //     setVisibleStaff(results);
    // }, [searchTerm]);


    const getTeachersToImport = () => {
        if (duringSync || duringAdditionalImport) {
            return [...visibleStaff.filter(el => selectedStaff.includes(el.id)).map(el => el)]
        } else {
            return [...(school.teachers), ...visibleStaff.filter(el => selectedStaff.includes(el.id)).map(el => el)]
        }
    }

    return (
        <>
            {showStaffSettings ?
                <ManageStaffSettings
                    setCurrentSyncStep={setCurrentSyncStep}
                    duringSync={duringSync}
                    selectedToImport={getTeachersToImport()}
                    duringAdditionalImport={duringAdditionalImport}
                    closeAdditionalImport={closeAdditionalImport}
                /> :
                <>
                    <div className={styles.wondeMain}>
                        <h1>Additional Staff</h1>
                        <p>Which other staff members should be able to access BoomReader?</p>
                        <div className="row u-m-top-2">
                            <div className='col-md-6'>
                                <ul className="list-group u-m-right-3 u-m-base-2">
                                    <li className='list-item u-m-base-2'>
                                        <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                            <input
                                                name="all"
                                                className="basic-form__check-box"
                                                type="checkbox"
                                                checked={selectedStaff.length > 0 && selectedStaff.length === extraStaff.length}
                                                onChange={(e) => onSelectAllStaff(e)}
                                                id="selectAll"
                                            />
                                            <label className="basic-form__check-label" htmlFor='selectAll'>All</label>
                                        </div>
                                        {/* <div className="u-m-left-auto">
                                            <input
                                                type="text"
                                                className='basic-form__text-box'
                                                value={searchTerm}
                                                placeholder='Type to find staff'
                                                onChange={e => setSearchTerm(e.target.value)}
                                            />
                                        </div> */}
                                    </li>
                                    {visibleStaff.map(teacher => (
                                        <li key={`kls-${teacher.id}`} className='list-item'>
                                            <div className="u-display-flex u-align-center">
                                                <div className="basic-form__group basic-form__group--check u-m-base-0">
                                                    <input
                                                        type='checkbox'
                                                        className="basic-form__check-box"
                                                        value={teacher.id}
                                                        onChange={(e) => onSelectStaff(e)}
                                                        id={`kls-${teacher.id}`}
                                                        checked={selectedStaff.indexOf(teacher.id) > -1}
                                                    />
                                                    <label className="basic-form__check-label" htmlFor={`kls-${teacher.id}`}>&nbsp;</label>
                                                </div>
                                                {teacher.firstName} {teacher.lastName}
                                            </div>
                                            <div className="u-m-left-auto">
                                                {teacher.email}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <h3 className="heavy">{duringSync ? '' : 'Current Staff:'}</h3>
                                <ul>
                                    {school.teachers.map(teacher => (
                                        <li key={teacher.id}>
                                            {teacher.firstName} {teacher.lastName}
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className={styles.wondeFooter}>
                        <h2 className="heavy">Additional staff: {selectedStaff.length}</h2>
                        <div>
                            <Button disabled={getTeachersToImport().length === 0} onClick={() => setShowStaffSettings(true)}>Next</Button>
                            {duringSync && <button className='btn-reset link-underline u-m-left-2' onClick={() => setCurrentSyncStep(WONDE_SYNC_STEPS.syncCompleted)}>Skip Additional Staff</button>}
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default WondeStaffList;