import React, { useState, useRef } from "react";
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { ADD_INTEREST } from '../services/graphql';
import ReCAPTCHA from "react-google-recaptcha";
import { TEACHER_JOB_ROLES, GOOGLE_SITE_KEY } from '@axeedge/go-shared-utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import styles from '../RegisterInterest.module.scss';
import { getRoleLabel, ukOnlyRoles, usaOnlyRoles } from "../../../services/utils";
import cx from 'classnames';
import { INTEREST_SOURCE } from "../services/constants";
import ls from 'local-storage'

const InterestForm = ({ close, currentUser = null, setCurrentUser, pupilsCount = null, isWriteToRaise }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [captchaValue, setCaptchaValue] = useState(null);
    const [raisedAmount, setRaisedAmount] = useState(null);

    const captchaRef = useRef(null);

    const [addInterest, { loading }] = useMutation(ADD_INTEREST, {
        update: (_, { data }) => {
            if (data.addInterest.errors && data.addInterest.errors.length !== 0) {
                setFormErrors(data.addInterest.errors);
                setCaptchaValue(null);
                captchaRef.current.reset();
                return;
            }
            if (data.addInterest?.interest?.id) {
                if (currentUser) {
                // if (currentUser && currentUser.school.id === data.addInterest.interest.school?.id) {
                    const cu = { ...currentUser, school: {...currentUser.school, hasRegisteredInterestForWritePromo: true} }
                    setCurrentUser(cu);
                }
                close();
            }

        }
    })


    const schema = Yup.object().shape({
        pupilsCount: Yup.number().required().typeError('Please enter pupils count'),
        tos: Yup.boolean().oneOf([true], "You must agree to receive email updates"),
        ...(!currentUser && {
            schoolName: Yup.string().required('Please enter a school name'),
            schoolLocation: Yup.string().required('Please enter a school location'),
            firstName: Yup.string().required('Please enter first name'),
            lastName: Yup.string().required('Please enter last name'),
            email: Yup.string().email().required('Please enter an email address'),
            roleId: Yup.string().required('Please select your role'),
        })


    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const handleChange = value => {
        setCaptchaValue(value);
    }

    const getAmount = value => {
        const amount = (value * 4.99 * 2.5).toFixed(2);
        setRaisedAmount(amount);
    }

    const onSubmit = values => {
        const { pupilsCount, schoolName, schoolLocation, firstName, lastName, email, roleId } = values;
        addInterest({
            variables: {
                pupilsCount,
                schoolName: currentUser?.school.name || schoolName,
                schoolLocation: currentUser?.school.city || schoolLocation,
                firstName: currentUser?.firstName || firstName,
                lastName: currentUser?.lastName || lastName,
                email: currentUser?.email || email,
                roleId: currentUser?.roleId || +roleId,
                schoolId: currentUser?.school.id || null,
                source: isWriteToRaise ? INTEREST_SOURCE.gowrite : INTEREST_SOURCE.goread
            }
        });
    }


    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={cx(styles.interestRow, styles.interestRowAlt)}>
                <div className={styles.interestRowContent}>
                    <h2 className="u-m-top-1">About your school</h2>
                    <div className={cx({ [styles.interestColElement]: isWriteToRaise })}>
                        <div className='basic-form__group'>
                            <label htmlFor="pupilsCount">No. of pupils</label>
                            <input
                                name='pupilsCount'
                                className='basic-form__text-box'
                                ref={register}
                                type='number'
                                id="pupilsCount"
                                min={0}
                                defaultValue={pupilsCount}
                                onChange={(e) => getAmount(e.target.value)}
                            />
                        </div>
                        {isWriteToRaise &&
                            <div className="heavy">
                                <p>You could raise:</p>
                                <p>£{raisedAmount || '??'}</p>
                            </div>
                        }
                    </div>
                    {isWriteToRaise &&
                        <p className="small">Amount shown is an estimate based on our average sales rate of 2.5 book sales per pupil. Actual amount raised can vary.</p>
                    }
                    {errors.pupilsCount && <p className='basic-form__hint'>{errors.pupilsCount.message}</p>}
                    {!currentUser && (
                        <>
                            <div className='basic-form__group'>
                                <label htmlFor="schoolName">School name</label>
                                <input
                                    name='schoolName'
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='text'
                                    id="schoolName"
                                />
                            </div>
                            {errors.schoolName && <p className='basic-form__hint'>{errors.schoolName.message}</p>}

                            <div className='basic-form__group'>
                                <label htmlFor="schoolLocation">Location (town/city)</label>
                                <input
                                    name='schoolLocation'
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='text'
                                    id="schoolLocation"
                                />
                            </div>
                            {errors.schoolLocation && <p className='basic-form__hint'>{errors.schoolLocation.message}</p>}
                        </>
                    )}
                </div>
            </div>

            <div className={styles.interestRow}>
                <div className={styles.interestRowContent}>
                    {!currentUser && (
                        <>
                            <h2 className="u-m-top-3">About you</h2>
                            <div className={styles.interestColElement}>
                                <div className='basic-form__group'>
                                    <label htmlFor="firstName">First name</label>
                                    <input
                                        name='firstName'
                                        className='basic-form__text-box'
                                        ref={register}
                                        type='text'
                                        id="firstName"
                                    />
                                </div>
                                <div className='basic-form__group'>
                                    <label htmlFor="lastName">Last name</label>
                                    <input
                                        name='lastName'
                                        className='basic-form__text-box'
                                        ref={register}
                                        type='text'
                                        id="lastName"
                                    />
                                </div>
                            </div>
                            {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                            {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}

                            <div className='basic-form__group'>
                                <label htmlFor="email">Your work/school email address</label>
                                <input
                                    name='email'
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='email'
                                    id="email"
                                />
                            </div>
                            {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}

                            <div className='basic-form__group'>
                                <label htmlFor="roleId">Which role best describes your position in the school</label>
                                <select id="roleId" name="roleId" ref={register} className="basic-form__text-select">
                                    <option value=''>Please select your role</option>
                                    {ls('country') === 'US' ?
                                        Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                            return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                        })
                                    :
                                        Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                            return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}
                        </>
                    )}
                    <div className="basic-form__group basic-form__group--check u-text-left">
                        <div>
                            <input
                                name="tos"
                                className="basic-form__check-box"
                                ref={register}
                                type="checkbox"
                                id="tos"
                            />
                            {isWriteToRaise ?
                                <label className="basic-form__check-label" htmlFor="tos">I agree to receive email updates about this project. I can unsuscribe from these at any time.</label> :
                                <label className="basic-form__check-label" htmlFor="tos">I agree to receive email updates about the Winter Grand Tour. I can unsubscribe from these at any time.</label>
                            }
                        </div>
                    </div>
                    {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}
                </div>
            </div>
            {isWriteToRaise &&
                <div className={cx(styles.interestRow, styles.interestRowAlt)}>
                    <div className={styles.interestRowContent}>
                        <>
                            <p>By submitting this form I agree to registering my schools' interest in taking part in the Write to Raise project.</p>
                            <p>Full details of this project and terms will be provided before January 2023.</p>
                            <p>Your school is under no obligation and will be able to decide at a later stage if you wish to participate.</p>
                        </>
                    </div>
                </div>
            }

            <div className={styles.interestRow}>
                <div className={styles.interestRowContent}>
                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}

                    {currentUser ? <Button type='submit' className='u-width-full' primary disabled={loading}>{loading ? 'Saving...' : 'Register interest'}</Button> :
                        <>
                            <div className={styles.interestCaptcha}>
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    ref={r => captchaRef.current = r}
                                    sitekey={GOOGLE_SITE_KEY}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className='u-display-flex u-justify-center u-m-base-3'>
                                <Button type='submit' className='u-width-full' primary disabled={!captchaValue || loading}>{loading ? 'Saving...' : 'Register interest'}</Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </form>
    )
}

export default InterestForm;