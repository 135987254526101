import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import { useQuery } from '@apollo/react-hooks';

import { Loader, Button } from '@axeedge/go-teacher-components';
import PageHeader from '../../components/PageHeader';
import { GET_OTHER_TEACHERS } from './services/graphql';
import { GET_TEACHERS_QUERY } from '../../services/school/graphql';
import SideNav from './components/SideNav';

import TeachersList from './components/TeachersList';
import RemovedTeachersList from './components/RemovedTeacherList';
import JoinRequestsList from './components/JoinRequestsList';
import { WONDE_SYNC_STEPS } from '../../services/constants';
import RemoveTeacher from './components/RemoveTeacher';

import { useTranslation } from 'react-i18next';
import SaveConfirm from '../../components/SaveConfirm';

import cx from 'classnames';
import styles from './Teachers.module.scss';
import WondeStaffImport from '../Onboarding/components/WondeStaffImport';

const TABS = {
    allActiveTeachers: 1,
    administrators: 2,
    other: 3,
    removedTeachers: 4,
    requests: 5
}

const Teachers = ({ location }) => {

    const { t } = useTranslation(['classes', 'common']);

    const auth = useContext(AuthContext);
    const { currentUser: user } = auth;

    const [showMisCheck, setShowMisCheck] = useState(false);

    const [baseTeachers, setBaseTeachers] = useState([]);

    const [visibleTeachers, setVisibleTeachers] = useState(baseTeachers);

    const [removeTeacher, setRemoveTeacher] = useState();
    const [selectedTab, setSelectedTab] = useState(location?.state?.showRequests ? TABS.requests : TABS.allActiveTeachers);
    const [removeSuccess, setRemoveSuccess] = useState(false);

    const { data, error, loading } = useQuery(GET_TEACHERS_QUERY, {
        variables: {
            schoolCode: user.school.schoolCode,
            includeInvited: true
        }
    });

    const { data: otherTeachersData, error: otherTeachersError, loading: otherTeachersLoading } = useQuery(GET_OTHER_TEACHERS, {
        variables: {
            schoolId: user.school.id
        },
    });

    useEffect(() => {
        if (data && data.teachersBySchoolCode) {
            setBaseTeachers(data.teachersBySchoolCode);
        }
    }, [data]);

    const misCheckBtnVisible = user?.school?.wondeAccessApprovedAt && user?.school?.wondeSyncStep === WONDE_SYNC_STEPS.syncCompleted && user.isSchoolAdmin;

    useEffect(() => {
        switch (selectedTab) {
            case TABS.allActiveTeachers:
                setVisibleTeachers([...baseTeachers]);
                break;
            case TABS.administrators:
                setVisibleTeachers([...baseTeachers].filter(teacher => teacher.isSchoolAdmin));
                break;
            case TABS.other:
                setVisibleTeachers([...baseTeachers].filter(teacher => !teacher.isSchoolAdmin));
                break;
            default:
                setVisibleTeachers(baseTeachers);
                break;
        }
    }, [selectedTab, baseTeachers, setVisibleTeachers]);

    if (error || otherTeachersError) {
        return <p>{error.message}</p>
    }

    if (loading || otherTeachersLoading) {
        return <Loader />
    }



    if (showMisCheck) {
        return <WondeStaffImport duringAdditionalImport={true} closeAdditionalImport={() => setShowMisCheck(false)} />
    }

    if (visibleTeachers && otherTeachersData) {
        return (
            <Fragment>
                {removeTeacher ? (
                    <RemoveTeacher selectedTeacher={removeTeacher} school={user.school} setRemoveTeacher={setRemoveTeacher} setRemoveSuccess={setRemoveSuccess} />
                ) : (
                    <Fragment>
                        <PageHeader title={t('common:manage_staff')} />
                        <div className={cx(styles.container, 'card-mobile')}>
                            <div className='row'>
                                <div className={cx('col-md', styles.containerSide)}>
                                    <SideNav
                                        selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab}
                                        baseTeachers={baseTeachers}
                                        tabs={TABS}
                                        removedTeachers={otherTeachersData.school.removedTeachers}
                                        joinRequests={otherTeachersData.school.joinRequests}
                                    />
                                        {misCheckBtnVisible && <Button outline onClick={() => setShowMisCheck(true)}>Check MIS</Button>}
                                </div>
                                <div className='col-md u-m-base-2'>
                                    {removeSuccess && <SaveConfirm message={t('teacher_removed')} hideConfirm={setRemoveSuccess} />}
                                    {
                                        selectedTab === TABS.removedTeachers ?
                                            <RemovedTeachersList
                                                schoolCode={user.school.schoolCode}
                                                removedData={otherTeachersData}
                                                schoolId={user.school.id}
                                            /> :
                                            selectedTab === TABS.requests ?
                                                <JoinRequestsList
                                                    schoolId={user.school.id}
                                                    schoolCode={user.school.schoolCode}
                                                    requests={otherTeachersData.school.joinRequests}
                                                /> :
                                                <TeachersList
                                                    selectedTab={selectedTab}
                                                    allTabs={TABS}
                                                    schoolId={user.school.id}
                                                    teachers={visibleTeachers}
                                                    setRemoveTeacher={setRemoveTeacher}
                                                />
                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        )
    }

    return null

}

export default Teachers;
