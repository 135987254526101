import React, { useState, useContext } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TERMS} from './services/graphql';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { TERMS_VERSION } from '@axeedge/go-shared-utils';
import styles from './TermsUpdate.module.scss';
import cx from 'classnames';

const TermsUpdate = () => {
    const { currentUser: user, setCurrentUser } = useContext(AuthContext);
    const [formErros, setFormErrors] = useState(null)
    const [updateTerms, { loading: updatingTerms }] = useMutation(UPDATE_TERMS, {
        update: (_, { data }) => {
            if (data.approveTerms.errors && data.approveTerms.errors.length !== 0) {
                setFormErrors(data.approveTerms.errors);
                return;
            }
            if (data.approveTerms.user.id) {
                const cu = {...user,lastTermsVersionApproved: data.approveTerms.user.lastTermsVersionApproved}
                setCurrentUser(cu);
            }
        },
    });
    return (
        <div className={styles.privacyContainer}>
            {user.isSchoolAdmin ?
            <>
                <h1 className="u-m-base-2">We’ve updated our Terms and Privacy Policy</h1>
                <p className="u-m-base-2">We’ve made some changes to our Terms and Conditions and our Privacy Policy. To continue using BoomReader please review and accept the updated versions. To help we’ve outlined the key changes that apply to you as a School Administrator below:</p>
                <h2>Terms Summary</h2>
                <ul  className={cx("u-m-base-2", styles.privacyList)}>
                    <li>We’ve consolidated the separate Terms for BoomReader and BoomWriter into a single document.</li>
                    <li>We’ve simplified the wording of the terms document to make it more concise and easier to read and understand.</li>
                </ul>
                <h2>Privacy Policy</h2>
                <ul className={cx("u-m-base-2", styles.privacyList)}>
                        <li>We’ve consolidated the Privacy Policies from BoomReader, BoomWriter and Squirrel Learning into a single policy document.</li>
                    <li>We’ve made it clearer who the Data Controllers are (both the School and Squirrel Learning are or can be Data Controllers)</li>
                    <li>We’ve added a change of use for data to allow us to use and share (including in exchange for payment) aggregated data like statistical or demographic data (e.g., user habits of apps) for any purpose. This is not personal data as the person the data relates to can no longer be identified.</li>
                </ul>
                <ul className="u-m-base-2">
                        <li><a href="https://www.boomreader.co.uk/policies" target="_blank">Terms &amp; Conditions</a></li>
                        <li><a href="https://www.boomreader.co.uk/privacypolicy" target="_blank">Privacy Policy</a></li>
                </ul>
            </>
            :
            <>
                <h1 className="u-m-base-2">We’ve updated our Terms and Privacy Policy</h1>
                <p className="u-m-base-2">We’ve made some changes to our Terms and Conditions and our Privacy Policy. To continue using BoomReader please review and accept the updated versions. To help we’ve outlined the key changes that apply to you as a teacher or parent below:</p>
                <h2>Terms Summary</h2>
                <ul  className={cx("u-m-base-2", styles.privacyList)}>
                        <li>We’ve consolidated the separate terms for BoomReader and BoomWriter into a single document.</li>
                    <li>We’ve simplified the wording of the Terms document to make it more concise and easier to read and understand.</li>
                </ul>
                <h2>Privacy Policy</h2>
                <ul className={cx("u-m-base-2", styles.privacyList)}>
                        <li>We’ve consolidated the Privacy Policies from BoomReader, BoomWriter and Squirrel Learning into a single policy document.</li>
                </ul>
                <ul className="u-m-base-2">
                        <li><a href="https://www.boomreader.co.uk/policies" target="_blank">Terms &amp; Conditions</a></li>
                        <li><a href="https://www.boomreader.co.uk/privacypolicy" target="_blank">Privacy Policy</a></li>
                </ul>
                </>
            }
            <Button onClick={() => updateTerms({variables: {
                version: (TERMS_VERSION).toString()
            }})}>{updatingTerms ? 'Saving' : 'Accept'}</Button>
            <p className="u-m-top-2">If you don’t wish to accept the new terms &amp; privacy policy please contact us at <a href="mailto:privacy@boomhub.app">privacy@boomhub.app</a> to discuss how to close your account.</p>
        </div>
    )
}

export default TermsUpdate