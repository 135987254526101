
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import ls from 'local-storage';

const isUS = ls('country') === 'US';

// parse purchased packs status (to display purchased packs as class instance packs)
export const parseOfferStatus = data => {
    let offerDetails = [];
    Object.entries(JSON.parse(data)).map(e => offerDetails.push({
        id: +e[0],
        students: e[1].counter,
        students_class: e[1].students_class
    }))
    return offerDetails
}


// check the current academic year 
export const isCurrentAcademicYear = academicYear => {
    const END_MONTH = 6;
    const currYear = new Date().getFullYear()
    const currMonth = new Date().getMonth();
    const testYear = currMonth < END_MONTH ? currYear - 1 : currYear;
    return +academicYear.substring(0, 4) === testYear;
}


export const getRoleLabel = role => {
    switch (role) {
        case TEACHER_JOB_ROLES.teacher:
            return 'Teacher';
        case TEACHER_JOB_ROLES.teacher_assistant:
            return 'Teaching Assistant (TA)';
        case TEACHER_JOB_ROLES.senior_leadership_team:
            return ls('country') === 'US' ? 'Principal' : 'Senior Leadership Team';
        case TEACHER_JOB_ROLES.office:
            return 'Office';
        case TEACHER_JOB_ROLES.english_lead:
            return 'English/Reading/Literacy Lead';
        case TEACHER_JOB_ROLES.volunteer:
            return 'Volunteer';
        case TEACHER_JOB_ROLES.tutor:
                return 'Tutor';
        case TEACHER_JOB_ROLES.math_lead:
            return 'Math Lead/Math Coach';
        case TEACHER_JOB_ROLES.curriculum_specialist:
                return 'Curriculum Specialist';
        default:
            return '--'
    }
}

export const usaOnlyRoles = [TEACHER_JOB_ROLES.tutor, TEACHER_JOB_ROLES.math_lead, TEACHER_JOB_ROLES.curriculum_specialist]
export const ukOnlyRoles = [TEACHER_JOB_ROLES.english_lead, TEACHER_JOB_ROLES.teacher_assistant]

export const getYearGroupLabel = year => {
    switch (year) {
        case 1:
            return 'Nursery';
        case 2:
            return isUS ? 'K1' : 'Reception'
        case 3:
            return isUS ? 'K2' : 'Year 1';
        case 4:
            return isUS ? 'Grade 1' : 'Year 2'
        case 5:
            return isUS ? 'Grade 2' : 'Year 3'
        case 6:
            return isUS ? 'Grade 3' : 'Year 4'
        case 7:
            return isUS ? 'Grade 4' : 'Year 5';
        case 8:
            return isUS ? 'Grade 5' : 'Year 6';
        case 9:
            return isUS ? 'Grade 6' : 'Year 7'
        case 10:
            return isUS ? 'Grade 7' : 'Year 8'
        case 11:
            return isUS ? 'Grade 8' : 'Year 9'
        case 12:
            return isUS ? 'Grade 9' : 'Year 10'
        case 13:
            return isUS ? 'Grade 10' : 'Year 11'
        case 14:
            return isUS ? 'Grade 11' : 'Year 12'
        case 15:
            return isUS ? 'Grade 12' : 'Year 13';
        default:
            return '-'
    }
}

export const getYearGroupShortName = year => {
    switch (year) {
        case 1:
            return 'N';
        case 2:
            return isUS ? 'K1' : 'R'
        case 3:
            return isUS ? 'K2' : '1';
        case 4:
            return isUS ? 'Grade 1' : '2'
        case 5:
            return isUS ? 'Grade 2' : '3'
        case 6:
            return isUS ? 'Grade 3' : '4'
        case 7:
            return isUS ? 'Grade 4' : '5';
        case 8:
            return isUS ? 'Grade 5' : '6';
        case 9:
            return isUS ? 'Grade 6' : '7'
        case 10:
            return isUS ? 'Grade 7' : '8'
        case 11:
            return isUS ? 'Grade 8' : '9'
        case 12:
            return isUS ? 'Grade 9' : '10'
        case 13:
            return isUS ? 'Grade 10' : '11'
        case 14:
            return isUS ? 'Grade 11' : '12'
        case 15:
            return isUS ? 'Grade 12' : '13';
        default:
            return '-'
    }
}



