import React, {Fragment, useContext, useEffect, useState} from 'react'
import styles from '../NewAcademicYear/NewAcademicYear.module.scss';
import { Loader, Button } from '@axeedge/go-teacher-components';
import { Link, navigate } from '@reach/router';
import { useQuery, useMutation, lazyQuery, useLazyQuery} from '@apollo/react-hooks';
import { GET_CLASSES_QUERY, ARCHIVE_CLASSES, UNARCHIVE_CLASSES } from '../NewAcademicYear/services/graphql';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import NavBar from '../../components/NewAcademicYearNav'
import _ from 'lodash';
import cx from 'classnames'
import {ArrowLeft, ArrowLeftCircle, CheckCircle, ChevronDown} from 'react-feather'
import { CLASS_TYPE } from '../../../../services/constants';

const MoveClasses = ({groups = false}) => {

    const [classes, setClasses] = useState([])
    const [archivedClasses, setArchivedClasses] = useState([])
    const { currentUser: user } = useContext(AuthContext);
    const [selectedClasses, setSelectedClasses] = useState([])
    const [selectedArchiveClasses, setSelectedArchiveClasses]=useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [showActions, setShowActions] = useState(false)

    useEffect(() => {
        if (selectAll) {
            let newClasses = []
            let newArchivedClasses = []
            classes.forEach(c => {
                newClasses.push(c.id)
            })
            archivedClasses.forEach(c => {
                newArchivedClasses.push(c.id)
            })
            setSelectedClasses(newClasses)
            setSelectedArchiveClasses(newArchivedClasses)
        } else {
            setSelectedClasses([])
            setSelectedArchiveClasses([])
        }
    } ,[selectAll])

    const { data, error, loading } = useQuery(GET_CLASSES_QUERY, {
        variables: {
            includeAdminedClasses: user && user.isSchoolAdmin,
        },
        fetchPolicy: 'network-only'
    });


    const [getArchivedClasses, {data: archivedData, error: archivedError, loading: archivedLoading}] = useLazyQuery(GET_CLASSES_QUERY, {
        variables: {
            includeAdminedClasses: user && user.isSchoolAdmin,
            onlyArchived: true,
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (groups) {
                setArchivedClasses(data.myClasses.filter(c => c.typeId !== CLASS_TYPE.registration && c.academicYear === 2022))
            } else {
            setArchivedClasses(data.myClasses.filter(c => c.typeId === CLASS_TYPE.registration && c.academicYear <= 2022))
            }
        }
    })

    const [archiveClasses, { loading: archiveLoading, error: archiveError }] = useMutation(ARCHIVE_CLASSES, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.archiveMultipleClasses.errors && data.archiveMultipleClasses.errors.length > 0) {
                console.log("error")
            } else {
                setSelectedArchiveClasses([])
                setSelectedClasses([])
            }
        }
        ,
        onError: (error) => {
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY,
                variables: {
                    includeAdminedClasses: user && user.isSchoolAdmin,
                },
            }
        ]
    });

    const [unarchiveClasses, { loading: unarchiveLoading, error: unarchiveError }] = useMutation(UNARCHIVE_CLASSES, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.unarchiveMultipleClasses.errors && data.unarchiveMultipleClasses.errors.length > 0) {
                console.log("error")
            } else {
                setSelectedArchiveClasses([])
                setSelectedClasses([])
            }
        }
        ,
        onError: (error) => {
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY,
                variables: {
                    includeAdminedClasses: user && user.isSchoolAdmin,
                },
            }
        ]
    });

    useEffect(() => {
        if (groups) {
            data && setClasses(data.myClasses.filter(c => c.typeId !== CLASS_TYPE.registration && c.academicYear === 2022))
        } else {
            data && setClasses(data.myClasses.filter(c => c.typeId === CLASS_TYPE.registration && c.academicYear <= 2022))
        }
        getArchivedClasses();
    }, [groups, data])

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(s => s !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const onSelectArchiveClass = e => {
        if (selectedArchiveClasses.indexOf(e.target.value) !== -1) {
            setSelectedArchiveClasses(selectedArchiveClasses.filter(s => s !== e.target.value));
        } else {
            setSelectedArchiveClasses([...selectedArchiveClasses, e.target.value]);
        }
    }

    return (
        <div onClick={() => setShowActions(false)}>
            <div className={styles.pageHeader}>
                <div>
                <Link to="/classes/getReady" className={styles.backButton}>
                    <ArrowLeft size="30"/>
                </Link>
                </div>
                <h1 className={styles.pageHeading}>Start Academic Year 2022/2023</h1>
            </div>
            <NavBar step={groups ? 3 : 2} />
            <div className="card card-mobile">
            <h2 className="u-bold">{groups ? <>Archive or Move other Classes &amp; Groups</> : 'Move Your Registration Classes'}</h2>
            <p className="u-m-base-20 u-bold u-large-text">Changes you make now will be instant.</p>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.controls}>
                            <p>2021/2022 {groups && 'Other'} Classes</p>
                            <div style={{marginLeft:'auto'}}>
                                {groups ?
                                    <Button disabled={classes.length > 0} onClick={() => navigate('/classes/newIntake')}>Done</Button>
                                :
                                    <Button disabled={classes.length > 0} onClick={() => navigate('/classes/moveGroups')}>Done</Button>
                                }
                            </div>
                        </div>
                        {groups &&
                        <div className={styles.controls} style={{position: 'relative'}} >
                            <input type="checkbox" onChange={() => setSelectAll(!selectAll)} checked={selectAll} style={{marginLeft: '15px'}}/>
                            <div
                                className={cx(styles.actionDrop, (selectedClasses.length === 0) && (selectedArchiveClasses.length === 0) && styles.actionDropDisabled)}
                                style={{marginLeft: '10px'}}
                                onClick={(e) => {e.stopPropagation();selectedClasses.length + selectedArchiveClasses.length > 0 && setShowActions(!showActions)}}    
                            >
                                Select Action <ChevronDown />
                            </div>
                            {showActions && (selectedClasses.length + selectedArchiveClasses.length) > 0 &&
                                <div className={styles.actionDropDown} onClick={(e) => {e.stopPropagation()}}>
                                    <button onClick={() => {setShowActions(false);archiveClasses({variables: {studentsClassIds: selectedClasses}})}} className={cx(styles.actionBtn, styles.actionBtnBorder)} type="button">Archive</button>
                                    <button onClick={() => {setShowActions(false);unarchiveClasses({variables: {studentsClassIds: selectedArchiveClasses}})}} className={styles.actionBtn} type="button">Unarchive</button>
                                </div>
                            }
                        </div>
                        }
                        {loading || archivedLoading || archiveLoading || unarchiveLoading ?
                        <Loader />
                        :
                        <>
                        {data && archivedData &&
                            <div>
                                {groups ? 
                                <>
                                    {classes.length > 0 &&
                                        _.map(classes, c => (
                                            <div className={styles.groupRow} key={`class${c.id}`} >
                                                <input
                                                    name="class"
                                                    type="checkbox"
                                                    value={c.id}
                                                    onChange={(e) => onSelectClass(e)}
                                                    checked={selectedClasses.indexOf(c.id) > -1}
                                                    className={styles.checkbox}
                                                    id={`st_${c.id}`}
                                                />
                                                <Link to={`/classes/moveGroup/${c.id}`} className={cx(styles.classRow, styles.classLink)}>
                                                    {c.name}
                                                    <div style={{marginLeft: 'auto'}}>{c.description} Other</div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </>
                                :
                                <>
                                {classes.length > 0 &&
                                    _.map(classes, c => (
                                        <>
                                        <Link to={`/classes/moveClass/${c.id}`} key={`class${c.id}`} className={cx(styles.classRow, styles.classLink)}>
                                            {c.name}
                                            <div style={{marginLeft: 'auto'}}>{c.description} Registration</div>
                                        </Link>
                                        </>
                                    ))
                                }
                                </>
                                }
                                {groups ? <>
                                    {archivedClasses.length > 0 &&
                                        _.map(archivedClasses, c => (
                                            <div className={styles.groupRow} key={`class${c.id}`}>
                                                <input
                                                    name="class"
                                                    type="checkbox"
                                                    value={c.id}
                                                    onChange={(e) => onSelectArchiveClass(e)}
                                                    checked={selectedArchiveClasses.indexOf(c.id) > -1}
                                                    className={styles.checkbox}
                                                    id={`st_${c.id}`}
                                                />
                                                <span className={cx(styles.classRow, styles.classLink)}>
                                                    <CheckCircle color="#9DC030" style={{marginRight: '10px'}} />
                                                    {c.name}
                                                    <div style={{marginLeft: 'auto'}}>{c.description} Other</div>
                                                </span>
                                            </div>
                                        ))
                                    }
                                </>
                                :
                                <>
                                {archivedClasses.length > 0 &&
                                    _.map(archivedClasses, c => (
                                        <span key={`class${c.id}`} className={cx(styles.classRow, styles.classLink)}>
                                            <CheckCircle color="#9DC030" style={{marginRight: '10px'}} />
                                            {c.name}
                                            <div style={{marginLeft: 'auto'}}>{c.description} Registration</div>
                                        </span>
                                    ))
                                }
                                </>
                                }

                                {/*
                                    <div className={styles2.archiveBanner}>
                                        <div>
                                            <h2 className="u-bold">Done! 2022/2023 setup complete!</h2>
                                            <p className="u-bold u-m-base-1">For any new in-take pupils or classes you can add them in the usual way.</p>
                                            <p className="u-m-base-2">Any class or pupil you create from this point on will automatically be tagged as 2022/2023</p>
                                            <Button onClick={() => navigate('/classes')}>Manage Classes</Button>
                                        </div>
                                    </div>
                                */}
                            </div>
                        }
                        </>
                        }
                    </div>
                    <div className={styles.right}>
                        <div className={styles.greySide}>
                            <h2 className="u-bold">{archivedClasses.length}/{classes.length + archivedClasses.length} Moved</h2>
                            {groups ? 
                                <>
                                    <p className="u-m-base-2">For Groups that won't be used next year you can simply archive these</p>
                                    <p className="u-m-base-2">You can also click into each group to see the list of pupils and create/set their target groups for 2022/23.</p>
                                    <p>You'll be able to create any new-intake groups in the next step.</p>
                                </>
                                :
                                <>
                                    <p className="u-m-base-2">Click into each of your registration classes to see the list of pupils and set their target classes for 2022/23.</p>
                                    <p className="u-m-base-2">You'll be able to create your 2022/23 classes when assigning the pupils.</p>
                                    <Link to="/classes/" className={styles.backLink}>
                                        <div>
                                            <ArrowLeftCircle size="34" className={styles.backLinkIcon}/>
                                        </div>
                                        <div>
                                            <p className={styles.backLinkText}>Go back to Manage Classes to identify your Registration Classes</p>
                                        </div>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoveClasses;