import React from 'react';
import { Loader } from '@axeedge/go-teacher-components';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { GET_UNASSIGNED_STUDENTS_QUERY } from '../../services/graphql';
import StudentsList from '../../scenes/Students/components/StudentsList';

const UnassignedPupils = ({ schoolId }) => {

    const { t } = useTranslation();

    const { data, error, loading } = useQuery(GET_UNASSIGNED_STUDENTS_QUERY, {
        variables: {
            schoolId: schoolId
        },
        fetchPolicy: 'network-only'
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.school) {
        const { school: { unassignedStudents } } = data;
        return (
            <div className='row'>
                <div className="col-md-7">
                    {unassignedStudents && unassignedStudents.length > 0 ?
                        <StudentsList schoolId={schoolId} students={unassignedStudents} />
                        : <p>{t('no_unassigned_pupils_in_school')}</p>}
                </div>
            </div>
        );
    }
    return null;
}

export default UnassignedPupils;