import React, { Fragment, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FINALIZE_IMPORT_STUDENTS, GET_CLASS_QUERY } from '../../services/graphql';
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import ImportIntoClassErrors from './ImportIntoClassErrors';
import styles from './UploadStudentsList.module.scss';

const FinalizeUploadStudents = ({ classData, manageUploadData, setManageUploadData, setUploaded }) => {

    const [manageErrors, setManageErrors] = useState([]);
    const [includeDuplicates, setIncludeDuplicates] = useState([]);

    const [showConfirmImport, setShowConfirmImport] = useState(false);
    const [reviewFinalStudents, setReviewFinalStudents] = useState(null);


    useEffect(() => {
        if (manageUploadData && manageUploadData.importFile.notValidStudents.length === 0) {
            setReviewFinalStudents(manageUploadData.importFile.importStudents);
        }
    }, [manageUploadData]);

    const [finalizeImportStudents, { loading: finalizing }] = useMutation(FINALIZE_IMPORT_STUDENTS, {
        update: (_, { data }) => {
            if (data.finalizeImportStudentsFromXlsx.errors && data.finalizeImportStudentsFromXlsx.errors.length !== 0) {
                setManageErrors(data.finalizeImportStudentsFromXlsx.errors);
                return;
            }
            if (data.finalizeImportStudentsFromXlsx.importFile.errors && data.finalizeImportStudentsFromXlsx.importFile.errors.length !== 0) {
                setManageErrors([`Imported ${data.finalizeImportStudentsFromXlsx.importFile.imported} out of ${data.finalizeImportStudentsFromXlsx.importFile.total}. ${data.finalizeImportStudentsFromXlsx.importFile.errors[0]}`]);
                return;
            }
            if (data.finalizeImportStudentsFromXlsx.importFile.imported === data.finalizeImportStudentsFromXlsx.importFile.total) {
                setUploaded(true);
                setManageUploadData(null);
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classData.id } }]
    });

    return (
        <Fragment>
            {reviewFinalStudents ? (
                <>
                    {reviewFinalStudents.length === 0 ? (
                        <>
                            <p className='u-m-base-2'>No students to import</p>
                            <Button onClick={() => setManageUploadData(null)}>Try again</Button>
                        </>
                    ) : (<>
                        <h3 className='heavy u-m-base-2'>We've found {reviewFinalStudents.length} pupils</h3>
                        <p>Here's a preview of your students:</p>
                        <table className={styles.importTable}>
                            <thead>
                                <tr>
                                    <th>UPN</th>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th>Year Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviewFinalStudents.map(student => (
                                    <tr key={`to-import-${student.id}`}>
                                        <td>{student.upn}</td>
                                        <td>{student.firstName}</td>
                                        <td>{student.lastName}</td>
                                        <td>{student.yearGroup}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <p className='u-m-base-2'>If your data is incorrect, please press cancel and check your spreadsheet.</p>
                        <Button disabled={finalizing} className='u-m-right-2' onClick={() => setShowConfirmImport(true)}>Import</Button>
                        <Button outline onClick={() => setManageUploadData(null)}>Cancel</Button>
                        {showConfirmImport && (
                            <Modal closeModal={() => setShowConfirmImport(false)}>
                                <div className="u-text-center">
                                    <h3 className='u-m-base-2'>Are you sure?</h3>
                                    {manageErrors.length !== 0 && <FormErrors errors={manageErrors} />}
                                    <Button
                                        disabled={finalizing}
                                        className='u-m-right-2'
                                        onClick={() => finalizeImportStudents({
                                            variables: {
                                                importTicketId: manageUploadData.importFile.id
                                            }
                                        })}
                                    >{finalizing ? 'Saving...' : 'Yes'}</Button>
                                    <Button outline onClick={() => setShowConfirmImport(false)}>No</Button>
                                </div>
                            </Modal>
                        )}
                    </>)}
                </>) :
                manageUploadData.importFile.notValidStudents.length > 0 &&
                <ImportIntoClassErrors
                    close={() => setManageUploadData(null)}
                    notValidStudents={manageUploadData.importFile.notValidStudents}
                    setReviewFinalStudents={setReviewFinalStudents}
                    includeDuplicates={includeDuplicates}
                    setIncludeDuplicates={setIncludeDuplicates}
                    ticketId={manageUploadData.importFile.id}
                />
            }
        </Fragment>
    )
}

export default FinalizeUploadStudents;