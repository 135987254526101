import { gql } from 'apollo-boost';


export const GET_STEP = gql`
    query schoolYearStats($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            newYearStep
        }
    }
`;

export const SET_NEXT_STEP = gql`
    mutation updateSchoolNewYearStep($schoolId: ID!, $newYearStep: Int!) {
        updateSchoolNewYearStep(schoolId: $schoolId, newYearStep: $newYearStep) {
            school {
                id
                newYearStep
            }
            errors
        }
    }
`

export const AUTO_ARCHIVE = gql`
    mutation increaseStudentsYearGroup($schoolId: ID!, $finalYearGroup: Int!) {
        increaseStudentsYearGroup(schoolId: $schoolId, finalYearGroup: $finalYearGroup) {
            school {
                id
            }
            errors
        }
    }
`