import React from 'react';
import SettingsNav from './SettingsNav';

const Settings = ({children}) => {
    return (
        <>
            <SettingsNav />
            {children}
        </>
    );
}
export default Settings;