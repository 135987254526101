import { gql } from 'apollo-boost';
import { teacherQuery } from '../../../services/constants';

export const EDIT_TEACHER_MUTATION = gql`
mutation editCurrentTeacher($teacherId: ID!, $firstName: String, $lastName: String, $displayName: String, $email: String, $roleId: Int) {
    editCurrentTeacher(teacherId: $teacherId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, roleId: $roleId) {
        teacher {
            ${teacherQuery}
        }
        errors
    }
}
`;

export const EDIT_TEACHER_AVATAR_MUTATION = gql`
mutation addTeacherAvatar($avatarUrl: String!) {
    addTeacherAvatar(avatarUrl: $avatarUrl) {
        teacher {
            ${teacherQuery}
        }
        errors
    }
}
`

export const EDIT_TEACHER_JOB_ROLE = gql`
mutation editCurrentTeacherRole($teacherId: ID!, $schoolId: ID!, $roleId: Int!) {
    editCurrentTeacherRole(teacherId: $teacherId, schoolId: $schoolId, roleId: $roleId) {
        teacher {
            ${teacherQuery}
        }
        errors
    }
}
`

export const CHANGE_PASSWORD_MUTATION = gql`
mutation changePassword($existingPassword: String!, $newPassword: String!, $passwordConfirmation: String!) {
    changePassword(existingPassword: $existingPassword, newPassword: $newPassword, passwordConfirmation: $passwordConfirmation) {
        user {
            id
        }
        errors
    }
}
`;