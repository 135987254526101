import React, { useState, useContext } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { EDIT_TEACHER_JOB_ROLE } from '../../scenes/TeacherProfile/services/graphql';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';

import { getRoleLabel } from '../../services/utils';

const JobRoleForm = () => {

    const { t } = useTranslation(['classes', 'common']);

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);


    const schema = Yup.object().shape({
        roleId: Yup.string().required(t('common:please_select_role')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });


    const [editCurrentTeacherRole, { loading }] = useMutation(EDIT_TEACHER_JOB_ROLE, {
        update: (_, { data }) => {
            if (data.editCurrentTeacherRole.errors && data.editCurrentTeacherRole.errors.length !== 0) {
                setFormErrors(data.editCurrentTeacherRole.errors);
                return;
            }
            if (data.editCurrentTeacherRole.teacher.id) {
                setCurrentUser(data.editCurrentTeacherRole.teacher);
            }
        }
    });

    const onSubmit = values => {
        const { roleId } = values;
        editCurrentTeacherRole({
            variables: {
                roleId: +roleId,
                schoolId: currentUser.school.id,
                teacherId: currentUser.id
            }
        });
    }

    return (
        <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-form__group">
                <select defaultValue={currentUser.roleId} name="roleId" ref={register} className="basic-form__text-select">
                    <option value=''>{t('please_select')}</option>
                    {Object.entries(TEACHER_JOB_ROLES).map(role => {
                        return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                    })}
                </select>
            </div>
            {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}
            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(formError => t(formError))} />}
            <div className="u-display-flex u-align-center u-m-top-3">
                <Button type="submit" primary className="u-m-right-1">{loading ? t('common:saving') : 'Save & Continue'}</Button>
            </div>
        </form>
    )
}

export default JobRoleForm;