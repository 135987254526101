import React, { useState } from 'react';
import FindByUrn from './FindByUrn';
import ManualSchoolSetup from './ManualSchoolSetup';
import { ArrowLeft } from 'react-feather';
import { SUBSCRIPTION_INTENT } from '../../../../services/constants';

const SchoolSetup = ({ close, currentUser }) => {

    const [showManualSetup, setShowManualSetup] = useState(currentUser.subscriptionIntent === SUBSCRIPTION_INTENT.write);

    return (
        <>
            <button className='btn-reset u-m-base-2' onClick={close}><ArrowLeft /></button>
            {showManualSetup ?
                <ManualSchoolSetup close={close} /> :
                <FindByUrn exit={close} setShowManualSetup={setShowManualSetup} />
            }
        </>

    )

}

export default SchoolSetup;
