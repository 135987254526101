import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { Button, Modal } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../services/auth/AuthProvider';
import ProfileImage from '../../components/ProfileImage';
import EditAddress from './components/EditAddress';
import SchoolWeekForm from './components/SchoolWeekForm';

import { EDIT_SCHOOL_AVATAR_MUTATION } from './services/graphql';
import defaultImg from './images/default.svg'

import { useTranslation } from 'react-i18next';

import styles from './SchoolProfile.module.scss';
import SaveConfirm from '../../components/SaveConfirm';

const SchoolProfile = () => {

    const { t } = useTranslation(['profile', 'common']);

    const [showCropModal, setShowCropModal] = useState(false);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [editAddress, setEditAddress] = useState(false);

    const { currentUser, setCurrentUser } = useContext(AuthContext);


    const [saveAvatar, { loading: savingAvatar }] = useMutation(EDIT_SCHOOL_AVATAR_MUTATION, {
        update: (_, { data }) => {
            if (data.addSchoolAvatar.errors && data.addSchoolAvatar.errors.length !== 0) {
                alert(data.addSchoolAvatar.errors[0]);
                return;
            }
            if (data.addSchoolAvatar.school.id) {
                const cu = { ...currentUser, school: data.addSchoolAvatar.school }
                setCurrentUser(cu);
                setShowCropModal(false);
                setShowSaveConfirm(true);
            }
        },
    })

    const onSaveNewAvatar = ({ variables }) => {
        saveAvatar({
            variables: {
                ...variables,
                schoolId: currentUser.school.id
            }
        });
    }

    return (
        <>
            <div className='card card-mobile'>
                <div className="u-display-md-flex u-justify-between u-align-center u-m-base-2">
                    <h3 className='heavy u-m-base-0'>{t('school_details')}</h3>
                    {showSaveConfirm && <SaveConfirm hideConfirm={setShowSaveConfirm} />}
                </div>
                <div className={styles.container}>
                    <div className={styles.contentHolder}>
                        <div className={styles.contentHolderLeft}>
                            <ProfileImage
                                defaultImg={(currentUser.school?.avatarUrl) || defaultImg}
                                heading={t('school_crest')}
                                imgName={`schools/profile${currentUser.school?.id}`}
                                onSave={onSaveNewAvatar}
                                savingImage={savingAvatar}
                                setShowCropModal={setShowCropModal}
                                showCropModal={showCropModal}
                                width={140}
                            />
                        </div>
                        {currentUser.school &&
                            <div className={styles.details}>
                                <h3 className='heavy u-m-base-2'>{currentUser.school.name}</h3>
                                <p>{currentUser.school.address}</p>
                                {currentUser.school.address2 && <p>{currentUser.school.address2}</p>}
                                <p>{currentUser.school.zip}</p>
                                <p>{currentUser.school.city}</p>
                                {currentUser.school.country && <p>{currentUser.school.country.name}</p>}
                                {currentUser.school.state && <p>{currentUser.school.state.code}</p>}
                                <Button className='u-m-top-2' onClick={() => setEditAddress(true)}>{t('update_address')}</Button>
                            </div>
                        }
                    </div>

                    <hr className='separator' style={{ height: '1px', margin: '20px 0', background: '#D8D8D8', border: 'none' }} />
                    <SchoolWeekForm
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        setShowSaveConfirm={setShowSaveConfirm}
                    />


                </div>


            </div >
            {
                editAddress && (
                    <Modal closeModal={() => setEditAddress(false)}>
                        <EditAddress
                            close={() => setEditAddress(false)}
                            setShowSaveConfirm={setShowSaveConfirm}
                        />
                    </Modal>
                )
            }
        </>
    )
}

export default SchoolProfile;
