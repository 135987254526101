import { gql } from 'apollo-boost';
import { schoolQuery } from '../../../services/constants';


export const EDIT_SCHOOL_MUTATION = gql`
mutation editSchool($schoolId: ID!, $name: String!, $address: String!, $address2: String, $city: String!, $zip: String!, $financeOfficerName: String, $financeOfficerEmail: String, $financeOfficerPhone: String, $localAuthority: String, $country: String, $schoolPhoneNumber: String, $state: String) {
    editSchool(schoolId: $schoolId, name: $name, address: $address, address2: $address2, city: $city, zip: $zip, financeOfficerName: $financeOfficerName, financeOfficerEmail: $financeOfficerEmail, financeOfficerPhone: $financeOfficerPhone, localAuthority: $localAuthority, country: $country, schoolPhoneNumber: $schoolPhoneNumber, state: $state) {
        school {
            ${schoolQuery}
        }
        errors
    }
}
`;

//not used anymore, replaced by createPromoSchool
export const CREATE_SCHOOL_MUTATION = gql`
mutation createSchool($name: String!, $address: String!, $address2: String, $city: String!, $zip: String!, $financeOfficerName: String, $financeOfficerEmail: String, $financeOfficerPhone: String, $localAuthority: String, $roleId: Int!, $country: String) {
    createSchool(name: $name, address: $address, address2: $address2, city: $city, zip: $zip, financeOfficerName: $financeOfficerName, financeOfficerEmail: $financeOfficerEmail, financeOfficerPhone: $financeOfficerPhone, localAuthority: $localAuthority, roleId: $roleId, country: $country) {
        school {
            ${schoolQuery}
            admins {
                id
            }
        }
        errors
    }
}
`;

export const CREATE_PROMO_SCHOOL_MUTATION = gql`
mutation createPromoSchool($name: String!, $address: String!, $address2: String, $city: String!, $zip: String!,  $roleId: Int!, $country: String!, $schoolPhoneNumber: String!, $weekStart: Int, $avatarUrl: String) {
    createPromoSchool(name: $name, address: $address, address2: $address2, city: $city, zip: $zip, roleId: $roleId, country: $country, schoolPhoneNumber: $schoolPhoneNumber, weekStart: $weekStart, avatarUrl: $avatarUrl) {
        school {
            ${schoolQuery}
            admins {
                id
            }
        }
        errors
    }
}
`;

//math API only
export const CREATE_MATH_PROMO_SCHOOL_MUTATION = gql`
mutation createPromoSchool($name: String!, $address: String!, $address2: String, $city: String!, $zip: String!,  $roleId: Int!, $country: String!, $state: String!, $schoolPhoneNumber: String!, $weekStart: Int, $avatarUrl: String, $mathCurriculumId: ID!) {
    createPromoSchool(name: $name, address: $address, address2: $address2, city: $city, zip: $zip, roleId: $roleId, country: $country, state: $state, schoolPhoneNumber: $schoolPhoneNumber, weekStart: $weekStart, avatarUrl: $avatarUrl, mathCurriculumId: $mathCurriculumId) {
        school {
            ${schoolQuery}
            admins {
                id
            }
        }
        errors
    }
}
`;

export const EDIT_SCHOOL_AVATAR_MUTATION = gql`
mutation addSchoolAvatar($avatarUrl: String!, $schoolId: ID!) {
    addSchoolAvatar(avatarUrl: $avatarUrl, schoolId: $schoolId) {
        school {
            ${schoolQuery}
        }
        errors
    }
}
`;



export const UPDATE_WEEK_START = gql`
mutation updateSchoolWeekStart($schoolId: ID!, $weekStart: Int!) {
    updateSchoolWeekStart(schoolId: $schoolId, weekStart: $weekStart) {
        school {
            ${schoolQuery}
        }
        errors
    }
}
`;
