import React, { useState, useEffect, useContext } from "react";
import { AlertCircle, CheckCircle, Delete, PauseCircle } from "react-feather";
import StaffMatchItem from "./StaffMatchItem";
import { useMutation } from "@apollo/react-hooks";
import { WONDE_SYNC_TEACHERS } from "../../services/graphql";
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import styles from '../../WondeSync.module.scss';
import cx from 'classnames';

const StaffList = ({ matchTeachers, setCurrentStep }) => {

    const { currentUser } = useContext(AuthContext);

    const [staff, setStaff] = useState(matchTeachers);
    const [staffError, setStaffError] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const [wondeSyncTeachers, { loading }] = useMutation(WONDE_SYNC_TEACHERS, {
        update: (_, { data }) => {
            if (data && data.wondeSyncTeachers.errors && data.wondeSyncTeachers.errors.length > 0) {
                setFormErrors(data.wondeSyncTeachers.errors);
                return;
            }
            if (data && data.wondeSyncTeachers?.teachers) {
                //this is also updated from the backend for cases where users are logged out when matching themselves to anotheer
                setCurrentStep(WONDE_SYNC_STEPS.syncStudentsUpn);
            }
        }
    });

    const getStats = field => {
        if (field === 'matched') {
            return [...staff].filter(el => el.wondeId || (el.wondeTeacherId && el.wondeTeacherId !== '-1' && el.wondeTeacherId !== '-99')).length;
        }
        if (field === 'not_matched') {
            return [...staff].filter(el => !el.wondeId && !el.wondeTeacherId).length;
        }
        if (field === 'removed') {
            return staff.filter(el => el.wondeTeacherId === '-1').length || 0;
        }

        if (field === 'skipped') {
            return staff.filter(el => el.wondeTeacherId === '-99').length || 0;
        }
    }


    useEffect(() => {
        if (!staff.find(el => !el.wondeId && !el.wondeTeacherId) && staffError) {
            setStaffError(null);
        }
    }, [staff, staffError])

    const finalizeStaffSync = () => {
        if (staff.find(el => !el.wondeId && !el.wondeTeacherId)) {
            setStaffError(true);
            return;
        }
        const syncDetails = [];
        staff.forEach(el => {
            //don't send to backend the teachers marked as skipped (-99)
            if (el.wondeTeacherId !== '-99') {
                syncDetails.push({
                    teacherId: el.id,
                    wondeTeacherId: el.wondeId || el.wondeTeacherId,
                    ...(el.matchTo && { wondeTeacherEmail: el.matchTo.email })
                })
            }
        });
        wondeSyncTeachers({
            variables: {
                schoolId: currentUser?.school?.id,
                wondeTeacherDetails: syncDetails,
            }
        });
    }

    return (
        <>
            <div className={styles.syncMain}>
                <h3 className="heavy">Sync Existing Staff</h3>
                <div className="u-flex-center u-m-base-3">
                    <div className="u-m-right-3 u-flex-center">
                        <CheckCircle size={18} className="u-m-right-1" /> Matched: {getStats('matched')}
                    </div>
                    <div className={cx("u-m-right-3 u-flex-center", { 'u-text-danger': getStats('not_matched') > 0 })}>
                        <AlertCircle size={18} className="u-m-right-1" /> Not Matched: {getStats('not_matched')}
                    </div>
                    <div className="u-m-right-3 u-flex-center">
                        <Delete size={18} className="u-m-right-1" /> Will be removed: {getStats('removed')}
                    </div>

                    <div className="u-m-right-3 u-flex-center">
                        <PauseCircle size={18} className="u-m-right-1" /> Skipped: {getStats('skipped')}
                    </div>
                </div>

                {staffError && <p className="u-text-danger u-m-top-2">Please update all non matched accounts!</p>}
                {formErrors && <FormErrors errors={formErrors} />}
                <ul>
                    {staff.map(teacher => (
                        <StaffMatchItem key={`match-${teacher.id}`} staff={staff} setStaff={setStaff} teacher={teacher} />
                    ))}
                </ul>
            </div>
            <div className={styles.syncFooter}>
                {/* <button onClick={() => ''} className="btn-reset u-flex-center heavy"><AlertCircle className="u-m-right-1" size={18} /> Help</button> */}
                <Button disabled={loading} className='u-m-left-auto' onClick={() => finalizeStaffSync()}>{loading ? 'Please wait, saving...' : 'Confirm and continue'}</Button>
            </div>
        </>
    )
}

export default StaffList;