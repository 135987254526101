import React from "react";
import { Link } from "@reach/router";
import { useQuery } from "@apollo/react-hooks";
import styles from '../../Dashboard.module.scss';
import { PENDING_JOIN_REQUESTS } from "../../services/graphql";

const PendingStaffRequests = ({ school }) => {

    const { data } = useQuery(PENDING_JOIN_REQUESTS, {
        variables: {
            schoolId: school.id
        }
    });

    if (data && data.school && data.school.joinRequests.filter(r => !r.approved).length > 0) {
        const { school: { joinRequests } } = data;

        return (
            <>

                <Link to='/teachers' state={{ showRequests: true }} className={styles.pendingRequests}>
                    <p className="lead">{joinRequests.filter(r => !r.approved).length} Teacher Access Requests</p>
                    <p>{joinRequests.filter(r => !r.approved).map(r => r.teacher.displayName).join(', ')}</p>
                </Link>
            </>
        )
    }
    return null
}

export default PendingStaffRequests