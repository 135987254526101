import React, { useState, useEffect, useRef } from "react";
import { getYearGroupShortName } from "../../../../services/utils";
import { CheckCircle, Trash2 } from "react-feather";
import { useLazyQuery } from "@apollo/react-hooks";
import styles from '../../WondeSync.module.scss';
import cx from 'classnames';
import loader from '../../../../images/loader.svg';
import { SEARCH_WONDE_STUDENTS } from "../../services/graphql";

const ManualStudentItem = ({ school, student, setConfirmDelete, setConfirmMatch }) => {


    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);


    const intervalRef = useRef(null);

    const [searchWondeStudents, { loading, error }] = useLazyQuery(SEARCH_WONDE_STUDENTS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getWondeStudentsByNameOrUpn.length > 0) {
                setResults(data.getWondeStudentsByNameOrUpn);
                setNoResults(false);
            } else {
                setResults([]);
                setNoResults(true);
            }
        }
    });


    const onSelectMatch = result => {
        setResults([]);
        setNoResults(false);
        setSearchTerm('');
        const match = {
            ...student,
            wondeStudentId: result.wondeId,
            matchTo: result
        }
        setConfirmMatch(match);
    }

    useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            intervalRef.current = setTimeout(() => {
                searchWondeStudents({
                    variables: {
                        nameOrUpn: searchTerm,
                        wondeSchoolId: school.wondeId
                    }
                })
            }, 1000);
        } else {
            setResults([]);
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [searchTerm]);


    return (

        <li className={cx(styles.manualMatch, styles.altRow)}>
            <div className={cx(styles.manualMatchBoom, styles.altRowBoom)}>
                <div>{student.name}</div>
                <div className="u-text-right">{getYearGroupShortName(student.yearGroup)}</div>
            </div>
            <div className={cx(styles.manualMatchWonde, styles.altRowWonde)}>

                <div style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className={styles.searchStudent}
                        placeholder="Type name or UPN"
                        autoComplete='off'
                        value={searchTerm}
                        onChange={(e) => { setSearchTerm(e.target.value); setNoResults(false) }}
                    />

                    {error && <p>{error.message}</p>}
                    <div className={styles.resultsWrapper}>
                        {loading && <p className="u-flex-center">Loading... <img src={loader} alt='' className="u-m-left-2" width={25} /></p>}
                        {results.length > 0 ? (
                            <ul className={styles.resultsWrapperList}>
                                {results.map(result => (
                                    <li key={`result-${result.id}`} onClick={() => onSelectMatch(result)} className="u-flex-center u-justify-between">
                                        <div>
                                            <p>{result.firstName} {result.lastName}</p>
                                            <p>{result.wondeUpn}</p>
                                        </div>
                                        <CheckCircle />
                                    </li>
                                ))}
                            </ul>
                        ) : noResults && <p>No students found</p>}
                    </div>
                </div>
                <button className="btn-reset u-m-left-auto" onClick={() => setConfirmDelete(student)}><Trash2 size={18} /></button>
            </div>
        </li>

    )
}

export default ManualStudentItem;