import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import ModuleTour from '../../../../components/ModuleTour';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { ADD_READ_TRIAL_ACCESS, GET_READ_SUBSCRIPTIONS } from '../../services/graphql';
import styles from './ReadAppLocked.module.scss';
import cx from 'classnames';
import PriceQuote from '../../components/PriceQuote';

const GoReadLocked = () => {

    const { t } = useTranslation(['apps']);
    const [tourFinished, setTourFinished] = useState(false);
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [postTour, setPostTour] = useState(false);

    const [formErrors, setFormErrors] = useState([]);
    const [studentsCounter, setStudentsCounter] = useState(1);
    const [showPricing, setShowPricing] = useState(false);


    const [addBoomReaderTrialAccess, { loading }] = useMutation(ADD_READ_TRIAL_ACCESS, {
        update: (_, { data }) => {
            if (data.addBoomReaderTrialAccess.errors && data.addBoomReaderTrialAccess.errors.length) {
                setFormErrors(data.addBoomReaderTrialAccess.errors)
            }
            if (data.addBoomReaderTrialAccess.school.id) {
                const cu = { ...currentUser, school: data.addBoomReaderTrialAccess.school }
                setCurrentUser(cu);
            }
        },
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: currentUser.school.id } }],
        awaitRefetchQueries: true
    });

    const slideData = [
        {
            id: 0,
            text: 'Take a quick tour of our platform here:',
            youtubeUrl: 'https://www.youtube.com/watch?v=fR_SGrGyeys&t'
        },
        {
            id: 1,
            text: 'Are you interested in our Data Dashboard?',
            youtubeUrl: 'https://www.youtube.com/watch?v=AFoWC0dIhpY'
        },
        {
            id: 2,
            text: 'Do you want to hear more about how BoomReader works in school?',
            youtubeUrl: 'https://www.youtube.com/watch?v=L-QZAxVaf80'
        },
        {
            id: 3,
            text: 'Parents can download the FREE app from here:',
            linkText: 'go-read.co.uk/parents',
            link: 'https://go-read.co.uk/parents',
        },
        {
            id: 4,
            text: 'Don’t forget to book your FREE staff training session.',
            linkText: 'Book now!',
            link: `mailto:support@boomhub.app?subject=Book Staff Training - ${currentUser.school.name}`
        },
    ]

    if (showPricing) {
        return <PriceQuote close={() => setShowPricing(false)} />
    }

    return (
        <>
            {postTour ?
                <>
                    <div className='row'>
                        <div className='col-md-12 col-lg-8'>
                            <h1 className={cx(styles.goReadLockedTitle, "u-m-base-2")}>Start Your Free Trial</h1>
                            <p className="u-m-base-2">Start your free 30 day trial. This gives your school unlimited access to BoomReader. There's no obligation ot purchase at the end of this trial period.</p>
                            <div className={styles.trialNoStudents}>
                                <h3 className='heavy'>{t('number_of_pupils')}</h3>
                                <input
                                    type='number'
                                    className={cx('basic-form__text-box', styles.trialNoStudentsInput)}
                                    min='1'
                                    defaultValue='1'
                                    onChange={(e) => setStudentsCounter(e.target.value)}
                                />
                            </div>
                    
                            {formErrors && <FormErrors errors={formErrors} />}
                            <Button
                                disabled={loading}
                                onClick={() => addBoomReaderTrialAccess({
                                    variables: {
                                        studentsCounter: +studentsCounter,
                                        schoolId: currentUser?.school?.id
                                    }
                                })}
                            >{loading ? 'Saving...' : 'Start Free Trial'}</Button>
                        </div>
                        <div className='col-md-12 col-lg-4'>
                            <div className='panel'>
                                <h4 className='heavy'>Pricing</h4>
                                <p className='u-m-base-2'>BoomReader uses a simple per student pricing model. To find out how much BoomReader will cost you can use our simple pricing calculator.</p>
                                <Button outline fullWidth onClick={() => setShowPricing(true)}>Get a quote</Button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='row'>
                    <div className='col-md-12 col-lg-8'>
                        <div className={cx(styles.goReadLocked, 'card card-mobile')}>
                            <div className={styles.goReadLockedText}>
                                <h1 className={styles.goReadLockedTitle}>{t('take_tour')}</h1>
                                <p>{t('see_how_easy')}</p>
                            </div>
                            <ModuleTour slideData={slideData} onFinishTour={setTourFinished} />
                            <div className={styles.goReadLockedFooter}>
                                {
                                    tourFinished ?
                                        <Button className={styles.goReadLockedTextLink} onClick={() => setPostTour(true)}>Next</Button> :
                                        (
                                            <Fragment>
                                                <a className={styles.button} target='_blank' rel="noopener noreferrer" href='https://www.boomreader.co.uk/walkthrough'>{t('book_demo')}</a>
                                                <span className={styles.goReadLockedTextLink} onClick={() => setPostTour(true)}>{t('skip_step')}</span>
                                            </Fragment>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default GoReadLocked;