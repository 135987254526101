import { gql } from 'apollo-boost';

export const MOVE_YEAR = gql`
mutation updateStudentsYearGroup($studentIds: [ID!]!, $yearGroup: Int!) {
    updateStudentsYearGroup(studentIds: $studentIds, yearGroup: $yearGroup) {
        students {
            id
        }
        errors
    }
}`;

export const MOVE_STUDENTS = gql`
mutation addStudentsToClass($studentsClassId: ID!, $studentsData: [StudentToClassAttributes!]!) {
    addStudentsToClass(studentsClassId: $studentsClassId, studentsData: $studentsData) {
        studentsClass {
            id
        }
        errors
    }
}`;

export const REMOVE_STUDENTS_FROM_SCHOOL = gql`
mutation removeStudentsFromSchool($studentIds: [ID!]!, $schoolId: ID!, $is6YearLeaver: Boolean) {
    removeStudentsFromSchool(studentIds: $studentIds, schoolId: $schoolId, is6YearLeaver: $is6YearLeaver) {
        students {
            id
        }
        errors
    }
}`;

export const GET_CLASS_QUERY = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        academicYear
        teachers {
            id
            displayName
         }
        students {
            id
            firstName
            lastName
            name
            yearGroup
            school {
                id
            }
            classes {
                id
                name
                academicYear
                typeId
            }
        }
        inactiveStudents {
            id
            firstName
            lastName
            name
            yearGroup
            school {
                id
            }
            classes {
                id
                name
                academicYear
                typeId
            }
        }

    }
}
`;