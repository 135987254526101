import { gql } from 'apollo-boost';

export const GET_S3_UPLOAD_URL_QUERY = gql`
query s3UploadUrl($name: String!) {
    s3UploadUrl(name: $name) 
}
`;

export const STRIPE_DETAILS_QUERY = gql`
query stripeDetails {
    stripeDetails {
        publicKey
    }
}
`;

