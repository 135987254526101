import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { WONDE_STUDENTS_SUGGESTED_MATCH } from "../../services/graphql";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import SuggestedMatchContent from "./SuggestedMatchContent";
import loader from '../../../../images/loader.svg';
import { WONDE_SYNC_STEPS } from "../../../../services/constants";

const SuggestedMatchQuery = ({ setCurrentStep }) => {

    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(WONDE_STUDENTS_SUGGESTED_MATCH, {
        variables: {
            schoolId: currentUser?.school?.id
        },
        onCompleted: ({ school }) => {
            if (school?.wondeSuggestedMatchStudents?.length === 0) {
                setCurrentStep(WONDE_SYNC_STEPS.syncStudentsManual);
            }
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <>
            {loading ?
                <>
                    <p className="u-display-flex u-align-center heavy">Please wait, loading suggested matches for students... <img className="u-m-left-2" src={loader} /></p>
                </> :
                data?.school?.wondeSuggestedMatchStudents.length > 0 &&
                <SuggestedMatchContent
                    setCurrentStep={setCurrentStep}
                    school={currentUser?.school}
                    suggestedStudents={data.school.wondeSuggestedMatchStudents}
                />
            }
        </>
    )
}

export default SuggestedMatchQuery;