import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { X, PlusCircle, CheckCircle } from 'react-feather';
import ld from 'lodash';

import { Loader, FormErrors, Button } from '@axeedge/go-teacher-components';

import { ADD_CLASS_TEACHER_MUTATION, REMOVE_TEACHER_FROM_CLASS_MUTATION } from '../../services/graphql';
import { GET_CLASS_QUERY } from '../../services/graphql';
import { GET_TEACHERS_QUERY } from '../../../../services/school/graphql';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const AddClassTeacher = ({ schoolCode, studentClass, removingTeacher, closeAddTeacher }) => {

    const { t } = useTranslation(['classes', 'common']);

    const { currentUser: user, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [confirmRemove, setConfirmRemove] = useState(null);
    const [baseTeachers, setBaseTeachers] = useState([]);
    const [visibleTeachers, setVisibleTeachers] = useState(baseTeachers);
    const [searchTerm, setSearchTerm] = useState('');

    const { data, error, loading } = useQuery(GET_TEACHERS_QUERY, {
        variables: {
            schoolCode
        },
        onCompleted: (data) => setBaseTeachers(data.teachersBySchoolCode)
    });

    useEffect(() => {
        const results = [...baseTeachers].filter(t => t.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setVisibleTeachers(results);
    }, [searchTerm, baseTeachers]);

    const [removeTeacher, { loading: removing }] = useMutation(REMOVE_TEACHER_FROM_CLASS_MUTATION, {
        update: (_, { data }) => {
            if (data.removeTeacherFromClass.errors && data.removeTeacherFromClass.errors.length) {
                setFormErrors(data.removeTeacherFromClass.errors);
            }
            if (data.removeTeacherFromClass.deleted) {
                setConfirmRemove(null);
                if (confirmRemove && confirmRemove.id === user.id) {
                    const studentsClasses = user.studentsClasses.filter(c => c.id !== studentClass.id);
                    setCurrentUser({ ...user, studentsClasses })
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: studentClass.id } }]
    });

    const [addTeacher, { loading: adding }] = useMutation(ADD_CLASS_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.addClassTeacher.errors && data.addClassTeacher.errors.length) {
                setFormErrors(data.addClassTeacher.errors);
            }
            if (data.addClassTeacher.studentsClass.id) {
                const sClass = data.addClassTeacher.studentsClass;
                if (ld.find(sClass.teachers, ['id', user.id])) {
                    if (!ld.find(user.studentsClasses, ['id', sClass.id])) {
                        const studentsClasses = user.studentsClasses;
                        studentsClasses.push(sClass);
                        const cu = { ...user, studentsClasses }
                        setCurrentUser(cu)
                    }
                }
                if (removingTeacher) {
                    closeAddTeacher();
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: studentClass.id } }]
    });

    const isClassTeacher = teacher => {
        return studentClass.teachers.find(t => { return t.id === teacher.id });
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.teachersBySchoolCode) {
        return (
            <Fragment>
                <h2>{user.isSchoolAdmin && !removingTeacher ? t('add_remove_teachers_in_class_name', { classname: studentClass.name }) : t('add_teachers_to_class_name', { classname: studentClass.name })}</h2>
                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                <div className='u-m-base-2'>
                    <input
                        type="text"
                        className='basic-form__text-box'
                        value={searchTerm}
                        placeholder='Type to find teacher'
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {
                    !!data.teachersBySchoolCode.length ? (
                        <ul className='list-group'>
                            {
                                visibleTeachers.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map((teacher) => {
                                    return removingTeacher && removingTeacher.id === teacher.id ? null : (
                                        <li
                                            key={`t-${teacher.id}`}
                                            className={cx('list-item', { ['heavy']: isClassTeacher(teacher) })}
                                        >
                                            <span className='list-item--child'>{teacher.displayName} ({teacher.firstName} {teacher.lastName})</span>
                                            
                                                <div className='u-m-left-auto list-item--child'>
                                                    {isClassTeacher(teacher) ? (
                                                        <Fragment>
                                                            {user.isSchoolAdmin ? (
                                                                <button className='btn-reset link-reverse' onClick={() => setConfirmRemove(teacher)}><X /></button>
                                                            ) : <CheckCircle className='u-text-muted' />
                                                            }
                                                        </Fragment>
                                                    ) : (
                                                        <button
                                                            disabled={adding}
                                                            className='btn-reset link-reverse'
                                                            onClick={() => addTeacher({
                                                                variables: {
                                                                    studentsClassId: studentClass.id,
                                                                    teacherId: teacher.id
                                                                }
                                                            })}
                                                        ><PlusCircle /></button>
                                                    )}
                                                </div>
                                            
                                            {confirmRemove && confirmRemove.id === teacher.id && (
                                                <div className='list-item--full u-m-y-1'>
                                                    {t('common:are_you_sure')}
                                                    <Button
                                                        disabled={removing}
                                                        className='u-m-left-1 u-m-right-1'
                                                        onClick={() => removeTeacher({
                                                            variables: {
                                                                studentsClassId: studentClass.id,
                                                                teacherId: teacher.id
                                                            }
                                                        })} >{t('common:button.yes')}</Button>
                                                    <Button type='button' outline onClick={() => setConfirmRemove(null)}>{t('common:button.no')}</Button>
                                                </div>
                                            )}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    ) : <p>{t('common:no_teachers')}</p>
                }
            </Fragment>
        )
    }

    return null
}

export default AddClassTeacher;