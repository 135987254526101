import React, {useState} from 'react'
import ls from 'local-storage'
import styles from './CountrySelect.module.scss';
import cx from 'classnames'
import { CheckCircle, ArrowLeft } from 'react-feather';
import { Button} from '@axeedge/go-teacher-components';
import usa from '../../../images/usa.png'
import uk from '../../../images/uk.png'
import row from '../../../images/row.png'
const CountrySelect = ({setShowCountrySelect}) => {
    const [selectedCountry, setSelectedCountry] = useState(ls('country'))
    return (
        <div className={styles.container}>
            <span className={styles.close} onClick={() => setShowCountrySelect(false)}>
                <ArrowLeft />
            </span>
            <h1>Select Country</h1>
            <ul>
                <li onClick={() => setSelectedCountry('US')} className={cx(styles.option, selectedCountry === 'US' && styles.optionSelected)}><p className={styles.countrySelectPara}><img alt='' className={styles.countrySelectImgAlt} src={usa}/>USA</p>{selectedCountry === 'US' && <CheckCircle />}</li>
                <li onClick={() => setSelectedCountry('GB')} className={cx(styles.option, selectedCountry === 'GB' && styles.optionSelected)}><p className={styles.countrySelectPara}><img alt='' className={styles.countrySelectImgAlt} src={uk}/>United Kingdom</p>{selectedCountry === 'GB' && <CheckCircle />}</li>
                <li onClick={() => setSelectedCountry('OTHER')} className={cx(styles.option, selectedCountry === 'OTHER' && styles.optionSelected)}><p className={styles.countrySelectPara}><img alt='' className={styles.countrySelectImgAlt} src={row}/>Other Country</p>{selectedCountry === 'OTHER' && <CheckCircle />}</li>
            </ul>
            <Button onClick={() => {ls('country', selectedCountry);window.location.replace(window.location.pathname);}}>Set My Country</Button>
        </div>
    )
}

export default CountrySelect