import { gql } from 'apollo-boost';


export const ADD_INTEREST = gql`
mutation addInterest(
    $pupilsCount: Int!,
    $schoolName: String!,
    $schoolLocation: String!,
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $roleId: Int!,
    $schoolId: ID,
    $additionalRequests: String,
    $source: Int!){
    addInterest(
        pupilsCount: $pupilsCount,
        schoolName: $schoolName,
        schoolLocation: $schoolLocation,
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        roleId: $roleId,
        schoolId: $schoolId,
        additionalRequests: $additionalRequests,
        source: $source) {
        errors
        interest {
            id
            firstName
            lastName
            email
            roleId
            source
        }
    }
}`;

