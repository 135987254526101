
import { gql } from 'apollo-boost';
import { schoolQuery } from '../../../services/constants';

export const GET_MATH_SUBSCRIPTION = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        ${schoolQuery}
        boomMathSubscription {
            id
            appName
            expiresAt
            status
            isTrial
            boomMathTrialStartedAt
            boomMathPaymentStartedAt
            subscribedSeats
            subscriptionCancelledAt
            nextPaymentDate
            payments {
                id
                status
                amount
                receiptUrl
                createdAt
            }
        }
    }
}
`;

export const CREATE_STRIPE_CUSTOMER_AND_INTENT = gql`
mutation createStripeCustomerAndIntent($schoolId: ID!, $inputData: Int!) {
    createStripeCustomerAndIntent(schoolId: $schoolId, inputData: $inputData) {
        errors
        customerSecret
    }
}
`;

export const START_MATH_ACCESS = gql`
mutation startBoomMathAccess($schoolId: ID!, $studentsCounter: Int!, $stripeSubscriptionId: String!) {
    startBoomMathAccess(schoolId: $schoolId, studentsCounter: $studentsCounter, stripeSubscriptionId: $stripeSubscriptionId) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

export const START_MATH_STRIPE_SUBSCRIPTION = gql`
mutation startBoomMathStripeSubscription($schoolId: ID!, $studentsCounter: Int!, $inputData: Int!) {
    startBoomMathStripeSubscription(schoolId: $schoolId, studentsCounter: $studentsCounter, inputData: $inputData) {
        errors
        subscriptionId
        clientSecret
    }
}
`;

export const ADD_BOOM_MATH_ACCESS = gql`
mutation addBoomMathAccess($schoolId: ID!, $studentsCounter: Int!, $stripePmId: String!) {
    addBoomMathAccess(schoolId: $schoolId, studentsCounter: $studentsCounter, stripePmId: $stripePmId) {
        errors
        school {
         ${schoolQuery}
        }
    }
}
`;

export const ADD_STRIPE_PAYMENT_METHOD = gql`
mutation addStripePaymentMethod($schoolId: ID!, $stripePmId: String!) {
    addStripePaymentMethod(schoolId: $schoolId, stripePmId: $stripePmId) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

//Only for invoice purchase (not used currently: Mar, 2023)
export const PURCHASE_BOOM_MATH_ACCESS = gql`
mutation purchaseBoomMathAccess($schoolId: ID!, $studentsCounter: Int!, $contactPerson: String!, $contactEmail: String!, $contactPhone: String!, $contactIsFinancialManager: Boolean!, $amount: Float!, $purchaseOrderReferenceNumber: String) {
    purchaseBoomMathAccess(schoolId: $schoolId, studentsCounter: $studentsCounter, contactPerson: $contactPerson, contactEmail: $contactEmail, contactPhone: $contactPhone, contactIsFinancialManager: $contactIsFinancialManager, amount: $amount, purchaseOrderReferenceNumber: $purchaseOrderReferenceNumber ) {
        errors
        school {
         ${schoolQuery}
        }
    }
}
`;

export const EDIT_BILLING_PROFILE = gql`
mutation editSchoolBillingProfile($schoolId: ID!, $companyName: String, $address: String!, $address2: String, $city:String!, $state: String, $country: String!  $zip: String!, $contactPhone: String!, $contactPerson: String!, $contactEmail: String!) {
    editSchoolBillingProfile(schoolId: $schoolId, companyName: $companyName, address: $address, address2: $address2, city: $city, state: $state, country: $country, zip: $zip, contactPhone: $contactPhone, contactPerson: $contactPerson, contactEmail: $contactEmail ) {
        errors
        school {
         ${schoolQuery}
        }
    }
}
`;

export const CANCEL_BOOM_MATH_ACCESS = gql`
mutation cancelBoomMathAccess($schoolId: ID!) {
    cancelBoomMathAccess(schoolId: $schoolId) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

//start trial in onboarding without card
export const ADD_BOOM_MATH_TRIAL_ACCESS = gql`
mutation addBoomMathTrialAccess($schoolId: ID!) {
    addBoomMathTrialAccess(schoolId: $schoolId) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;