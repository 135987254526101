import React, { useEffect, useState } from 'react';
import { ArrowRightCircle, ChevronLeft, ChevronRight } from 'react-feather';
import cx from 'classnames';
import ReactPlayer from 'react-player/youtube'

import styles from './ModuleTour.module.scss';

const ModuleTour = ({ onFinishTour, slideData }) => {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        if (slide === slideData.length - 1) {
            onFinishTour(true);
        } else {
            onFinishTour(false)
        }
    }, [slide, slideData, onFinishTour])

    return (
        <div className={styles.moduleTour}>
            <div className={styles.moduleTourContents}>
                <button 
                    onClick={() => setSlide(slide - 1)}
                    className={cx(styles.moduleTourSlideButton, {[styles.moduleTourSlideButtonHidden]: slide === 0})}
                >
                    <ChevronLeft size={36} />
                </button>
                <div className={styles.moduleTourSlideContainer}>
                    <p className={styles.moduleTourSlideText}>{slideData[slide].text}</p>
                    {slideData[slide].youtubeUrl && (
                        <div className={styles.moduleTourSlideVideo}>
                            <ReactPlayer  controls={true} url={slideData[slide].youtubeUrl} />
                        </div>
                    )}
                    {slideData[slide].link && (
                        <a className={styles.moduleTourSlideLink} href={slideData[slide].link}>{slideData[slide].linkText || slideData[slide].link} <ArrowRightCircle className={styles.moduleTourSlideLinkIcon} /></a>
                    )}
                </div>
                <button 
                    onClick={() => setSlide(slide + 1)}
                    className={cx(styles.moduleTourSlideButton, {[styles.moduleTourSlideButtonHidden]: slide === slideData.length - 1})}
                >
                    <ChevronRight size={36} />
                </button>
            </div>
            <div className={styles.moduleTourProgress}>
                {
                    slideData.map((_, i) => {
                        return (
                            <div key={`progress-${i}`} className={cx(styles.moduleTourProgressMarker, {[styles.moduleTourProgressMarkerCurrent]: slide === i})} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ModuleTour;