import React, { useState } from "react";
import SideModal from "../../../components/SideModal";
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { getRoleLabel, usaOnlyRoles, ukOnlyRoles } from '../../../services/utils';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { useTranslation } from 'react-i18next';
import { APPROVE_TEACHER_JOIN_REQUEST, GET_OTHER_TEACHERS } from "../services/graphql";
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GET_TEACHERS_QUERY } from '../../../services/school/graphql';
import styles from '../Teachers.module.scss'
import {ArrowRight} from 'react-feather'
import ls from 'local-storage'
const JoinRequestsList = ({ schoolId, schoolCode, requests }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [editRequest, setEditRequest] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        roleId: Yup.string().required(t('common:please_select_role')),
    });

    const { register, handleSubmit, errors, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });

    const [approveTeacherJoinRequest, { loading }] = useMutation(APPROVE_TEACHER_JOIN_REQUEST, {
        update: (_, { data }) => {
            if (data.approveTeacherJoinRequest.errors && data.approveTeacherJoinRequest.errors.length !== 0) {
                setFormErrors(data.approveTeacherJoinRequest.errors);
                return;
            }
            if (data.approveTeacherJoinRequest.teacher && data.approveTeacherJoinRequest.teacher.id) {
                setEditRequest(null);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GET_OTHER_TEACHERS, variables: { schoolId } },
            { query: GET_TEACHERS_QUERY, variables: { schoolCode, includeInvited: true } }
        ]
    });

    const onSubmit = values => {
        const { roleId, isSchoolAdmin } = values;
        approveTeacherJoinRequest({
            variables: {
                joinRequestId: editRequest.id,
                roleId: +roleId,
                isSchoolAdmin,
                approved: true
            }
        });
    }

    const cancelJoinRequest = () => {
        const values = getValues();
        approveTeacherJoinRequest({
            variables: {
                joinRequestId: editRequest.id,
                roleId: +(values.roleId),
                isSchoolAdmin: values.isSchoolAdmin,
                approved: false
            }
        });
    }

    return (
        <>
            <div className="u-display-flex u-align-center u-justify-between">
                <h3>Requests</h3>
            </div>
            <hr className='separator' />

            <ul className='list-group'>
                {requests.map(r => (
                    <li key={`t-${r.id}`}>
                        <button onClick={() => setEditRequest(r)} className={styles.requestItem}>
                            <div>
                                <p className="lead">{r.teacher.name}</p>
                                <p>{r.teacher.email}</p>
                            </div>
                            <ArrowRight />
                        </button>
                    </li>
                ))}
            </ul>

            {editRequest && (
                <SideModal closeModal={() => setEditRequest(null)}>
                    <div className="row">
                        <div className="col-lg-8">
                            <h2>{editRequest.teacher.name}</h2>
                            <p className="heavy">Personal info</p>
                            <p>{editRequest.teacher.name}</p>
                            <p>{editRequest.teacher.displayName}</p>
                            <p className="u-m-base-3">{editRequest.teacher.email}</p>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <p className='heavy u-m-base-1'>Administrator rights</p>
                                <div className='u-display-flex u-align-center u-m-base-2'>
                                    <div className="basic-form__group basic-form__group--check">
                                        <input
                                            type="checkbox"
                                            ref={register}
                                            className="switchToggle"
                                            name="isSchoolAdmin"
                                            id="isSchoolAdmin"
                                        />
                                        <label className="switchLabel" htmlFor="isSchoolAdmin">toggle</label>
                                    </div>
                                    <p className='u-m-left-3 u-p-right-3'>{t('admin_access_info')}</p>
                                </div>
                                <p className='heavy u-m-base-1'>School Role</p>

                                <div className="basic-form__group basic-form__group--check">
                                    <select defaultValue={editRequest.roleId} name="roleId" ref={register} className="basic-form__text-select">
                                        <option value=''>{t('please_select')}</option>
                                        {ls('country') === 'US' ?
                                            Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                                return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                            })
                                        :
                                            Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                                return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}

                                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(formError => t(formError))} />}
                                <div className="u-display-flex u-align-center u-m-top-3">
                                    <Button type="submit" className="u-m-right-1" primary>{loading ? t('common:saving') : 'Approve'}</Button>
                                    <Button outline type='button' onClick={() => cancelJoinRequest()}>Decline</Button>
                                </div>
                            </form>

                        </div>
                    </div>

                </SideModal>
            )}

        </>
    )
}

export default JoinRequestsList;