import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import { AlertCircle, ArrowRight } from 'react-feather';

import styles from './SetupPrompts.module.scss';

const UpdatePrompts = ({ school }) => {
    return (
        <Fragment>
            {
                school.teachers.length <= 1 && (
                    <Link to='/teachers' className={styles.setupPrompt}>
                        <AlertCircle />
                        <div className={styles.setupPromptText}>
                            <p className={styles.setupPromptTitle}>Invite Staff & Colleagues<ArrowRight className={styles.setupPromptArrow}/></p>
                            <p className={styles.setupPromptSubTitle}>Invite your teaching staff and others.</p>
                        </div>
                    </Link>
                )
            }
            {
                school.classes.length === 0 && (
                    <Link to='/classes' className={styles.setupPrompt}>
                        <AlertCircle />
                        <div className={styles.setupPromptText}>
                            <p className={styles.setupPromptTitle}>Create Your Classes<ArrowRight className={styles.setupPromptArrow}/></p>
                            <p className={styles.setupPromptSubTitle}>Create the classes that should use BoomApps.</p>
                        </div>
                    </Link>
                )
            }
        </Fragment>
    )
}

export default UpdatePrompts;
