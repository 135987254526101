import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useMutation } from "@apollo/react-hooks";
import { TEACHER_REQUEST_TO_JOIN, CHECK_JOIN_REQUESTS } from '../../services/graphql';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import ImageFadeIn from 'react-image-fade-in';
import SearchSchool from "./SearchSchool";
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ls from 'local-storage'

import styles from '../../Onboarding.module.scss';
import { getRoleLabel, ukOnlyRoles, usaOnlyRoles } from '../../../../services/utils';


const JoinSchoolForm = ({ close }) => {


    const [school, setSchool] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        roleId: Yup.string().required('Please select role')
    })
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });


    const [teacherRequestToJoinSchool, { loading }] = useMutation(TEACHER_REQUEST_TO_JOIN, {
        update: (_, { data }) => {
            if (data?.teacherRequestToJoinSchool?.errors && data?.teacherRequestToJoinSchool?.errors.length > 0) {
                setFormErrors(data.teacherRequestToJoinSchool.errors);
                return
            }
            if (data.teacherRequestToJoinSchool.teacher && data.teacherRequestToJoinSchool.teacher.id) {
                close();
            }
        },
        refetchQueries: [{ query: CHECK_JOIN_REQUESTS }]
    })

    const onSubmit = values => {
        const { roleId } = values;
        teacherRequestToJoinSchool({
            variables: {
                roleId: +roleId,
                schoolCode: school.schoolCode
            }
        });
    }



    return (
        <>
            {!school ?
                <SearchSchool setSchool={setSchool} close={close} />
                :
                <div className={styles.onboardingJoin}>
                    <div className={styles.schoolHeader}>
                        {school.avatarUrl && (
                            <div className={styles.schoolHeaderImg}>
                                <ImageFadeIn src={school.avatarUrl} alt='school logo' />
                            </div>
                        )}
                        <div>
                            <h2 className="u-m-base-0">Join: {school.name}</h2>
                            <p className="u-m-base-0">{school.address}, {school.city}, {school.zip}</p>
                        </div>
                    </div>
                    <p>What is your role at this school?</p>
                    <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                        <div className="basic-form__group">
                            <select id="roleId" name="roleId" ref={register} className="basic-form__text-select">
                                <option value=''>Please select</option>
                                {ls('country') === 'US' ?
                                    Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                        return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                    })
                                    :
                                    Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                        return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                    })
                                }
                            </select>
                        </div>
                        {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}
                        {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                        <div className="u-display-flex u-align-center u-m-top-3">
                            <Button disabled={loading} type="submit" primary className="u-m-right-1">{loading ? 'Saving' : 'Request Access'}</Button>
                        </div>
                        <button onClick={() => setSchool(null)} className="u-m-top-2 btn-reset link-underline u-text-primary">This isn't my school</button>
                    </form>
                </div>
            }
        </>
    )
}

export default JoinSchoolForm;