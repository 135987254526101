import React, { Fragment, useState } from 'react';
import { Loader } from '@axeedge/go-teacher-components';
import { useQuery } from '@apollo/react-hooks';

import SelectPacks from './components/SelectPacks';
import SuccessPanel from '../../../../components/Purchase/SuccessPanel'

import { GET_PACK_OFFERS } from '../../services/graphql';

const PurchasePack = () => {

    const [purchaseDone, setPurchaseDone] = useState(false);

    const {data, loading, error} = useQuery(GET_PACK_OFFERS);

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.packOffers) {
        return(
            <Fragment>
                {
                    !purchaseDone ? (
                        <SelectPacks packOffersData={data.packOffers} setPurchaseDone={setPurchaseDone} />
                    ) : <SuccessPanel mainText='Your packs are ready for you to use.' linkText='Manage my Book Packs' />
                }
            </Fragment>
        )
    }

    return null
}

export default PurchasePack;