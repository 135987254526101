import React, {useState} from 'react'
import styles from '../NewAcademicYear/NewAcademicYear.module.scss';

import {ArrowLeft, ArrowLeftCircle} from 'react-feather'
import { Link, navigate } from '@reach/router';
import { useQuery, useMutation} from '@apollo/react-hooks';
import { Loader, Button } from '@axeedge/go-teacher-components';
import { GET_CLASSES_QUERY } from '../NewAcademicYear/services/graphql';
import { CLASS_TYPE } from '../../../../services/constants';
import NavBar from '../../components/NewAcademicYearNav'

const GetReady = () => {

    const [regClasses, setRegClasses] = useState("")
    const [amountInput, setAmountInput] = useState(null)

    const { data, error, loading } = useQuery(GET_CLASSES_QUERY, {
        variables: {
            includeAdminedClasses: true
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setRegClasses(data.myClasses.filter(c => c.typeId === CLASS_TYPE.registration && c.academicYear <= 2022))
        }
    });
    
    return (
        <>
            <div className={styles.pageHeader}>
                <div>
                    <Link to="/classes/" className={styles.backButton}>
                        <ArrowLeft size="30"/>
                    </Link>
                </div>
                <h1 className={styles.pageHeading}>Start Academic Year 2022/2023</h1>
            </div>
            <NavBar step={1} />
            <div className={styles.maxTextWidth}>
                <h1 className="u-bold">Follow these simple steps</h1>
                <p>Please take a minute to walk through this simple guide.</p>
                <p className="u-m-base-2 u-bold u-italic">It is important you follow these simple steps in order.</p>
                <h2 className="u-bold">1: Registration Classes</h2>
                <p className="u-m-base-2">We'll show you a list of registration classes. One at a time you'll be able to create the new academic year registration classes and move your pupils. You'll also be able to remove pupils that are leaving your school. We'll auto-archive as you complete each class.</p>
                <h2 className="u-bold">2: Other Groups</h2>
                <p className="u-m-base-2">For any other classes or groups (such as phonics or gifted readers) you have set up you'll be able to archive them or set-up and move the pupils into their new groups</p>
                <h2 className="u-bold">3: New Intakes</h2>
                <p>You'll then be prompted to create your new intake registration classes and create/upload the pupil accounts as normal.</p>
            </div>
            <h2 className="u-bold u-m-top-4">Ready?</h2>
            <div className={styles.regClassCheck}>
                <div className="u-m-right-4">
                    <div className={styles.regClassSideCheck}>
                        <p>We found:</p>
                        <h1 className="u-bold">{regClasses.length}</h1>
                        <p>21/22 Registration Classes</p>
                    </div>
                </div>
                <div>
                    <h2 className="u-bold">Confirm 2021/2022 Registration Classes</h2>
                    <p>First lets make sure you have correctly identified all your registration classes</p>
                    <p className="u-bold">Some Missing?</p>
                    <Link to="/classes/" className={styles.backLink}>
                        <div>
                            <ArrowLeftCircle size="34" className={styles.backLinkIcon}/>
                        </div>
                        <div>
                            <p className={styles.backLinkText}>Go back to Manage Classes to identify your Registration Classes</p>
                        </div>
                    </Link>
                    <h3 className="u-bold">Looks good?</h3>
                    <p>Please confirm the current number of Registration Classes that you should have in GoApps</p>
                    <input className={styles.regCount} value={amountInput} onChange={(e) => setAmountInput(e.target.value)} type="text" />
                    <Button disabled={parseInt(amountInput) !== regClasses.length} onClick={() => navigate('/classes/moveClasses')}>Get Started</Button>
                </div>
            </div>
        </>
    )
}

export default GetReady