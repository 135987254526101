import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, FormErrors, Modal } from "@axeedge/go-teacher-components";
import { WONDE_SYNC_STUDENTS } from "../../services/graphql";
import { AlertCircle } from "react-feather";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import PageHeader from "../../../../components/PageHeader";
import ManualStudentItem from "./ManualStudentItem";

import styles from '../../WondeSync.module.scss';

const ManualMatchContent = ({ school, manualStudents, setCurrentStep }) => {

    const [students, setStudents] = useState(manualStudents)
    const [formErrors, setFormErrors] = useState([]);
    const [stMatchError, setStMatchError] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(null);
    const [confirmMatch, setConfirmMatch] = useState(null);


    const [wondeSyncStudents, { loading }] = useMutation(WONDE_SYNC_STUDENTS, {
        update: (_, { data }) => {
            if (data && data.wondeSyncStudents.errors && data.wondeSyncStudents.errors.length > 0) {
                setFormErrors(data.wondeSyncStudents.errors);
                return;
            }
            if (data && data.wondeSyncStudents?.students) {
                if (confirmDelete) {
                    const updatedStudents = students.filter(el => el.id !== confirmDelete.id)
                    setStudents(updatedStudents);
                }

                if (confirmMatch) {
                    const updatedStudents = students.filter(el => el.id !== confirmMatch.id)
                    setStudents(updatedStudents);
                }
                setConfirmDelete(null);
                setConfirmMatch(null);
                setFormErrors([]);
            }
        }
    });

    useEffect(() => {
        if (students.length === 0) {
            setCurrentStep(WONDE_SYNC_STEPS.syncClasses);
        }
    }, [students])

    const finalizeManualSync = () => {
        if (students.find(el => !el.wondeStudentId)) {
            setStMatchError(true);
            return
        }

        if (students.length === 0) {
            setCurrentStep(WONDE_SYNC_STEPS.syncClasses);
        }
        // const studentDetails = [];
        // students.forEach(st => {
        //     studentDetails.push({
        //         wondeStudentId: st.wondeStudentId,
        //         studentId: st.id
        //     })
        // })

        // wondeSyncStudents({
        //     variables: {
        //         schoolId: school.id,
        //         wondeStudentDetails: studentDetails
        //     }
        // });
    }

    const onDelete = (data) => {
        wondeSyncStudents({
            variables: {
                schoolId: school.id,
                wondeStudentDetails: [
                    {
                        wondeStudentId: "-1",
                        studentId: data.id
                    }
                ]
            }
        });

    }

    const onMatch = data => {
        wondeSyncStudents({
            variables: {
                schoolId: school.id,
                wondeStudentDetails: [
                    {
                        wondeStudentId: data.wondeStudentId,
                        studentId: data.id
                    }
                ]
            }
        });
    }

    return (
        <><div className={styles.syncMain}>
            <PageHeader showBackLink={false} title={'MIS Update: Existing Students - 3/3 Manual Match'} />

            <p className="u-m-base-2">Please manually match or remove these students:</p>
            <div className="u-flex-center u-m-base-3">
                <div className="u-m-right-3 u-flex-center u-text-danger">
                    <AlertCircle size={18} className="u-m-right-1" /> Not Matched: {students.length}
                </div>
            </div>

            {stMatchError && <p className="u-text-danger u-m-top-2">Please update all non matched accounts!</p>}
            {formErrors && <FormErrors errors={formErrors} />}
            <div className={styles.manualMatch}>
                <div>
                    <p>Currently in <b>BoomHub</b>:</p>
                    <div className={styles.manualMatchBoom}>
                        <div>Name</div>
                        <div className="u-text-right">Year</div>
                    </div>
                </div>
                <div>
                    <p>Action from your <b>MIS</b>:</p>
                    <div className={styles.manualMatchWonde}>
                        <div>Find or remove the student</div>
                    </div>
                </div>
            </div>

            <ul className="u-m-base-3">
                {students.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(student =>
                    <ManualStudentItem
                        key={`manual-${student.id}`}
                        student={student}
                        school={school}
                        setConfirmDelete={setConfirmDelete}
                        setConfirmMatch={setConfirmMatch}
                    />
                )}
            </ul>
        </div>
            <div className={styles.syncFooter}>
                <Button disabled={loading} className='u-m-left-auto' onClick={() => finalizeManualSync()}>{loading ? 'Saving...' : 'Confirm and continue'}</Button>
            </div>

            {confirmDelete && <Modal closeModal={() => setConfirmDelete(null)}>
                <div className="u-text-center">
                    <h3 className='u-m-base-3'>Please confirm you want to delete/archive {confirmDelete.name} </h3>
                    <Button disabled={loading} onClick={() => onDelete(confirmDelete)}>{loading ? 'Saving...' : 'Yes'}</Button>
                    <Button className='u-m-left-2' outline onClick={() => setConfirmDelete(null)}>No</Button>
                    {formErrors && <FormErrors errors={formErrors} />}

                </div>
            </Modal>
            }

            {confirmMatch && <Modal closeModal={() => setConfirmMatch(null)}>
                <div className="u-text-center">
                    <h3 className='u-m-base-2'> Confirm Match for <b className="heavy">{confirmMatch.name}</b> to:</h3>
                    {confirmMatch.matchTo && <h3 className='u-m-base-3'> <b className="heavy">{confirmMatch.matchTo.firstName} {confirmMatch.matchTo.lastName}</b> - UPN: {confirmMatch.matchTo.wondeUpn}, Year group {confirmMatch.matchTo.wondeYearGroup}</h3>}
                    <Button disabled={loading} onClick={() => onMatch(confirmMatch)}>{loading ? 'Saving...' : 'Yes'}</Button>
                    <Button className='u-m-left-2' outline onClick={() => setConfirmMatch(null)}>No</Button>
                    {formErrors && <FormErrors errors={formErrors} />}

                </div>
            </Modal>
            }
        </>
    )
}

export default ManualMatchContent;