import React, { Fragment, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PlusCircle, CheckCircle } from 'react-feather';
import { Loader, Button } from '@axeedge/go-teacher-components';
import { GET_TEACHERS_QUERY } from '../../../../services/school/graphql';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from '../../Classes.module.scss';

const SelectClassTeachers = ({ closeTeachers, currentTeachers, setCurrentTeachers }) => {

    const { t } = useTranslation(['classes', 'common']);

    const { currentUser: user } = useContext(AuthContext);

    const { data, error, loading } = useQuery(GET_TEACHERS_QUERY, {
        variables: {
            schoolCode: user.school.schoolCode
        }
    });


    const isClassTeacher = teacher => {
        return currentTeachers.find(t => { return t.id === teacher.id });
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.teachersBySchoolCode) {
        return (
            <Fragment>
                <h2 className={styles.selectTeachersHeader}>Select Teachers
                    {currentTeachers.length > 0 && (
                        <Button className='btn-small u-m-left-2' onClick={() => closeTeachers()}>Done</Button>

                    )}
                </h2>
                {
                    data.teachersBySchoolCode.length > 0 ? (
                        <ul className='list-group'>
                            {
                                data.teachersBySchoolCode.map((teacher) => {
                                    return (
                                        <li
                                            key={`t-${teacher.id}`}
                                            className={cx('list-item', { ['heavy']: isClassTeacher(teacher) })}
                                        >
                                            <span className='list-item--child'>{teacher.displayName}</span>
                                            <div className='u-m-left-auto list-item--child u-p-right-2'>
                                                <button
                                                    type='button'
                                                    className='btn-reset link-reverse'
                                                    onClick={() => {
                                                        isClassTeacher(teacher) ? setCurrentTeachers(currentTeachers.filter(t => t.id !== teacher.id)) : setCurrentTeachers([...currentTeachers, teacher]);
                                                    }}
                                                >{isClassTeacher(teacher) ? <CheckCircle className='u-text-primary' /> : <PlusCircle />}</button>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    ) : <p>{t('common:no_teachers')}</p>
                }
            </Fragment>
        )
    }

    return null
}

export default SelectClassTeachers;