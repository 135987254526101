import React from 'react';
import { CURRENT_PROMOS_QUERY } from './services/graphql';
import ImageFadeIn from 'react-image-fade-in';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { useQuery } from '@apollo/react-hooks';
import leaversLabel from '../../images/dashboard/leavers-label.png'

import { APP_NAME, APP_NAME_US } from '../../services/constants';

import styles from './Dashboard.module.scss';
import ls from 'local-storage';

const PromoBanners = () => {

    const token = ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));

    const { data } = useQuery(CURRENT_PROMOS_QUERY);


    if (data && data.currentPromos) {
        return (
            <>
                {data.currentPromos.map((promo) => {
                    return (
                        <div key={promo.id} className={styles.appBannerLeavers}>
                            <div className={styles.appBannerLeaversMain}>
                                <h2 className='u-m-base-2 u-m-top-2'>Class of 2023 leavers book</h2>
                                <a className={styles.appBannerLeaversBtn} href={`${getUrl(APPS.go_write_teacher)}${ls('go_impersonating') ? `/promo/${promo.id}/impersonate` : `/promo/${promo.id}`}?token=${token}`}>
                                    Start your leavers book
                                </a>
                                <p className='small u-m-top-2 u-m-base-2'>Launches 1 June - Pre-order your book packs<br /> before May 31 for an early bird discount</p>
                                <ImageFadeIn src={leaversLabel} className={styles.appBannerLeaversImg} />
                            </div>
                        </div>
                    );
                })}
            </>
        )
    }
    return null;
}

export default PromoBanners;