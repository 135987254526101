import React, { Fragment, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from '../../../../../services/auth/AuthProvider';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';

import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import { getYearGroupLabel } from '../../../../../services/utils';
import ListItem from '../../../../../components/ListItem';
import EditStudent from './EditStudent';
import MoveToClass from './MoveToClass';
import LoginCode from './LoginCode';
import { REMOVE_STUDENT_FROM_SCHOOL, REMOVE_STUDENT_FROM_CLASS } from '../services/graphql';
import { GET_CLASS_QUERY, GET_UNASSIGNED_STUDENTS_QUERY } from '../../../services/graphql';
import { useTranslation } from 'react-i18next';


const StudentsList = ({ classId, students, schoolId }) => {

    const { t } = useTranslation(['classes', 'common']);
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;

    const setDeleteType = () => {
        if (!teacher.isSchoolAdmin) {
            return 'class'
        }
        if (!classId) {
            return 'school'
        }
        return null
    };

    const showDeleteLink = !teacher.school?.wondeAccessApprovedAt && (teacher.isSchoolAdmin || classId);

    const showYearGroupWarning = student => {
        return student.yearGroup && !YEAR_GROUPS.find(yg => yg.val === student.yearGroup) && !teacher.school?.wondeAccessApprovedAt
    };

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }


    const [showEditStudentModal, setShowEditStudentModal] = useState(false);
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState({
        student: null,
        deleteType: setDeleteType(),
    });
    const [showMove, setShowMove] = useState(false);

    const [fromEditModal, setFromEditModal] = useState(false);
    const [enableDelete, setEnableDelete] = useState('');

    const [formErrors, setFormErrors] = useState([]);

    const studentQueries = () => {
        if (classId) {
            return [{
                query: GET_CLASS_QUERY,
                variables: {
                    id: classId
                }
            }]
        } else {
            return [{
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: schoolId
                }
            }]
        }
    }

    const [removeStudentFromClass] = useMutation(REMOVE_STUDENT_FROM_CLASS, {
        update: (_, { data }) => {
            if (data.removeStudentFromClass.errors && data.removeStudentFromClass.errors.length) {
                setFormErrors(data.removeStudentFromClass.errors);
                return;
            }
            if (data.removeStudentFromClass.deleted) {
                setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: null })
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId } }],
        awaitRefetchQueries: true
    });

    const [removeStudentFromSchool] = useMutation(REMOVE_STUDENT_FROM_SCHOOL, {
        update: (_, { data }) => {
            if (data.removeStudentFromSchool.errors && data.removeStudentFromSchool.errors.length) {
                setFormErrors(data.removeStudentFromSchool.errors);
                return;
            }
            if (data.removeStudentFromSchool.deleted) {
                setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: null })
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => studentQueries()

    });

    const onDeleteStudent = data => {
        if (data.deleteType === 'class') {
            removeStudentFromClass({
                variables: {
                    studentId: data.student.id,
                    studentsClassId: classId
                }
            })
        } else if (data.deleteType === 'school') {
            removeStudentFromSchool({
                variables: {
                    studentId: data.student.id,
                    schoolId: schoolId
                }
            })
        }
    }

    return (
        <div className='hidden-print'>
            {
                students.length === 0 ?
                    <p className='u-m-base-2'>{t('add_first_pupil_for_class')}:</p> :
                    students.map(student =>
                        <ListItem
                            key={`student-${student.id}`}
                            avatarJson={student.avatarJson}
                            boomerAvatarUrl={student.boomerAvatarUrl}
                            updatedAt={student.updatedAt}
                            mainText={student.name}
                            onClick={() => setShowStudentModal(student)}
                            {...(showDeleteLink && { onDelete: () => setShowDeleteModal({ ...showDeleteModal, student: student }) })}
                            {...(!teacher.school?.wondeAccessApprovedAt && { onSettings: () => setShowEditStudentModal(student) })}
                            {...(showYearGroupWarning(student) && { onWarning: () => setShowEditStudentModal(student) })}
                            {...(!teacher.school?.wondeAccessApprovedAt && { onMove: () => setShowMove(student) })}
                            short
                            withCard
                            isStudent={true}
                            showTip={!teacher.school?.wondeAccessApprovedAt}
                            yearGroup={getYearGroupLabel(student.yearGroup)}
                        />
                    )
            }
            {
                showEditStudentModal && (
                    <Modal closeModal={() => {
                        setShowEditStudentModal(false);
                        setFromEditModal(false);
                    }}>
                        <EditStudent
                            setShowStudentModal={setShowStudentModal}
                            schoolId={schoolId}
                            closeModal={() => {
                                setShowEditStudentModal(false);
                                setFromEditModal(false);
                            }}
                            student={showEditStudentModal}
                            setFromEditModal={setFromEditModal}
                        />
                    </Modal>
                )
            }
            {
                showStudentModal && (
                    <Modal closeModal={() => setShowStudentModal(false)}>
                        <LoginCode
                            student={showStudentModal}
                            closeModal={() => setShowStudentModal(false)}
                            setShowEditStudentModal={setShowEditStudentModal}
                            fromEditModal={fromEditModal}
                        />
                    </Modal>
                )
            }

            {
                showDeleteModal.student && (
                    <Modal closeModal={() => setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: setDeleteType() })}>
                        <div className='u-text-center'>
                            {
                                !showDeleteModal.deleteType ? (
                                    <Fragment>
                                        <h3 className='u-m-base-2'>{t('delete_student', { studentName: showDeleteModal.student.name })} </h3>
                                        <p className='u-m-base-2'>{t('please_choose_delete_option')}:</p>
                                        <Button className='btn-small u-m-right-1' outline onClick={() => setShowDeleteModal({ ...showDeleteModal, deleteType: 'class' })}>{t('remove_from_class')}</Button>
                                        <Button className='btn-small' outline onClick={() => setShowDeleteModal({ ...showDeleteModal, deleteType: 'school' })}>{t('delete_from_school')}</Button>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {
                                            showDeleteModal.deleteType === 'school' ? (
                                                <Fragment>
                                                    <h3 className='u-m-base-3'>{t('delete_student_from_this_school', { studentName: showDeleteModal.student.name })}</h3>
                                                    <h3>{t('common:are_you_sure')}</h3>
                                                    <p className='u-m-base-2'>{t('this_will_remove_pupil_and_delete_all_data')}</p>
                                                    <p className='u-m-base-1'>{t('please_type_delete_to_confirm')}:</p>
                                                    <input
                                                        className='basic-form__text-box u-m-base-2'
                                                        type="text"
                                                        value={enableDelete}
                                                        onChange={(e) => setEnableDelete(e.target.value)}
                                                    />
                                                    <p className='u-m-base-1'><Button onClick={() => onDeleteStudent(showDeleteModal)} disabled={enableDelete !== 'DELETE'}>{t('i_understand_delete')}</Button></p>
                                                    <Button outline onClick={() => setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: setDeleteType() })}>{t('common:button.cancel')}</Button>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <h3>{t('remove_student', { studentName: showDeleteModal.student.name })}  </h3>
                                                    <p className='u-m-base-2'>{t('remove_student_only_from_this_class', { studentName: showDeleteModal.student.name })}</p>
                                                    <Button onClick={() => onDeleteStudent(showDeleteModal)} className='u-m-right-2'>{t('common:button.remove')}</Button>
                                                    <Button outline onClick={() => setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: setDeleteType() })}>{t('common:button.cancel')}</Button>
                                                </Fragment>
                                            )
                                        }


                                    </Fragment>
                                )
                            }
                        </div>
                    </Modal>
                )
            }

            {showMove && (
                <Modal closeModal={() => setShowMove(false)}>
                    <MoveToClass
                        schoolId={schoolId}
                        student={showMove}
                        closeMove={() => setShowMove(null)}
                        classId={classId}
                    />
                </Modal>
            )}

            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
        </div>
    )
}

export default StudentsList;
