import React, { useState } from 'react';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { useMutation } from '@apollo/react-hooks';
import { MANAGE_IMPORT_STUDENTS } from '../../services/graphql';
import ls from 'local-storage';
import cx from 'classnames';
import styles from './UploadStudentsList.module.scss';

const ImportIntoClassErrors = ({ close, ticketId, notValidStudents, includeDuplicates, setIncludeDuplicates, setReviewFinalStudents }) => {

    const isUS = ls('country') === 'US';

    const notValidExceptDuplicates = notValidStudents.filter(s => s.missingFirstName || s.missingLastName || s.missingYearGroup || s.duplicatedUpn);
    const duplicates = notValidStudents.filter(s => s.duplicated || s.duplicatedInFile)
    
    const [manageErrors, setManageErrors] = useState([]);

    const onSelectStudent = e => {
        if (includeDuplicates.indexOf(e.target.value) !== -1) {
            setIncludeDuplicates(includeDuplicates.filter(t => t !== e.target.value));
        } else {
            setIncludeDuplicates([...includeDuplicates, e.target.value]);
        }
    }

    //send ids for students to be ignored from import ;
    const [manageImport, { loading }] = useMutation(MANAGE_IMPORT_STUDENTS, {
        update: (_, { data }) => {
            if (data.manageImportStudentsFromXlsx.errors && data.manageImportStudentsFromXlsx.errors.length !== 0) {
                setManageErrors(data.manageImportStudentsFromXlsx.errors);
                return;
            }
            if (data.manageImportStudentsFromXlsx.importTicket.errors && data.manageImportStudentsFromXlsx.errors.length !== 0) {
                setManageErrors(data.manageImportStudentsFromXlsx.errors);
                return;
            }
            if (data.manageImportStudentsFromXlsx.importTicket && data.manageImportStudentsFromXlsx.importTicket.importStudents) {
                setIncludeDuplicates([]);
                setReviewFinalStudents(data.manageImportStudentsFromXlsx.importTicket.importStudents);
            }
        },
    });


    return (
        <>
            <div className={styles.previewErrorsHeader}>
                <div>
                    <h3>Oops, we encountered a few problems…</h3>
                    {notValidExceptDuplicates.length > 0 ?
                        <p className="u-m-base-1">Sorry, we were unable to process your uploaded file. Please check your file for the following issues and try again:</p> :
                        <p className='u-m-base-2'>We've found the following students with the same names, please review to see if these are duplicates or unique students.</p>
                    }
                </div>
            </div>
            <div className={styles.previewErrors}>
                {notValidExceptDuplicates.length > 0 ? (
                    <div>
                        <p className='lead'>Missing data</p>
                        <p className='u-m-base-2'>
                            Please make sure that each student has a valid value for first name, last name, {isUS ? 'grade' : 'year group'}.
                            {!isUS && notValidExceptDuplicates.find(s => s.duplicatedUpn) && <> Please make sure the UPN is unique.</>}
                        </p>
                        <ul className={styles.previewErrorsList}>
                            {notValidExceptDuplicates.map(st => (
                                <li key={`st-${st.id}`}>
                                    <span className='u-m-right-2'>Line {st.line}:</span>
                                    <span className='u-m-right-2'> {st.missingFirstName ? 'Missing first name' : st.firstName} {st.missingLastName ? 'Missing last name' : st.lastName}</span>
                                    {st.missingYearGroup && <span className='u-m-right-1'>Missing {isUS ? 'grade' : 'year group'}</span>}
                                    {st.duplicatedUpn && <span>Duplicated UPN</span>}
                                </li>
                            ))}
                        </ul>
                        {isUS ?
                            <p className='u-m-base-3'><a href="https://goapps.s3.eu-west-2.amazonaws.com/sample-class-upload-usa.xlsx" className='u-text-primary link-underline u-m-base-2' target="_blank" rel="noopener noreferrer">Download Template</a></p> :
                            <p className='u-m-base-3'><a href="https://goapps.s3.eu-west-2.amazonaws.com/resources/sample-class-upload.xlsx" className='u-text-primary link-underline u-m-base-2' target="_blank" rel="noopener noreferrer">Download Template</a></p>
                        }
                        <Button onClick={close} className='u-m-right-2'>Start over</Button>
                    </div>
                ) : <>
                    <div>
                        <p>Check the students you wish to import</p>
                        <ul className={cx(styles.previewErrorsList, 'list-group')}>
                            {duplicates.map(st => (
                                <li className='list-item' key={`st-d-${st.id}`}>
                                    <div className="basic-form__group basic-form__group--check u-m-base-0 u-m-top-1">
                                        <input
                                            type='checkbox'
                                            className="basic-form__check-box"
                                            value={st.id}
                                            onChange={(e) => onSelectStudent(e)}
                                            id={`t-${st.id}`}
                                            checked={includeDuplicates.indexOf(st.id) > -1}
                                        />
                                        <label className="basic-form__check-label" htmlFor={`t-${st.id}`}>&nbsp;</label>
                                    </div>
                                    <span className='u-m-right-2'>Line {st.line}:</span>
                                    <span className='u-m-right-2'> {st.firstName} {st.lastName}</span>
                                    {st.duplicated && <span className='u-m-right-1'>Duplicated</span>}
                                    {st.duplicatedInFile && <span>Duplicated in File</span>}
                                </li>
                            ))}
                        </ul>
                        {manageErrors && <FormErrors errors={manageErrors} />}
                         <Button
                            className='u-m-right-2'
                            onClick={() =>
                                manageImport({
                                    variables: {
                                        importTicketId: ticketId,
                                        importedStudentIds: [...duplicates.filter(d => !includeDuplicates.includes(d.id)).map(d => d.id)]
                                    }
                                })
                            }
                            disabled={loading}
                        >Import</Button> 
                    </div>
                </>
                }
            </div>
        </>
    )
}

export default ImportIntoClassErrors;