import React from 'react';
import { Check } from 'react-feather';
import boomReaderLogo from '../../../../images/boomreader_reversed.png';
import bwLogo from '../../../../images/boomwriter_reversed.png';
// import { ACTIVE_PROMOTIONS } from '../../services/graphql';
// import { useQuery } from '@apollo/react-hooks';
// import grandTourLogo from '../../../../images/onboarding/grand-tour-logo-rev.png';

import mathLogo from '../../../../images/boom-math-reversed.png'
import ImageFadeIn from 'react-image-fade-in';
import { SUBSCRIPTION_INTENT, SCHOOL_SETUP_STEPS, SCHOOL_MATH_SETUP_STEPS } from '../../../../services/constants';
import styles from './ProgressIndicator.module.scss';
import cx from 'classnames';

const ProgressIndicator = ({ user }) => {

    //const { data, loading } = useQuery(ACTIVE_PROMOTIONS);

    return (
        <div className={cx(styles.progressWrapper, { [styles.progressWrapperRead]: user.subscriptionIntent === SUBSCRIPTION_INTENT.read }, { [styles.progressWrapperWrite]: user.subscriptionIntent === SUBSCRIPTION_INTENT.write || user.subscriptionIntent === SUBSCRIPTION_INTENT.leavers }, { [styles.progressWrapperMath]: user.subscriptionIntent === SUBSCRIPTION_INTENT.math })}>
            {user.subscriptionIntent === SUBSCRIPTION_INTENT.math ? (
                <ul className={cx(styles.progress, { [styles.progressMath]: user.subscriptionIntent === SUBSCRIPTION_INTENT.math })}>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: !user.school }, { [styles.progressIndicatorDone]: user.school })}>
                        {user.school ? <Check size='22' /> : '1'}
                    </li>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: user.school && user.school.setupStep <= SCHOOL_MATH_SETUP_STEPS.classesStepDone }, { [styles.progressIndicatorDone]: user?.school?.setupStep >= SCHOOL_MATH_SETUP_STEPS.classesStepDone })}>
                        {user?.school?.setupStep >= SCHOOL_MATH_SETUP_STEPS.classesStepDone ? <Check size='22' /> : '2'}
                    </li>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: user.school && user.school.setupStep > SCHOOL_MATH_SETUP_STEPS.classesStepDone }, { [styles.progressIndicatorDone]: user?.school?.setupStep === SCHOOL_MATH_SETUP_STEPS.staffAccessDone })}>
                        {user?.school?.setupStep >= SCHOOL_MATH_SETUP_STEPS.staffAccessDone ? <Check size='22' /> : '3'}
                    </li>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: user.school && user.school.setupStep >= SCHOOL_MATH_SETUP_STEPS.staffAccessDone })}>
                        4
                    </li>
                </ul>
            ) : (
                <ul className={styles.progress}>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: !user.school }, { [styles.progressIndicatorDone]: user.school })}>
                        {user.school ? <Check size='22' /> : '1'}
                    </li>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: user.school && user.school.setupStep <= SCHOOL_SETUP_STEPS.classesStepDone }, { [styles.progressIndicatorDone]: user?.school?.setupStep >= SCHOOL_SETUP_STEPS.classesStepDone })}>
                        {user?.school?.setupStep >= SCHOOL_SETUP_STEPS.classesStepDone ? <Check size='22' /> : '2'}
                    </li>
                    <li className={cx(styles.progressIndicator, { [styles.progressIndicatorActive]: user.school && user.school.setupStep >= SCHOOL_SETUP_STEPS.classesStepDone })}>
                        3
                    </li>
                </ul>
            )}

            <>
                {(user.subscriptionIntent === SUBSCRIPTION_INTENT.write || user.subscriptionIntent === SUBSCRIPTION_INTENT.leavers) && (
                    <div className={styles.progressImages}>
                        <ImageFadeIn className={styles.progressImagesImg} src={bwLogo} alt='' />
                    </div>
                )}

                {user.subscriptionIntent === SUBSCRIPTION_INTENT.read && (
                    <div className={styles.progressImages}>
                        <ImageFadeIn className={styles.progressImagesImg} src={boomReaderLogo} alt='' />
                        {/* {data.activePromotions.find(p => p.code === PROMO_CODES.goReadGrandTour) && (
                                <ImageFadeIn className={styles.progressImagesImg} src={grandTourLogo} alt='' />
                            )} */}
                    </div>
                )}

                {user.subscriptionIntent === SUBSCRIPTION_INTENT.math && (
                    <div className={styles.progressImages}>
                        <ImageFadeIn className={styles.progressImagesImg} src={mathLogo} alt='' />
                    </div>
                )}
            </>

        </div>
    )
}

export default ProgressIndicator;