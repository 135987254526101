import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { useMutation } from '@apollo/react-hooks';
import styles from './PurchaseActivities.module.scss';
import cx from 'classnames';
import InvoiceDetailsForm from '../../../../components/Purchase/InvoiceDetailsForm';
import {PURCHASE_ACTIVITY_ACCESS, GET_SUBS_QUERY} from '../../services/graphql';
import { navigate } from '@reach/router';

const PurchaseActivities = () => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [numStudents, setNumStudents] = useState(5);

    const [purchaseAccess, { loading }] = useMutation(PURCHASE_ACTIVITY_ACCESS, {
        update: (_, { data }) => {
            if (data.purchaseGoWriteActivitiesAccess.errors && data.purchaseGoWriteActivitiesAccess.errors.length) {
                setFormErrors(data.purchaseGoWriteActivitiesAccess.errors)
            }
            if (data.purchaseGoWriteActivitiesAccess.school.id) {
                const cu = {...currentUser, school: {...currentUser.school, subscriptions: data.purchaseGoWriteActivitiesAccess.school}}
                setCurrentUser(cu);
                navigate('/settings/boomwriter');
            }
        },
        refetchQueries: [{query: GET_SUBS_QUERY, variables: {schoolId: currentUser.school.id}}],
        awaitRefetchQueries: true
    });

    const totalAmount = () => {
        return parseFloat((numStudents * 2) + (((numStudents * 2) / 100) * 20)).toFixed(2);
    }

    const onSubmit = values => { 
        const { contactPerson, contactEmail, contactPhone, contactIsFinancialManager } = values;
        
        purchaseAccess({
            variables: {
                schoolId: currentUser.school.id,
                amount: +totalAmount(),
                studentsCounter: +numStudents,
                contactPerson,
                contactPhone,
                contactEmail,
                contactIsFinancialManager
            }
        });
    }

    return (
        <div className={styles.purchaseContainer}>
            <div className="row">
                <div className="col-lg-6 col-md-8">
                    <h1>Add BoomWriter Subscription</h1>
                    <p>We recommend this feature for KS2 pupils, but it may be suitable for older KS1 classes.</p>
                </div>
            </div>
                <div className="row u-m-top-4">
                    <div className="col-lg-6 col-md-8">
                        <h2>How many pupils?</h2>
                        <div className={styles.numStudents}>
                            <div>
                                <h3 className="u-m-base-0 u-bold">Number of pupils</h3>
                                <p>£2/pupil/year</p>
                            </div>
                            <input type="number" min="1" className={styles.studentInput} value={numStudents} onChange={(e) => setNumStudents(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-8">
                        <div className={styles.totalPanel}>
                            <h2 className="u-bold">Payment Summary</h2>
                            <div className={cx(styles.numStudents, "u-m-base-1")}>
                                <p>KS2 120 @ £2/pupil</p>
                                <p className={styles.subTotal}><span className={styles.subTotalLeft}>£</span>{(numStudents * 2).toFixed(2)}</p>
                            </div>
                            <div className={cx(styles.numStudents, "u-m-base-1")}>
                                <p>VAT @ 20%</p>
                                <p className={styles.subTotal}><span className={styles.subTotalLeft}>£</span>{(((numStudents * 2) / 100) * 20).toFixed(2)}</p>
                            </div>
                            <div className={cx(styles.numStudents, "u-m-base-1")}>
                                <p className="u-bold">Total:</p>
                                <p className={cx(styles.subTotal, "u-bold")}><span className={styles.subTotalLeft}>£</span>{((numStudents * 2) + ((numStudents * 2) / 100) * 20).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-8">
                        <h2>Invoice Details</h2>
                        <p className="u-m-base-3">We’ll send an invoice using the contact details below. You’ll be able to use your subscription straight away.</p>
                        <InvoiceDetailsForm 
                            onSubmit={onSubmit}
                            formErrors={formErrors}
                            user={currentUser}
                            totalValue={numStudents}
                            purchaseType='packs'
                            loading={loading}
                            back={'/settings/boomwriter'}
                        />
                    </div>
                </div>
        </div>
    )
}

export default PurchaseActivities;