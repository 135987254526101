
import React, { useState } from 'react';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from "react-hook-form";
import { CheckCircle } from 'react-feather';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { GET_NOTES_PERMISSIONS, EDIT_NOTES_PERMISSIONS } from '../../services/graphql';
import styles from '../../scenes/GoReadDashboard/GoReadDashboard.module.scss';
import { getRoleLabel, usaOnlyRoles } from '../../../../services/utils';
import cx from 'classnames';

const PupilNotesAccess = ({ school, setShowSaveConfirm, closeModal }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [permittedRoles, setPermittedRoles] = useState([]);

    const { data, error, loading } = useQuery(GET_NOTES_PERMISSIONS, {
        variables: {
            schoolId: school.id
        },
        onCompleted: (data) => {
            if (data && data.school && data.school.manageStudentNotesSettings && data.school.manageStudentNotesSettings.length) {
                setPermittedRoles([...data.school.manageStudentNotesSettings]);
            }
        }
    })




    const { register, handleSubmit } = useForm({
        mode: "onSubmit"
    });

    const [editAccessForStudentNotes, { loading: savingPermissions }] = useMutation(EDIT_NOTES_PERMISSIONS, {
        update: (_, { data }) => {
            if (data.editSchoolSettingsForStudentNotes.errors && data.editSchoolSettingsForStudentNotes.errors.length !== 0) {
                setFormErrors(data.editSchoolSettingsForStudentNotes.errors);
                return;
            }
            if (data.editSchoolSettingsForStudentNotes.school.id) {
                setShowSaveConfirm && setShowSaveConfirm(true);
                closeModal();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_NOTES_PERMISSIONS, variables: { schoolId: school.id } }]
    });

    const onSelectRole = val => {
        if (permittedRoles.indexOf(val) !== -1) {
            setPermittedRoles(permittedRoles.filter(r => r !== val));
        } else {
            setPermittedRoles([...permittedRoles, val]);
        }
    }

    const onSubmit = () => {
        editAccessForStudentNotes({
            variables: {
                schoolId: school.id,
                permittedRoleIds: permittedRoles
            }
        });
    }

    if (loading) return <Loader />

    if (error) return <p>{error.message}</p>

    if (data && data.school) {

        const { school: { manageStudentNotesSettings } } = data;

        return (
            <>
                <h2 className='heavy'>Pupil Notes</h2>
                <p className='heavy u-m-base-2'>Notes are not displayed to parents or pupils. Select which members of staff (by school role) should be able to view and add notes.</p>
                <div className={styles.adminAccess}>
                    <div>
                        <h3>Administrators</h3>
                        <p>Administrators are always able to view/add notes. They are also able to edit notes created by other staff members.</p>
                    </div>
                    <CheckCircle />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ul className={cx(styles.roleCheckList, 'u-m-base-3')}>
                        {Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map((role, i) => {
                            return (
                                <li key={`role-${role[1]}`}>
                                    <p >{getRoleLabel(role[1])}</p>

                                    <div className="basic-form__group basic-form__group--check">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => onSelectRole(+e.target.value)}
                                            ref={register}
                                            className="switchToggle"
                                            name='role'
                                            value={role[1]}
                                            defaultChecked={manageStudentNotesSettings && manageStudentNotesSettings.find(r => r === role[1])}
                                            id={`check-${role[1]}`}
                                        />
                                        <label className='switchLabel' htmlFor={`check-${role[1]}`}>toggle</label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <Button disabled={savingPermissions}>Update Permissions</Button>
                    {formErrors && <FormErrors errors={formErrors} />}
                </form>
            </>
        )
    }
    return null;
}

export default PupilNotesAccess;