import { gql } from 'apollo-boost';

export const GET_TEACHERS = gql`
    query schoolTeachers($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            teachers {
                id
                displayName
                isSchoolAdmin
            }
        }
    }
`;