import React, { Fragment, useState, useContext, useRef } from 'react';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { useMutation } from '@apollo/react-hooks';
import { FormErrors } from '@axeedge/go-teacher-components';
import SideModal from '../../../components/SideModal';
import EditTeacher from './EditTeacher';
import AddTeacher from './AddTeacher';
import StaffAccess from './StaffAccess';

import { UPDATE_SCHOOL_MEMBERSHIPS, } from '../services/graphql';
import { GET_TEACHERS_QUERY } from '../../../services/school/graphql';
import { useClickAway } from 'react-use';

import { useTranslation } from 'react-i18next';
import { ChevronDown, PlusCircle, Watch, Settings } from 'react-feather';
import styles from '../Teachers.module.scss';
import { getRoleLabel } from '../../../services/utils';

const TeachersList = ({ teachers, schoolId, setRemoveTeacher, selectedTab, allTabs }) => {

    const { t } = useTranslation(['classes', 'common']);
    const { currentUser } = useContext(AuthContext);

    const [showEditTeacherModal, setShowEditTeacherModal] = useState(null);
    const [showAddTeacherModal, setShowAddTeacherModal] = useState(false);
    const [showStaffAccess, setShowStaffAccess] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [dropdown, setDropdown] = useState(false);

    const [staffDropDown, setStaffDropdown] = useState(false);

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));
    const ref2 = useRef(null);
    useClickAway(ref2, () => setStaffDropdown(false));

    const [updateSchoolMemberships, { loading }] = useMutation(UPDATE_SCHOOL_MEMBERSHIPS, {
        update: (_, { data }) => {
            if (data.updateSchoolMemberships.errors && data.updateSchoolMemberships.errors.length) {
                setFormErrors(data.updateSchoolMemberships.errors);
            }
            if (data.updateSchoolMemberships && data.updateSchoolMemberships.school && data.updateSchoolMemberships.school.id) {
                setSelectedTeachers([]);
                setFormErrors([]);
                setDropdown(false);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TEACHERS_QUERY, variables: { schoolCode: currentUser.school.schoolCode, includeInvited: true } }]
    });

    const renderTitle = () => {
        switch (selectedTab) {
            case allTabs.allActiveTeachers:
                return t('all_active_staff');
            case allTabs.administrators:
                return t('administrators');
            case allTabs.other:
                return 'Other Staff';
            case allTabs.removedTeachers:
                return t('removed');
            case allTabs.requests:
                return "Requests";
            default:
                return t('common:teachers');
        }
    }


    const onSelectTeacher = e => {
        if (selectedTeachers.indexOf(e.target.value) !== -1) {
            setSelectedTeachers(selectedTeachers.filter(t => t !== e.target.value));
        } else {
            setSelectedTeachers([...selectedTeachers, e.target.value]);
        }
    }

    const onSelectAllTeachers = e => {
        const teacherIds = teachers.filter(t => t.id !== currentUser.id).map(t => t.id);
        e.target.checked ? setSelectedTeachers(teacherIds) : setSelectedTeachers([])
    }

    const updateMemberships = (isAdmin) => {
        updateSchoolMemberships({
            variables: {
                teacherIds: selectedTeachers,
                schoolId: schoolId,
                isSchoolAdmin: isAdmin
            }
        });
    }



    return (
        <Fragment>
            <h3>{renderTitle()}</h3>
            {selectedTab === allTabs.administrators && <p className='small'>{t('admin_access_info')}</p>}
            <hr className='separator' />
            {showStaffAccess ? <StaffAccess isOnboarding={false} schoolCode={currentUser?.school?.code} close={() => setShowStaffAccess(false)} /> :
                <>
                    <div className={styles.actions}>
                        <div className={styles.actionsLeft}>
                            <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                <input
                                    name="all"
                                    className="basic-form__check-box"
                                    type="checkbox"
                                    checked={selectedTeachers.length > 0 && selectedTeachers.length === teachers.filter(t => t.id !== currentUser.id).length}
                                    onChange={(e) => onSelectAllTeachers(e)}
                                    id="selectAll"
                                />
                                <label className="basic-form__check-label" htmlFor='selectAll'>All / None</label>
                            </div>
                            <div ref={ref} className='dropdownHolder'>
                                <button disabled={selectedTeachers.length === 0} className='dropdownBtn' onClick={() => setDropdown(!dropdown)}>Actions <ChevronDown /> </button>
                                {dropdown && (
                                    <ul className='dropdownMenu'>
                                        {selectedTab !== allTabs.other && (
                                            <li><button disabled={loading} className='dropdownMenuItem' onClick={() => updateMemberships(false)}>{loading ? t('common:saving') : t('set_as_teacher')}</button></li>
                                        )}
                                        {selectedTab !== allTabs.administrators && (
                                            <li><button disabled={loading} className='dropdownMenuItem' onClick={() => updateMemberships(true)}>{loading ? t('common:saving') : t('set_as_admin')}</button></li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                            <div ref={ref2} className='dropdownHolder'>
                                <button className='dropdownBtn heavy' onClick={() => setStaffDropdown(!staffDropDown)}><PlusCircle size="18" className='u-m-right-1' /> Add Staff <ChevronDown /> </button>
                                {staffDropDown && (
                                    <ul className='dropdownMenu'>
                                        {!currentUser?.school?.wondeAccessApprovedAt &&   <li> <button className='dropdownMenuItem' onClick={() => setShowStaffAccess(true)}>Send multiple invites</button></li>}
                                        <li><button className='dropdownMenuItem' onClick={() => setShowAddTeacherModal(true)}>Add staff member</button></li>
                                    </ul>
                                )}
                            </div>
                        
                    </div>
                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                    <ul className='list-group'>
                        {
                            teachers.length > 0 ? teachers.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(teacher => {
                                return (
                                    <li key={`t-${teacher.id}`} className='list-item'>
                                        {currentUser.id !== teacher.id &&
                                            <div className="basic-form__group basic-form__group--check u-m-base-0 u-m-top-1">
                                                <input
                                                    type='checkbox'
                                                    className="basic-form__check-box"
                                                    value={teacher.id}
                                                    onChange={(e) => onSelectTeacher(e)}
                                                    id={`t-${teacher.id}`}
                                                    checked={selectedTeachers.indexOf(teacher.id) > -1}
                                                />
                                                <label className="basic-form__check-label" htmlFor={`t-${teacher.id}`}>&nbsp;</label>
                                            </div>}
                                        <button type='button' className='btn-reset link-reverse u-display-flex u-align-center' onClick={() => setShowEditTeacherModal(teacher)}>
                                            {!teacher.active && <Watch className='u-text-accent-3' />} {teacher.active ? teacher.displayName : teacher.email}
                                        </button>
                                        <div className={styles.roleLabels}>
                                            {teacher.isSchoolAdmin && <span className={styles.roleLabel}><Settings size={16} /> Admin</span>}
                                            {teacher.roleId && <span className={styles.roleLabel}>{getRoleLabel(teacher.roleId)}</span>}
                                        </div>
                                    </li>
                                )
                            }) : <p>{t('common:no_teachers')}</p>
                        }
                    </ul>
                </>
            }

            {
                showAddTeacherModal && (
                    <SideModal closeModal={() => setShowAddTeacherModal(false)}>
                        <AddTeacher schoolCode={currentUser.school.schoolCode} schoolId={currentUser.school.id} closeModal={() => setShowAddTeacherModal(false)} />
                    </SideModal>
                )
            }

            {
                showEditTeacherModal && (
                    <SideModal isVisible={showEditTeacherModal} closeModal={() => setShowEditTeacherModal(false)}>
                        <EditTeacher
                            currentUser={currentUser}
                            schoolId={schoolId}
                            closeModal={() => setShowEditTeacherModal(null)}
                            teacher={showEditTeacherModal}
                            onDelete={() => setRemoveTeacher(showEditTeacherModal)}

                        />
                    </SideModal>
                )
            }
        </Fragment>
    )
}

export default TeachersList;
