// Queries and mutations specifically for the Classes Scene
import { gql } from 'apollo-boost';
const teacherQuery = `
id
isSchoolAdmin
active
displayName
firstName
lastName
`
export const GET_CLASSES_QUERY = gql`
query myClasses($includeAdminedClasses: Boolean, $onlyArchived: Boolean) {
    myClasses(includeAdminedClasses: $includeAdminedClasses, onlyArchived: $onlyArchived) {
        id
        name
        archived
        description
        academicYear
        typeId
        teachers {
           ${teacherQuery}
        }
    }
}
`

export const ARCHIVE_CLASSES = gql`
mutation archiveMultipleClasses($studentsClassIds: [ID!]!) {
    archiveMultipleClasses(studentsClassIds: $studentsClassIds) {
        studentsClasses {
            id
        }
        errors
    }
}`;

export const UNARCHIVE_CLASSES = gql`
mutation unarchiveMultipleClasses($studentsClassIds: [ID!]!) {
    unarchiveMultipleClasses(studentsClassIds: $studentsClassIds) {
        studentsClasses {
            id
        }
        errors
    }
}`;


