import React from 'react';
import { CheckCircle } from 'react-feather';
import { Button } from '@axeedge/go-teacher-components';
import { navigate } from '@reach/router';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

import { useTranslation } from 'react-i18next';
import { parse } from "query-string";
import { useLocation } from 'react-use';

const SuccessPanel = ({ mainText, linkText }) => {

    const { t } = useTranslation(['apps']);
    const location = useLocation();

    return(
        <div className='card card-mobile u-text-center'>
            <h1>Thanks</h1>
            <div>
                <CheckCircle className='u-text-primary' size="80" />
            </div>
            <p className='u-m-base-3'>{mainText} {t('well_send_invoice_to_finance_manager_shortly')}.</p>
            <p className='u-m-base-2'><Button onClick={() => navigate('/classes')}>{linkText}</Button></p>
            <p>
                { parse(location.search).book ? (
                    <a href={`${getUrl(APPS.go_write_teacher)}/book/${parse(location.search).book}?packAdded=true`}><Button>Back to book</Button></a>

                ) :  <Button onClick={() => navigate('/')}>{t('back_to_dashboard')}</Button> }
            </p>
        </div>
    )
}

export default SuccessPanel;