import React, { useState, useEffect, useContext } from "react";
import { Button, FormErrors, Modal } from "@axeedge/go-teacher-components";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { BOOMREAD_COST, GET_READ_SUBSCRIPTIONS } from "../../services/graphql";
import SelectStudents from "../../scenes/PurchaseSubscription/components/SelectStudents";
import BillingProfileForm from '../../../Settings/scenes/Billing/components/BillingProfileForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EDIT_RENEWAL } from "../../services/graphql";

import { CheckSquare, Square } from 'react-feather';
import { READ_SUB_DURATION } from "../../../../services/constants";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import MisFindByUrn from "../PurchaseDataDashboardAccess/MisFindByUrn";
import cx from 'classnames';
import styles2 from '../../scenes/PurchaseSubscription/components/Payment/Payment.module.scss';
import styles from './AutoRenewal.module.scss';


const EditRenewal = ({ subDuration, renewal, price, close, currentUser }) => {

    const { t } = useTranslation(['apps', 'profile', 'common']);

    const { currentUser: user } = useContext(AuthContext);


    const [additionalFeatures, setAdditionalFeatures] = useState({
        wondeAndDataDash: renewal.dataDashboardAccess,
        wondeOnly: !renewal.dataDashboardAccess && renewal.wondeAccess,
        urn: renewal.urn ? renewal.urn : null
    });

    const wantsWonde = additionalFeatures.wondeOnly || additionalFeatures.wondeAndDataDash;
    const hasUrn = additionalFeatures.urn || user.school.urn;
    const needsUrn = wantsWonde && !hasUrn;


    const [totalStudents, setTotalStudents] = useState(renewal.studentsCounter);
    const [costs, setCosts] = useState(null);

    const [editBillingProfile, setEditBillingProfile] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const [editRenewal, { loading }] = useMutation(EDIT_RENEWAL, {
        update: (_, { data }) => {
            if (data.editRenewal.errors && data.editRenewal.errors.length > 0) {
                setFormErrors(data.editRenewal.errors);
            } else {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: user?.school.id } }]
    });

    const { register, handleSubmit } = useForm({
        mode: 'onSubmit'
    });

    const [getCost] = useLazyQuery(BOOMREAD_COST, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => setCosts(data.boomreadCost)
    });

    useEffect(() => {
        if (totalStudents) {
            getCost({
                variables: {
                    schoolId: user.school?.id,
                    years: subDuration,
                    studentsCount: +totalStudents,
                    withDataDashboard: additionalFeatures.wondeAndDataDash,
                    withWonde: additionalFeatures.wondeAndDataDash || additionalFeatures.wondeOnly
                }
            })
        }
    }, [totalStudents, subDuration, additionalFeatures])

    const onSubmit = values => {
        const { purchaseOrderReferenceNumber } = values;
        editRenewal({
            variables: {
                renewalId: renewal.id,
                studentsCounter: +totalStudents,
                duration: +subDuration,
                purchaseOrderReferenceNumber,
                dataDashboardAccess: additionalFeatures.wondeAndDataDash,
                wondeAccess: wantsWonde,
                ...(additionalFeatures.urn && wantsWonde && { urn: (additionalFeatures.urn).toString() })
            }
        })
    }



    return (
        <>
            <h1>Edit/Change</h1>
            <SelectStudents setTotalStudents={setTotalStudents} totalStudents={totalStudents} />
            <h3 className='heavy'>2: Subscription term:
                £{subDuration === READ_SUB_DURATION.oneYear ? price.oneYearPricePerStudent : price.twoYearPricePerStudent}/student
            </h3>

            {!user?.school.canAccessWonde &&
                <ul className={styles2.subType}>
                    <li className={cx(styles2.subTypeLink, { [styles.subTypeLinkActive]: additionalFeatures.wondeAndDataDash })}>
                        <div className={styles2.subTypeMain} onClick={() => setAdditionalFeatures({ ...additionalFeatures, wondeOnly: false, wondeAndDataDash: !additionalFeatures.wondeAndDataDash })}>
                            <span className={cx(styles2.subTypeCheck, { [styles2.subTypeCheckActive]: additionalFeatures.wondeAndDataDash })}>{additionalFeatures.wondeAndDataDash ? <CheckSquare /> : <Square />}</span>
                            <p><b>Data Dashboard & MIS Integration - £{1}/student</b></p>
                        </div>
                        <div className={styles2.subTypeContent}>
                            <p className='small'>Monitor levels of reading engagement, compare reading trends and parental engagement.</p>
                            {additionalFeatures.wondeAndDataDash && !additionalFeatures?.urn && !currentUser.school?.urn &&
                                <div className='u-m-top-2'>
                                    <MisFindByUrn subWithAdditional={additionalFeatures} onConfirmWondeSchool={(data) => { setAdditionalFeatures({ ...additionalFeatures, urn: data.urn }) }} withDataDashboard={true} />
                                </div>
                            }
                        </div>
                    </li>

                    <li className={cx(styles2.subTypeLink, { [styles2.subTypeLinkActive]: additionalFeatures.wondeOnly })}>
                        <div className={styles2.subTypeMain} onClick={() => setAdditionalFeatures({ ...additionalFeatures, wondeAndDataDash: false, wondeOnly: !additionalFeatures.wondeOnly })}>
                            <span className={cx(styles2.subTypeCheck, { [styles2.subTypeCheckActive]: additionalFeatures.wondeOnly })}>{additionalFeatures.wondeOnly ? <CheckSquare /> : <Square />}</span>
                            <p><b>MIS Integration </b></p>
                        </div>
                        <div className={styles2.subTypeContent}>
                            <p className='small'>Monitor levels of reading engagement, compare reading trends and parental engagement.</p>
                            {additionalFeatures.wondeOnly && !additionalFeatures.urn && !currentUser.school?.urn &&
                                <div className='u-m-top-2'> <MisFindByUrn subWithAdditional={additionalFeatures} onConfirmWondeSchool={(data) => { setAdditionalFeatures({ ...additionalFeatures, urn: data.urn }) }} withDataDashboard={false} /></div>
                            }
                        </div>
                    </li>
                </ul>
            }

            <ul className={cx(styles2.paymentSummary, styles.paymentSummaryFull)}>
                <li className={styles2.paymentSummaryItem}>
                    {totalStudents} students at £{subDuration === READ_SUB_DURATION.oneYear ? price.oneYearPricePerStudent : price.twoYearPricePerStudent}/student
                    <div className={styles2.paymentSummaryData}>
                        <span className={styles2.paymentSummaryDataValue}>£{costs?.standardCost.toFixed(2) || "0.00"}</span>
                    </div>
                </li>
                {renewal.discount !== 0 && (
                    <li className={cx(styles2.paymentSummaryItem, 'heavy')}>
                        {price.renewalDiscount}% discount
                        <div className={styles2.paymentSummaryData}>
                            <span className={styles2.paymentSummaryDataValue}>-£{costs?.discountValue.toFixed(2) || "0.00"}</span>
                        </div>
                    </li>
                )}


                {
                    (additionalFeatures.wondeAndDataDash || additionalFeatures.wondeOnly) &&
                    <li className={styles2.paymentSummaryItem}>
                        BoomReader Administration Fee
                        <div className={styles2.paymentSummaryData}>
                            <span className={styles2.paymentSummaryDataValue}>£{(costs?.wondeCost)?.toFixed(2) || '0.00'}</span>
                        </div>
                    </li>
                }

                {
                    additionalFeatures.wondeAndDataDash &&
                    <li className={styles2.paymentSummaryItem}>
                        Data Dashboard
                        <div className={styles2.paymentSummaryData}>
                            <span className={styles2.paymentSummaryDataValue}>£{(costs?.dataDashboardCost)?.toFixed(2) || '0.00'}</span>
                        </div>
                    </li>
                }
                <li className={styles2.paymentSummaryItem}>
                    VAT @ 20%
                    <div className={styles2.paymentSummaryData}>
                        <span className={styles2.paymentSummaryDataValue}>£{costs?.VAT.toFixed(2) || "0.00"}</span>
                    </div>
                </li>
                <li className={cx(styles2.paymentSummaryItem, styles2.paymentSummaryItemTotal)}>
                    Total
                    <div className={styles2.paymentSummaryData}>
                        <span className={styles2.paymentSummaryDataValue}>£{costs ? (costs.VAT + costs.totalCost).toFixed(2) : "0.00"}</span>
                    </div>
                </li>
            </ul>


            <h3 className='heavy'>3: Invoice Details</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="u-m-base-3">
                <div className={styles.invoicePanel}>
                    <h3 className='heavy'>{t('common:po_number')} <span className='u-m-left-1 light'>({t('common:optional')})</span></h3>
                    <div className='basic-form__group'>
                        <input
                            name='purchaseOrderReferenceNumber'
                            id='purchaseOrderReferenceNumber'
                            className='basic-form__text-box'
                            type='text'
                            placeholder={t('common:po_number_placeholder')}
                            ref={register}
                        />
                    </div>
                    {user?.school?.billingProfile && (
                        <>
                            {user?.school?.billingProfile?.companyName && <p><b>{user.school.billingProfile.companyName}</b></p>}
                            <div className="u-m-base-1">
                                {user?.school?.billingProfile?.address && <p>{user.school.billingProfile.address}</p>}
                                {user?.school?.billingProfile?.address2 && <p>{user.school.billingProfile.address2}</p>}
                                {user?.school?.billingProfile?.city && <p>{user.school.billingProfile.city}</p>}
                                {user?.school?.billingProfile?.state && <p>{user.school.billingProfile.state}</p>}
                                {user?.school?.billingProfile?.zip && <p>{user.school.billingProfile.zip}</p>}
                                {user?.school?.billingProfile?.country?.name && <p>{user.school.billingProfile.country.name}</p>}
                            </div>
                            <div className="u-m-base-2">
                                {user?.school?.billingProfile?.contactPerson && <p>Contact: {user.school.billingProfile.contactPerson}</p>}
                                {user?.school?.billingProfile?.contactEmail && <p>Email: {user.school.billingProfile.contactEmail}</p>}
                                {user?.school?.billingProfile?.contactPhone && <p>Phone: {user.school.billingProfile.contactPhone}</p>}
                            </div>
                        </>
                    )}
                    <button type='button' className='btn-reset link-underline' onClick={() => setEditBillingProfile(true)}>Edit billing profile</button>

                </div>
                <Button
                    className='u-m-top-2'
                    disabled={+totalStudents === 0 || loading || needsUrn}
                    type='submit'
                    fullWidth
                >{renewal.isSecondYearRenewal ? 'Save invoice details' : 'Save renewal details'}</Button>

                {formErrors && <FormErrors errors={formErrors} />}
            </form>
            {editBillingProfile &&
                <Modal wide closeModal={() => setEditBillingProfile(false)}>
                    <BillingProfileForm isEdit={true} school={user?.school} close={() => setEditBillingProfile(false)} />
                </Modal>
            }
        </>
    )

}

export default EditRenewal;