import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { CHANGE_PASSWORD_MUTATION } from '../services/graphql';
import { Check } from 'react-feather';

import { useTranslation } from 'react-i18next';

const ChangePassword = ({ closeModal }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    const { t } = useTranslation(['common', 'profile']);


    const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.changePassword.errors && data.changePassword.errors.length !== 0) {
                setFormErrors(data.changePassword.errors);
                return;
            }

            if (data.changePassword.user.id) {
                setSuccess(true);
                reset({
                    existingPassword: '',
                    newPassword: '',
                    passwordConfirmation: ''
                })
            }
        }
    });

    const schema = Yup.object().shape({
        existingPassword: Yup.string()
            .required(t('profile:please_enter_current_password')),
        newPassword: Yup.string()
            .required(t('profile:please_enter_new_password')),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], t('profile:passwords_must_match'))
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        setFormErrors([]);
        const { existingPassword, newPassword, passwordConfirmation } = values;
        changePassword({
            variables: {
                existingPassword,
                newPassword,
                passwordConfirmation
            }
        })
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className='light u-m-base-3'>{t('profile:please_set_new_password')}</h1>
                <div className='basic-form__group'>
                    <label htmlFor='existingPassword' className='basic-form__text-label'>{t('profile:current_password')}</label>
                    <input
                        name='existingPassword'
                        id='existingPassword'
                        className='basic-form__text-box'
                        type='password'
                        ref={register}
                    />
                </div>
                {errors.existingPassword && <p className='basic-form__hint'>{errors.existingPassword.message}</p>}
                <div className='basic-form__group'>
                    <label htmlFor='newPassword' className='basic-form__text-label'>{t('profile:new_password')}</label>
                    <input
                        name='newPassword'
                        id='newPassword'
                        className='basic-form__text-box'
                        type='password'
                        ref={register}
                    />
                </div>
                {errors.newPassword && <p className='basic-form__hint'>{errors.newPassword.message}</p>}
                <div className='basic-form__group'>
                    <label htmlFor='passwordConfirmation' className='basic-form__text-label'>{t('profile:confirm_new_password')}</label>
                    <input
                        name='passwordConfirmation'
                        id='passwordConfirmation'
                        className='basic-form__text-box'
                        type='password'
                        ref={register}
                    />
                </div>
                {errors.passwordConfirmation && <p className='basic-form__hint'>{errors.passwordConfirmation.message}</p>}
                <div className='u-m-top-3'>
                    <Button className='u-m-right-1' type='submit'>{loading ? t('saving') : t('button.save')}</Button>
                    <Button type='button' outline onClick={() => closeModal()}>{t('button.cancel')}</Button>
                </div>
            </form>
            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(formError => t(formError))} />}
            {
                success && (
                    <p className='basic-form__success'><Check className='basic-form__success__icon'/>{t('profile:new_password_saved')}</p>
                )
            }
        </Fragment>
    )
}

export default ChangePassword;
