import React, { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import ls from 'local-storage';
import { navigate } from '@reach/router';

import { getAppTokenName } from '@axeedge/go-shared-utils';

import { APP_NAME, APP_NAME_US } from '../../services/constants';
import { AuthContext } from '../../services/auth/AuthProvider';

const Impersonate = ({ id, token }) => {
    const [message, setMessage] = useState('Growing false moustache...');

    const { setCurrentUser } = useContext(AuthContext);

    useEffectOnce(() => {
        if (id && token) {
            ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US :APP_NAME), token);
            ls('go_teacher_user', JSON.stringify({ id }));
            ls('go_impersonating', true);
            setCurrentUser({ id });
            navigate('/');
        } else {
            setMessage('Something went wrong');
        }
    })

    return <p>{message}</p>
}

export default Impersonate;
