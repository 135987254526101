import React, { useState, useEffect, useContext } from "react";
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GET_TEACHERS_QUERY, UPDATE_SCHOOL_SETUP_STEP } from "../../../../services/school/graphql";
import { WONDE_IMPORT_TEACHERS } from "../../services/graphql";
import { SCHOOL_SETUP_STEPS, WONDE_SYNC_STEPS } from "../../../../services/constants";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { useMutation } from "@apollo/react-hooks";
import { getRoleLabel, usaOnlyRoles } from "../../../../services/utils";
import styles2 from '../../../Teachers/components/StaffAccess/StaffAccess.module.scss';
import styles from '../../Onboarding.module.scss';
import cx from 'classnames';

const ManageStaffSettings = ({ selectedToImport, duringSync, setCurrentSyncStep, duringAdditionalImport, closeAdditionalImport }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [selectedStaff, setSelectedStaff] = useState([]);

    const [staff, setStaff] = useState(selectedToImport);

    const [staffRoleError, setStaffRoleError] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const [updateSchoolSetupStep, { loading: updatingStep }] = useMutation(UPDATE_SCHOOL_SETUP_STEP, {
        update: (_, { data }) => {
            if ((data && data.updateSchoolSetupStep.errors && data.updateSchoolSetupStep.errors.length > 0)) {
                setFormErrors(data.updateSchoolSetupStep.errors);
                return;
            }
            if (data?.updateSchoolSetupStep?.school?.id) {
                const cu = { ...currentUser, school: data.updateSchoolSetupStep.school }
                setCurrentUser(cu);
            }
        }
    });

    const onSelectStaff = e => {
        if (selectedStaff.indexOf(e.target.value) !== -1) {
            setSelectedStaff(selectedStaff.filter(el => el !== e.target.value));
        } else {
            setSelectedStaff([...selectedStaff, e.target.value]);
        }
    }

    const onSelectAllStaff = e => {
        const selectedIds = selectedToImport.map(t => t.id);
        e.target.checked ? setSelectedStaff(selectedIds) : setSelectedStaff([])
    }

    const onChangeField = (field, val, i) => {
        const updatedStaff = [...staff];
        updatedStaff[i][field] = field === 'roleId' ? +val : val;
        setStaff(updatedStaff);
    }

    const onChangeFieldForAll = (field, val) => {
        const value = field === 'roleId' ? +val : field === 'isAdmin' ? Boolean(+val) : val;
        const updatedStaff = staff.map(el => {
            return selectedStaff.find(t => t === el.id) ? { ...el, [field]: value } : el
        });
        setStaff([...updatedStaff]);
    }



    const teacherQueries = () => {
        if (duringAdditionalImport) {
            return [{
                query: GET_TEACHERS_QUERY,
                variables: {
                    schoolCode: currentUser?.school?.schoolCode,
                    includeInvited: true
                }
            }]
        } else {
            return []
        }
    }
    const [importWondeAdditionalTeachers, { loading }] = useMutation(WONDE_IMPORT_TEACHERS, {
        update: (_, { data }) => {
            if (data && data.importWondeAdditionalTeachers.errors && data.importWondeAdditionalTeachers.errors.length > 0) {
                setFormErrors(data.importWondeAdditionalTeachers.errors);
                return;
            }
            if (data && data.importWondeAdditionalTeachers?.importedTeachers?.length > 0 || data.importWondeAdditionalTeachers?.updatedTeachers?.length > 0) {
                if (duringSync) {
                    setCurrentSyncStep(WONDE_SYNC_STEPS.syncCompleted)
                } else if (duringAdditionalImport) {
                    closeAdditionalImport();
                } else {
                    updateSchoolSetupStep({
                        variables: {
                            schoolId: currentUser?.school.id,
                            step: SCHOOL_SETUP_STEPS.finalStepDone
                        }
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => teacherQueries(),
    });

    useEffect(() => {
        if (!staff.find(el => !el.roleId) && staffRoleError) {
            setStaffRoleError(null);
        }
    }, [staff, staffRoleError])

    const finalizeStaffImport = () => {
        if (staff.find(el => !el.roleId)) {
            setStaffRoleError(true);
            return;
        }
        const wondeStaffDetails = [];
        const currentStaffDetails = [];
        staff.forEach(el => {
            el.wondeId ?
                wondeStaffDetails.push({
                    roleId: el.roleId,
                    isAdmin: Boolean(el.isAdmin),
                    wondeTeacherId: el.wondeId
                }) :
                currentStaffDetails.push({
                    roleId: el.roleId,
                    isAdmin: el.isSchoolAdmin ? true : Boolean(el.isAdmin),
                    teacherId: el.id
                });
        });
        importWondeAdditionalTeachers({
            variables: {
                schoolId: currentUser.school?.id,
                wondeTeacherDetails: wondeStaffDetails,
                updateTeacherDetails: currentStaffDetails
            }
        });
    }


    return (
        <>
            <div className={styles.wondeMain}>
                <h1>Staff settings</h1>
                <p>Please take a few moments to assign the correct roles and rights to these staff members</p>
                <div className="u-m-top-2">
                    <ul className="list-group u-m-right-3">
                        <li className={cx(styles2.staffItem, styles2.staffItemHeader)}>
                            <div className={styles2.staffItemMain}>
                                <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                    <input
                                        name="all"
                                        className="basic-form__check-box"
                                        type="checkbox"
                                        checked={selectedStaff.length === selectedToImport.length}
                                        onChange={(e) => onSelectAllStaff(e)}
                                        id="selectAll"
                                    />
                                    <label className="basic-form__check-label" htmlFor='selectAll'>All</label>
                                </div>
                            </div>
                            <div className={styles2.staffItemActions}>
                                <div className={styles2.staffItemActionsItem}>
                                    <select value='' disabled={selectedStaff.length === 0} onChange={(e) => onChangeFieldForAll('isAdmin', e.target.value)} name="adminAll" className={cx("basic-form__text-select", styles2.select)}>
                                        <option value=''>Admin</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>

                                <div className={styles2.staffItemActionsItem}>
                                    <select value='' disabled={selectedStaff.length === 0} onChange={(e) => onChangeFieldForAll('roleId', e.target.value)} name="roleAll" className={cx("basic-form__text-select", styles2.select)}>
                                        <option value=''>Role</option>
                                        {Object.entries(TEACHER_JOB_ROLES).sort((a, b) => a[0].localeCompare(b[0])).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                            return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </li>

                        {staff.map((teacher, i) => (
                            <li key={`teacher-${teacher.id}`} className={styles2.staffItem}>
                                <div className={styles2.staffItemMain}>
                                    <div className="basic-form__group basic-form__group--check u-m-base-0">
                                        <input
                                            type='checkbox'
                                            className="basic-form__check-box"
                                            value={teacher.id}
                                            onChange={(e) => onSelectStaff(e)}
                                            id={`teacher-${teacher.id}`}
                                            checked={selectedStaff.indexOf(teacher.id) > -1}
                                        />
                                        <label className="basic-form__check-label" htmlFor={`kls-${teacher.id}`}>&nbsp;</label>
                                    </div>
                                    {teacher.firstName} {teacher.lastName}
                                </div>
                                <div className={styles2.staffItemActions}>
                                    <div className={cx(styles2.staffItemActionsItem, "basic-form__group basic-form__group--check u-m-base-0")}>
                                        <span className="u-m-right-1">Admin</span>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => onChangeField('isAdmin', e.target.checked, i)}
                                            className="switchToggle"
                                            name='admin'
                                            id={`admin-${i}`}
                                            disabled={teacher.id === currentUser.id}
                                            checked={teacher.isSchoolAdmin ? true : (teacher.isAdmin || false)}
                                        />
                                        <label className={cx("switchLabel", styles.switchAdminLabel)} htmlFor={`admin-${i}`}><span>Admin</span></label>
                                    </div>
                                    <div className={styles2.staffItemActionsItem}>
                                        <select value={teacher.roleId || ''} onChange={(e) => onChangeField('roleId', e.target.value, i)} name="roleId" className={cx("basic-form__text-select", styles2.select)}>
                                            <option value=''>Role</option>
                                            {Object.entries(TEACHER_JOB_ROLES).sort((a, b) => a[0].localeCompare(b[0])).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                                return (<option key={`role-${role[1]}`} value={+role[1]}>{getRoleLabel(role[1])}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {staffRoleError && <p className="basic-form__hint u-m-top-2">Please select a role for each member of the staff!</p>}
                    {formErrors && <FormErrors errors={formErrors} />}
                </div>
            </div>
            <div className={styles.wondeFooter}>
                <Button disabled={updatingStep || loading} className='u-m-left-auto' onClick={() => finalizeStaffImport()}>{updatingStep || loading ? 'Saving... ' : 'Next'}</Button>
            </div>
        </>
    )
}

export default ManageStaffSettings;