import { gql } from 'apollo-boost';
import { schoolQuery } from '../../../services/constants';

export const SCHOOL_BY_CODE = gql`
query schoolByCode($schoolCode: ID!) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        name
        schoolCode
        city
        zip
        address
        avatarUrl
    }
}
`;

export const TEACHER_REQUEST_TO_JOIN = gql`
mutation teacherRequestToJoinSchool($schoolCode: String!, $roleId: Int!){
    teacherRequestToJoinSchool(schoolCode: $schoolCode, roleId: $roleId) {
        errors
        teacher {
            id
            firstName
            lastName
            joinRequests {
                id
                approved
            }
        }
    }
}
`;

export const TEACHER_CANCEL_JOIN_REQUEST = gql`
mutation teacherCancelRequestToJoinSchool($joinRequestId: ID!, $teacherId: ID!){
    teacherCancelRequestToJoinSchool(joinRequestId: $joinRequestId, teacherId: $teacherId) {
        errors
        teacher {
            id
            firstName
            lastName
            joinRequests {
                id
                approved
            }
        }
    }
}
`;




export const CHECK_JOIN_REQUESTS = gql`
query currentTeacher {
    currentTeacher {
        id
        joinRequests {
            id
            approved
            school {
                id
                name
                city
                avatarUrl
                country {
                    id
                    name
                }
            }
        }
    }
}
`;
export const SCHOOL_ACCEPT_PROMOTION = gql`
mutation schoolAcceptPromotion($schoolId: ID!, $promotionCode: String!){
    schoolAcceptPromotion(schoolId: $schoolId, promotionCode: $promotionCode) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

export const ACTIVE_PROMOTIONS = gql`
query activePromotions{
    activePromotions{
        id
        name
        code
        app
        startDate
        endDate
    }
}
`;

export const GET_SCHOOL_BY_URN = gql`
query getSchoolByUrn($urn: String!) {
    getSchoolByUrn(urn: $urn) {
        errors
        school {
            ${schoolQuery}
        }
        wondeSchool {
            id
            urn
            wondeId
            name
            address
            address2
            city
            country
            zip 
        }
    }
}
`;

export const CONFIRM_WONDE_SCHOOL = gql`
mutation confirmWondeSchool($urn: String!) {
    confirmWondeSchool(urn: $urn) {
        errors
        school {
           ${schoolQuery}
        }
    }
}
`;

export const UPDATE_WONDE_SCHOOL = gql`
mutation updateWondeSchool($schoolId: ID!, $weekStart: Int!, $roleId: Int!) {
    updateWondeSchool(schoolId: $schoolId, weekStart: $weekStart, roleId: $roleId) {
        errors
        school {
            ${schoolQuery}
        }
    }
}
`;

export const WONDE_CLASSES_DATA = gql`
query school($schoolId: ID!, $additionalOnly: Boolean, $page: Int) {
    school(schoolId: $schoolId) {
        id
        classes {
            id
            typeId
        }
        wondeSchool {
            id
            classes(additionalOnly: $additionalOnly, page: $page) {
                id
                name
                subject
                teachers {
                    id
                    firstName
                    lastName
                }
                students {
                    id
                }
            }
        }
    }
}
`;

export const WONDE_TEACHERS_DATA = gql`
query school($schoolId: ID!, $additionalOnly: Boolean) {
    school(schoolId: $schoolId) {
        id
        ${schoolQuery}
        teachers {
            id
            firstName
            lastName
            isSchoolAdmin
        }
        wondeSchool {
            id
            teachers(additionalOnly: $additionalOnly) {
                id
                wondeId
                email
                firstName
                lastName
            }
        }
    }
}
`;

export const START_WONDE_IMPORT = gql`
mutation startWondeImport($schoolId: ID!, $wondeClassesData: [ImportWondeClassAttributes!]!) {
    startWondeImport(schoolId: $schoolId, wondeClassesData: $wondeClassesData) {
        errors
        importFile {
            id
            errors
            status
            total
        }
    }
}
`;

export const WONDE_IMPORT_TEACHERS = gql`
mutation importWondeAdditionalTeachers($schoolId: ID!, $wondeTeacherDetails: [ImportWondeTeacherAttributes!], $updateTeacherDetails: [UpdateTeacherAttributes!]) {
    importWondeAdditionalTeachers(schoolId: $schoolId, wondeTeacherDetails: $wondeTeacherDetails, updateTeacherDetails: $updateTeacherDetails) {
        errors 
        importedTeachers {
            id
        }
        updatedTeachers {
            id
        }
    }
}
`;

export const CANCEL_WONDE_REQUEST = gql`
mutation cancelWondeAccessRequest($schoolId: ID!) {
    cancelWondeAccessRequest(schoolId: $schoolId) {
        errors 
        school {
            id
            ${schoolQuery}
        }
    }
}
`;