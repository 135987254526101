
import React, { useState } from 'react';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from "react-hook-form";
import { CheckCircle } from 'react-feather';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { GET_DOWNLOAD_DATA_PERMISSIONS, EDIT_DOWNLOAD_PERMISSIONS } from '../../services/graphql';
import styles from '../../scenes/GoReadDashboard/GoReadDashboard.module.scss';
import { getRoleLabel, usaOnlyRoles} from '../../../../services/utils';
import cx from 'classnames';

const DataDownloadAccess = ({ school, setShowSaveConfirm, closeModal }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [permittedRoles, setPermittedRoles] = useState([]);

    const { data, error, loading } = useQuery(GET_DOWNLOAD_DATA_PERMISSIONS, {
        variables: {
            schoolId: school.id
        },
        onCompleted: (data) => {
            if (data && data.school && data.school.downloadDataSettings) {
                setPermittedRoles([...data.school.downloadDataSettings]);
            }
        }
    })




    const { register, handleSubmit } = useForm({
        mode: "onSubmit"
    });

    const [editAccessForDataDownload, { loading: savingPermissions }] = useMutation(EDIT_DOWNLOAD_PERMISSIONS, {
        update: (_, { data }) => {
            if (data.editSchoolSettingsForDownloadData.errors && data.editSchoolSettingsForDownloadData.errors.length !== 0) {
                setFormErrors(data.editSchoolSettingsForDownloadData.errors);
                return;
            }
            if (data.editSchoolSettingsForDownloadData.school.id) {
                setShowSaveConfirm && setShowSaveConfirm(true);
                closeModal();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_DOWNLOAD_DATA_PERMISSIONS, variables: { schoolId: school.id } }]
    });

    const onSelectRole = val => {
        if (permittedRoles.indexOf(val) !== -1) {
            setPermittedRoles(permittedRoles.filter(r => r !== val));
        } else {
            setPermittedRoles([...permittedRoles, val]);
        }
    }

    const onSubmit = () => {
        editAccessForDataDownload({
            variables: {
                schoolId: school.id,
                permittedRoleIds: permittedRoles
            }
        });
    }

    if (loading) return <Loader />

    if (error) return <p>{error.message}</p>

    if (data && data.school) {

        const { school: { downloadDataSettings } } = data;

        return (
            <>
                <h2 className='heavy'>Pupil Data Access</h2>
                <p className='heavy u-m-base-2'>Control which members of your school staff and wider team can download pupil reading data.</p>
                <div className={styles.adminAccess}>
                    <div>

                        <h3>Administrators</h3>
                        <p>Administrators are always able to view and download pupil data. This access right cannot be changed</p>
                    </div>
                    <CheckCircle />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <ul className={cx(styles.roleCheckList, 'u-m-base-3')}>
                        {Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map((role, i) => {
                            return (
                                <li key={`role-${role[1]}`}>
                                    <p >{getRoleLabel(role[1])}</p>

                                    <div className="basic-form__group basic-form__group--check">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => onSelectRole(+e.target.value)}
                                            ref={register}
                                            className="switchToggle"
                                            name='role'
                                            value={role[1]}
                                            defaultChecked={downloadDataSettings && downloadDataSettings.find(r => r === role[1])}
                                            id={`check-${role[1]}`}
                                        />
                                        <label className='switchLabel' htmlFor={`check-${role[1]}`}>toggle</label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <Button disabled={savingPermissions}>Update Permissions</Button>
                    {formErrors && <FormErrors errors={formErrors} />}
                </form>
            </>
        )
    }
    return null;
}

export default DataDownloadAccess