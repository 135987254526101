import React, { useState } from "react";
import { PROMO_CODES } from "../../../../services/constants";
import { useMutation } from "@apollo/react-hooks";
import { SCHOOL_ACCEPT_PROMOTION } from "../../services/graphql";
import { GET_READ_SUBSCRIPTIONS } from "../../../GoReadSettings/services/graphql";
import brianImg from '../../../../images/signup/brian_blessed_cutout.png';
import writeToRaiseLogo from '../../../../images/dashboard/write_to_raise_logo.png';
import grandTourLogo from '../../../../images/onboarding/grand-tour-logo-rev.png';
import boomReaderLogo from '../../../../images/boomreader_reversed.png';
import ImageFadeIn from 'react-image-fade-in';
import styles from './SchoolAcceptPromotion.module.scss';
import { Button, FormErrors } from "@axeedge/go-teacher-components";

import cx from 'classnames';

const SchoolAcceptPromotion = ({ close, promo, currentUser, setCurrentUser, afterOnboarding = false }) => {
    const [formErrors, setFormErrors] = useState([]);


    const [schoolAcceptPromotion, { loading }] = useMutation(SCHOOL_ACCEPT_PROMOTION, {
        update: (_, { data }) => {
            if (data && data.schoolAcceptPromotion.errors && data.schoolAcceptPromotion.errors.length > 0) {
                setFormErrors(data.schoolAcceptPromotion.errors);
                return;
            }

            if (data && data.schoolAcceptPromotion.school && data.schoolAcceptPromotion.school.id) {
                const cu = { ...currentUser, school: data.schoolAcceptPromotion.school }
                setCurrentUser(cu);
                close();
            }
        },
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: currentUser.school.id } }]
    })

    return (
        <>
            {!afterOnboarding &&
                <><h2>Account created!</h2>
                    {currentUser?.school && <h2 className="u-m-base-2">{currentUser.school.name} </h2>}
                </>
            }
            {promo.code === PROMO_CODES.writeToRaise ? (
                <div className={cx(styles.panel, styles.panelWrite)}>
                    <div>
                        <h2>Write To Raise Opt In</h2>
                        <p className="heavy u-m-base-3 lead">I'd like my school to take part in the Write To Raise promotion. I agree to the following:</p>
                        <p>I have permission, or the authority to accept these terms on behalf of this school.</p>
                        <p>While there is no parental/community obligation to purchase, I/We will send home the supplied parent communication.</p>
                        <p>Profits from qualifying book sales will be refunded to my school.</p>
                        <p>Books must be completed and published by 1 June 2023.</p>
                        <p>Book purchases must be made by parents or the community between 9 January 2022 - 1 June 2023.</p>
                        <p>The school will receive at least £4.99 for every qualifying book purchase.</p>
                        <p>Funds raised can be used for any purpose including school energy bills. It is the responsibility of the school to accurately communicate the intended use and distribution of raised funds.</p>
                        <p className="u-m-base-3">Full terms for this promotion can be found here</p>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                schoolAcceptPromotion({
                                    variables: {
                                        schoolId: currentUser && currentUser.school.id,
                                        promotionCode: promo.code
                                    }
                                })
                            }}
                            className='u-m-base-1 u-display-block'
                        >Agree & Join Write To Raise</Button>
                        <button onClick={close} className="btn-reset link-underline u-text-white">I'll do this later or ask another to sign up</button>
                    </div>
                    <div>
                        <ImageFadeIn className={styles.panelImg} src={writeToRaiseLogo} alt='' />
                        <ImageFadeIn className={styles.panelImg} src={brianImg} alt='' />
                    </div>
                </div>
            ) : promo.code === PROMO_CODES.goReadGrandTour && (
                <div className={cx(styles.panel, styles.panelRead)}>
                    <div>
                        <h2>Winter Grand Tour</h2>
                        <p className="heavy u-m-base-3 lead">I'd like my school to take part in the Winter Grand Tour</p>
                        <p>I understand my school will begin with a free, no-obligation trial for the duration of the Grand Tour.</p>
                        <p> At the end of the Tour, my school will be able to purchase a subscription continue using BoomReader.</p>
                        <p className="u-m-base-3">If the school chooses to discontinue with BoomReader there will be no charge and our account and access will be suspended.</p>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                schoolAcceptPromotion({
                                    variables: {
                                        schoolId: currentUser && currentUser.school.id,
                                        promotionCode: promo.code
                                    }
                                })
                            }}
                            className='u-m-base-1 u-display-block'
                        >Agree & Join Grand Tour</Button>
                        <button onClick={close} className="btn-reset link-underline u-text-white">I'll do this later or ask another to sign up</button>
                    </div>
                    <div>
                        <ImageFadeIn className={cx('u-m-base-3', styles.panelImg)} src={boomReaderLogo} alt='' />
                        <ImageFadeIn className={styles.panelImg} src={grandTourLogo} alt='' />
                    </div>
                </div>
            )}
            {formErrors && <FormErrors errors={formErrors} />}
        </>
    )
}

export default SchoolAcceptPromotion;