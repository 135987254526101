import React from 'react';
import cx from 'classnames';
import { Link } from '@reach/router';
import { ArrowLeft } from 'react-feather';
import { Dropdown  } from '@axeedge/go-teacher-components';
import styles from './PageHeader.module.scss';

const PageHeader = ({ centered, title, subtitle, icon: Icon, dropdownOpts, showBackLink = true, close, backTo }) => {
    
    return(
        <div className={cx(styles.pageHeader, {[styles.pageHeaderCentered]: centered})}>
            <div className={styles.pageHeaderContent}>
   
                <h1 className={styles.pageHeaderContentTitle}>
                    {showBackLink && <Link className={styles.pageHeaderContentBack} to={backTo ? backTo : '/'}><ArrowLeft /></Link>}
                    {close && <button className={cx('btn-reset', styles.pageHeaderContentBack)} onClick={close}><ArrowLeft /></button>}
                    {/* {Icon && <Icon />} */}
                    {title}
                    {subtitle && <span className='u-m-left-2 u-smaller-text'> - {subtitle}</span>}
                </h1>
            </div>
            {
                dropdownOpts && (
                    <div>
                        <Dropdown dropdownOpts={dropdownOpts}></Dropdown>
                    </div>
                )
            }
         
        </div>
    )
}

export default PageHeader;
