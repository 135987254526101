import React, { useEffect, useState } from "react";
import { GET_READ_SUBSCRIPTIONS, PURCHASE_DATA_DASH_ACCESS, PURCHASE_WONDE_ACCESS_ONLY } from "../../services/graphql";
import { Button } from "@axeedge/go-teacher-components";

import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { EDIT_RENEWAL_SUBSCRITIONS } from "../../services/graphql";
import CheckWondeAccessStatus from "./CheckWondeAccessStatus";
import { BOOMREAD_COST } from "../../services/graphql";
import wondeLogo from '../../../../images/dashboard/wonde-logo2.png';
import ImageFadeIn from "react-image-fade-in";
import cx from 'classnames';
import styles from '../../scenes/GoReadDashboard/GoReadDashboard.module.scss';
import MisFindByUrn from "./MisFindByUrn";

const PurchaseDataDashboardAccess = ({ currentUser, setCurrentUser, subscription, withRenewal = null }) => {

    const [showUrnForm, setShowUrnForm] = useState('');
    const [formErrors, setFormErrors] = useState([]);

    const [costs, setCosts] = useState(null);

    const [getCost] = useLazyQuery(BOOMREAD_COST, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => setCosts(data.boomreadCost)
    });

    useEffect(() => {
        getCost({
            variables: {
                schoolId: currentUser.school?.id,
                years: 1,
                studentsCount: withRenewal ? withRenewal.studentsCounter : subscription?.subscribedSeats,
                withDataDashboard: true,
                withWonde: true
            }
        })
    }, []);



    //purchase both Wonde & Data dashboard
    const [purchaseSchoolDataDashboardAccess, { loading }] = useMutation(PURCHASE_DATA_DASH_ACCESS, {
        update: (_, { data }) => {
            if (data.purchaseSchoolDataDashboardAccess.errors && data.purchaseSchoolDataDashboardAccess.errors.length > 0) {
                setFormErrors(data.purchaseSchoolDataDashboardAccess.errors);
            } else {
                const cu = { ...currentUser, school: data.purchaseSchoolDataDashboardAccess.school }
                setCurrentUser(cu);
                setShowUrnForm('');
            }
        },
    });

    //purchase Wonde only
    const [purchaseSchoolWondeAccess, { loading: purchasingWondeOnly }] = useMutation(PURCHASE_WONDE_ACCESS_ONLY, {
        update: (_, { data }) => {
            if (data.purchaseSchoolWondeAccess.errors && data.purchaseSchoolWondeAccess.errors.length > 0) {
                setFormErrors(data.purchaseSchoolWondeAccess.errors);
            } else {
                const cu = { ...currentUser, school: data.purchaseSchoolWondeAccess.school }
                setCurrentUser(cu);
                setShowUrnForm('');
            }
        },
    });

    // edit renewal subscription
    const [editRenewalSubscriptions, { loading: editingRenewalSub }] = useMutation(EDIT_RENEWAL_SUBSCRITIONS, {
        update: (_, { data }) => {
            if (data.editRenewalSubscriptions.errors && data.editRenewalSubscriptions.errors.length > 0) {
                setFormErrors(data.editRenewalSubscriptions.errors);
            } else {
                const cu = { ...currentUser, school: data.editRenewalSubscriptions.school }
                setCurrentUser(cu);
                setShowUrnForm('');
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: currentUser?.school.id } }]
    });

    const purchaseDataDashOrWondeAccess = (data) => {
        if (data.withDataDashboard) {
            purchaseSchoolDataDashboardAccess({
                variables: {
                    schoolId: currentUser?.school?.id,
                    allowAccess: true,
                    wondeAccess: true,
                    misUrn: (data.urn).toString()
                }
            });
        } else {
            purchaseSchoolWondeAccess({
                variables: {
                    schoolId: currentUser?.school?.id,
                    allowAccess: true,
                    urn: (data.urn).toString()
                }
            });
        }
    }

    const editRenewalSub = (data) => {
        if (data.withDataDashboard) {
            editRenewalSubscriptions({
                variables: {
                    renewalId: withRenewal.id,
                    dataDashboardAccess: true,
                    wondeAccess: true,
                    urn: (data.urn).toString()
                }
            });
        } else {
            editRenewalSubscriptions({
                variables: {
                    renewalId: withRenewal.id,
                    dataDashboardAccess: false,
                    wondeAccess: true,
                    urn: (data.urn).toString()
                }
            })
        }
    }

    // const test = () => {
    //     const cu = { ...currentUser, school: { ...currentUser.school, canAccessDataDashboard: false } }
    //     setCurrentUser(cu);
    // }


    return (

        <>
            {currentUser.school.canAccessWonde &&
                <div className="card-border u-m-top-2">
                    <div className="u-flex-center u-justify-between">
                        <p className="heavy">{currentUser.school.canAccessDataDashboard ? 'Data Dashboard & Wonde Integration' : 'Wonde Integration'}</p>
                        <ImageFadeIn width={100} src={wondeLogo} alt='Wonde logo' />
                    </div>
                    <CheckWondeAccessStatus currentUser={currentUser} setCurrentUser={setCurrentUser} />
                </div>
            }

            {
                !currentUser.school.canAccessDataDashboard && !currentUser.school.canAccessWonde ?
                    <>
                        <div className="card-border u-m-top-2">
                            <div className="u-flex-center u-m-base-2 u-justify-between">
                                <p className="heavy">Data Dashboard & Wonde Integration</p>
                                <ImageFadeIn width={100} src={wondeLogo} alt='Wonde logo' />
                            </div>
                            {
                                costs && <>
                                    {withRenewal ?
                                        <p className="u-m-base-2">Total - £{(costs.wondeCost + costs.dataDashboardCost).toFixed(2)} (excl. VAT)</p>
                                        :
                                        <p className="u-m-base-2">Total - £{(costs.wondeCost + costs.dataDashboardCost + ((costs.wondeCost + costs.dataDashboardCost) / 100 * 20)).toFixed(2)} (inc VAT)</p>
                                    }
                                </>
                            }

                            {showUrnForm === '' ? <button onClick={() => setShowUrnForm('both')} className={cx('btn-reset', styles.switchBtn)}><span></span>Activate</button> :
                                showUrnForm === 'both' && <MisFindByUrn withDataDashboard={true} purchasing={loading || editingRenewalSub} setShowUrnForm={setShowUrnForm} formErrors={formErrors} onConfirmWondeSchool={withRenewal ? editRenewalSub : purchaseDataDashOrWondeAccess} />
                            }
                        </div>

                        <div className="card-border u-m-top-2">
                            <div className="u-flex-center u-m-base-2 u-justify-between">
                                <p className="heavy">Wonde Integration (without Data Dashboard)</p>
                                <ImageFadeIn width={100} src={wondeLogo} alt='Wonde logo' />
                            </div>

                            {showUrnForm === '' ? <button onClick={() => setShowUrnForm('misOnly')} className={cx('btn-reset', styles.switchBtn)}><span></span>Activate</button> :
                                showUrnForm === 'misOnly' && (
                                    <>
                                        {costs &&
                                            <>
                                                {withRenewal ?
                                                    <p className="u-m-base-2">BoomReader Administration fee - £{(costs.wondeCost).toFixed(2)} (excl. VAT)</p>
                                                    :
                                                    <p className="u-m-base-2">BoomReader Administration fee - £{(costs.wondeCost + (costs.wondeCost / 100 * 20)).toFixed(2)} (inc VAT)</p>
                                                }
                                            </>
                                        }

                                        <MisFindByUrn withDataDashboard={false} purchasing={purchasingWondeOnly || editingRenewalSub} setShowUrnForm={setShowUrnForm} formErrors={formErrors} onConfirmWondeSchool={purchaseDataDashOrWondeAccess} />
                                    </>
                                )
                            }
                        </div>
                    </>
                    : currentUser.school.canAccessWonde && !currentUser.school.canAccessDataDashboard &&
                    <div className="card-border u-m-top-2">
                        <p className="u-m-base-2"><b>Data dashboard</b></p>

                        {costs && <p className="u-m-base-2">Total - £{(costs.dataDashboardCost + (costs.dataDashboardCost / 100 * 20)).toFixed(2)} (inc VAT)</p>}

                        {showUrnForm === '' ? <button onClick={() => setShowUrnForm('dataDashOnly')} className={cx('btn-reset', styles.switchBtn)}><span></span>Activate</button> :
                            showUrnForm === 'dataDashOnly' && <>
                                <Button
                                    disabled={loading}
                                    onClick={() => purchaseSchoolDataDashboardAccess({
                                        variables: {
                                            schoolId: currentUser?.school?.id,
                                            allowAccess: true,
                                            wondeAccess: false,
                                        }
                                    })}
                                >Purchase</Button>
                            </>
                        }
                    </div>
            }
        </>
    )
}

export default PurchaseDataDashboardAccess;