import { gql } from 'apollo-boost';

export const LOGOUT_MUTATION = gql`
mutation logOut($token: String!) {
    logOut(token: $token) {
        loggedOut
        errors
    }
}
`;

export const IMPERSONATE_LOGOUT_MUTATION = gql`
mutation impersonateLogOut($token: String!) {
    logOut(token: $token) {
        loggedOut
        errors
    }
}
`;