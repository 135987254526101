import React, { useContext, useState, useEffect } from "react";
import SyncStudents from "./components/SyncStudents";
import SyncStaff from "./components/SyncStaff";
import { AuthContext } from "../../services/auth/AuthProvider";
import { WONDE_SYNC_STEPS } from "../../services/constants";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_WONDE_SYNC_STEP, UPDATE_WONDE_SYNC_STEP } from "./services/graphql";
import SyncClasses from "./components/SyncClasses";
import AdditionalStaff from "./components/SyncStaff/AdditionalStaff";
import { CURRENT_TEACHER_QUERY } from '../Home/services/graphql'
import ButtonLink from "../../components/ButtonLink";

const WondeSync = () => {

    const { currentUser: user, setCurrentUser } = useContext(AuthContext);

    const [currentStep, setCurrentStep] = useState(null);

    const { data } = useQuery(GET_WONDE_SYNC_STEP, {
        variables: {
            schoolId: user && user.school.id
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ school }) => {
            setCurrentStep(school.wondeSyncStep);
        }
    });

    useEffect(() => {
        if (currentStep && currentStep >= data.school.wondeSyncStep && user.school?.wondeAccessApprovedAt) {
            updateStep({
                variables: {
                    schoolId: user && user.school.id,
                    wondeSyncStep: currentStep
                }
            })
        }
    }, [currentStep]);

    const [updateStep] = useMutation(UPDATE_WONDE_SYNC_STEP, {
        onCompleted: ({ updateWondeSyncStep }) => {
            if (updateWondeSyncStep.errors && updateWondeSyncStep.errors.length !== 0) {
                return;
            } else {
                if (currentStep === 99) {
                    setCurrentUser({ ...user, school: { ...user.school, wondeSyncStep: 99 } })
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: CURRENT_TEACHER_QUERY }]
    })

    const renderSteps = () => {
        switch (currentStep) {
            case WONDE_SYNC_STEPS.syncTeachers:
                return <SyncStaff currentStep={currentStep} setCurrentStep={setCurrentStep} />
            case WONDE_SYNC_STEPS.syncStudentsUpn:
            case WONDE_SYNC_STEPS.syncStudentsSuggested:
            case WONDE_SYNC_STEPS.syncStudentsManual:
                return <SyncStudents currentStep={currentStep} setCurrentStep={setCurrentStep} />
            case WONDE_SYNC_STEPS.syncClasses:
                return <SyncClasses setCurrentStep={setCurrentStep} />
            case WONDE_SYNC_STEPS.syncExtraStaff:
                return <AdditionalStaff setCurrentStep={setCurrentStep} />
            case WONDE_SYNC_STEPS.syncCompleted:
                return (
                    <>
                        <h3 className="heavy u-m-base-3">Sync completed!</h3>
                        <ButtonLink to="/">Back to dashboard</ButtonLink>
                    </>
                )
            default:
                return <p>Loading...</p>
        }
    }


    if (user?.school?.wondeAccessApprovedAt) {
        return (
            <div className="card-mobile">
                {/* <p>currentStep: {currentStep}</p> */}
                {currentStep !== null && renderSteps()}
            </div>
        )
    }
    
    return null;
}

export default WondeSync;