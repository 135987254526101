import React from 'react'
import * as Icon from 'react-feather'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import styles from './Share.module.scss';

const Share = ({ url, title }) => {

    return (
        <div className={styles.share}>
            <div className={styles.shareLayout}>
                <FacebookShareButton
                    url={url}
                    title={title}
                    className={`${styles.shareBtn} ${styles.facebook}`}
                    resetButtonStyle={false}
                >
                    <Icon.Facebook/>
                </FacebookShareButton>
                <TwitterShareButton
                    url={url}
                    title={title}
                    className={`${styles.shareBtn} ${styles.twitter}`}
                    resetButtonStyle={false}
                >
                    <Icon.Twitter />
                </TwitterShareButton>
            </div>
        </div>
    )
}

export default Share