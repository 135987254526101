import React, { Fragment } from 'react';
import { ArrowLeft, PlusCircle } from 'react-feather';
import { Link } from '@reach/router';
import styles from './ViewPackItem.module.scss';
import {  isCurrentAcademicYear } from '../../../../services/utils';
import moment from 'moment';

const ViewPackItem = ({ pack, setViewPack }) => {    
    return(
        <div className={styles.pack}>
            <div className='u-m-y-2'>
                <button className='btn-reset u-text-primary' onClick={() => setViewPack(null)}>
                    <ArrowLeft size="24"></ArrowLeft>
                </button>
            </div>
            {pack.writingActivity ? <>
                <h2 className='u-m-base-1'>Writing Activities</h2>
                <h2>{pack.studentsUsing}/{pack.invoiceRequest.studentsCounter} Pupils</h2>
                <p className="u-bold">Purchased: {moment(new Date(pack.invoiceRequest.orderedAt)).format('DD MMM YYYY')}</p>
                <p className="u-bold">Expires: {moment(new Date(pack.expiresAt)).format('DD MMM YYYY')}</p>
                <p className="u-m-top-2">To edit this subscription please contact <a href="mailto:support@boomhub.app">support@boomhub.app</a></p>
            </> :
            <>
                <h2 className='u-m-base-1'>{pack.packOffer}</h2>
                <p className='u-m-base-1'>Purchased: {pack.purchasedAt}</p>
                <p className='u-m-base-3'>Academic Year: {pack.academicYear}</p>

                <div className={`${!pack.students_class && styles.packActions}`}>
                    <p>{pack.students} pupils</p>
                    {
                        isCurrentAcademicYear(pack.academicYear) && (
                            <Fragment>
                                {
                                    pack.students_class ?  (
                                        <p>Class: {pack.students_class }</p>
                                    ) : (
                                        <Link to='/classes?showAll=true'  className='link-reverse icon-title'>Assign Class <PlusCircle className='u-m-left-1' size="16"/></Link>
                                    )
                                }
                            </Fragment>
                        )
                    }
                </div>
            </>
            }
        </div>
    )
}

export default ViewPackItem;