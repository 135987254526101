import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { UPDATE_WEEK_START } from '../services/graphql';
import { WEEK_STARTS } from '../../../services/constants';
import { useTranslation } from 'react-i18next';

const SchoolWeekForm = ({ currentUser, setShowSaveConfirm, setCurrentUser }) => {

    const { t } = useTranslation(['profile', 'common']);


    const [formErrors, setFormErrors] = useState([]);

    const [updateSchoolWeekStart, { loading }] = useMutation(UPDATE_WEEK_START, {
        update: (_, { data }) => {
            if (data.updateSchoolWeekStart.errors && data.updateSchoolWeekStart.errors.length !== 0) {
                setFormErrors(data.updateSchoolWeekStart.errors);
                return;
            }
            if (data.updateSchoolWeekStart.school.id) {
                const cu = { ...currentUser, school: data.updateSchoolWeekStart.school }
                setCurrentUser(cu);
                setShowSaveConfirm(true);
            }
        },
    });

    const schema = Yup.object().shape({
        weekStart: Yup.number().required().typeError(t('please_select_week_start')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const onSubmit = values => {
        const { weekStart } = values
        updateSchoolWeekStart({
            variables: {
                schoolId: currentUser.school.id,
                weekStart
            }
        });
    }

    return (
        <div className='row'>
            <div className="col-md-5">
                <p className='lead heavy'>{t('your_school_week')}</p>
                <p className=' u-m-base-2 u-m-top-1'>This is when BoomReader will count data for a week in the system.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='basic-form__group'>
                        <select
                            name='weekStart'
                            className='basic-form__text-select'
                            ref={register}
                            defaultValue={currentUser.school.weekStart}
                        >
                            <option value=''>Please select</option>
                            {Object.entries(WEEK_STARTS).map(week => {
                                return (<option key={`week-${week[0]}`} value={week[0]}>{week[1]}</option>)
                            })}
                        </select>
                    </div>
                    {errors.weekStart && <p className='basic-form__hint'>{errors.weekStart.message}</p>}
                    <p className='small u-m-base-2'>{t('school_week_info')}</p>


                    {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                    <div className='u-display-flex u-align-center'>
                        <Button type='submit' disabled={loading} primary className='u-m-right-1'>{loading ? t('common:saving') : t('common:button.save')}</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default SchoolWeekForm;