import React from 'react';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

const UploadActionCable = ({ setReceivedData, ticketId }) => {

    return (
        <ActionCableConsumer
            channel={{
                channel: "ImportDataChannel",
                id: `${ticketId}`
            }}
            onReceived={(message) => {
                setReceivedData(message)
            }}
        ></ActionCableConsumer>
    );
};

export default UploadActionCable;

