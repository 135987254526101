import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Loader } from 'react-feather';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Modal } from '@axeedge/go-teacher-components';
import BillingProfileForm from '../../Billing/components/BillingProfileForm';
import { CREATE_STRIPE_CUSTOMER_AND_INTENT, ADD_STRIPE_PAYMENT_METHOD, GET_MATH_SUBSCRIPTION } from '../../../services/graphql';
import { XCircle } from 'react-feather';
import styles from '../BoomMathSettings.module.scss';
import cx from 'classnames';

const RECEIPT_SETTING = {
    personal: 1,
    other: 3
}

const CardFormUpdate = ({ school, user, onDone, setConfirmSuccess, confirmSuccess, close }) => {

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [card, setCard] = useState(null);

    const [receiptSetting, setReceiptSetting] = useState(null);
    const [editBillingProfile, setEditBillingProfile] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const { handleSubmit } = useForm({
        mode: 'onSubmit'
    });


    const [addStripePaymentMethod, { loading: updatingCard }] = useMutation(ADD_STRIPE_PAYMENT_METHOD, {
        onCompleted: ({ addStripePaymentMethod }) => {
            if (addStripePaymentMethod.errors && addStripePaymentMethod.errors.length !== 0) {
                alert(addStripePaymentMethod.errors[0]);
                setProcessing(false);
                return;
            }
            if (addStripePaymentMethod && addStripePaymentMethod.school) {
                setProcessing(false);
                setConfirmSuccess(true);
                setTimeout(() => {
                    onDone(addStripePaymentMethod.school);
                }, 400)
            }
        },
        refetchQueries: [{ query: GET_MATH_SUBSCRIPTION, variables: { schoolId: school.id } }]
    })

    const onPaymentIntent = async (clientSecret) => {
        const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card,
                billing_details: null
            }
        });
        if (setupIntent && setupIntent.status === 'succeeded') {
            addStripePaymentMethod({
                variables: {
                    schoolId: school.id,
                    stripePmId: setupIntent.payment_method
                }
            })
        } else {
            setProcessing(false);
            setShowErrorModal(error.message);
        }
    }

    const [createStripeCustomerAndIntent] = useMutation(CREATE_STRIPE_CUSTOMER_AND_INTENT, {
        onCompleted: ({ createStripeCustomerAndIntent }) => {
            if (createStripeCustomerAndIntent.errors && createStripeCustomerAndIntent.errors.length !== 0) {
                alert(createStripeCustomerAndIntent.errors[0]);
                setProcessing(false);
            } else {
                onPaymentIntent(createStripeCustomerAndIntent.customerSecret);
            }
        }
    });


    const onSubmit = () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        setProcessing(true);
        setCard(elements.getElement(CardElement));
        createStripeCustomerAndIntent({
            variables: {
                schoolId: school.id,
                inputData: receiptSetting
            }
        });
    }


    return (
        <div className='row'>
            <div className='col-md-8'>
                <div className={styles.cancelHeader}>
                    <h3>Update your card details</h3>
                    <button className='btn-reset' onClick={close}><XCircle /></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='u-m-base-3 u-m-top-2'>
                        <h4>Enter card details</h4>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        color: '#4A4A4A',
                                        fontSize: '16px'
                                    }
                                }
                            }}
                        />
                    </div>

                    <p className='heavy'>Receipt Settings</p>
                    <p>Please confirm the details for your receipt</p>
                    <ul className={styles.receiptOpts}>
                        <li onClick={() => setReceiptSetting(RECEIPT_SETTING.personal)}>
                            <div className={styles.receiptOptsMain}><span className={cx(styles.receiptOptsBullet, { [styles.receiptOptsBulletActive]: receiptSetting === RECEIPT_SETTING.personal })}></span> Use my details</div>
                            {receiptSetting === RECEIPT_SETTING.personal && (
                                <div className='u-m-base-2'>
                                    <p>Name: <span className='u-m-left-1'>{user.firstName} {user.lastName}</span></p>
                                    <p>Email: <span className='u-m-left-1'>{user.email}</span></p>
                                </div>
                            )}
                        </li>
                        <li onClick={() => setReceiptSetting(RECEIPT_SETTING.other)}>
                            <div className={styles.receiptOptsMain}><span className={cx(styles.receiptOptsBullet, { [styles.receiptOptsBulletActive]: receiptSetting === RECEIPT_SETTING.other })}></span> Use School or other details</div>
                            {receiptSetting === RECEIPT_SETTING.other &&
                                <>
                                    <p><b>{school.billingProfile.companyName}</b></p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>{school.billingProfile.address}</p>
                                            <p>{school.billingProfile.address2}</p>
                                            <p>{school.billingProfile.city}</p>
                                            <p>{school.billingProfile.state.code}</p>
                                            <p>{school.billingProfile.zip}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>Contact: {school.billingProfile.contactPerson}</p>
                                            <p>Email: {school.billingProfile.contactEmail}</p>
                                            <p>Phone: {school.billingProfile.contactPhone}</p>
                                        </div>
                                    </div>
                                    <button
                                        type='button'
                                        onClick={() => setEditBillingProfile(true)}
                                        className='btn-reset link-underline u-m-base-2'>
                                        Edit billing profile</button>
                                </>
                            }
                        </li>
                    </ul>
                    <p >Your subscription will automatically renew each year unless you cancel.</p>

                    <Button
                        className='u-m-top-2'
                        disabled={!(stripe && elements) || updatingCard || !receiptSetting}
                        type='submit'
                    >Update details</Button>
                </form>


                {(processing || confirmSuccess) &&
                    <div className={styles.processing}>
                        {processing && <Loader className={styles.processingLoader} />}
                        <p className={styles.processingText}>{processing ? 'Processing, please wait' : 'Card updated successfully!'}</p>

                    </div>
                }

                {showErrorModal &&
                    <Modal closeModal={() => setShowErrorModal(false)}>
                        <h1>Payment failed</h1>
                        <p className='u-m-base-2'>
                            {showErrorModal}
                        </p>
                    </Modal>
                }

                {editBillingProfile &&
                    <Modal wide closeModal={() => setEditBillingProfile(false)}>
                        <BillingProfileForm isEdit={true} school={school} close={() => setEditBillingProfile(false)} />
                    </Modal>
                }
            </div>
        </div>
    )
}

export default CardFormUpdate;