import React, { useState } from 'react';
import PackItem from '../PackItem';
import { listPurchasedPackInstances } from '../../../../services/utils';

const PurchasedPacksList = ({ purchasedPacks, setViewPack }) => {

    const getYearFilters = purchasedPacks => {
        const academicYears = [];
        purchasedPacks.map(pack => academicYears.push(pack.academicYear));
        return [...new Set(academicYears)].sort((a, b) => b.localeCompare(a));
    }

    const [yearFilter, setYearFilter] = useState(getYearFilters(purchasedPacks)[0]);

    const packs = listPurchasedPackInstances(purchasedPacks);

    return (
        <div className='card card-mobile'>
            <h4>Manage Packs
                {
                    packs && packs.length > 0 && (
                        <select
                            className='basic-form__text-select basic-form__text-select--inline u-m-left-2'
                            defaultValue={yearFilter}
                            onChange={(e) => setYearFilter(e.target.value)}
                        >
                            {getYearFilters(purchasedPacks).map((year, i) => <option key={`year-${i}`} value={year}>{year}</option>)}
                        </select>
                    )
                }

            </h4>
            <ul className='list-group'>
                {
                    packs && packs.length ? packs.filter(p => p.academicYear === yearFilter ).map(pack => {
                        return <PackItem setViewPack={setViewPack} pack={pack} key={`p-item-${pack.id}`} />
                    }) : (
                        <p>No packs purchased yet</p>
                    )
                }
            </ul>
        </div>
    )
}

export default PurchasedPacksList;