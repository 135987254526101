import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { FormErrors, Button, Modal } from '@axeedge/go-teacher-components';
import styles from './InvoiceDetails.module.scss';
import { useTranslation } from 'react-i18next';
import BillingProfileForm from '../../../scenes/Settings/scenes/Billing/components/BillingProfileForm'

const InvoiceDetailsForm = ({ user, purchaseType, totalValue, formErrors, onSubmit, loading = false, back = null, tourChecked = false, currentReadSub = null }) => {

    const [showEditBilling, setShowEditBilling] = useState(false);

    const { t } = useTranslation(['apps', 'profile', 'common']);

    const { school, email } = user;

    const tosLink = 'https://goapps.app/terms';

    const schema = Yup.object().shape({
        contactPerson: Yup.string()
            .required(t('please_enter_contact_name')),
        contactEmail: Yup.string()
            .required(t('please_enter_contact_email')),
        contactPhone: Yup.string()
            .required(t('please_enter_school_phone')),
        tos: Yup.boolean().oneOf([true], t('profile:must_agree_to_tos')),
        authority: Yup.boolean().oneOf([true], t('profile:must_have_authority'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!tourChecked && (
                    <Fragment>
                        <h3 className='heavy'>{t('common:po_number')} <span className='u-m-left-1 light'>({t('common:optional')})</span></h3>
                        <div className='basic-form__group'>
                            <input
                                name='purchaseOrderReferenceNumber'
                                id='purchaseOrderReferenceNumber'
                                className='basic-form__text-box'
                                type='text'
                                placeholder={t('common:po_number_placeholder')}
                                ref={register}
                            />
                        </div>
                    </Fragment>
                )}

                <h3 className='heavy u-m-top-2'>Billing details</h3>
                <p className='u-m-base-1'>
                    {tourChecked ? t('grand_tour_invoice_info') : 'Please check you we have the correct billing profile:'}
                </p>

                {user?.school?.billingProfile && (
                    <>
                        <p><b>{user.school.billingProfile.companyName}</b></p>
                        <div className="u-m-base-1">
                            <p>{user.school.billingProfile.address}</p>
                            <p>{user.school.billingProfile.address2}</p>
                            <p>{user.school.billingProfile.city}</p>
                            <p>{user.school.billingProfile.state}</p>
                            <p>{user.school.billingProfile.zip}</p>
                            <p>{user.school.billingProfile.country?.name}</p>
                        </div>
                        <div className="u-m-base-2">
                            <p>Contact: {user.school.billingProfile.contactPerson}</p>
                            <p>Email: {user.school.billingProfile.contactEmail}</p>
                            <p>Phone: {user.school.billingProfile.contactPhone}</p>
                        </div>
                    </>
                )}
                <button type='button' className='btn-reset link-underline' onClick={() => setShowEditBilling(true)}>Edit billing profile</button>

                <p className='u-m-top-2'><b>Confirm purchase</b></p>
                {!tourChecked && <p className='u-m-base-2'>{t('common:service_starts_immediately')}</p>}

                <div className="basic-form__group basic-form__group--check">
                    <input
                        name="tos"
                        className="basic-form__check-box"
                        ref={register}
                        type="checkbox"
                        id="tos"
                    />
                    <label className="basic-form__check-label" htmlFor="tos">{t('common:i_have_read_and_accept_the')} </label> <a href={tosLink} rel='noopener noreferrer' target='blank' className='u-m-left-1'>{t('common:terms_and_conditions')}</a>
                </div>
                {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}
                <div className="basic-form__group basic-form__group--check">
                    <input
                        name="authority"
                        className="basic-form__check-box"
                        ref={register}
                        type="checkbox"
                        id="authority"
                    />
                    {tourChecked ?
                        <label className="basic-form__check-label" htmlFor="authority">{t('have_authority_to_start_trial_subscription')}</label> :
                        <label className="basic-form__check-label" htmlFor="authority">{t('common:i_have_authority', { type: purchaseType === 'packs' ? 'purchase' : 'subscription' })}</label>
                    }
                </div>
                {errors.authority && <p className='basic-form__hint'>{errors.authority.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                <input
                    name="contactIsFinancialManager"
                    ref={register}
                    type="checkbox"
                    className={styles.hiddenCheckbox}
                    checked={school.billingProfile?.contactEmail === email}
                    readOnly
                />
                <input
                    name="contactPerson"
                    ref={register}
                    type="hidden"
                    defaultValue={school.billingProfile.contactPerson}
                />
                <input
                    name="contactEmail"
                    ref={register}
                    type="hidden"
                    defaultValue={school.billingProfile.contactEmail}
                />
                <input
                    name="contactPhone"
                    ref={register}
                    type="hidden"
                    defaultValue={school.billingProfile.contactPhone}
                />
                {purchaseType === 'subscription' && +totalValue === 0 && <p className='basic-form__hint u-m-top-2'>Please select how many pupils should have access to BoomReader</p>}
                {(!school.billingProfile.contactPhone || !school.billingProfile.contactEmail || !school.billingProfile.contactPhone) && <p onClick={() => setShowEditBilling(true)} className='u-text-danger u-m-top-2'>Please fill in all Billing details</p>}

                <Button type='submit' disabled={+totalValue === 0 || !school.billingProfile.contactPhone} className="u-m-right-1 u-m-top-2 u-m-base-2">{loading ? t('common:saving') : (purchaseType === 'subscription' && !currentReadSub) ? 'Purchase with 30 Day Trial' : t('common:button.purchase')}</Button>
            </form>
            {
                showEditBilling && (
                    <Modal wide closeModal={() => setShowEditBilling(false)}>
                        <BillingProfileForm school={school} close={() => setShowEditBilling(false)} />
                    </Modal>
                )
            }
        </Fragment>
    )
}

export default InvoiceDetailsForm;