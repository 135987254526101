import React from 'react'
import styles from './NavBar.module.scss';
import { Link } from '@reach/router';
import cx from 'classnames'
import {Lock} from 'react-feather'
import { I18nContext } from 'react-i18next';

const NavBar = ({step}) => {
    return (
        <div className={styles.navBar}>
            <Link to="/classes/getReady" className={cx(styles.navLink, step === 1 && styles.navLinkActive)}>Getting Ready</Link>
            {step < 2 ?
            <span className={styles.navLink}>Registration Classes<Lock style={{marginLeft: '5px'}} /></span>
            :
            <Link to="/classes/moveClasses" className={cx(styles.navLink, step === 2 && styles.navLinkActive)}>Registration Classes</Link>
            }
            {step < 3 ?
            <span className={styles.navLink}>Other Groups<Lock style={{marginLeft: '5px'}} /></span> :
            <Link to="/classes/moveGroups" className={cx(styles.navLink, step === 3 && styles.navLinkActive)}>Other Groups</Link>
            }
            {step < 4 ?
            <span className={styles.navLink}>New Intake<Lock style={{marginLeft: '5px'}} /></span> :
            <Link to="/" className={cx(styles.navLink, step === 4 && styles.navLinkActive)}>New Intake</Link>
            }
        </div>
    )
}

export default NavBar