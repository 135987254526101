import React, { useContext, useEffect, useRef, useState } from 'react';
import { GET_ARCHIVED_CLASSES, } from '../../services/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { UNARCHIVE_CLASSES } from '../../scenes/NewAcademicYear/services/graphql';
import { GET_CLASSES_QUERY } from '../../services/graphql';
import { Link } from '@reach/router';
import { Button, FormErrors, Modal, Loader } from '@axeedge/go-teacher-components';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { useClickAway } from 'react-use';
import { AlertCircle, ChevronDown } from 'react-feather';
import styles from '../../Classes.module.scss';

const ArchivedClasses = () => {

    const { currentUser: user } = useContext(AuthContext);
    const [classes, setClasses] = useState([]);

    const [selectedClasses, setSelectedClasses] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const [confirmUnArchive, setConfirmUnArchive] = useState(false);

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));

    const { data, error, loading } = useQuery(GET_ARCHIVED_CLASSES, {
        variables: {
            includeAdminedClasses: user && user.isSchoolAdmin,
            onlyArchived: true
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (data && data.myClasses) {
            setClasses(data.myClasses);
        }
    }, [data]);

    const [unarchiveClasses, { loading: unarchiveLoading }] = useMutation(UNARCHIVE_CLASSES, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.unarchiveMultipleClasses.errors && data.unarchiveMultipleClasses.errors.length > 0) {
                setFormErrors(data.unarchiveMultipleClasses.errors)
            } else {
                setConfirmUnArchive(false);
                setSelectedClasses([])
            }
        },
        refetchQueries: () => [
            {
                query: GET_CLASSES_QUERY,
                variables: {
                    includeAdminedClasses: user && user.isSchoolAdmin,
                },
            },
            {
                query: GET_ARCHIVED_CLASSES,
                variables: {
                    includeAdminedClasses: user && user.isSchoolAdmin,
                    onlyArchived: true
                }
            }
        ]
    });

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(t => t !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const onSelectAllClasses = e => {
        const clsIds = classes.filter(c => c.academicYear === user?.school.currentAcademicYear).map(t => t.id);
        e.target.checked ? setSelectedClasses(clsIds) : setSelectedClasses([])
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.myClasses) {
        return (
            <>
                <h3 className='u-display-flex u-justify-between u-align-center'>
                    Archived classes
                </h3>
                <hr className='separator' />

                {user.isSchoolAdmin && classes.find(c => c.academicYear === user?.school.currentAcademicYear) &&
                    <div className={styles.actions}>
                        <div className={styles.actionsLeft}>
                            {classes.length > 0 && (
                                <>
                                    <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                        <input
                                            name="all"
                                            className="basic-form__check-box"
                                            type="checkbox"
                                            checked={classes.length > 0 && selectedClasses.length === classes.filter(c => c.academicYear === user?.school.currentAcademicYear).length}
                                            onChange={(e) => onSelectAllClasses(e)}
                                            id="selectAll"
                                        />
                                        <label className="basic-form__check-label" htmlFor='selectAll'>All / None</label>
                                    </div>
                                    <div ref={ref} className='dropdownHolder'>
                                        <button disabled={selectedClasses.length === 0} className='dropdownBtn' onClick={() => setDropdown(!dropdown)}>Actions <ChevronDown /> </button>
                                        {dropdown && (
                                            <ul className='dropdownMenu'>
                                                <li><button
                                                    className='dropdownMenuItem'
                                                    onClick={() => setConfirmUnArchive(true)}
                                                >Un-Archive classes</button></li>
                                            </ul>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                }

                {formErrors.length > 0 && <FormErrors errors={formErrors} />}

                <ul className='list-group'>
                    {classes.length > 0 ? classes.sort((a, b) => b.academicYear - a.academicYear).map(studentClass => (
                        <li key={studentClass.id} className='list-item'>
                            {user.isSchoolAdmin && studentClass.academicYear === user?.school.currentAcademicYear &&
                                <div className="basic-form__group basic-form__group--check u-m-base-0 u-m-top-1">
                                    <input
                                        type='checkbox'
                                        className="basic-form__check-box"
                                        value={studentClass.id}
                                        onChange={(e) => onSelectClass(e)}
                                        id={`t-${studentClass.id}`}
                                        checked={selectedClasses.indexOf(studentClass.id) > -1}
                                    />
                                    <label className="basic-form__check-label" htmlFor={`t-${studentClass.id}`}>&nbsp;</label>
                                </div>
                            }
                            <Link className='link-reverse' to={`/classes/archived/${studentClass.id}`}>{studentClass.name}</Link>
                            <p className='small u-m-left-auto'>{studentClass.description}</p>
                        </li>
                    )) : <p>No classes</p>}
                </ul>

                {confirmUnArchive && (
                    <Modal closeModal={() => setConfirmUnArchive(false)}>
                        <h1>Un-Archive {`${selectedClasses.length === 1 ? '1 class' : selectedClasses.length + ' classes'}`} </h1>
                        <div className={styles.confirmArchive}>
                            <div className="u-display-flex u-align-center">
                                <AlertCircle className='u-m-right-2' />
                                <p className='lead heavy u-m-base-0'>Are you sure?</p>
                            </div>
                        </div>
                        <Button
                            className='u-m-right-1'
                            disabled={unarchiveLoading}
                            onClick={() =>
                                unarchiveClasses(
                                    { variables: { studentsClassIds: selectedClasses } }
                                )
                            }>{`${unarchiveLoading ? 'Saving...' : `Yes, un-archive ${selectedClasses.length === 1 ? 'class' : 'classes'}`}`}</Button>
                        <Button outline onClick={() => setConfirmUnArchive(false)}>Cancel</Button>
                    </Modal>
                )}
            </>
        );
    }
    return null;
}

export default ArchivedClasses;
