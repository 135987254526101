import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { getYearGroupLabel } from '../../../services/utils';
import { ADD_STUDENT_MUTATION, GET_YEAR_STATS, GET_FILTERED_STUDENTS,GET_UNASSIGNED_STUDENTS_QUERY  } from '../services/graphql';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import SaveConfirm from '../../../components/SaveConfirm';

const AddStudent = ({schoolId, currentYear = 'UPN', source = null, onAdded}) => {

    const { t } = useTranslation(['classes', 'common']);

    const isUS = ls('country') === 'US';

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        yearGroup: Yup.number().typeError(isUS ? 'Please select grade' : 'Please select year group')
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const getQueries = () => {
        if (currentYear === 'UPN') {
            return {
                schoolId: schoolId,
                withoutUpn: true,
            }
        } else {
            return {
                schoolId: schoolId,
                yearGroup: currentYear && currentYear.toString(),
            }
        }
    }

    const [addStudent, { loading }] = useMutation(ADD_STUDENT_MUTATION, {
        refetchQueries: [
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: schoolId,
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId: schoolId,
                }
            }
        ],
        onCompleted: ({ addStudent }) => {
            if (addStudent.errors && addStudent.errors.length !== 0) {
                setFormErrors(addStudent.errors);
                return;
            } else {
                reset({
                    firstName: '',
                    lastName: '',
                    yearGroup: 'blank',
                    upn: ''
                });
                setFormErrors([]);
                setShowSaveConfirm(true);
                if (source === 'NewYear') {
                    onAdded(addStudent.student)
                }
            }
        }
    })

    const onSubmit = values => {
        const { firstName, lastName, yearGroup, upn } = values;
        addStudent({
            variables: {
                firstName,
                lastName,
                displayName: `${firstName} ${lastName}`,
                email: `${firstName}_${lastName}@null.com`,
                yearGroup,
                upn,
                schoolId
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className='u-m-base-1'>{t('create_new_pupil')}</p>

            <div className='basic-form__group'>
                <input
                    name='firstName'
                    className='basic-form__text-box'
                    type='text'
                    placeholder={t('common:first_name')}
                    ref={register}
                />
            </div>
            {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
            <div className='basic-form__group'>
                <input
                    name='lastName'
                    className='basic-form__text-box'
                    type='text'
                    placeholder={t('common:last_name')}
                    ref={register}
                />
            </div>
            {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}

            <div className='basic-form__group'>
                <select defaultValue='blank' ref={register} name="yearGroup" className="basic-form__text-select">
                    <option value='blank'>{isUS ? 'Select grade' : 'Select year group'}</option>
                    {YEAR_GROUPS.map(yearGroup => (
                        <option key={yearGroup.val} value={yearGroup.val}>{getYearGroupLabel(yearGroup.val)}</option>
                    ))}
                </select>
            </div>
            {errors.yearGroup && <p className='basic-form__hint'>{errors.yearGroup.message}</p>}

            {!isUS &&
                <div className='basic-form__group'>
                    <input
                        name='upn'
                        id='upn'
                        placeholder='UPN'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
            }


            <Button type='submit'>
                {loading ? t('common:adding') : t('common:button.add')}
            </Button>


            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
            {showSaveConfirm && <SaveConfirm message={t('pupil_added')} hideConfirm={setShowSaveConfirm} />}
        </form>
    )
}

export default AddStudent;
