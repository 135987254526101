import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { IMPORT_STUDENTS_CLASS_QUERY } from "../../services/graphql";
import { Button, Loader } from "@axeedge/go-teacher-components";

const PreviewClassPupils = ({ stClass, closePreview }) => {

    const { data, loading, error } = useQuery(IMPORT_STUDENTS_CLASS_QUERY, {
        variables: {
            id: stClass.id
        }
    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }
    if (data && data.importStudentsClass) {
        return (
            <>
                <h2>Pupils in {stClass.name}</h2>
                <ul className="list-group u-m-base-2" style={{ maxHeight: '500px', overflow: 'auto' }}>
                    {data.importStudentsClass.importedStudents.map(student => (
                        <li key={`st-${student.id}`} className="list-item">
                            <div className='list-item--child'>
                                {student.firstName} {student.lastName} 
                            </div>
                            <span className="u-m-left-auto">Year group {student.yearGroup}</span>
                        </li>
                    ))}
                </ul>
                <Button onClick={closePreview}>Close</Button>
            </>
        )
    }
}

export default PreviewClassPupils;