import React, {Fragment} from 'react'
import styles from '../NewAcademicYear/NewAcademicYear.module.scss';
import { Button } from '@axeedge/go-teacher-components';
import { Link, navigate } from '@reach/router';
import NavBar from '../../components/NewAcademicYearNav'
import {ArrowLeft} from 'react-feather'

const NewIntake = () => {

    return (
        <Fragment>
            <div className={styles.pageHeader}>
                <div>
                <Link to="/classes/getReady" className={styles.backButton}>
                    <ArrowLeft size="30"/>
                </Link>
                </div>
                <h1 className={styles.pageHeading}>Start Academic Year 2022/2023</h1>
            </div>
            <NavBar step={4} />
            <div className="card card-mobile">
                <h2 className="u-bold">Setup Complete!</h2>
                <h2 className="u-bold">New Intake</h2>
                <p>You can now create your new nursery/reception classes in the usual way.</p>
                <p>You can also create any new pupile accounts directly in the Registration classes you have just set up.</p>
                <div style={{marginRight: 'auto', marginTop: '20px'}}>
                    <Button onClick={() => navigate('/classes/')}>View &amp; Manage Classes</Button>
                </div>
            </div>
        </Fragment>
    )
}

export default NewIntake;