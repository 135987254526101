import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { CANCEL_BOOM_MATH_ACCESS, GET_MATH_SUBSCRIPTION } from '../../services/graphql';
import styles from './BoomMathSettings.module.scss';
import { File, XCircle } from 'react-feather';
import { MATH_PRICE_PER_STUDENT } from '../../../../services/constants';
import UpdateCardDetails from './UpdateCardDetails';
import RestartSubscription from '../RestartSubscription';
import { MATH_SUB_STATUS } from '../../../../services/constants';

const BoomMathDashboard = ({ user, setCurrentUser, subscription }) => {

    const [confirmCancel, setConfirmCancel] = useState(false);
    const [showUpdateCard, setShowUpdateCard] = useState(false);
    const [showCreateSub, setShowCreateSub] = useState(false);
    const [enableCancel, setEnableCancel] = useState(false);
    const [cancelErrors, setCancelErrors] = useState([]);

    const now = moment();
    const daysTillSubExpires = moment(subscription.expiresAt.substring(0, 10)).diff(now, 'days');

    const [cancelBoomMathAccess, { loading: cancelling }] = useMutation(CANCEL_BOOM_MATH_ACCESS, {
        update: (_, { data }) => {
            if ((data && data.cancelBoomMathAccess.errors && data.cancelBoomMathAccess.errors.length > 0)) {
                setCancelErrors(data.cancelBoomMathAccess.errors);
                return;
            }
            if (data?.cancelBoomMathAccess?.school?.id) {
                const cu = { ...user, school: data.cancelBoomMathAccess.school }
                setCurrentUser(cu);
                setConfirmCancel(false);
                setEnableCancel(false);
            }
        },
        variables: {
            schoolId: user?.school.id
        },
        refetchQueries: [{ query: GET_MATH_SUBSCRIPTION, variables: { schoolId: user?.school.id } }]
    });


    const renderSubLabel = () => {
        if (subscription.isTrial && subscription.status === MATH_SUB_STATUS.active && daysTillSubExpires >= 0) {
            return <span className={styles.labelTrial}>Trial</span>
        }
        if ((subscription.status === MATH_SUB_STATUS.cancelled_renewal && daysTillSubExpires < 0) || daysTillSubExpires < 0) {
            return <span className={styles.labelCancelled}>Expired</span>
        }
        if (subscription.status === MATH_SUB_STATUS.active) {
            return <span className={styles.labelActive}>Active</span>
        }
        if (daysTillSubExpires > 0 && (subscription.status === MATH_SUB_STATUS.cancelled || subscription.status === MATH_SUB_STATUS.cancelled_renewal)) {
            return <span className={styles.labelCancelled}>Cancelled</span>
        }

        return null;
    }

    if (confirmCancel) {
        return (
            <div className={styles.cancel}>
                <div className={styles.cancelHeader}>
                    <h3>Cancel your {subscription.isTrial ? 'trial' : 'subscription'}</h3>
                    <button className='btn-reset' onClick={() => { setConfirmCancel(false); setEnableCancel(false) }}><XCircle /></button>
                </div>
                <p>{daysTillSubExpires} days remaining</p>
                {!subscription.isTrial && <p className='u-m-base-2'>Unless you cancel, your card will be charged ${(subscription.subscribedSeats * MATH_PRICE_PER_STUDENT).toFixed(2)} on {moment(subscription.expiresAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</p>}
                <h3>Are you sure?</h3>
                <div className={styles.cancelPanel}>
                    {subscription.isTrial ?
                        <p>If you cancel your trial you'll immediately lose access to BoomMath.</p> :
                        <p>If you cancel you will be able to continue to access BoomMath until the end of your current subscription term.</p>
                    }
                    <div className="basic-form__group basic-form__group--check u-m-base-2 u-m-top-2">
                        <input
                            id='startCancel'
                            type='checkbox'
                            value={enableCancel}
                            checked={enableCancel}
                            className='basic-form__check-box'
                            onChange={(e) => setEnableCancel(e.target.checked)}
                        />
                        <label htmlFor='startCancel' className="basic-form__check-label">Yes, please cancel my {subscription.isTrial ? 'trial' : 'subscription'}</label>
                    </div>
                    {cancelErrors && <FormErrors errors={cancelErrors} />}
                    <Button disabled={!enableCancel || cancelling} onClick={() => cancelBoomMathAccess()}>{cancelling ? 'Saving...' : subscription.isTrial ? 'Please cancel my trial' : 'Cancel subscription'}</Button>
                </div>
            </div>
        )
    }

    return (
        <div className='card-mobile'>
            {showUpdateCard ? (
                <UpdateCardDetails close={() => setShowUpdateCard(false)} />
            ) : showCreateSub ? (
                <RestartSubscription
                    close={() => setShowCreateSub(false)}
                    school={user?.school} />)
                : (
                    <>
                        {subscription.isTrial &&
                            <div className={styles.subInfo}>
                                <div className={styles.subInfoMain}>
                                    <h2>Your subscription  {renderSubLabel()}</h2>
                                    <ul className={styles.subDates}>
                                        <li>
                                            <span className={styles.subDatesLabel}>Trial started: </span>
                                            <span>{moment(subscription.boomMathTrialStartedAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                        </li>
                                        {subscription.status === MATH_SUB_STATUS.active &&
                                            <li>
                                                <span className={styles.subDatesLabel}>Expires on: </span>
                                                <span>{moment(subscription.expiresAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                            </li>
                                        }
                                    </ul>
                                    <Button onClick={() => setShowCreateSub(true)}>Start annual subscription</Button>
                                </div>

                                {daysTillSubExpires >= 0 && subscription.status !== MATH_SUB_STATUS.cancelled && subscription.status !== MATH_SUB_STATUS.cancelled_renewal &&  (
                                    <div className={styles.subInfoSide}>
                                        <p>{daysTillSubExpires} days remaining</p>
                                        <button className='btn-reset link-underline' onClick={() => setConfirmCancel(true)}>Cancel subscription</button>
                                    </div>
                                )}
                            </div>
                        }
                        {!subscription.isTrial &&
                            <div className={styles.subInfo}>
                                <div className={styles.subInfoMain}>
                                    <h2>Students {user?.school.studentsCounter}/{subscription.subscribedSeats} {renderSubLabel()}</h2>
                                    {subscription.status === MATH_SUB_STATUS.active &&
                                        <ul className={styles.subDates}>
                                            <li>
                                                <span className={styles.subDatesLabel}>Started: </span>
                                                <span>{moment(subscription.boomMathPaymentStartedAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                            </li>
                                            {subscription.nextPaymentDate &&
                                                <li>
                                                    <span className={styles.subDatesLabel}>Next payment: </span>
                                                    <span>{moment(subscription.nextPaymentDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                                </li>
                                            }
                                        </ul>

                                    }

                                    {(subscription.status === MATH_SUB_STATUS.cancelled || subscription.status === MATH_SUB_STATUS.cancelled_renewal) &&
                                        <>
                                            {daysTillSubExpires > 0 ?
                                                <p>You can continue to access BoomMath until: {moment(subscription.expiresAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}.</p> :
                                                <p>Your subscription has been cancelled</p>
                                            }
                                            <ul className={styles.subDates}>
                                                <li>
                                                    <span className={styles.subDatesLabel}>Started:</span>
                                                    {subscription.status === MATH_SUB_STATUS.cancelled ?
                                                        <span>{moment(subscription.boomMathTrialStartedAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span> :
                                                        <span>{moment(subscription.boomMathPaymentStartedAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                                    }
                                                </li>
                                                <li>
                                                    <span className={styles.subDatesLabel}>Cancelled:</span>
                                                    <span>{moment(subscription.subscriptionCancelledAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                                </li>
                                            </ul>
                                            {/* TODO: For cancelled_renewal, no scenarion to restart yet */}
                                            {subscription.status === MATH_SUB_STATUS.cancelled &&
                                                <Button onClick={() => setShowCreateSub(true)}>Restart subscription</Button>
                                            }
                                        </>
                                    }


                                    {subscription.status === MATH_SUB_STATUS.active && user?.school.stripeCustomerPaymentMethod &&
                                        <>
                                            <h2>Payment details</h2>
                                            <ul className={styles.cardInfo}>
                                                <li>
                                                    <p>Card:</p>
                                                    <div className={styles.cardInfoData}>**** **** **** {user?.school.stripeCustomerPaymentMethod?.last4}</div>
                                                </li>
                                                <li>
                                                    <p>Exp:</p>
                                                    <div className={styles.cardInfoData}>{user?.school.stripeCustomerPaymentMethod?.expMonth}/{user?.school.stripeCustomerPaymentMethod?.expYear}</div>
                                                </li>
                                            </ul>
                                        </>
                                    }
                                </div>

                                {(subscription.status === MATH_SUB_STATUS.active || subscription.status === MATH_SUB_STATUS.expired) &&
                                    <div className={styles.subInfoSide}>
                                        <ul>
                                            {(!subscription.isTrial && (subscription.status === MATH_SUB_STATUS.active || subscription.status === MATH_SUB_STATUS.expired)) &&
                                                <li><button className='btn-reset link-underline' onClick={() => setShowUpdateCard(true)}>Update Card details</button></li>
                                            }
                                            {subscription.status === MATH_SUB_STATUS.active &&
                                                <li><button className='btn-reset link-underline' onClick={() => setConfirmCancel(true)}>Cancel subscription</button></li>
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        }

                        {subscription.payments.length > 0 &&
                            <>
                                <h2 className='u-m-top-3'>Billing history</h2>
                                <ul className={styles.billingHistory}>
                                    {subscription.payments.map(payment => (
                                        <li key={payment.id} className={styles.billingHistoryItem}>
                                            <span className={styles.billingHistoryDate}>{moment(payment.createdAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                            <span className={styles.billingHistoryMain}>Annual Subscription </span>
                                            <span className={styles.billingHistorySide}>
                                                {payment.receiptUrl && <a target='blank' href={payment.receiptUrl} rel='noopener noreferrer'><File /></a>}
                                                {payment.status === 'FAILED' && <span>Status: {payment.status}</span>}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        }

                    </>
                )}
        </div>
    )
}

export default BoomMathDashboard;