import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { Button } from '@axeedge/go-teacher-components';
import BillingProfileForm from './components/BillingProfileForm';

const Billing = () => {

    const { currentUser: user } = useContext(AuthContext);

    const [editBillingProfile, setEditBillingProfile] = useState(false);


    if (editBillingProfile) {
        return (
            <BillingProfileForm
                school={user?.school}
                isEdit={editBillingProfile}
                close={() => setEditBillingProfile(false)}
            />
        )
    }

    if (user?.school?.billingProfile) {
        return (
            <>
                <p><b>{user.school.billingProfile.companyName}</b></p>
                <div className="row u-m-base-2">
                    <div className="col-md-6">
                        <p>{user.school.billingProfile.address}</p>
                        <p>{user.school.billingProfile.address2}</p>
                        <p>{user.school.billingProfile.city}</p>
                        <p>{user.school.billingProfile.state?.code}</p>
                        <p>{user.school.billingProfile.zip}</p>
                        <p>{user.school.billingProfile.country?.name}</p>
                    </div>
                    <div className="col-md-6">
                        <p>Contact: {user.school.billingProfile.contactPerson}</p>
                        <p>Email: {user.school.billingProfile.contactEmail}</p>
                        <p>Phone: {user.school.billingProfile.contactPhone}</p>
                    </div>
                </div>
                <Button onClick={() => setEditBillingProfile(true)}>Edit billing profile</Button>
            </>
        )
    }
    return null;
}

export default Billing;
