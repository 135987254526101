import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Payment from './components/Payment';
import { Loader } from '@axeedge/go-teacher-components';
import { BOOMREAD_PRICING, GET_READ_SUBSCRIPTIONS } from '../../services/graphql';
import { READ_SUB_DURATION } from '../../../../services/constants';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import moment from 'moment';
// import StripeWrapper from '../../../Settings/components/StripeWrapper';

const PurchaseSubscription = ({ location }) => {

    const { currentUser } = useContext(AuthContext);
    const now = moment();

    const [subDuration, setSubDuration] = useState(READ_SUB_DURATION.oneYear);

    const { data, loading, error } = useQuery(BOOMREAD_PRICING);

    const { data: subData, loading: subLoading, error: subError } = useQuery(GET_READ_SUBSCRIPTIONS, {
        variables: {
            schoolId: currentUser?.school?.id
        }
    });

    if (error || subError) {
        return <p>{error.message}</p>
    }

    if (loading || subLoading) {
        return <Loader />
    }

    if (data && data.boomReadPricing && subData && subData.school) {
        const { boomReadPricing } = data;

        const { schoolSubscriptions: subscriptions } = subData.school;
        const readSubscriptions = subscriptions.filter(s => s.app && s.app.name === 'BoomReader');
        const currentSub = [...readSubscriptions].sort((a, b) => a.expiresAt.localeCompare(b.expiresAt)).pop();

        return (
            // <StripeWrapper>
            //     <div className='card card-mobile'>
            //         <h2 className='u-m-base-3'>{location.state?.renew ? 'Renew subscription' : 'Start subscription'}</h2>
            //         <Payment
            //             boomReadPricing={boomReadPricing}
            //             subDuration={subDuration}
            //             setSubDuration={setSubDuration}
            //         />
            
            //     </div>
            // </StripeWrapper>
            <div className='card card-mobile'>
            <h2 className='u-m-base-3'>{location.state?.renew ? 'Renew subscription' : 'Start subscription'}</h2>
            <Payment
                boomReadPricing={boomReadPricing}
                subDuration={subDuration}
                setSubDuration={setSubDuration}
            />
    
        </div>
        )
    }
    return null;
}

export default PurchaseSubscription;