import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import SaveConfirm from '../../../../components/SaveConfirm';
import { GET_CLASS_QUERY, EDIT_CLASS_MUTATION } from '../../services/graphql';
import { useTranslation } from 'react-i18next';
import { CLASS_TYPE } from '../../../../services/constants';
import styles from './EditClassForm.module.scss';

const EditClassForm = ({ schoolId, classData, isSchoolAdmin, school }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [formErrors, setFormErrors] = useState([]);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);

    const schema = Yup.object().shape({
        ...(!school.wondeAccessApprovedAt && { name: Yup.string().required(t('please_enter_class_name')) }),
        type: Yup.string().required(t('please_select_class_type')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            name: classData.name,
            type: classData.typeId,
            academicYear: classData.academicYear
        }
    });


    const [editClass, { loading: savingClass }] = useMutation(EDIT_CLASS_MUTATION, {
        update: (_, { data }) => {
            if (data.editStudentsClass.errors && data.editStudentsClass.errors.length !== 0) {
                setFormErrors(data.editStudentsClass.errors);
                return;
            }
            if (data.editStudentsClass.studentsClass && data.editStudentsClass.studentsClass.id) {
                setShowSaveConfirm(t('common:changes_saved'));
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classData.id } }]
    });

    const onSubmit = values => {
        const { name, visibleToAllTeachers, type, academicYear } = values;
        editClass({
            variables: {
                schoolId,
                studentsClassId: classData.id,
                visibleToAllTeachers: visibleToAllTeachers !== undefined ? visibleToAllTeachers : classData?.visibleToAllTeachers,
                typeId: +type,
                ...(!school.wondeAccessApprovedAt && {
                    academicYear: parseInt(academicYear),
                    name,
                })
            }
        })

    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    isSchoolAdmin && (
                        <>
                            <div className="basic-form__group basic-form__group--check">
                                <p className='u-m-right-2 heavy'>{t('visible_to_all_teachers')}</p>
                                <input
                                    type="checkbox"
                                    ref={register}
                                    className="switchToggle"
                                    name="visibleToAllTeachers"
                                    id="visibleToAllTeachers"
                                    defaultChecked={classData && classData.visibleToAllTeachers}
                                />
                                <label className="switchLabel" htmlFor="visibleToAllTeachers">toggle</label>
                            </div>
                            <div className={styles.separator}></div>
                        </>
                    )
                }

                {!school.wondeAccessApprovedAt && (
                    <>
                        <div className="basic-form__group">
                            <label htmlFor='name' className='basic-form__text-label'>{t('common:name')}</label>
                            <input
                                name="name"
                                id="name"
                                placeholder={t('class_name_placeholder')}
                                className="basic-form__text-box"
                                ref={register}
                                defaultValue={classData && classData.name}
                                type="text"
                            />
                        </div>
                        {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                        <div className={styles.separator}></div>
                    </>
                )}


                <div className="basic-form__group">
                    <label htmlFor='name' className='basic-form__text-label'>{t('class_type')}</label>
                    <select name="type" ref={register} className="basic-form__text-select u-display-block">
                        <option value=''>{t('please_select')}</option>
                        <option value={CLASS_TYPE.registration}>{t('registration')}</option>
                        <option value={CLASS_TYPE.other}>{t('other')}</option>
                    </select>
                </div>
                {errors.type && <p className="basic-form__hint">{errors.type.message}</p>}
                <div className={styles.separator}></div>

                {!school.wondeAccessApprovedAt && (
                    <>
                        <div className="basic-form__group">
                            <label htmlFor='academicYear' className='basic-form__text-label'>Academic Year</label>
                            <select ref={register} name="academicYear" className="basic-form__text-select u-display-block">
                                <option value='2025'>2024/2025</option>
                                <option value='2024'>2023/2024</option>
                                <option value='2023'>2022/2023</option>
                            </select>
                        </div>
                        <div className={styles.separator}></div>
                    </>
                )}

                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                <div className="u-display-flex u-align-center u-m-top-2">
                    <Button type="submit" primary className="u-m-right-1">{savingClass ? t('common:saving') : t('common:button.save')}</Button>
                </div>
                {showSaveConfirm && <SaveConfirm message={showSaveConfirm} hideConfirm={setShowSaveConfirm} />}
            </form>
        </Fragment>
    )
}
export default EditClassForm;
