import React, {useState, useEffect} from 'react'
import PageHeader from '../../../components/PageHeader';
import { Button, Modal  } from '@axeedge/go-teacher-components';
import {Users} from 'react-feather'
import styles from '../NewYear.module.scss'
import { useMutation, useQuery } from '@apollo/react-hooks';
import { AUTO_ARCHIVE } from '../services/graphql';
import { GET_YEAR_STATS } from '../../Students/services/graphql';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import StudentUpdateCable from './studentUpdateCable';
import { getYearGroupLabel } from '../../../services/utils';
import ls from 'local-storage'
const Step3 = ({setCurrentStep, schoolId, schoolStep}) => {

    const { data, error, loading } = useQuery(GET_YEAR_STATS, {
        variables: {
            schoolId
        },
        fetchPolicy: 'network-only'
    });

    const [updatingStudents, setUpdatingStudents] = useState(false)
    const [receivedData, setReceivedData] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const [selectedYear, setSelectedYear] = useState(YEAR_GROUPS[YEAR_GROUPS.length - 1].val)
    const [showConfirm, setShowConfirm] = useState(false)

    const [updateYears, { loading: updatingStep }] = useMutation(AUTO_ARCHIVE, {
        onCompleted: ({ increaseStudentsYearGroup }) => {
            if (increaseStudentsYearGroup.errors && increaseStudentsYearGroup.errors.length !== 0) {
                setFormErrors(increaseStudentsYearGroup.errors);
                return;
            }
        }
    })

    useEffect(() => {
        if (receivedData && receivedData.progress === 100) {
            setCurrentStep(4)
        }
    }, [receivedData])

    const handleUpdate = () => {
        setUpdatingStudents(true)
        updateYears({variables: {
            schoolId,
            finalYearGroup: selectedYear
        }})
    }

    return (
        <>
            {updatingStudents && 
            <>
                <StudentUpdateCable setReceivedData={setReceivedData} schoolId={schoolId}  />
                <div className={styles.updateProgress}>
                    <p>This might take a moment...</p>
                    {ls('noSocket') ?       
                    <Button onClick={() => window.location.reload()}>Check Status</Button>
                    :
                    <p>{receivedData && `${receivedData.progress}%`}</p>
                    }
                </div>
            </>}
            <div className={styles.stepContainer}>
                <PageHeader title={`2 Auto Archive & Move`} icon={Users} close={() => setCurrentStep(2)} showBackLink={false}  />
                {schoolStep > 3 ?
                <p>You have already completed this step.</p>:
                <>
                <p className="u-m-base-2">In this step we'll move the students up a year and remove those that are leaving or have left the school.</p>
                <p className="u-bold">Please determine what your eldest year group is:</p>
                <div className="u-m-base-2">
                    <label>My school teachers up to: </label>
                    <select value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
                        {
                            YEAR_GROUPS.map( y => {
                                return <option key={`yg${y.val}`} value={y.val}>{y.name}</option>
                            })
                        }
                    </select>
                </div>
                <p>Children currently in <strong><em>{getYearGroupLabel(selectedYear)}</em></strong> will be archived from this school.</p>
                <ul>
                    {data && data.school.yearGroupCounters.map(y => {
                        if (y.yearGroup) {
                            return <li className={styles.archiveList} key={`y${y.id}`}>
                                    {y.yearGroup < selectedYear ?
                                    <>
                                    <strong>{y.studentCounter}</strong> pupils will be moved from <strong>{getYearGroupLabel(y.yearGroup)}</strong> into <strong>{getYearGroupLabel(y.yearGroup + 1)}</strong>
                                    </> :
                                    <>
                                    <strong>{y.studentCounter}</strong> pupils in <strong>{getYearGroupLabel(y.yearGroup)}</strong> will be <strong>archived</strong> from this school
                                    </>}
                                </li>
                        } else {
                            return null
                        }
                    })}
                </ul>
                </>
                }
            </div>
            <div className={styles.stepFooter}>
                {schoolStep > 3 ?
                    <Button onClick={() => setCurrentStep(4)}>Next</Button>
                    :
                    <Button onClick={() => setShowConfirm(true)}>Next</Button>
                }
            </div>
            {showConfirm &&
                <Modal closeModal={() => setShowConfirm(false)}>
                    <h1>Are you sure?</h1>
                    <p className="u-m-base-2">Your students will be advanced a year and your current {getYearGroupLabel(selectedYear)} and above will be archived.</p>
                    <Button disabled={updatingStep} className="u-m-right-2" onClick={() => handleUpdate()}>Yes</Button>
                    <Button outline onClick={() => setShowConfirm(false)}>No</Button>
                </Modal>
            }
        </>
    )
}

export default Step3