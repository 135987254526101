import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ACTIVE_CONTEST_QUERY, GET_SCHOOL_CONTESTS } from "../services/graphql";
import { getAppTokenName, getUrl, APPS, BOOK_STATUS } from '@axeedge/go-shared-utils';
import { APP_NAME, APP_NAME_US } from '../../../services/constants';
import btnStyles from '../../../components/ButtonLink/ButtonLink.module.scss'
import { Button } from '@axeedge/go-teacher-components';
import ls from 'local-storage';
import jkinney from '../../../images/JEFF KINNEY.png'
import cx from 'classnames';
import styles from '../Dashboard.module.scss';

const BeeBanner = ({ currentUser }) => {

    const token = ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));

    const { data, loading, error } = useQuery(ACTIVE_CONTEST_QUERY);

    const { data: data2, loading: loading2, error: error2 } = useQuery(GET_SCHOOL_CONTESTS, {
        variables: {
            schoolId: currentUser?.school?.id
        }
    });

    if (loading || loading2) {
        return null
    }
    if (error || error2) {
        return <p>{error.message}</p>
    }
    if (data && data.activeContest && data2 && data2.currentTeacher) {
        const { activeContest } = data;
        const { currentTeacher: { school } } = data2;

        const book = school?.contests?.find(c => c.id === activeContest?.id)?.bookForSchool;

        return (
            <>
                {school?.contests?.find(c => c.id === activeContest?.id) ? (
                    <div className={styles.indiaBanner}>
                        <div>
                            <h2><span className="u-bold">
                                {book?.status === BOOK_STATUS.new ? 'Start ' : book?.status === BOOK_STATUS.started ? 'Continue ' : 'View '}
                                Your Bee Book
                            </span></h2>
                            <Button onClick={() => window.location = `${getUrl(APPS.go_write_teacher)}${ls('go_impersonating') ? '/impersonate' : ''}?token=${token}`} outline className={'u-m-right-1'}>
                                {book?.status < BOOK_STATUS.completed ? 'Write Now' : 'View Book'}
                            </Button>
                        </div>
                        <img src={jkinney} className={styles.jkImg} alt="Jeff Kinney" />
                    </div>
                 ) :
                    <div className={styles.indiaBanner}>
                        <div>
                            <h2>Join The<br /><span className="u-bold">National Writing Bee</span></h2>
                            <a href="https://boomwriter.co.uk/india-writing-bee/" target="_blank" rel="noopener noreferrer" className={cx(btnStyles.button, btnStyles.buttonOutline, 'u-m-right-1')}>Join Now</a>
                            <a href="https://boomwriter.co.uk/india-writing-bee/" target="_blank" rel="noopener noreferrer" className={cx(btnStyles.button, btnStyles.buttonOutlineBorder)}>More Info</a>
                        </div>
                        <img src={jkinney} className={styles.jkImg} alt="Jeff Kinney" />
                    </div>
                }
            </>
        )
    }
    return null;
}

export default BeeBanner