import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import SaveConfirm from '../../../../components/SaveConfirm';

import { EDIT_TEACHER_MUTATION, RESEND_INVITE_EMAIL } from '../../services/graphql';
import { useTranslation } from 'react-i18next';
import { Delete, PlusCircle, Watch } from 'react-feather';
import styles from '../../Teachers.module.scss';
import { getRoleLabel, usaOnlyRoles, ukOnlyRoles } from '../../../../services/utils';
import ls from 'local-storage'
import AddToMultipleClasses from '../AddToMultipleClasses';

const EditTeacher = ({ closeModal, schoolId, teacher, onDelete, currentUser }) => {

    const [formErrors, setFormErrors] = useState([]);
    const { t } = useTranslation(['classes', 'common']);

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);

    const [showAddToClasses, setShowAddToClasses] = useState(false);


    const [resendEmail, { loading }] = useLazyQuery(RESEND_INVITE_EMAIL, {
        onCompleted: () => {
            setShowSaveConfirm(true);
        }
    });

    const [editTeacher, { loading: savingTeacher }] = useMutation(EDIT_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.editTeacher.errors && data.editTeacher.errors.length !== 0) {
                setFormErrors(data.editTeacher.errors);
                return;
            }
            if (data.editTeacher.teacher.id) {
                closeModal();
            }
        }
    });

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        email: Yup.string().email().required(t('common:email_validation')),
        displayName: Yup.string().required(t('common:display_name_validation')),
        roleId: Yup.string().required(t('common:please_select_role')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            firstName: teacher.firstName,
            lastName: teacher.lastName,
            email: teacher.email,
            displayName: teacher.displayName,
            isSchoolAdmin: teacher.isSchoolAdmin,
            roleId: teacher.roleId,
        }
    });




    const onSubmit = values => {
        const { firstName, lastName, displayName, email, roleId, isSchoolAdmin } = values;
        editTeacher({
            variables: {
                firstName,
                lastName,
                email,
                displayName,
                isSchoolAdmin,
                roleId: + roleId,
                schoolId,
                teacherId: teacher.id
            }
        });
        closeModal();
    }

    if (showAddToClasses) {
        return <AddToMultipleClasses currentUser={currentUser} teacherId={teacher.id} close={() => { closeModal(); setShowAddToClasses(false) }} />
    }

    return (
        <Fragment>
            <h2 className="u-m-base-1">{teacher.displayName}</h2>
            {teacher.isSchoolAdmin && <p className='u-m-base-2 small'>{t('administrator')}</p>}

            {!teacher.active && (
                <div className={styles.inactiveBar}>
                    <p className={styles.inactiveBarInfo}><Watch className='u-m-right-2 u-text-accent-3' />{t('account_not_activated_resend_email_invite')}</p>
                    {showSaveConfirm ? <SaveConfirm message={t('email_sent')} hideConfirm={setShowSaveConfirm} /> :
                        <Button
                            outline
                            onClick={() => resendEmail({
                                variables: { teacherId: teacher.id }
                            })}
                        >
                            {loading ? t('resending') : t('resend_invite')}
                        </Button>
                    }

                </div>
            )}


            <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-md-7'>

                        <p className='heavy'>{t('common:personal_info')}</p>
                        <div className="basic-form__group">
                            <label htmlFor='firstName' className='basic-form__text-label'>{t('common:first_name')}</label>
                            <input
                                name="firstName"
                                id="firstName"
                                placeholder={t('common:first_name_placeholder')}
                                className="basic-form__text-box"
                                ref={register}
                                type="text"
                            />
                        </div>
                        {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                        <div className="basic-form__group">
                            <label htmlFor='lastName' className='basic-form__text-label'>{t('common:last_name')}</label>
                            <input
                                name="lastName"
                                id="lastName"
                                placeholder={t('common:last_name_placeholder')}
                                className="basic-form__text-box"
                                ref={register}
                                type="text"
                            />
                        </div>
                        {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                        <div className="basic-form__group">
                            <div className="u-display-flex u-justify-between">
                                <label htmlFor='displayName' className='basic-form__text-label'>{t('common:display_name')}</label>
                                <p className='u-m-base-0'>{t('shown_to_pupils_parents')}</p>
                            </div>
                            <input
                                name="displayName"
                                id="displayName"
                                placeholder={t('common:display_name_placeholder')}
                                className="basic-form__text-box"
                                ref={register}
                                type="text"
                            />
                        </div>
                        {errors.displayName && <p className="basic-form__hint">{errors.displayName.message}</p>}


                        <div className="basic-form__group">
                            <label htmlFor='email' className='basic-form__text-label'>{t('common:email')}</label>
                            <input
                                name="email"
                                id="email"
                                placeholder={t('common:email_placeholder')}
                                className="basic-form__text-box"
                                ref={register}
                                type="email"
                            />
                        </div>
                        {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}


                        <p className='heavy u-m-base-1'>Administrator rights</p>
                        <div className='u-display-flex u-align-center u-m-base-2'>
                            <div className="basic-form__group basic-form__group--check">
                                <input
                                    type="checkbox"
                                    ref={register}
                                    className="switchToggle"
                                    name="isSchoolAdmin"
                                    id="isSchoolAdmin"
                                    disabled={teacher.id === currentUser.id}
                                />
                                <label className="switchLabel" htmlFor="isSchoolAdmin">toggle</label>
                            </div>
                            <p className='u-m-left-3 u-p-right-3'>{t('admin_access_info')}</p>
                        </div>

                        <p className='heavy'>School Role</p>
                        <div className="basic-form__group basic-form__group--check">
                            <select defaultValue={teacher.roleId} name="roleId" ref={register} className="basic-form__text-select">
                                <option value=''>{t('please_select')}</option>
                                {ls('country') === 'US' ?
                                    Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                        return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                    })
                                    :
                                    Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                        return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                    })
                                }
                            </select>
                        </div>
                        {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}

                        {formErrors.length !== 0 && <FormErrors errors={formErrors.map(formError => t(formError))} />}
                        <div className="u-display-flex u-align-center u-m-top-3">
                            <Button type="submit" primary className="u-m-right-1">{savingTeacher ? t('common:saving') : t('common:button.save_changes')}</Button>
                            <Button outline type="button" onClick={() => closeModal()} >{t('common:button.cancel')}</Button>
                        </div>
                    </div>
                    <div className="col-md">
                        {teacher.id !== currentUser.id && (
                            <>
                                <p className='heavy'>{t('common:actions')}</p>
                                <button
                                    type='button'
                                    className='btn-reset u-display-flex u-m-base-2 u-align-center u-justify-between u-m-top-2'
                                    onClick={() => onDelete(teacher.id)}
                                >{t('remove_teacher')} <Delete className='u-m-left-1' /></button>

                            </>
                        )}

                        {teacher.active &&
                            <button
                                type='button'
                                className='btn-reset u-display-flex u-m-base-2 u-align-center u-justify-between u-m-top-2'
                                onClick={() => setShowAddToClasses(true)}
                            >Add to classes <PlusCircle className='u-m-left-1' /></button>
                        }
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

export default EditTeacher;

