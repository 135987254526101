import React, { useState, useContext } from "react";
import { MATH_PRICE_PER_STUDENT } from "../../../../services/constants";

import CardFormRestart from "./components/CardFormRestart";
import cx from 'classnames';
import { AuthContext } from "../../../../services/auth/AuthProvider";
import StripeWrapper from "../../components/StripeWrapper";
import styles from '../BoomMathSettings/BoomMathSettings.module.scss';

const RestartSubscription = ({ school, close }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [totalStudents, setTotalStudents] = useState(school.studentsCounter > 0 ? school.studentsCounter : 1);


    return (
        <StripeWrapper>
            <h1>Start BoomMath annual subscription</h1>
            <p className="lead heavy u-m-base-2">Total cost:  ${(totalStudents * MATH_PRICE_PER_STUDENT).toFixed(2)} </p>

            <div className={styles.students}>
                <h2 className={styles.studentsTitle}>Number of students:</h2>
                <div className={styles.studentsTotal}>
                    <input
                        type="number"
                        min={1}
                        defaultValue={totalStudents}
                        onChange={(e) => setTotalStudents(e.target.value)}
                        className={cx('basic-form__text-box', styles.studentsInput)}
                        width={50}
                    />
                    @ ${MATH_PRICE_PER_STUDENT} / year
                </div>
            </div>

            <CardFormRestart
                school={school}
                user={currentUser}
                setCurrentUser={setCurrentUser}
                totalStudents={totalStudents}
                onDone={close}
            />
        </StripeWrapper>
    )
}

export default RestartSubscription;