import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { CLASS_TYPE } from '../../../../services/constants';
import { useClickAway, useWindowSize } from 'react-use';
import { ChevronDown } from 'react-feather';

const SideClassNav = ({ tabs, selectedTab, setSelectedTab, classList, user }) => {

    const { t } = useTranslation(['classes', 'common']);

    const myClasses = classList.filter(c => c.teachers.find(t => t.id === user.id));
    const registrationClasses = classList.filter(c => c.typeId === CLASS_TYPE.registration);
    const otherClasses = classList.filter(c => c.typeId !== CLASS_TYPE.registration);

    const [dropdown, setDropdown] = useState(false);

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));

    const { width } = useWindowSize();

    useEffect(() => {
        setDropdown(false);
    }, [selectedTab]);

    const getSelectedTabTitle = selectedTab => {
        switch (selectedTab) {
            case tabs.allClasses:
                return t('all_classes');
            case tabs.registrationClasses:
                return t('registration_classes');
            case tabs.myClasses:
                return t('my_classes');
            case tabs.otherClasses:
                return t('other_classes_groups');
            case tabs.archivedClasses:
                return t('archived_classes');
            default:
                return t('classes');
        }
    }

    const renderLinks = (styleName, activeStyleName) => {
        return (
            <>
                <li>
                    <button onClick={() => setSelectedTab(tabs.myClasses)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.myClasses })}>
                        <div>{t('my_classes')}</div>
                        <span>{myClasses.length}</span>
                    </button>
                </li>
                <li>
                    <button onClick={() => setSelectedTab(tabs.registrationClasses)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.registrationClasses })}>
                        <div>{t('registration_classes')}</div>
                        <span>{registrationClasses.length}</span>
                    </button>
                </li>
                <li>
                    <button onClick={() => setSelectedTab(tabs.otherClasses)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.otherClasses })}>
                        <div>{t('other_classes_groups')}</div>
                        <span>{otherClasses.length}</span>
                    </button>
                </li>
                {user.isSchoolAdmin &&
                    <li>
                        <button onClick={() => setSelectedTab(tabs.allClasses)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.allClasses })}>
                            <div>{t('all_classes')}</div>
                            <span>{classList.length}</span>
                        </button>
                    </li>
                }
                <li>
                    <button onClick={() => setSelectedTab(tabs.archivedClasses)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.archivedClasses })}>
                        <div>{t('archived')}</div>
                    </button>
                </li>
            </>
        );
    }

    return (
        <>
            {width < 768 ?
                <div ref={ref} className='dropdownHolder u-m-base-3'>
                    <button className='dropdownBtn dropdownBtnFull' onClick={() => setDropdown(!dropdown)}>{getSelectedTabTitle(selectedTab)} <ChevronDown /> </button>
                    {dropdown && (
                        <ul className='dropdownMenu dropdownMenuFull'>
                            {renderLinks('dropdownMenuItem', 'dropdownMenuItemActive')}
                        </ul>
                    )}
                </div> :
                <ul className='side-nav-list'>
                    {renderLinks('side-nav-btn', 'side-nav-btn-active')}
                </ul>
            }
        </>
    );
}

export default SideClassNav;
