import React, { useState, useEffect } from 'react';
import { PlusCircle } from 'react-feather';
import { Modal } from  '@axeedge/go-teacher-components';
import PackOffers from '../PackOffers';
import Payment from '../Payment';
import SelectedPackItem from '../SelectedPackItem';
import { PACK_EXTRA_COPY_PRICE } from '../../../../../../services/constants';
import { displayPackOffers, setPurchaseAcademicYears } from '../../../../services/utils';

import { parse } from "query-string";
import { useLocation } from 'react-use';

const SelectPacks = ({ packOffersData, setPurchaseDone }) => {

    const [showPackOffers, setShowPackOffers] = useState();
    const [academicYear, setAcademicYear] = useState(setPurchaseAcademicYears()[0]);
    const [selectedPacks, setSelectedPacks] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [packOffers, setPackOffers] = useState(displayPackOffers(packOffersData));

    useEffect(()=> {
        setPackOffers(displayPackOffers(packOffersData))
    }, [packOffersData, showPackOffers]);


    const selectPack = pack => {
        setSelectedPacks([...selectedPacks, pack]);
        setTotalPrice(prevPrice => prevPrice + pack.price);
        setShowPackOffers(false);
    }

    const removePack = (pack, i) => {
        setSelectedPacks(selectedPacks.filter((_, idx) => idx !== i));
        setTotalPrice(prevPrice => prevPrice - pack.price)
    }

    const updatePackStudents = (value, i, classIdx) => {
        const packs = [...selectedPacks];
        const newStudents = value - packs[i].classes[classIdx].students;
        const newPrice = packs[i].price + newStudents * PACK_EXTRA_COPY_PRICE;

        packs[i].classes[classIdx].students = +value;
        packs[i].price = newPrice;

        setSelectedPacks(packs);
        setTotalPrice(prevPrice => prevPrice + newStudents * PACK_EXTRA_COPY_PRICE);
    }

    // buy from GoWrite app
    const location = useLocation();
    useEffect(() => {
        if (selectedPacks.length === 0 && packOffers && parse(location.search).book && parse(location.search).students) {
            const studentsNo = parse(location.search).students;
            const offer = studentsNo < packOffers[1].defaultNumberOfStudentsPerInstance ? packOffers[0] : packOffers[1];
            const newStudents = studentsNo > offer.defaultNumberOfStudentsPerInstance ? studentsNo : offer.defaultNumberOfStudentsPerInstance ;
            offer.classes[0].students = newStudents;
            offer.price = newStudents * PACK_EXTRA_COPY_PRICE;
            setSelectedPacks([...selectedPacks, offer]);
            setTotalPrice(offer.price);
        }
    },[location, packOffers, selectedPacks]);



    return(
        <div className="row">
            <div className="col-md-7 col-lg-6">
                <div className="card card-mobile u-m-base-2">
                    <h2>Select Packs <a className='body u-text-tertiary' href="https://go-write.co.uk/pricing/" target='blank' rel="noopener noreferrer">(View Writing packs)</a></h2>
                    <p className='u-m-base-2'>School Year
                        <select
                            className='basic-form__text-select basic-form__text-select--inline u-m-left-2'
                            defaultValue={academicYear}
                            onChange={(e) => setAcademicYear(e.target.value)}
                        >
                            {setPurchaseAcademicYears().map(y => <option key={y} value={y}>{y}</option>)}
                        </select>
                    </p>
                    <ul>
                    {
                        selectedPacks && !!selectedPacks.length && selectedPacks.map((pack, i) => {
                            return (
                                <SelectedPackItem
                                    key={`selected-p-${i}`}
                                    pack={pack}
                                    i={i}
                                    updatePackStudents={updatePackStudents}
                                    removePack={removePack}
                                />
                            )
                        })
                    }
                    </ul>

                    {
                        !parse(location.search).classId && 
                        <button onClick={() => setShowPackOffers(true)} className='btn-reset icon-title heavy link-reverse'>
                            <PlusCircle size='20' className='u-m-right-1' />Pack
                        </button>
                    }
               
                </div>
            
                <Payment classId={parse(location.search).classId} academicYear={academicYear} totalPrice={totalPrice} setPurchaseDone={setPurchaseDone} selectedPacks={selectedPacks} />

                { 
                    showPackOffers && (
                        <Modal closeModal={() => setShowPackOffers(false)}>
                            <PackOffers setPackOffers={setPackOffers} packOffers={packOffers} selectPack={selectPack} />
                        </Modal>
                    )
                }
            </div>
        </div>
    )
}

export default SelectPacks;