import React from 'react';
import cx from 'classnames';
import styles from './SelectedPackItem.module.scss';
import { Book, Users, XCircle } from 'react-feather';
import { packStudentsRange } from '../../../../services/utils'

const SelectedPackItem = ({ pack, i, removePack,  updatePackStudents }) => {

	return(
      	<li className={cx('u-m-base-2', styles.selectedPack)}>
			<div className={styles.selectedPackHead}>
				<div className='icon-title'>
					<button
						className={cx('btn-reset', styles.selectedPackHeadBtn)}
						type="button"
						onClick={() => removePack(pack, i)}
					>
						<XCircle size="20"/>
					</button>
					<p className='heavy'>{pack.name}</p>
				</div>
				{pack.instances === 1 && <span className={styles.selectedPackHeadPrice}>£{pack.price.toFixed(2)}</span>} 
			</div>
			<div className={styles.selectedPackBody}>
				{
					pack.classes.map((packClass, classIndex) => {
						return(
							<ul className={styles.selectedPackMeta} key={`p-class-${packClass.id}`}>
								<li>
									{pack.instances !== 1 && (<span className='u-m-right-3'>Class {packClass.id}</span>)}
									<Users size="20" />
									<input 
										onChange={e => updatePackStudents(e.target.value, i, classIndex)}
										onKeyDown={e => e.preventDefault(e)}
										type="number"
										defaultValue={packClass.students}
										max={packStudentsRange(pack, false)}
										min={packStudentsRange(pack, true)}
										key={`${pack.id}`}
										className={cx('basic-form__text-box', styles.selectedPackMetaInput)}
									/>
								</li>
								<li>
									<Book size="20" />{ +packClass.students + 1}
								</li>
							</ul>
						)
					})
				}

				{
					pack.instances !== 1 && (
						<p className={styles.selectedPackTotal}>
							<span>Pack Total</span> 
							<span>£{pack.price.toFixed(2)}</span>
						</p>
					)
				}
			</div>
    	</li>
    )
}

export default SelectedPackItem;