import React, { Fragment, useState, useContext } from 'react';
import { User } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ls from 'local-storage';

import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../services/auth/AuthProvider';
import PageHeader from '../../components/PageHeader';
import ProfileImage from '../../components/ProfileImage';
import { EDIT_TEACHER_MUTATION, EDIT_TEACHER_AVATAR_MUTATION } from './services/graphql';
import ChangePassword from './components/ChangePassword';
import defaultImg from './images/default.png';
import SaveConfirm from '../../components/SaveConfirm';
import { getRoleLabel, ukOnlyRoles, usaOnlyRoles } from '../../services/utils';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';

import { useTranslation } from 'react-i18next';

import styles from './TeacherProfile.module.scss';

const TeacherProfile = () => {

    const { t } = useTranslation(['profile', 'common']);

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [showCropModal, setShowCropModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [editTeacher, { loading: savingProfile }] = useMutation(EDIT_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.editCurrentTeacher.errors && data.editCurrentTeacher.errors.length !== 0) {
                setFormErrors(data.editCurrentTeacher.errors);
                return;
            }

            if (data.editCurrentTeacher.teacher.id) {
                setCurrentUser(data.editCurrentTeacher.teacher);
                setShowSaveConfirm(true);
            }
        },
    });

    const schema = Yup.object().shape({
        ...(!currentUser.school?.wondeAccessApprovedAt && {
            firstName: Yup.string().required(t('common:first_name_validation')),
            lastName: Yup.string().required(t('common:last_name_validation')),
            displayName: Yup.string().required(t('common:display_name_validation')),
            email: Yup.string().email().required(t('common:email_validation')),
        }),
        roleId: Yup.string().required(t('common:please_select_role')),
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        const { firstName, lastName, displayName, email, roleId } = values;
        editTeacher({
            variables: {
                ...(!currentUser.school?.wondeAccessApprovedAt && {
                    firstName,
                    lastName,
                    displayName,
                    email
                }),
                teacherId: currentUser.id,
                roleId: +roleId,
            }
        });
    }

    const [saveNewAvatar, { loading: savingAvatar }] = useMutation(EDIT_TEACHER_AVATAR_MUTATION, {
        update: (_, { data }) => {
            if (data.addTeacherAvatar.errors && data.addTeacherAvatar.errors.length !== 0) {
                alert(data.editTeacher.errors[0]);
                return;
            }

            if (data.addTeacherAvatar.teacher.id) {
                setCurrentUser(data.addTeacherAvatar.teacher);
                setShowCropModal(false);
                setShowSaveConfirm(true);
            }
        }
    });

    return (
        <Fragment>
            <PageHeader title={t('your_profile')} icon={User} />
            <div className='card card-mobile'>
                <div className={styles.contentHolder}>
                    <div className={styles.contentHolderLeft}>
                        <ProfileImage
                            defaultImg={currentUser.avatarUrl || defaultImg}
                            heading={t('profile:profile_photo')}
                            imgName={`teachers/profile${currentUser.id}`}
                            onSave={saveNewAvatar}
                            savingImage={savingAvatar}
                            setShowCropModal={setShowCropModal}
                            showCropModal={showCropModal}
                            width={140}
                        />
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            {!currentUser.school?.wondeAccessApprovedAt ?
                                <>
                                    <div className='basic-form__group'>
                                        <label htmlFor='firstName' className='basic-form__text-label'>{t('common:first_name')}</label>
                                        <input
                                            name='firstName'
                                            id='firstName'
                                            placeholder={t('common:first_name_placeholder')}
                                            className='basic-form__text-box'
                                            ref={register}
                                            defaultValue={currentUser.firstName}
                                            type='text'
                                        />
                                    </div>
                                    {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                                    <div className='basic-form__group'>
                                        <label htmlFor='lastName' className='basic-form__text-label'>{t('common:last_name')}</label>
                                        <input
                                            name='lastName'
                                            id='lastName'
                                            placeholder={t('common:last_name_placeholder')}
                                            className='basic-form__text-box'
                                            ref={register}
                                            defaultValue={currentUser.lastName}
                                            type='text'
                                        />
                                    </div>
                                    {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}
                                    <div className='basic-form__group'>
                                        <label htmlFor='displayName' className='basic-form__text-label'>{t('common:display_name')}</label>
                                        <input
                                            name='displayName'
                                            id='displayName'
                                            placeholder={t('common:display_name_placeholder')}
                                            className='basic-form__text-box'
                                            ref={register}
                                            defaultValue={currentUser.displayName}
                                            type='text'
                                        />
                                    </div>
                                    {errors.displayName && <p className='basic-form__hint'>{errors.displayName.message}</p>}
                                    <div className='basic-form__group'>
                                        <label htmlFor='email' className='basic-form__text-label'>{t('common:email')}</label>
                                        <input
                                            name='email'
                                            id='email'
                                            placeholder={t('common:email_placeholder')}
                                            className='basic-form__text-box'
                                            ref={register}
                                            defaultValue={currentUser.email}
                                            type='email'
                                        />
                                    </div>
                                    {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
                                </> : 
                                <>
                                    <p>{currentUser.displayName}</p>
                                    <p>{currentUser.firstName} {currentUser.lastName}</p>
                                    <p className='u-m-base-2'>{currentUser.email}</p>
                                </>
                            }
                            <div className="basic-form__group">
                                <label htmlFor='roleId' className='basic-form__text-label'>School Role</label>
                                <select defaultValue={currentUser.roleId} name="roleId" ref={register} className="basic-form__text-select">
                                    <option value=''>{t('common:please_select_role')}</option>
                                    {/*filter job roles to only include tutor role if the user is US based */}
                                    {ls('country') === 'US' ?
                                        Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                            return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                        })
                                        :
                                        Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                            return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}

                            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                            <div className='u-display-flex u-align-center'>
                                <Button type='submit' primary className='u-m-right-1'>{savingProfile ? t('common:saving') : t('common:button.save_changes')}</Button>
                                <Button
                                    outline
                                    onClick={() => {
                                        reset({
                                            firstName: currentUser.firstName,
                                            lastName: currentUser.lastName,
                                            displayName: currentUser.displayName,
                                            email: currentUser.email,
                                            roleId: currentUser.roleId
                                        })
                                    }}
                                    type='button'
                                >
                                    {t('common:button.cancel')}
                                </Button>

                            </div>
                            {showSaveConfirm && <SaveConfirm hideConfirm={setShowSaveConfirm} />}
                        </form>
                    </div>


                    <div className='card-pill u-m-top-3 u-m-top-md-0'>
                        <p>{t('change_password')}</p>
                        <Button onClick={() => setShowPasswordModal(true)} outline>{t('change_password')}</Button>
                    </div>
                </div>
            </div>
            {
                showPasswordModal && (
                    <Modal closeModal={() => setShowPasswordModal(false)}>
                        <ChangePassword closeModal={() => setShowPasswordModal(false)} />
                    </Modal>
                )
            }
        </Fragment>
    )
}

export default TeacherProfile;
