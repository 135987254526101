import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { shared_en, shared_sv } from '@axeedge/go-shared-utils';

import apps_en from './locales/en/apps.json';
import common_en from './locales/en/common.json';
import classes_en from './locales/en/classes.json';
import dashboard_en from './locales/en/dashboard.json';
import profile_en from './locales/en/profile.json';

import apps_sv from './locales/sv/apps.json';
import common_sv from './locales/sv/common.json';
import classes_sv from './locales/sv/classes.json';
import dashboard_sv from './locales/sv/dashboard.json';
import profile_sv from './locales/sv/profile.json';

const languages = ['en', 'sv'];

const DETECTION_OPTIONS = {
    order: ['path', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'querystring', 'cookie', 'subdomain'],
};

const resources = {
    en: {
        common: common_en,
        apps: apps_en,
        classes: classes_en,
        dashboard: dashboard_en,
        profile: profile_en,
        shared: shared_en
    },
    sv: {
        common: common_sv,
        apps: apps_sv,
        classes: classes_sv,
        dashboard: dashboard_sv,
        profile: profile_sv,
        shared: shared_sv
    }
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: DETECTION_OPTIONS,
        fallbackLng: 'en',
        debug: false,
        supportedLngs: languages,
        ns: ['common'],
        defaultNS: 'common',
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;