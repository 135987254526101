import React, { useContext } from "react";
import PageHeader from "../../../../components/PageHeader";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { UPDATE_SCHOOL_SETUP_STEP } from "../../../../services/school/graphql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { WONDE_TEACHERS_DATA } from "../../services/graphql";
import loader from '../../../../images/loader.svg';
import WondeStaffList from "./WondeStaffList";

const WondeStaffImport = ({ duringAdditionalImport, closeAdditionalImport }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [updateSchoolSetupStep] = useMutation(UPDATE_SCHOOL_SETUP_STEP, {
        update: (_, { data }) => {
            if (data && data.updateSchoolSetupStep.errors && data.updateSchoolSetupStep.errors.length > 0) {
                return;
            }
            if (data?.updateSchoolSetupStep?.school?.id) {
                const cu = { ...currentUser, school: data.updateSchoolSetupStep.school }
                setCurrentUser(cu);
            }
        }
    });


    const { data, loading, error } = useQuery(WONDE_TEACHERS_DATA, {
        variables: {
            additionalOnly: true,
            schoolId: currentUser?.school?.id
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <>
            <PageHeader showBackLink={false} title={currentUser.school.name} subtitle={`URN ${currentUser.school.urn}`} />

            {/* <button onClick={() => {
                updateSchoolSetupStep({
                    variables: {
                        schoolId: currentUser?.school.id,
                        step: 0
                    }
                })
            }}>reset step</button> */}

            {loading ?
                <>
                    <p className="u-display-flex u-align-center heavy">Please wait, loading teachers... <img className="u-m-left-2" src={loader} /></p>
                </> :
                data?.school?.wondeSchool &&
                <WondeStaffList
                    school={data.school}
                    extraStaff={data.school.wondeSchool.teachers}
                    closeAdditionalImport={closeAdditionalImport}
                    duringAdditionalImport={duringAdditionalImport}
                />
            }
        </>
    );
}

export default WondeStaffImport;