import React, { useState } from "react";
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';
import BillingProfileForm from '../../../../../Settings/scenes/Billing/components/BillingProfileForm';
import { PURCHASE_READ_ACCESS, GET_READ_SUBSCRIPTIONS } from '../../../../services/graphql';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

import payStyles from '../Payment/Payment.module.scss';
import cx from 'classnames';

const tosLink = 'https://goapps.app/terms';


const InvoiceSubscription = ({ user, setCurrentUser, school, totalStudents, setPaymentType, subDuration, additionalFeatures }) => {

    const { t } = useTranslation(['apps', 'profile', 'common']);


    const [editBillingProfile, setEditBillingProfile] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        tos: Yup.boolean().oneOf([true], t('profile:must_agree_to_tos')),
        authority: Yup.boolean().oneOf([true], t('profile:must_have_authority'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [purchaseReadAccess, { loading }] = useMutation(PURCHASE_READ_ACCESS, {
        update: (_, { data }) => {
            if (data.purchaseGoReadAccess.errors && data.purchaseGoReadAccess.errors.length) {
                setFormErrors(data.purchaseGoReadAccess.errors)
            }
            if (data.purchaseGoReadAccess.school.id) {
                const cu = { ...user, school: data.purchaseGoReadAccess.school }
                setCurrentUser(cu);
                navigate('/settings/boomreader');
            }
        },
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: user.school.id } }],
        awaitRefetchQueries: true
    });


    const wantsWonde = additionalFeatures.wondeOnly || additionalFeatures.wondeAndDataDash;
    const hasUrn = additionalFeatures.urn || user.school.urn;
    const needsUrn = wantsWonde && !hasUrn;

    const onSubmit = values => {
        const { purchaseOrderReferenceNumber } = values;
        const wantsWonde = additionalFeatures.wondeOnly || additionalFeatures.wondeAndDataDash;
        purchaseReadAccess({
            variables: {
                schoolId: user.school.id,
                studentsCounter: +totalStudents,
                purchaseOrderReferenceNumber,
                duration: subDuration,
                withDataDashboardAccess: additionalFeatures.wondeAndDataDash,
                misAccess: wantsWonde,
                ...(additionalFeatures.urn && wantsWonde && { misUrn: (additionalFeatures.urn).toString() })
            }
        });
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ul className={payStyles.subType}>
                    {/* <li className={payStyles.subTypeLink} onClick={() => setPaymentType('card')}>
                        <div className={payStyles.subTypeMain}>
                            <span className={payStyles.subTypeCheck}><CheckCircle /></span>
                            <p><b>Card payment</b></p>
                            <span className='u-m-left-auto heavy u-text-accent-3'>Recommended</span>
                        </div>

                    </li> */}

                    <li className={cx(payStyles.subTypeLink)} onClick={() => setPaymentType('invoice')}>
                        {/* <div className={payStyles.subTypeMain}>
                            <span className={cx(payStyles.subTypeCheck, payStyles.subTypeCheckActive)}><CheckCircle /></span>
                            <p className='u-m-base-0'><b>Request invoice</b></p>
                        </div> */}
                        <div className={payStyles.subTypeContent}>

                            <h3 className='heavy'>{t('common:po_number')} <span className='u-m-left-1 light'>({t('common:optional')})</span></h3>
                            <div className='basic-form__group'>
                                <input
                                    name='purchaseOrderReferenceNumber'
                                    id='purchaseOrderReferenceNumber'
                                    className='basic-form__text-box'
                                    type='text'
                                    placeholder={t('common:po_number_placeholder')}
                                    ref={register}
                                />
                            </div>

                            {user?.school?.billingProfile && (
                                <>
                                    {user?.school?.billingProfile?.companyName && <p><b>{user.school.billingProfile.companyName}</b></p>}
                                    <div className="u-m-base-1">
                                        {user?.school?.billingProfile?.address && <p>{user.school.billingProfile.address}</p>}
                                        {user?.school?.billingProfile?.address2 && <p>{user.school.billingProfile.address2}</p>}
                                        {user?.school?.billingProfile?.city && <p>{user.school.billingProfile.city}</p>}
                                        {user?.school?.billingProfile?.state && <p>{user.school.billingProfile.state}</p>}
                                        {user?.school?.billingProfile?.zip && <p>{user.school.billingProfile.zip}</p>}
                                        {user?.school?.billingProfile?.country?.name && <p>{user.school.billingProfile.country.name}</p>}
                                    </div>
                                    <div className="u-m-base-2">
                                        {user?.school?.billingProfile?.contactPerson && <p>Contact: {user.school.billingProfile.contactPerson}</p>}
                                        {user?.school?.billingProfile?.contactEmail && <p>Email: {user.school.billingProfile.contactEmail}</p>}
                                        {user?.school?.billingProfile?.contactPhone && <p>Phone: {user.school.billingProfile.contactPhone}</p>}
                                    </div>
                                </>
                            )}
                            <button type='button' className='btn-reset link-underline' onClick={() => setEditBillingProfile(true)}>Edit billing profile</button>
                        </div>
                    </li>
                </ul>


                <h3 className='heavy'>Confirm purchase</h3>

                <div className="basic-form__group basic-form__group--check">
                    <input
                        name="tos"
                        className="basic-form__check-box"
                        ref={register}
                        type="checkbox"
                        id="tos"
                    />
                    <label className="basic-form__check-label" htmlFor="tos">{t('common:i_have_read_and_accept_the')} </label> <a href={tosLink} rel='noopener noreferrer' target='blank' className='u-m-left-1'>{t('common:terms_and_conditions')}</a>
                </div>
                {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}
                <div className="basic-form__group basic-form__group--check">
                    <input
                        name="authority"
                        className="basic-form__check-box"
                        ref={register}
                        type="checkbox"
                        id="authority"
                    />
                    <label className="basic-form__check-label" htmlFor="authority">{t('common:i_have_authority', { type: 'subscription' })}</label>
                </div>
                {errors.authority && <p className='basic-form__hint'>{errors.authority.message}</p>}
                <Button
                    className='u-m-top-2'
                    disabled={loading || +totalStudents === 0 || needsUrn}
                    type='submit'
                    fullWidth
                >Purchase subscription</Button>

                {formErrors && <FormErrors errors={formErrors} />}
            </form>

            {editBillingProfile &&
                <Modal wide closeModal={() => setEditBillingProfile(false)}>
                    <BillingProfileForm isEdit={true} school={school} close={() => setEditBillingProfile(false)} />
                </Modal>
            }
        </>
    )
}

export default InvoiceSubscription;