import React, { useState } from "react";
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';

import { ADD_TEACHER_TO_CLASSES, GET_TEACHER } from "../services/graphql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CheckCircle } from "react-feather";

const AddToMultipleClasses = ({ teacherId, close, currentUser }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [selectedClasses, setSelectedClasses] = useState([]);

    const [done, setDone] = useState(false);


    const { data, error, loading } = useQuery(GET_TEACHER, {
        variables: {
            teacherId: teacherId
        },
        fetchPolicy: 'network-only'
    });

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(t => t !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }

    const [addToMultipleClasses, { loading: adding }] = useMutation(ADD_TEACHER_TO_CLASSES, {
        update: (_, { data }) => {
            if (data.addTeacherToMultipleClasses.errors && data.addTeacherToMultipleClasses.errors.length !== 0) {
                setFormErrors(data.addTeacherToMultipleClasses.errors);
                return;
            }
            if (data.addTeacherToMultipleClasses.teacher.id) {
                // close();
                setDone(true);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TEACHER, variables: { teacherId } }]
    });

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.teacher?.id) {

        const { teacher } = data;
        return (
            <>
                <h2>Add {teacher.displayName} to classes:</h2>

                <ul className="u-m-base-2 list-group">
                    {teacher.school.classes.map(kls =>
                        <li key={kls.id} className='list-item'>
                            <div className="basic-form__group basic-form__group--check u-m-base-0 u-m-top-1">
                                {teacher.studentsClasses.find(stKls => stKls.id === kls.id) ? <CheckCircle size={18} className="u-m-right-2" /> :
                                    <>
                                        <input
                                            type='checkbox'
                                            className="basic-form__check-box"
                                            value={kls.id}
                                            onChange={(e) => onSelectClass(e)}
                                            id={`t-${kls.id}`}
                                            checked={selectedClasses.indexOf(kls.id) > -1}
                                        />
                                        <label className="basic-form__check-label" htmlFor={`t-${kls.id}`}>&nbsp;</label>
                                    </>}
                            </div>
                            <div>
                                {kls.name}
                            </div>
                        </li>
                    )}
                </ul>
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                {
                    done ? <Button outline onClick={close}>Done</Button> :
                        <Button onClick={() => addToMultipleClasses({
                            variables: {
                                teacherId,
                                studentsClassesId: selectedClasses

                            }
                        })} disabled={adding || selectedClasses.length === 0}>Add</Button>
                }
            </>
        )
    }
    return null;
}

export default AddToMultipleClasses;