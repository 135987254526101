import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_SITE_KEY, TERMS_VERSION } from '@axeedge/go-shared-utils';
import ImageFadeIn from 'react-image-fade-in';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { PROMO_TEACHER_SIGNUP } from '../../services/graphql';
import { useTranslation } from 'react-i18next';
import boomerReading from '../../../../images/onboarding/reading-boomer.png';
import styles from '../../SignUp.module.scss';
import { SUBSCRIPTION_INTENT, SIGNUP_REASON } from '../../../../services/constants';
import ls from 'local-storage'
import usa from '../../../../images/usa.png'
import uk from '../../../../images/uk.png'
import row from '../../../../images/row.png'
import styles2 from '../../../Login/components/CountrySelect.module.scss'
import cx from 'classnames'
import { Link } from '@reach/router';

const SignUpForm = ({ subscriptionIntent, setShowCountrySelect }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [signupReason, setSignupReason] = useState(null);

    const { t } = useTranslation(['profile', 'common']);

    const captchaRef = useRef(null);


    const [promoTeacherSignUp, { loading }] = useMutation(PROMO_TEACHER_SIGNUP, {
        update: (_, { data }) => {
            if (data.promoTeacherSignUp.errors && data.promoTeacherSignUp.errors.length !== 0) {
                setFormErrors(data.promoTeacherSignUp.errors);
                setCaptchaValue(null);
                captchaRef.current.reset();
                return;
            }
            if (data.promoTeacherSignUp.user.id) {
                navigate('/signup_confirm', { state: { confirm_email: data.promoTeacherSignUp.user.email, subscriptionIntent: subscriptionIntent } });
            }
        }
    })

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        displayName: Yup.string().required(t('common:display_name_validation')),
        email: Yup.string().email().required(t('enter_your_email')),
        password: Yup.string().required(t('please_enter_password')),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], t('passwords_must_match')),
        tos: Yup.boolean().oneOf([true], t('must_agree_to_tos'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const handleChange = value => {
        setCaptchaValue(value);
    }

    const onSubmit = values => {
        const { firstName, lastName, displayName, email, password, passwordConfirmation } = values;
        promoTeacherSignUp({
            variables: {
                firstName,
                lastName,
                displayName,
                email,
                password,
                passwordConfirmation,
                subscriptionIntent: subscriptionIntent,
                signupReason: signupReason,
                termsVersionApproved: (TERMS_VERSION).toString(),
            }
        });
    }



    return (
        <div className={styles.signupWrapper}>
            {!signupReason ?
                <div>
                    <h1>{subscriptionIntent === SUBSCRIPTION_INTENT.read ? 'Boomreader' : 'BommWriter'}</h1>
                    {subscriptionIntent === SUBSCRIPTION_INTENT.read ? (
                        <>
                            <h2>Start your 30 day free trial</h2>
                            <p className='u-m-base-2'>Your trial will start straight away so please be ready so you can make the most of this period </p>
                            <Button onClick={() => setSignupReason(SIGNUP_REASON.createSchool)}>Signup & start trial</Button>
                        </>
                    ) :
                        <Button onClick={() => setSignupReason(SIGNUP_REASON.createSchool)}>Signup</Button>
                    }

                    <h2 className='u-m-top-3'>OR Join your existing school account</h2>
                    <p className='heavy u-m-base-2'>The best way to join your school is to ask one of your administrators to invite you.</p>
                    <button onClick={() => setSignupReason(SIGNUP_REASON.requestAccess)} className='btn-reset link-underline'>Or signup and request access</button>
                </div> :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h2>Let's create your teacher account</h2>
                    <div className={styles.signupCol}>
                        <div className='basic-form__group'>
                            <label htmlFor='firstName' className='basic-form__text-label'>{t('common:first_name')}</label>
                            <input
                                name='firstName'
                                placeholder={t('common:first_name_placeholder')}
                                className='basic-form__text-box'
                                ref={register}
                                type='text'
                            />
                        </div>
                        <div className='basic-form__group'>
                            <label htmlFor='lastName' className='basic-form__text-label'>{t('common:last_name')}</label>
                            <input
                                name='lastName'
                                placeholder={t('common:last_name_placeholder')}
                                className='basic-form__text-box'
                                ref={register}
                                type='text'
                            />
                        </div>
                    </div>
                    {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                    {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}

                    <div className='basic-form__group'>
                        <label htmlFor='displayName' className='basic-form__text-label'>{t('common:display_name')}</label>
                        <input
                            name='displayName'
                            placeholder={t('common:display_name_placeholder')}
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.displayName && <p className='basic-form__hint'>{errors.displayName.message}</p>}


                    <div className='basic-form__group'>
                        <label htmlFor='email' className='basic-form__text-label'>Your Work Email</label>
                        <input
                            name='email'
                            placeholder={t('common:email')}
                            className='basic-form__text-box'
                            ref={register}
                            type='email'
                        />
                    </div>
                    {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}

                    <div className="basic-form__group">
                        <label htmlFor='password' className='basic-form__text-label'>{t('password')}</label>
                        <input
                            name="password"
                            placeholder={t('password')}
                            className="basic-form__text-box"
                            autoComplete='off'
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                    <div className="basic-form__group">
                        <label htmlFor='passwordConfirmation' className='basic-form__text-label'>{t('confirm_password')}</label>
                        <input
                            name="passwordConfirmation"
                            placeholder={t('confirm_password')}
                            className="basic-form__text-box"
                            autoComplete='off'
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.passwordConfirmation && <p className="basic-form__hint">{errors.passwordConfirmation.message}</p>}
                    <div className={cx(styles2.countrySelectLabel, styles2.countrySelectLabelAlt)}>
                        <p className={styles2.countrySelectPara}>Your country is: <img alt='' className={styles2.countrySelectImg} src={ls('country') === 'US' ? usa : ls('country') === 'GB' ? uk : row} /></p>
                        <span className={styles2.countrySelectBtn} onClick={() => setShowCountrySelect(true)}>Change</span>
                    </div>
                    <div className="basic-form__group basic-form__group--check">
                        <div>
                            <input
                                name="tos"
                                className="basic-form__check-box"
                                ref={register}
                                type="checkbox"
                                id="tos"
                            />
                            <label className="basic-form__check-label" htmlFor="tos">{t('i_agree_to_the')}</label>
                        </div>
                        <a target='blank' className='link-underline u-m-left-1 u-m-right-1 ' href='https://goapps.app/terms'>{t('terms')}</a> &amp;
                        <a target='blank' className='link-underline u-m-left-1' href='https://goapps.app/privacy'>{t('privacy_policy')}</a>
                    </div>

                    {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}

                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}

                    <div className={styles.signupCaptcha}>
                        <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            ref={r => captchaRef.current = r}
                            sitekey={GOOGLE_SITE_KEY}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className='u-display-flex u-justify-center'>
                        <Button type='submit' className='u-width-full' primary disabled={!captchaValue || loading}>{loading ? t('saving') : t('create_account')}</Button>
                    </div>
                    <div className="u-m-top-2 u-display-flex u-justify-center">
                        <p>Already have an account?</p>
                        <Link className='link-underline auth__link u-m-left-1' to='/'>Login</Link>
                    </div>
                </form>
            }
            <div className={styles.signupAppBanner}>
                {
                    subscriptionIntent === SUBSCRIPTION_INTENT.read &&
                    <>
                        {/* <ImageFadeIn src={grandTourLogo} alt='' /> */}
                        {/* <p className='heavy u-m-base-2 u-m-top-2'>Create your account and join the Grand Tour. Make the successful shift to digital reading records.</p> */}
                        <ImageFadeIn src={boomerReading} alt='' />
                    </>
                }
            </div>

        </div>
    );

}

export default SignUpForm;