import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../../../services/auth/AuthProvider';
import { GET_MATH_SUBSCRIPTION } from '../../services/graphql';
import BoomMathDashboard from './BoomMathDashboard';

const BoomMathSettings = () => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(GET_MATH_SUBSCRIPTION, {
        variables: {
            schoolId: currentUser.school.id
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.school) {

        const { boomMathSubscription } = data.school;

        return (
            <div>
                {
                    currentUser.isSchoolAdmin && boomMathSubscription && (
                        <BoomMathDashboard user={currentUser} setCurrentUser={setCurrentUser} subscription={boomMathSubscription} />
                    )
                }
            </div>
        )
    }
    return null
}

export default BoomMathSettings;