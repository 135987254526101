import React, { useState, useRef } from 'react';
import AddClassTeacher from '../../components/AddClassTeacher';
import SideModal from '../../../../components/SideModal';
import { useTranslation } from 'react-i18next';
import EditClassForm from '../../components/EditClassForm';
import { UPDATE_SCHOOL_MEMBERSHIPS, } from '../../../Teachers/services/graphql';
import { GET_CLASS_QUERY } from '../../services/graphql';

import { useMutation } from '@apollo/react-hooks';
import { FormErrors } from '@axeedge/go-teacher-components';
import { PlusCircle, Watch, ChevronDown } from 'react-feather';
import { useClickAway } from 'react-use';

import styles from './ClassView.module.scss';
import styles2 from '../../Classes.module.scss';

const ClassViewTeachers = ({ classData, currentUser }) => {

    const { t } = useTranslation(['classes', 'common'])

    const [showAddTeachersModal, setShowAddTeachersModal] = useState();
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [formErrors, setFormErrors] = useState([]);

    const [dropdown, setDropdown] = useState(false);

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));

    const [updateSchoolMemberships, { loading }] = useMutation(UPDATE_SCHOOL_MEMBERSHIPS, {
        update: (_, { data }) => {
            if (data.updateSchoolMemberships.errors && data.updateSchoolMemberships.errors.length) {
                setFormErrors(data.updateSchoolMemberships.errors);
            }
            if (data.updateSchoolMemberships && data.updateSchoolMemberships.school && data.updateSchoolMemberships.school.id) {
                setSelectedTeachers([]);
                setFormErrors([]);
                setDropdown(false);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classData.id } }]
    });

    const onSelectTeacher = e => {
        if (selectedTeachers.indexOf(e.target.value) !== -1) {
            setSelectedTeachers(selectedTeachers.filter(t => t !== e.target.value));
        } else {
            setSelectedTeachers([...selectedTeachers, e.target.value]);
        }
    }

    const onSelectAllTeachers = e => {
        const teacherIds = classData.teachers.filter(t => t.id !== currentUser.id).map(t => t.id);
        e.target.checked ? setSelectedTeachers(teacherIds) : setSelectedTeachers([])
    }

    const updateMemberships = (isAdmin) => {
        updateSchoolMemberships({
            variables: {
                teacherIds: selectedTeachers,
                schoolId: currentUser.school.id,
                isSchoolAdmin: isAdmin
            }
        });
    }

    const showSelectAll = classData.teachers.filter(t => t.id !== currentUser.id).length > 0;

    return (
        <div className={styles.container}>
            <div className='row'>
                <div className="col-lg-8">

                    <div className={styles2.actions}>
                        {currentUser.isSchoolAdmin && showSelectAll && (
                            <div className={styles2.actionsLeft}>
                                <div className="basic-form__group basic-form__group--check u-m-right-2 u-m-base-0">
                                    <input
                                        name="all"
                                        className="basic-form__check-box"
                                        type="checkbox"
                                        checked={selectedTeachers.length === classData.teachers.filter(t => t.id !== currentUser.id).length}
                                        onChange={(e) => onSelectAllTeachers(e)}
                                        id="selectAll"
                                    />
                                    <label className="basic-form__check-label" htmlFor='selectAll'>All / None</label>
                                </div>
                                <div ref={ref} className='dropdownHolder'>
                                    <button disabled={selectedTeachers.length === 0} className='dropdownBtn' onClick={() => setDropdown(!dropdown)}>Actions <ChevronDown /> </button>
                                    {dropdown && (
                                        <ul className='dropdownMenu'>
                                            <li><button className='dropdownMenuItem' onClick={() => updateMemberships(false)}>{loading ? t('common:saving') : t('set_as_teacher')}</button></li>
                                            <li><button className='dropdownMenuItem' onClick={() => updateMemberships(true)}>{loading ? t('common:saving') : t('set_as_admin')}</button></li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}

                        <button className='btn-icon' onClick={() => setShowAddTeachersModal(true)}>
                            <span className='u-m-right-1'>{t('add_remove_teachers')}</span> <PlusCircle size="20" />
                        </button>
                    </div>

                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}

                    <ul className='u-m-base-3 list-group'>
                        {
                            classData.teachers && !!classData.teachers.length ? classData.teachers.sort((a, b) => a.lastName.localeCompare(b.lastName)).map(teacher => {
                                return (
                                    <li className='list-item' key={`class-t-${teacher.id}`}>
                                        {currentUser.isSchoolAdmin && currentUser.id !== teacher.id &&
                                            <div className="basic-form__group basic-form__group--check u-m-base-0 u-m-top-1">
                                                <input
                                                    type='checkbox'
                                                    className="basic-form__check-box"
                                                    value={teacher.id}
                                                    onChange={(e) => onSelectTeacher(e)}
                                                    id={`t-${teacher.id}`}
                                                    checked={selectedTeachers.indexOf(teacher.id) > -1}
                                                />
                                                <label className="basic-form__check-label" htmlFor={`t-${teacher.id}`}>&nbsp;</label>
                                            </div>
                                        }
                                        {!teacher.active && <Watch className='u-text-muted u-m-left-1' size='16' />} {teacher.displayName}
                                    </li>
                                )
                            }) : <li>{t('common:no_teachers')}</li>
                        }
                    </ul>
                </div>
                <div className="col-lg">
                    <div className={styles.classEditForm}>
                        <EditClassForm isSchoolAdmin={currentUser.isSchoolAdmin} school={currentUser.school} schoolId={currentUser.school.id} classData={classData} />
                    </div>
                </div>

                {
                    showAddTeachersModal && (
                        <SideModal closeModal={() => setShowAddTeachersModal(false)}>
                            <AddClassTeacher schoolCode={currentUser.school.schoolCode} studentClass={classData} />
                        </SideModal>
                    )
                }
            </div>
        </div>

    )
}
export default ClassViewTeachers;