
import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { Menu } from 'react-feather';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

import { Trans } from 'react-i18next';
import ls from 'local-storage'

const AuthLinks = ({ signup = false }) => {

    const [mobileVisible, setMobileVisible] = useState(false);
    
    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setMobileVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref} className='login-header'>
            <div className='login__mobile-toggle' onClick={() => setMobileVisible(!mobileVisible)}>
                <Trans i18nKey='im_a_teacher'>
                    I'm a <span>Teacher</span>
                </Trans> <Menu />
            </div>
            <ul className={cx('login__mobile', { 'login__mobile--visible': mobileVisible })}>
                <li className='login__mobile__link'><a href={`${getUrl(APPS.go_parents_desktop)}${signup ? '/signup' : '/'}`}><Trans i18nKey='im_a_parent'> I'm a <span>Parent</span></Trans></a></li>
                <li className='login__mobile__link'><a href={`${getUrl(APPS.go_portal_pupil)}`}><Trans i18nKey='im_a_pupil'> I'm a <span>Pupil</span></Trans></a></li>
            </ul>
            <ul className='login__opts'>
                <li className='login__opts__link'><a href={`${getUrl(APPS.go_portal_pupil)}?region=${ls('country')}`}><Trans i18nKey='im_a_pupil'> I'm a <span>Pupil</span></Trans></a></li>
                {ls('country') !== 'US' && <li className='login__opts__link'><a href={`${getUrl(APPS.go_parents_desktop)}${signup ? '/signup' : '/'}`}><Trans i18nKey='im_a_parent'> I'm a <span>Parent</span></Trans></a></li>}
                <li className='login__opts__link login__opts__link--active'>
                    <Trans i18nKey='im_a_teacher'> I'm a <span>Teacher</span></Trans>
                </li>
            </ul>
        </div>
    )
};

export default AuthLinks;