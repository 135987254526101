import React, { Fragment } from 'react';
import styles from './PackItem.module.scss';
import { Edit } from 'react-feather';


const PackItem = ({ pack, setViewPack }) => {
    return (
        <Fragment>
           <li className='list-item'>
                <div className={styles.packMeta}>
                    <b>{pack.students}</b> pupils
                </div>
                <div className={styles.packActions}>
                    <p>{pack.students_class || <i className='u-text-muted'>unassigned</i>}</p>
                    <button className='btn-reset link-reverse u-m-left-2' onClick={() => setViewPack(pack)}><Edit size='16'/></button>
                </div>
            </li>
        </Fragment>
    )
}

export default PackItem;