import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Modal } from '@axeedge/go-teacher-components';
import { FIND_STUDENTS } from '../../services/graphql';

import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';

import Result from './components/Result';
import { UserPlus } from 'react-feather';

const AddExistingStudents = ({ classId, schoolId }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [showAddModal, setShowAddModal] = useState();
    const [results, setResults ] = useState();

    const [findStudents, { data, error, loading }] = useLazyQuery(FIND_STUDENTS, {
        fetchPolicy: 'network-only'
    });

    const schema = Yup.object().shape({
        name: Yup.string().required(t('please_enter_search_term')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (data && data.studentsByName) {
            setResults(data.studentsByName);
        }
    }, [data, results]);

    useEffect(() => {
        if (!showAddModal) {
            setResults(null);
        }
    }, [results, showAddModal]);


    const onSubmit = ({ name } )=> {
        findStudents({
            variables: {
                name,
                schoolId
            }
        });
    }

    if (showAddModal) {
        return (
            <Modal closeModal={() => setShowAddModal(false)} >
                <h2 className='u-m-base-3'>{t('add_existing_pupils')}</h2>
                <form className='u-display-flex' onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type='text'
                        className='basic-form__text-box u-m-right-2 u-m-base-0 '
                        placeholder={t('search_by_name')}
                        ref={register}
                        name='name'
                    />
                    <Button>{loading ? t('common:searching') : t('common:button.search')}</Button>
                </form>
                {errors.name && <p className="u-text-danger u-m-top-2">{errors.name.message}</p>}
                
                {error && <p>{error.message}</p>}       
                {results && results.length !== 0 && (
                    <ul className='list-group u-m-top-2'>
                        {results.map(student => (
                            <Result key={`st-${student.id}`} student={student} classId={classId} />
                        ))}
                    </ul>
                )}

                {results && results.length === 0 && <p className='u-m-top-2'>{t('no_students_with_name_found')}</p>}
            </Modal>
        )
    }

    return(
        <button 
            onClick={() => setShowAddModal(true)}
            className='btn-icon'
        >
           <UserPlus size="18" /> {t('add_existing_pupils')}
        </button>
    )
}

export default AddExistingStudents;