import React, { useState, useContext } from 'react';
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import { useMutation } from '@apollo/react-hooks';
import { ArrowLeft, Delete } from 'react-feather';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { ADD_CLASS_MUTATION, ADD_CLASS_ASSIGN_STUDENTS_MUTATION, GET_CLASSES_QUERY } from '../../Classes/services/graphql';
import { GET_CLASS_QUERY } from '../../Classes/scenes/MoveClass/components/services/graphql'
import { useTranslation } from 'react-i18next';
import { PlusCircle } from 'react-feather';
import SelectClassTeachers from '../../Classes/components/CreateClassForm/SelectClassTeachers';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { CLASS_TYPE } from '../../../services/constants';
import { ADD_STUDENT_MUTATION, GET_YEAR_STATS, GET_FILTERED_STUDENTS,GET_UNASSIGNED_STUDENTS_QUERY  } from '../services/graphql';

const CreateClass = ({ studentObjects, close, setShowSaveConfirm, closeClassOptions, studentsClass, setSelectedTab, classTabs, currentYear, schoolId, clearStudents }) => {

    const { currentUser: user } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const { t } = useTranslation(['classes', 'common']);

    const [showAddTeachers, setShowAddTeachers] = useState(false);
    const [currentTeachers, setCurrentTeachers] = useState([]);

    const schema = Yup.object().shape({
        name: Yup.string().required(t('please_enter_class_name')),
        type: Yup.string().required(t('please_select_class_type')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });

    const [addClass, { loading: addingClass }] = useMutation(ADD_CLASS_MUTATION, {
        update: (_, { data }) => {
            if (data.createStudentsClass.errors && data.createStudentsClass.errors.length !== 0) {
                setFormErrors(data.createStudentsClass.errors);
                return;
            }
            if (data.createStudentsClass.studentsClass.id) {
                close();
                setSelectedTab(classTabs.allClasses);
                setShowSaveConfirm && setShowSaveConfirm('Class created successfully');
            }
        },
        refetchQueries: [{ query: GET_CLASSES_QUERY, variables: { includeAdminedClasses: user && user.isSchoolAdmin } }]
    });

    const getQueries = () => {
        if (currentYear === 'UPN') {
            return {
                schoolId: schoolId,
                withoutUpn: true,
            }
        } else {
            return {
                schoolId: schoolId,
                yearGroup: currentYear && currentYear.toString(),
            }
        }
    }

    const [addClassAssignStudents, { loading: addingClassAssigning }] = useMutation(ADD_CLASS_ASSIGN_STUDENTS_MUTATION, {
        update: (_, { data }) => {
            if (data.createStudentsClassAndAssignStudents.errors && data.createStudentsClassAndAssignStudents.errors.length !== 0) {
                setFormErrors(data.createStudentsClassAndAssignStudents.errors);
                return;
            }
            if (data.createStudentsClassAndAssignStudents.studentsClass.id) {
                closeClassOptions();
                close();
            }
        },
        refetchQueries: [
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: schoolId,
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId: schoolId,
                }
            }
        ],
    });

    const onSubmit = values => {
        const { name, visibleToAllTeachers, type } = values;
        let newStudents = []
        studentObjects.forEach(s => {
                newStudents.push({studentId: s, removeFromClassId: null})
        })
        addClassAssignStudents({
            variables: {
                name,
                schoolId: user.school.id,
                visibleToAllTeachers,
                teacherIds: currentTeachers.map(t => t.id),
                studentsData: newStudents,
                typeId: +type
            }
        });
    }

    return (
        <div>
            <div className="u-display-flex u-align-center u-m-top-2 u-m-base-3">
                {studentsClass && <ArrowLeft onClick={close} />}
                <h2 className='u-m-base-0'>{t('create_new_class')}</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <p className='heavy u-m-base-2'>{t('class_info')}</p>
                <div className="basic-form__group">
                    <label htmlFor='name' className='basic-form__text-label u-m-right-1'>{t('class_name')}</label>
                    <input
                        name="name"
                        id="name"
                        placeholder={t('class_name_placeholder')}
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                    {/* <b className='u-m-left-1'>2021/2022</b> */}
                </div>
                {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}

                <div className="basic-form__group">
                    <label htmlFor='name' className='basic-form__text-label'>{t('class_type')}</label>
                    <select name="type" ref={register} className="basic-form__text-select u-display-block">
                        <option value=''>{t('please_select')}</option>
                        <option value={CLASS_TYPE.registration}>{t('registration')}</option>
                        <option value={CLASS_TYPE.other}>{t('other')}</option>
                    </select>
                </div>
                {errors.type && <p className="basic-form__hint">{errors.type.message}</p>}


                <p className='heavy  u-m-base-1'>{t('teachers_and_access')}</p>
                {currentTeachers.length > 0 &&
                    <ul className='list-group'>
                        {currentTeachers.map(teacher => (
                            <li key={`t-${teacher.id}`} className='list-item'>
                                <span className='list-item--child'>{teacher.displayName}</span>
                                <div className='u-m-left-auto list-item--child'>
                                    <button
                                        type='button'
                                        className='btn-reset link-reverse'
                                        onClick={() => setCurrentTeachers(currentTeachers.filter(t => t.id !== teacher.id))}
                                    ><Delete /></button>
                                </div>
                            </li>
                        ))}
                    </ul>
                }

                <button
                    type='button'
                    onClick={() => { setShowAddTeachers(true); setFormErrors([]) }}
                    className='btn-icon u-m-base-1 u-m-top-1'
                >
                    <PlusCircle /> {t('add_teacher')}
                </button>
                {currentTeachers.length === 0 &&
                    <>
                        <p className='small'>{t('teachers_access_info_0')}</p>
                        <p className='small u-m-base-2'>{t('teachers_access_info_1')}</p>
                    </>
                }

                {
                    showAddTeachers && (
                        <Modal closeModal={() => setShowAddTeachers(false)}>
                            <SelectClassTeachers closeTeachers={() => setShowAddTeachers(false)} currentTeachers={currentTeachers} setCurrentTeachers={setCurrentTeachers} />
                        </Modal>
                    )
                }

                <div className="u-m-top-2 basic-form__group basic-form__group--check">
                    <p className='u-m-right-auto heavy'>{t('visible_to_all_teachers')}</p>
                    <input
                        type="checkbox"
                        name="visibleToAllTeachers"
                        ref={register}
                        className="switchToggle"
                        id="visibleToAllTeachers"
                    />
                    <label className="switchLabel" htmlFor="visibleToAllTeachers">toggle</label>
                </div>
                <p className='small'>{t('visible_class_to_all_info')}</p>

                {formErrors.length !== 0 && <FormErrors errors={formErrors.map(formError => t(formError))} />}

                <div className='u-m-top-3'>
                    <Button type="submit" primary className="u-m-right-1" disabled={addingClass || addingClassAssigning}>{addingClass || addingClassAssigning ? t('common:saving') : studentsClass ? "Create class & assign selected pupils" : "Create class"}</Button>
                    <Button outline onClick={close}>Cancel</Button>
                </div>
            </form>
        </div>
    )
}


export default CreateClass