import React, {Fragment} from 'react';
import styles from '../NewAcademicYear/NewAcademicYear.module.scss';
import {ArrowLeft} from 'react-feather'
import { Link } from '@reach/router';
import Vimeo from '@u-wave/react-vimeo';
import ButtonLink from '../../../../components/ButtonLink';

const HelpVideo = () => {

    return (
        <Fragment>
            <div className={styles.pageHeader}>
                <div>
                <Link to="/classes" className={styles.backButton}>
                    <ArrowLeft size="30"/>
                </Link>
                </div>
                <h1 className={styles.pageHeading}>Start Academic Year 2021/2022</h1>
            </div>
            <h2 className="u-bold">Before You Start&hellip;</h2>
            <p className="u-m-base-20 u-bold u-large-text">Please take a moment to review our quick guide.</p>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.vidContainer}>
                        <Vimeo video='https://vimeo.com/589796391/ff841580aa' responsive />
                        <p className="u-bold u-m-top-2 u-m-base-2">You will need to know your new classes - please have paper copies or a tab with them open for your reference.</p>
                        <ButtonLink to="/classes/newAcademicYear" fullWidth centered>Start Now</ButtonLink>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HelpVideo;
