import { gql } from 'apollo-boost';

export const UPDATE_TERMS = gql`
mutation approveTerms($version: String!) {
    approveTerms(version: $version) {
        user {
            id
            lastTermsVersionApproved
        }
        errors
    }
}
`;