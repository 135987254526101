import React, { useState } from 'react';
import { Button, Loader, FormErrors } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import cx from 'classnames';
import { BOOMREAD_COST, RENEW_AND_PAY_NOW, GET_READ_SUBSCRIPTIONS } from '../../services/graphql';
import styles2 from '../../scenes/PurchaseSubscription/components/Payment/Payment.module.scss';
import styles from './AutoRenewal.module.scss';

const RenewAndPayConfirm = ({ user, renewal, price, close }) => {


    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(BOOMREAD_COST, {
        fetchPolicy: 'network-only',
        variables: {
            schoolId: user?.school?.id,
            years: renewal.duration,
            studentsCount: +renewal.studentsCounter,
            withDataDashboard: renewal.dataDashboardAccess,
            withWonde: renewal.wondeAccess
        }
    });

    const [renewAndPayNow, { loading: renewing }] = useMutation(RENEW_AND_PAY_NOW, {
        update: (_, { data }) => {
            if (data.renewAndPayNow.errors && data.renewAndPayNow.errors.length > 0) {
                setFormErrors(data.renewAndPayNow.errors);
                return;
            }
            if (data.renewAndPayNow?.school?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: user?.school.id }, fetchPolicy: 'network-only' }]
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.boomreadCost) {

        const { boomreadCost: cost } = data;

        return (
            <>
                <h2>Renew Now</h2>
                <hr className='separator' />
                <p className='lead heavy'>Are you sure?</p>
                <p>Please confirm the details below and we'll raise and issue an invoice for payment.</p>
                <p>You'll keep your existing renewal date ({moment(renewal.toBeProcessedAt, 'YYYY-MM-DD').add(1, 'year').format('DD MMM YYYY')}).</p>
                <p>You'll be able to use any additional seats immediately but any reductions will take effect after your renewal date ({moment(renewal.toBeProcessedAt, 'YYYY-MM-DD').format('DD MMM YYYY')}).</p>
                <hr className='separator' />
                <h2 className='h1'><b className='heavy'>{renewal.studentsCounter}</b> Students</h2>


                <p><b>{renewal.duration === 1 ? <>One year - <span className={styles.renewStrike}>£{price.oneYearPricePerStudent}</span> /pupil</> :
                    <>2 Year - £{price.twoYearPricePerStudent}/pupil</>} - expires {moment(renewal.toBeProcessedAt, 'YYYY-MM-DD').add(1, 'year').format('DD MMM YYYY')}</b></p>


                <p className='heavy'>Invoice/Billing details</p>
                {user?.school?.billingProfile && (
                    <>
                        <p>FAO: {user.school.billingProfile.contactPerson}</p>
                        <p className="heavy">{user.school.billingProfile.companyName}</p>
                        <p>{user.school.billingProfile.address}</p>
                        <p>{user.school.billingProfile.address2}</p>
                        <p className="u-m-base-1">{user.school.billingProfile.city}, {user.school.billingProfile.zip}</p>

                        <p>{user.school.billingProfile.contactEmail}</p>
                        <p className='u-m-base-3'>{user.school.billingProfile.contactPhone}</p>
                    </>

                )}

                <p className='heavy u-m-base-2'>Invoice Summary</p>

                <p className='heavy u-m-base-1'>{renewal.isSecondYearRenewal ? 'Renewal (Year 1 of 2)' : 'Renewal'}</p>
                <ul className={cx(styles2.paymentSummary, styles.paymentSummaryFull)}>
                    <li className={styles2.paymentSummaryItem}>
                        {renewal.studentsCounter} students @ £{renewal.duration ? price.oneYearPricePerStudent : price.oneYearPricePerStudent}
                        <div className={styles2.paymentSummaryData}>
                            <span className={styles2.paymentSummaryDataValue}>£{cost.standardCost.toFixed(2)}</span>
                        </div>
                    </li>


                    {renewal.dataDashboardAccess && renewal.wondeAccess &&
                        <li className={styles2.paymentSummaryItem}>
                            Data Dashboard & MIS Integration
                            <div className={styles2.paymentSummaryData}>
                                <span className={styles2.paymentSummaryDataValue}>£{(cost.dataDashboardCost + cost?.wondeCost).toFixed(2) || '0.00'}</span>
                            </div>
                        </li>
                    }

                    {renewal.wondeAccess && !renewal.dataDashboardAccess &&
                        <li className={styles2.paymentSummaryItem}>
                            MIS Integration
                            <div className={styles2.paymentSummaryData}>
                                <span className={styles2.paymentSummaryDataValue}>£{cost?.wondeCost.toFixed(2) || '0.00'}</span>
                            </div>
                        </li>
                    }

                    {Boolean(cost.discountValue) &&
                        <li className={styles2.paymentSummaryItem}>
                            Discount
                            <div className={styles2.paymentSummaryData}>
                                <span className={styles2.paymentSummaryDataValue}>£{cost.discountValue.toFixed(2)}</span>
                            </div>
                        </li>
                    }
                    <li className={cx(styles2.paymentSummaryItem)}>
                        VAT @ 20%
                        <div className={styles2.paymentSummaryData}>
                            <span className={styles2.paymentSummaryDataValue}>£{cost.VAT.toFixed(2)}</span>
                        </div>
                    </li>
                    <li className={cx(styles2.paymentSummaryItem, styles2.paymentSummaryItemTotal)}>
                        Total
                        <div className={styles2.paymentSummaryData}>
                            <span className={styles2.paymentSummaryDataValue}>£{(cost.VAT + cost.totalCost).toFixed(2)}</span>
                        </div>
                    </li>
                </ul>
                {formErrors && <FormErrors errors={formErrors} />}
                <Button onClick={() => renewAndPayNow({
                    variables: {
                        renewalId: renewal.id
                    }
                })} disabled={renewing} className='u-m-base-3' fullWidth>Yes, renew and pay now</Button>
                <Button onClick={close} className='btn-danger u-m-base-3' fullWidth>Cancel</Button>

            </>
        )
    }
    return null;
}

export default RenewAndPayConfirm;