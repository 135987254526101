import React, { useState } from "react";
import { Button, Loader, FormErrors } from "@axeedge/go-teacher-components";
import { ArrowRight, CheckCircle, Edit } from "react-feather";
import SideModal from '../../../../components/SideModal';
import { BOOMREAD_PRICING } from "../../services/graphql";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

import RenewAndPayConfirm from "./RenewAndPayConfirm";
import EditRenewal from "./EditRenewal";
import { CONFIRM_RENEWAL, GET_READ_SUBSCRIPTIONS, BOOMREAD_COST } from "../../services/graphql";
import moment from "moment";
import styles from './AutoRenewal.module.scss';
import cx from 'classnames';
import PurchaseDataDashboardAccess from "../PurchaseDataDashboardAccess";

const AutoRenewal = ({ renewal, user, setCurrentUser, subscription, setShowCancel }) => {

    const [confirmRenewPay, setConfirmRenewPay] = useState(false);
    const [subDuration, setSubDuration] = useState(renewal.duration);
    const [formErrors, setFormErrors] = useState([]);

    const [editRenewal, setEditRenewal] = useState(false);
    const [showPriceChange, setShowPriceChange] = useState(false);

    const [costs, setCosts] = useState(null);

    // console.log('renewal', renewal);

    const { data, loading, error } = useQuery(BOOMREAD_PRICING);

    const [confirmRenew, { loading: confirming }] = useMutation(CONFIRM_RENEWAL, {
        update: (_, { data }) => {
            if (data.confirmRenew.errors && data.confirmRenew.errors.length > 0) {
                setFormErrors(data.confirmRenew.errors);
                return;
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId: user?.school.id } }]
    });


    const { data: dataCosts } = useQuery(BOOMREAD_COST, {
        fetchPolicy: 'network-only',
        variables: {
            schoolId: user.school?.id,
            years: subDuration,
            studentsCount: +renewal.studentsCounter,
            withDataDashboard: renewal.dataDashboardAccess,
            withWonde: renewal.wondeAccess
        },
        onCompleted: (data) => setCosts(data.boomreadCost),
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.boomReadPricing) {
        const { boomReadPricing: price } = data;

        return (
            <>
                <div className={styles.renewHeader}>
                    {renewal.isSecondYearRenewal ? 'Year 2 invoice will be issued: ' : 'Your Subscription auto-renews: '}
                    {moment(renewal.toBeProcessedAt, 'YYYY-MM-DD').format('DD MMM YYYY')}
                </div>
                <p className="heavy lead u-text-center u-m-base-2">Please check and update your renewal details</p>

                <div className={styles.renewPanel}>
                    <div className={styles.renewPanelSt}>
                        <b>{renewal.studentsCounter}</b> students <button className={cx("btn-reset", styles.renewPanelStBtn)} onClick={() => setEditRenewal(true)}><Edit size="18" className="u-m-right-1" /> Edit</button>
                    </div>
                    <hr className="separator" />
                    {renewal.discount ? (
                        <>
                            <div className={styles.renewDiscount}>
                                We've applied a {renewal.discount}% discount to your renewal.
                            </div>
                            <p><b>{renewal.duration === 1 ? <>One year plan - <span className={styles.renewStrike}>£{price.oneYearPricePerStudent}</span> £{(price.oneYearPricePerStudent - price.oneYearPricePerStudent / 100 * renewal.discount).toFixed(2)} /pupil</> :
                                <>Two Year plan - <span className={styles.renewStrike}>£{price.twoYearPricePerStudent}</span> £{(price.twoYearPricePerStudent - price.twoYearPricePerStudent / 100 * renewal.discount).toFixed(2)}/pupil</>} - expires {moment(renewal.toBeProcessedAt, 'YYYY-MM-DD').add(1, 'year').format('DD MMM YYYY')}</b></p>
                        </>
                    ) :
                        <p><b>{renewal.duration === 1 ? <>One year plan - £{price.oneYearPricePerStudent}/pupil</> : <>Two Year plan - ${price.twoYearPricePerStudent}/pupil</>} - expires {moment(renewal.toBeProcessedAt, 'YYYY-MM-DD').add(1, 'year').format('DD MMM YYYY')}</b></p>

                    }

                    {renewal.dataDashboardAccess && renewal.wondeAccess &&
                        <p>Data Dashboard & MIS Integration - £{(costs?.dataDashboardCost + costs?.wondeCost)?.toFixed(2) || '0.00'}</p>
                    }

                    {renewal.wondeAccess && !renewal.dataDashboardAccess &&
                        <p>MIS Integration - £{(costs?.wondeCost)?.toFixed(2) || '0.00'}</p>
                    }

                    {Boolean(renewal.discount) &&
                        <p>
                            Loyalty discount ({renewal.discount}%):
                            <span className=''>-£{costs?.discountValue.toFixed(2) || "0.00"}</span>
                        </p>
                    }
                    <p>Total cost: £{costs ? costs.totalCost.toFixed(2) : '0.0'} (excl.VAT) </p>

                    {!(user?.school?.canAccessWonde || renewal.wondeAccess) &&
                        <>
                            <PurchaseDataDashboardAccess withRenewal={renewal} subscription={subscription} currentUser={user} setCurrentUser={setCurrentUser} />
                            <p className="u-m-top-2 heavy"><a className="u-display-flex u-align-center" href="https://www.boomreader.co.uk/datadashboard" target='blank' rel='noopener noreferrer'>Find out more about the Data Dashboard <ArrowRight className="u-m-left-1" /></a></p>
                        </>
                    }

                    <hr className="separator" />
                    <p className="heavy">Invoice/Billing details</p>
                    {user?.school?.billingProfile && (
                        <>
                            <p>FAO: {user.school.billingProfile.contactPerson}</p>
                            <p className="heavy">{user.school.billingProfile.companyName}</p>
                            <p>{user.school.billingProfile.address}</p>
                            <p>{user.school.billingProfile.address2}</p>
                            <p className="u-m-base-1">{user.school.billingProfile.city}, {user.school.billingProfile.zip}</p>

                            <p>{user.school.billingProfile.contactEmail}</p>
                            <p>{user.school.billingProfile.contactPhone}</p>
                        </>

                    )}
                    <hr className="separator" />

                    <div className="u-display-flex u-justify-between u-align-center u-m-top-3">
                        <Button outline onClick={() => setEditRenewal(true)}><Edit size="18" /> Edit renewal</Button>
                        {!renewal.editedBy ? <>
                            <span className="heavy u-m-left-2 u-m-right-2">OR</span>
                            <Button outline disabled={confirming} onClick={() => confirmRenew({
                                variables: {
                                    renewalId: renewal.id
                                }
                            })}><CheckCircle size="18" /> This looks good</Button>
                        </> :
                            <p className="small u-display-flex u-align-center"><CheckCircle className="u-text-accent-3 u-m-right-1" /> Updated {moment(renewal.editedAt).format('DD MMM YYYY')} by {renewal.editedBy.displayName}</p>
                        }
                    </div>

                    {formErrors && <FormErrors errors={formErrors} />}
                </div>

                {Boolean(renewal.discount) && <button className="btn-reset link-underline u-m-base-3" onClick={() => setShowPriceChange(true)}>Our pricing has changed since 1 April 2023</button>}

                <p className="heavy">I'd like to pay now!</p>
                <p className="u-m-base-2">We'll automatically issue your invoice on {moment(subscription.expiresAt, 'YYYY-MM-DD').format('DD MMM')}, but you can use the option below and we'll issue your invoice now keeping your renewal date:</p>
                <Button disabled={!renewal.editedBy} fullWidth onClick={() => setConfirmRenewPay(true)} className='u-m-base-2'>Renew and pay now</Button>

                <hr className="separator" />
                <p className="heavy u-m-base-1">I don't want to renew</p>

                <p className="u-m-base-2">Cancelled accounts will still be active until your current subscription expires and will not auto-renew.</p>
                <Button onClick={() => setShowCancel(true)} className='btn-danger u-m-base-2'>Cancel subscription</Button>

                <hr className="separator" />
                <p className="heavy lead u-m-base-2">Current plan</p>
                <p className='u-m-base-1'><span className="lead heavy">{renewal.actualStudentsCounter}/{renewal.initialStudentsCounter}</span> pupils</p>
                {subscription.createdBy && <p>Created by: {subscription.createdBy?.displayName}</p>}

                <p className="small u-m-base-2">{!subscription.duration || subscription.duration === 1 ? 'One year plan' : 'Two Year plan'} - expires {moment(subscription.expiresAt, 'YYYY-MM-DD').format('DD MMM YYYY')}</p>

                {confirmRenewPay && (
                    <SideModal closeModal={() => setConfirmRenewPay(false)}>
                        <RenewAndPayConfirm
                            price={price}
                            renewal={renewal}
                            user={user}
                            close={() => setConfirmRenewPay(false)}
                        />
                    </SideModal>
                )}

                {editRenewal && (
                    <SideModal closeModal={() => setEditRenewal(null)}>
                        <EditRenewal
                            subDuration={subDuration}
                            setSubDuration={setSubDuration}
                            close={() => setEditRenewal(null)}
                            renewal={renewal}
                            price={price}
                            currentUser={user}
                        />
                    </SideModal>
                )}

                {showPriceChange && (
                    <SideModal closeModal={() => setShowPriceChange(false)}>
                        <h3 className="heavy u-m-base-3">Our pricing has changed since 1 April 2023</h3>
                        <p className="u-m-base-3">We are continuously investing in our products and the support we provide your teachers. This team effort helps maximize value in your classrooms and the outcomes you expect from education technology. Occasionally we have to raise prices to reflect added functionality and market conditions. We do this so that we can continue to provide you with the best solutions and ultimately empower your teachers and engage your students.</p>
                        <div className={styles.priceChange}>As an existing customer we’ll apply a 10% discount to your renewal.</div>
                    </SideModal>
                )}

            </>
        )
    }
    return null;
}

export default AutoRenewal;