import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button, Modal } from '@axeedge/go-teacher-components';
import { PURCHASE_ACTIVITY_TRIAL, GET_SUBS_QUERY } from '../../../../services/graphql';
import { AuthContext } from '../../../../../../services/auth/AuthProvider';
import moment from 'moment';
import ButtonLink from '../../../../../../components/ButtonLink';
import styles from '../../GoWriteDashboard.module.scss';
const Activities = ({ setViewPack }) => {

    const { currentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [moreInfo, setMoreInfo] = useState(false);
    const { data, error, loading } = useQuery(GET_SUBS_QUERY, {
        variables: {
            schoolId: currentUser.school.id
        },
        fetchPolicy: 'network-only'
    });

    const [purchaseTrial] = useMutation(PURCHASE_ACTIVITY_TRIAL, {
        update: (_, { data }) => {
            if (data.purchaseGoWriteActivitiesTrialAccess.errors && data.purchaseGoWriteActivitiesTrialAccess.errors.length) {
                setFormErrors(data.purchaseGoWriteActivitiesTrialAccess.errors);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SUBS_QUERY, variables: { schoolId: currentUser.school.id } }]
    })

    if (loading) {
        return <p>Loading...</p>
    }

    if (error) {
        return <p>Something went wrong...</p>
    }

    if (data) {
        let activities = data.school.schoolSubscriptions.filter(s => s.subscriptionType === "Writing Activities" && s.isTrial === null)
        let trials = data.school.schoolSubscriptions.filter(s => s.subscriptionType === 'Writing Activities' && s.isTrial === true)
        return (
            <>
                <div className={styles.actPanel}>
                    <div className={styles.actPanelHead}>
                        <h2>BoomWriter+ Individual Writing Activities</h2>
                        {activities.length > 0 && <span className={styles.statusLabel}>Active</span>}
                    </div>

                    {activities.length === 0 && trials.length === 0 ?
                        <>
                            <p>One off writing activities that are perfect for in-class and remote learning. All subscriptions come with a 30 day free trial.</p>
                            <span className={styles.helpLink}>More on Writing Activities</span>
                            <h2 className="u-m-top-4">Start Free Trial</h2>
                            <p className="u-m-base-1">Try full KS2 access for 30 days with no obligation. </p>
                            <Button onClick={() => { purchaseTrial({ variables: { schoolId: currentUser.school.id } }) }}>{/*<PlusCircle />*/}Start Free Trial</Button>
                        </>
                        :
                        <>
                            {activities.length > 0 ?
                                <>
                                    <h2>{data.school.studentsUsingGoWriteActivities}/{activities[0].invoiceRequest.studentsCounter} Pupils</h2>
                                    <p className="u-bold">Purchased: {moment(new Date(activities[0].invoiceRequest.orderedAt)).format('DD MMM YYYY')}</p>
                                    <p className="u-bold">Expires: {moment(new Date(activities[0].expiresAt)).format('DD MMM YYYY')}</p>
                                    <p className={styles.editActLink} onClick={() => setViewPack({ ...activities[0], writingActivity: true, studentsUsing: data.school.studentsUsingGoWriteActivities })}>Edit Subscription</p>
                                </>
                                :
                                <>
                                    <h2>{data.school.studentsUsingGoWriteActivities} Pupils</h2>
                                    <span className={styles.statusLabel}>Trial</span>
                                    <p className='u-m-base-2'>Ends: {moment(trials[0].expiresAt, "YYYY-MM-DD").format('DD MMM YYYY')}</p>
                                    <ButtonLink to='/settings/boomwriter/purchaseActivities'>Purchase Subscription</ButtonLink>
                                </>
                            }
                        </>
                    }
                </div>
                {moreInfo &&
                    <Modal closeModal={() => setMoreInfo(false)}>
                        <h2>About Writing Activities</h2>
                        <p className="u-m-base-2">Create individual writing activities that work both in-class and help support remote learning. You’ll build a digital writing record for every student that is easily visible and searchable by all staff.</p>
                        <p className="u-m-base-2">Activities are fully customisable and include features such as setting deadlines and when an activity should start. They include our powerful peer review feature, Starcasting, with the ability to turn this off where appropriate.</p>
                        <p>The built in student rewards, feedback system and optional Starcasting functionality will engage your young writers.</p>
                    </Modal>
                }
            </>
        )
    }
}

export default Activities;