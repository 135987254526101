import React from 'react'

import styles from '../classOptions/ClassOptions.module.scss'
import { MOVE_YEAR } from '../services/graphql';
import { useMutation } from '@apollo/react-hooks';
import { GET_CLASS_QUERY } from '../services/graphql';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';

import _ from 'lodash';

const YearOptions = ({close, students, classId, setStudentsYearUpdated, updatedStudents}) => {
    const [moveYear, { loading, error}] = useMutation(MOVE_YEAR, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.updateStudentsYearGroup.errors && data.updateStudentsYearGroup.errors.length > 0) {
                console.log("error from backend?")
            } else {
                close()
            }
            return;
        }
        ,
        onError: (error) => {
            console.log("error")
            return;
        },
        refetchQueries: () => [
            {
                query: GET_CLASS_QUERY, variables: {
                    id: classId
                }
            }
        ]
    });

    const handleClose = (e) => {
        close()
    }

    return (
        <div className={styles.optionsContainer} onClick={() => handleClose()}>
            <div className={styles.panel} onClick={(e) => e.stopPropagation()}>
                <div className={styles.panelLeft}>
                    <h2>Target Years</h2>
                    <ul>
                    {
                        _.map(YEAR_GROUPS, y => {
                            return <li key={`yg${y.val}`} className={styles.moveOptLink}  onClick={() =>
                                { 
                                setStudentsYearUpdated([...students, ...updatedStudents]);
                                moveYear({
                                    variables: {
                                        yearGroup: y.val,
                                        studentIds: students
                                    }
                                })
                            }
                            }
                        >{y.name}</li>
                        })
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default YearOptions