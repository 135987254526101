import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { Button } from '@axeedge/go-teacher-components';
import logo from '../../../../../../images/boomer.png'
import studentQrImg from '../../../../../../images/pupil_app_url.svg';
import studentQrImgBoom from '../../../../../../images/pupil_app_url_boom.svg';

import styles from './PrintableStudentsList.module.scss';
import { useTranslation } from 'react-i18next';

const PrintableStudentsList = ({ school, students }) => {

    const { t } = useTranslation(['classes', 'common'])

    const hostname = window && window.location && window.location.hostname;


    return (
        <div className={styles.studentsPrint}>
            <div className={cx(styles.noPrint, 'hidden-print')}>
                <div className={styles.noPrintContent}>
                    <p className='u-m-base-2'>{t('print_pupil_list_login_codes')}.</p>
                    <Button onClick={() => window.print()}>{t('common:button.print')}</Button>
                </div>
            </div>
 
            {
                _.sortBy(students, ['lastName']).map(student => {
                    return (
                        <div key={`print-${student.id}`} className={cx(styles.student, styles.printSheet)}>
                            <img className={styles.studentAppLogo} src={logo} alt='' />
                            <p className={styles.studentSchool}>{school.name}</p>
                            <h2 className={styles.studentName}>{student.name}</h2>

                            <p>{t('print_login_with_details_below')}.</p>
                            <p className='u-m-base-3'><b>{t('print_keep_details_safe_secret')}</b></p>

                            <p className='u-m-base-3'>{t('print_scan_type_school_pin_code')}</p>
                            <div className={styles.studentUrl}>
                               
                                {hostname.includes('boom') ? (
                                    <img className={styles.studentQrImg} src={studentQrImgBoom} alt='' />
                                ) : <img className={styles.studentQrImg} src={studentQrImg} alt='' />}

                                <div className={styles.studentUrlBody}>
                                    <strong>{hostname.includes('boom') ? 'pupils.boomhub.app' : 'pupils.goapps.app'}</strong>
                                </div>
                            </div>
                            <p className={styles.studentCode}>{t('common:school_code')}: <strong>{school.schoolCode}</strong></p>
                            <p className={styles.studentCode}>{t('common:pin_code')}: <strong>{student.loginCode}</strong></p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PrintableStudentsList;
