import React, { useContext } from "react";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { useQuery } from "@apollo/react-hooks";
import WondeStaffList from "../../../Onboarding/components/WondeStaffImport/WondeStaffList";
import loader from '../../../../images/loader.svg';
import { WONDE_TEACHERS_DATA } from "../../../Onboarding/services/graphql";

const AdditionalStaff = ({ setCurrentStep }) => {

    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(WONDE_TEACHERS_DATA, {
        variables: {
            additionalOnly: true,
            schoolId: currentUser?.school?.id
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }


    return (
        <>
            {loading ?
                <>
                    <p className="u-display-flex u-align-center heavy">Please wait, loading... <img className="u-m-left-2" src={loader} /></p>
                </> :
                data?.school?.wondeSchool &&
                <>
                    <WondeStaffList 
                        school={currentUser.school} 
                        extraStaff={data.school.wondeSchool.teachers} 
                        setCurrentSyncStep={setCurrentStep}
                        duringSync={true}
                    />
                </>
            }

        </>
    )
}

export default AdditionalStaff