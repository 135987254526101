import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';

import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { GET_TEACHERS_QUERY } from '../../../../services/school/graphql';
import { ADD_TEACHER_MUTATION } from '../../services/graphql';
import { useTranslation } from 'react-i18next';
import { getRoleLabel, usaOnlyRoles, ukOnlyRoles } from '../../../../services/utils';
import ls from 'local-storage'
const AddTeacher = ({ closeModal, schoolId, schoolCode }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [formErrors, setFormErrors] = useState([]);
    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        displayName: Yup.string().required(t('common:display_name_validation')),
        email: Yup.string().email().required(t('common:email_validation')),
        roleId: Yup.string().required(t('common:please_select_role')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });

    const [addTeacher, { loading }] = useMutation(ADD_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.addTeacher.errors && data.addTeacher.errors.length !== 0) {
                setFormErrors(data.addTeacher.errors);
                return;
            }
            if (data.addTeacher?.teacher?.id) {
                closeModal();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TEACHERS_QUERY, variables: { schoolCode, includeInvited: true } }]
    })

    const onSubmit = values => {
        const { firstName, lastName, displayName, email, roleId, isSchoolAdmin } = values;
        addTeacher({
            variables: {
                firstName,
                lastName,
                displayName,
                email,
                isSchoolAdmin,
                roleId: +roleId,
                schoolId
            }
        });
    }

    return (
        <div className='row'>
            <div className="col-lg-9">
                <h1 className="title u-m-base-2">{t('create_teacher_account')}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className='heavy'>{t('common:personal_info')}</p>

                    <div className="basic-form__group">
                        <label htmlFor='firstName' className='basic-form__text-label'>{t('common:first_name')}</label>
                        <input
                            name="firstName"
                            id="firstName"
                            placeholder={t('common:first_name_placeholder')}
                            className="basic-form__text-box"
                            ref={register}
                            type="text"
                        />
                    </div>
                    {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                    <div className="basic-form__group">
                        <label htmlFor='lastName' className='basic-form__text-label'>{t('common:last_name')}</label>
                        <input
                            name="lastName"
                            id="lastName"
                            placeholder={t('common:last_name_placeholder')}
                            className="basic-form__text-box"
                            ref={register}
                            type="text"
                        />
                    </div>
                    {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                    <div className="basic-form__group">
                        <label htmlFor='displayName' className='basic-form__text-label'>{t('common:display_name')}</label>
                        <input
                            name="displayName"
                            id="displayName"
                            placeholder={t('common:display_name_placeholder')}
                            className="basic-form__text-box"
                            ref={register}
                            type="text"
                        />
                    </div>
                    {errors.displayName && <p className="basic-form__hint">{errors.displayName.message}</p>}
                    <div className="basic-form__group">
                        <label htmlFor='email' className='basic-form__text-label'>{t('common:email')}</label>
                        <input
                            name="email"
                            id="email"
                            placeholder={t('common:email_placeholder')}
                            className="basic-form__text-box"
                            ref={register}
                            type="email"
                        />
                    </div>
                    {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}

                    <p className='heavy u-m-base-1'>Administrator rights</p>
                    <div className='u-display-flex u-align-center u-m-base-2'>
                        <div className="basic-form__group basic-form__group--check">
                            <input
                                type="checkbox"
                                ref={register}
                                className="switchToggle"
                                name="isSchoolAdmin"
                                id="isSchoolAdmin"
                            />
                            <label className="switchLabel" htmlFor="isSchoolAdmin">toggle</label>
                        </div>
                        <p className='u-m-left-3 u-p-right-3'>{t('admin_access_info')}</p>
                    </div>

                    <p className='heavy u-m-base-1'>School Role</p>

                    <div className="basic-form__group basic-form__group--check">
                        <select name="roleId" ref={register} className="basic-form__text-select">
                            <option value=''>{t('please_select')}</option>
                            {ls('country') === 'US' ?
                                Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                    return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                })
                                :
                                Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                    return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                })
                            }
                        </select>
                    </div>
                    {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}

                    {formErrors.length !== 0 && <FormErrors errors={formErrors.map(formError => t(formError))} />}
                    <div className="u-display-flex u-align-center u-m-top-3">
                        <Button disabled={loading} type="submit" className="u-m-right-1" primary>{loading ? t('common:saving') : t('common:button.create')}</Button>
                        <Button outline type='button' onClick={() => closeModal()}>{t('common:button.cancel')}</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddTeacher;

