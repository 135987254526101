import React, { useContext, useState } from "react";
import { AlertCircle, RefreshCw } from "react-feather";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import UpdateWondeSchool from "./UpdateWondeSchool";
import { Modal, Button, FormErrors } from "@axeedge/go-teacher-components";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { SCHOOL_SETUP_STEPS } from "../../../../services/constants";
import WondeClassImport from '../../components/WondeClassImport';
import WondeStaffImport from "../../components/WondeStaffImport";
import { CHECK_WONDE_ACCESS_STATUS } from "../../../GoReadSettings/services/graphql";
import SaveConfirm from "../../../../components/SaveConfirm";
import { CANCEL_WONDE_REQUEST } from "../../services/graphql";

const PostOnboardingWondeFlow = () => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [tryAgain, setTryAgain] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const [checkWondeStatus, { loading }] = useLazyQuery(CHECK_WONDE_ACCESS_STATUS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data?.currentTeacher?.school?.wondeAccessApprovedAt) {
                const cu = { ...currentUser, school: { ...currentUser.school, wondeAccessApprovedAt: data.currentTeacher.school.wondeAccessApprovedAt } }
                setCurrentUser(cu);
            } else if (!data?.currentTeacher?.school?.wondeAccessApprovedAt) {
                setTryAgain(true);
            }
        }
    });

    const [cancelWondeAccessRequest, { loading: cancelling }] = useMutation(CANCEL_WONDE_REQUEST, {
        update: (_, { data }) => {
            if (data.cancelWondeAccessRequest.errors && data.cancelWondeAccessRequest.errors.length !== 0) {
                setFormErrors(data.cancelWondeAccessRequest.errors);
                return;
            }
            if (data.cancelWondeAccessRequest?.school?.id) {
                setConfirmDelete(false);
                const cu = { ...currentUser, school: data.cancelWondeAccessRequest.school }
                setCurrentUser(cu);
            }
        }
    });


    if (currentUser) {
        const { school } = currentUser;

        return (
            <div className="card-mobile">
                {!school.wondeAccessApprovedAt ?
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className="heavy">{school.name} <span className="light">- URN {school.urn}</span></h2>
                            <div className="panel u-m-top-2">
                                <div className="u-display-flex">
                                    <AlertCircle />
                                    <div className="u-m-left-2">
                                        <p className="heavy lead u-m-base-1">Please authorize the data request from BoomHub in your Wonde dashboard</p>
                                        <p className="u-m-base-2">Once authorized, you can start importing your data (classes, pupils & staff)</p>
                                        <button disabled={loading} onClick={() => checkWondeStatus()} className="btn-reset u-flex-center"><span className="u-m-right-2">Check Status</span> <RefreshCw size={20} className='u-left-2' /></button>
                                        {tryAgain && <SaveConfirm withIcon={false} message={'Approve the Request in Wonde and check back again'} hideConfirm={setTryAgain} />}
                                    </div>
                                </div>
                            </div>
                            <div className="u-text-right">
                                <button onClick={() => setConfirmDelete(true)} className="btn-reset u-text-danger u-m-top-3">Cancel Wonde Access Request</button>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {!school.hasSetWeekStart ?
                            <UpdateWondeSchool /> :
                            school.setupStep < SCHOOL_SETUP_STEPS.classesStepDone ?
                                <WondeClassImport /> : <WondeStaffImport />
                        }
                    </>
                }


                {confirmDelete && (
                    <Modal closeModal={() => setConfirmDelete(false)}>

                        <div className="u-text-center">
                            <h3>Are you sure you want to delete the access request for Wonde?</h3>
                            <p className="u-m-base-3">If you cancel the access request, you will continue to setup your school manually</p>
                            <Button
                                disabled={cancelling}
                                onClick={() => cancelWondeAccessRequest({
                                    variables: {
                                        schoolId: school.id
                                    }
                                })
                                }>{cancelling ? 'Saving....' : 'Yes'}</Button>
                            <Button outline className='u-m-left-2' onClick={() => setConfirmDelete(false)}>No</Button>
                            {formErrors && <FormErrors errors={formErrors} />}
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default PostOnboardingWondeFlow;