import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { READD_TEACHER_TO_SCHOOL, GET_OTHER_TEACHERS } from '../services/graphql';
import { GET_TEACHERS_QUERY } from '../../../services/school/graphql';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import SaveConfirm from '../../../components/SaveConfirm';

const RemovedTeachersList = ({ schoolId, schoolCode, removedData }) => {

    const { school: { removedTeachers } } = removedData;

    const { t } = useTranslation(['classes', 'common']);
    const [formErrors, setFormErrors] = useState([]);
    const [reinstateSuccess, setReinstateSuccess] = useState(false);

    const getRemovedInfo = teacher => {
        const sm = teacher.inactiveSchoolMemberships.find(m => m.school && m.school.id === schoolId);
        if (sm) {
            return <p>Removed {sm.removedAt && moment(new Date(sm.removedAt)).format('DD MMM YYYY')} by {sm.removedBy && sm.removedBy.displayName}</p>
        }
        return null;
    }

    const [reAddTeacherToSchool] = useMutation(READD_TEACHER_TO_SCHOOL, {
        update: (_, { data }) => {
            if (data.reAddTeacherToSchool.errors && data.reAddTeacherToSchool.errors.length) {
                setFormErrors(data.reAddTeacherToSchool.errors);
            }
            if (data.reAddTeacherToSchool && data.reAddTeacherToSchool.teacher && data.reAddTeacherToSchool.teacher.id) {
                setReinstateSuccess(true);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_OTHER_TEACHERS, variables: { schoolId } }, {
            query: GET_TEACHERS_QUERY, variables: {
                schoolCode,
                includeInvited: true
            }
        },]
    });


    return (
        <>
            <div className="u-display-flex u-align-center u-justify-between">
                <h3>{t('removed')}</h3>
                {reinstateSuccess && <SaveConfirm message={t('common:changes_saved')} hideConfirm={setReinstateSuccess} />}
            </div>
            <hr className='separator' />

            <ul className='list-group'>
                {
                    !!removedTeachers.length ? removedTeachers.sort((a, b) => a.lastName.localeCompare(b.lastName)).map(teacher => {
                        return (
                            <li key={`removedT-${teacher.id}`} className='list-item'>
                                <div className='list-item--child'>
                                    <p className='heavy'>{teacher.displayName}</p>
                                    <div>{getRemovedInfo(teacher)}</div>
                                    {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
                                </div>
                                <div className='u-m-left-auto list-item--child'>
                                    <Button outline className='btn-small' onClick={() => reAddTeacherToSchool({
                                        variables: {
                                            teacherId: teacher.id,
                                            schoolId: schoolId
                                        }
                                    })}>{t('reinstate')}</Button>
                                </div>
                            </li>
                        )
                    }) : <p>{t('common:no_teachers')}</p>
                }
            </ul>

        </>
    )
}

export default RemovedTeachersList;
