import React, { useState, useContext, useEffect } from "react";
import styles from '../../WondeSync.module.scss';
import { Button } from "@axeedge/go-teacher-components";
import { useLazyQuery } from "@apollo/react-hooks";
import PageHeader from "../../../../components/PageHeader";
import { WONDE_CLASSES_DATA } from "../../../Onboarding/services/graphql";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import loader from '../../../../images/loader.svg';
import WondeClassesList from "../../../Onboarding/components/WondeClassImport/WondeClassesList";
import { useEffectOnce } from "react-use";

const PAGE_SIZE = 50;

const SyncClasses = ({ setCurrentStep }) => {

    const [showIntro, setShowIntro] = useState(true);
    const { currentUser } = useContext(AuthContext);


    const [wondeClasses, setWondeClasses] = useState([]);
    const [page, setPage] = useState(1);
    const [finishLoad, setFinishLoad] = useState(false);


    const getSubjects = (allClasses) => {
        return [...new Set(allClasses.map(obj => obj.subject))].length;
    }

    const [loadInitial, { data, error, loading: loadingInitial }] = useLazyQuery(WONDE_CLASSES_DATA, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setWondeClasses(data.school.wondeSchool.classes);
            if (data.school.wondeSchool.classes.length >0 ) {
                setPage(page + 1);
            } else {
                setFinishLoad(true);
            }
        }
    });

    useEffectOnce(() => {
        loadInitial({
            variables: {
                schoolId: currentUser?.school?.id,
                page: page,
            }
        })
    })

    const [loadMoreWondeClasses] = useLazyQuery(WONDE_CLASSES_DATA, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setWondeClasses(wondeClasses.concat(data.school.wondeSchool.classes));
            if (data.school.wondeSchool.classes.length >0) {
                setPage(page + 1);
            } else {
                setFinishLoad(true);
            }
        }
    });

    useEffect(() => {
        if (page > 1) {
            loadMoreWondeClasses({
                variables: {
                    schoolId: currentUser?.school?.id,
                    page: page
                }
            })
        }
    }, [page]);

    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <>
            <PageHeader showBackLink={false} title={'MIS Update'} />
            {loadingInitial ?
                <p className="u-display-flex u-align-center heavy">Please wait, loading classes... <img className="u-m-left-2" src={loader} /></p>
                :
                wondeClasses.length > 0 ?
                    <>
                        {finishLoad ?
                            <>
                                {showIntro ?
                                    <>
                                        <div className={styles.syncMain}>
                                            <h3 className="heavy">Sync classes</h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p className="u-m-base-2"> We found:</p>
                                                    <p>{wondeClasses.length} Classes on your MIS.</p>
                                                    <p className="u-m-base-2">These are grouped in {getSubjects(wondeClasses)} subjects</p>
                                                    <p className="u-m-base-2">We'll archive {data.school.classes.filter(el => el.typeId === 1).length} Registration classes{data.school.classes.filter(el => el.typeId === 2).length > 0 && <> and {data.school.classes.filter(el => el.typeId === 2).length} Other Groups</>}.</p>
                                                    <p className="u-m-base-2">You'll be able to select the classes that you wish to bring into BoomHub.</p>
                                                    <p>Importing a class with bring accross any students and teachers that are associtaed to the class. Where needed we'll automatically create accounts for students/staff that do not already exist.</p>
                                                </div>

                                                <div className="col-md-6">
                                                    <p className="u-m-base-2">We'll import your classes from your MIS system.</p>
                                                    <p className="u-m-base-2">These will replace your current classes.</p>
                                                    <p className="u-m-base-2">All reading data is stored at the child level - this means no class data will be lost.</p>
                                                    <p className="u-m-base-2">After this process you'll be able to recreate any custom data reports you have at the class or school level.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.syncFooter}>
                                            <Button onClick={() => setShowIntro(false)}>Continue</Button>
                                        </div>
                                    </> :
                                    <>
                                        <WondeClassesList
                                            setCurrentSyncStep={setCurrentStep}
                                            duringSync={true}
                                            allWondeClasses={wondeClasses}
                                        />
                                    </>
                                }
                            </> :
                            <p className="u-display-flex u-align-center heavy">Please wait, loading classes ({wondeClasses.length})... <img className="u-m-left-2" src={loader} /></p>
                        }
                    </> : <p>No classes found</p>
            }
        </>
    );

}

export default SyncClasses