import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { TEACHER_CANCEL_JOIN_REQUEST, CHECK_JOIN_REQUESTS } from "../../services/graphql";
import ImageFadeIn from "react-image-fade-in";
import { FormErrors, Button } from "@axeedge/go-teacher-components";
import styles from '../../Onboarding.module.scss';


const AccessRequestSent = ({ joinRequests, user }) => {

    const [formErrors, setFormErrors] = useState([]); 

    const [teacherCancelRequestToJoinSchool, { loading }] = useMutation(TEACHER_CANCEL_JOIN_REQUEST, {
        update: (_, { data }) => {
            if (data?.teacherCancelRequestToJoinSchool?.errors && data?.teacherCancelRequestToJoinSchool?.errors.length > 0) {
                setFormErrors(data.teacherCancelRequestToJoinSchool.errors);
                return
            }
            if (data.teacherCancelRequestToJoinSchool.teacher && data.teacherCancelRequestToJoinSchool.teacher.id) {
            }
        },
        refetchQueries: [{ query: CHECK_JOIN_REQUESTS }]
    })



    return (
        <>
            {joinRequests.filter(r => r.approved === null).map(request => (
                <div key={request.id} className={styles.onboardingJoin}>
                    <div className={styles.schoolAccessHeader}>
                        <div className={styles.schoolAccessHeaderMain}>
                            <h2 className="u-m-base-0">School Access Pending:</h2>
                            <h3>We've notified your Administrators</h3>
                            <p className="u-m-base-0">We'll notify you when your access request has been approved.</p>
                            {formErrors && <FormErrors errors={formErrors} />}
                            <button
                                disabled={loading}
                                onClick={() => teacherCancelRequestToJoinSchool({
                                    variables: {
                                        teacherId: user.id,
                                        joinRequestId: request.id
                                    }
                                })}
                                className="btn-reset u-text-primary link-underline"
                            >Cancel Access Request</button>
                        </div>
                        <div className={styles.schoolAccessHeaderSide}>
                            {request.school.avatarUrl && (
                                <ImageFadeIn src={request.school.avatarUrl} alt='school logo' />
                            )}
                            <p className="lead">{request.school.name}</p>
                            <p>{request.school.city}, {request.school?.country?.name}</p>
                        </div>
                    </div>
                    <h3>While you are waiting…</h3>
                    <p className="u-m-base-2">Check out our simple training and help guides on how to get the best out of BoomWriter!</p>
                    <a href="https://boomhub.freshdesk.com/support/home" target='blank' className={styles.btnLink}>View Training & Help</a>
                </div>
            ))}

            {joinRequests.filter(r => r.approved === false).map(request => (
                <div key={request.id} className={styles.onboardingJoin}>
                    <div className={styles.schoolAccessHeader}>
                        <div className={styles.schoolAccessHeaderMain}>
                            <h2 className="u-m-base-0">Access Declined:</h2>
                            <h3>Your request to join this school has been declined.</h3>
                            <p className="u-m-base-2">If you think this was an error please contact your administrator to confirm and try again.</p>
                            <Button
                                disabled={loading}
                                onClick={() => teacherCancelRequestToJoinSchool({
                                    variables: {
                                        teacherId: user.id,
                                        joinRequestId: request.id
                                    }
                                })}
                            >Continue</Button>
                        </div>
                        <div className={styles.schoolAccessHeaderSide}>
                            {request.school.avatarUrl && (
                                <ImageFadeIn src={request.school.avatarUrl} alt='school logo' />
                            )}
                            <p className="lead">{request.school.name}</p>
                            <p>{request.school.city}, {request.school?.country?.name}</p>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}

export default AccessRequestSent;