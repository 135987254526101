import React from 'react';
import { Link } from '@reach/router';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import PageHeader from '../../components/PageHeader';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';
import ls from 'local-storage';


const SettingsNav = () => {

    const { t } = useTranslation();
    const location = useLocation();

    return (
        <>
            <PageHeader title={t('settings')} backTo='/' />
            <Tabs withPanel className='u-m-base-3'>
                <Tab selected={location.pathname.includes('schoolProfile')}><Link to='/settings/schoolProfile'>{t('school')}</Link></Tab>
                <Tab selected={location.pathname.includes('billing')}><Link to='/settings/billing'>Billing Profile</Link></Tab>
                {ls('country') === 'US' ? (
                    <Tab selected={location.pathname.includes('boommath')}><Link to='/settings/boommath'>BoomMath</Link></Tab>
                ) : (
                    <>
                        <Tab selected={location.pathname.includes('boomreader')}><Link to='/settings/boomreader'>{t('BoomReader')}</Link></Tab>
                        <Tab selected={location.pathname.includes('boomwriter')}><Link to='/settings/boomwriter'>{t('BoomWriter')}</Link></Tab>
                    </>
                )}
            </Tabs>
        </>

    );
}

export default SettingsNav