import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Loader, Button } from '@axeedge/go-teacher-components';
import { navigate } from '@reach/router';

import { getAvailablePacks } from '../../services/utils';
import BookTemplateCategories from '../BookTemplateCategories';
import { ASSIGN_PACK_TO_CLASS_MUTATION, GET_CLASS_QUERY } from '../../services/graphql';
import { GET_PURCHASED_PACKS_QUERY }  from '../../../../services/school/graphql';
import { PlusCircle } from 'react-feather';
import cx from 'classnames';
import styles from './AddClassPack.module.scss';

const AddClassPack = ({ studentClass, closeModal, schoolCode }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [availablePacks, setAvailablePacks] = useState([]);
    const [selectedPack, setSelectedPack] = useState();

    const {data, loading, error} = useQuery(GET_PURCHASED_PACKS_QUERY, {
        variables: {
            schoolCode
        }
    })

    useEffect(() => {
        if (data && data.purchasedPacksBySchoolCode) {
            setAvailablePacks(getAvailablePacks(data.purchasedPacksBySchoolCode, studentClass.id));
        }
    }, [studentClass, data])


    const [assignPack] = useMutation(ASSIGN_PACK_TO_CLASS_MUTATION, {
        update: (_, { data }) => {
            if (data.assignPackToClass.errors && data.assignPackToClass.errors.length) {
                setFormErrors(data.assignPackToClass.errors);
            } else {
                closeModal();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: studentClass.id} }, { query: GET_PURCHASED_PACKS_QUERY, variables: {schoolCode} }]
    })

    const onAssignPack = (pack, bookTemplateCateg) => {
        assignPack({
            variables: {
                studentsClassId: studentClass.id,
                purchasedPackId: pack.purchasedPackId,
                packOfferIndex: pack.packOfferIndex,
                bookTemplateCategoryId: bookTemplateCateg && bookTemplateCateg.id
            }
        })
    }


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.purchasedPacksBySchoolCode) {
        return (
            <Fragment>
                {
                    availablePacks.length ? (
                        !selectedPack ? (
                            <Fragment>
                                <h2>Available BoomWriter Book Packs</h2>
                                <ul className='list-group'>
                                    {
                                        availablePacks.map(pack => (
                                            <li className='list-item' key={`add-p-${pack.id}`}>
                                                <button
                                                    className={cx('btn-reset', styles.classAssignPackButton)}
                                                    onClick={() => setSelectedPack(pack)}
                                                >
                                                    <div className={styles.classAssignPackButtonInfo}>
                                                        <p>Book Pack ({pack.maxNumberOfStudents} pupils)</p>
                                                    </div>
                                                    <PlusCircle />
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Fragment>
                        ) : (
                            <BookTemplateCategories 
                                selectedPack={selectedPack}
                                setSelectedPack={setSelectedPack}
                                onAssignPack={onAssignPack}
                                formErrors={formErrors}
                            />
                        )
                    ) : (
                        <Fragment>
                            <p className='u-m-base-2'>You don't have any available book packs.</p>
                            <Button type='button' onClick={() => navigate('/settings/boomwriter/purchase')}>Purchase packs</Button>
                        </Fragment>
                    )
                }
            </Fragment>
        )
    } 
    
    return null
}

export default AddClassPack;