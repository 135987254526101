import React, { useState } from 'react'
import { useQuery, useMutation} from '@apollo/react-hooks';
import styles from './Student.module.scss'
import loader from '../../../images/loader.svg';
import boomer from '../../../images/loader-boomer.png'
import {GET_STUDENT, REGENERATE_LOGIN_CODE, EDIT_STUDENT, GET_YEAR_STATS, GET_FILTERED_STUDENTS,GET_UNASSIGNED_STUDENTS_QUERY, REMOVE_STUDENT_FROM_CLASS } from '../services/graphql'
import { XCircle, RefreshCw, Edit, Delete } from 'react-feather';
import { YEAR_GROUPS, getUrl, APPS  } from '@axeedge/go-shared-utils';
import {Link} from '@reach/router'
import { Button  } from '@axeedge/go-teacher-components';
import ls from 'local-storage';
import { getYearGroupLabel } from '../../../services/utils';
import cx from 'classnames'
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import SaveConfirm from '../../../components/SaveConfirm';

const Student = ({student, close, currentYear}) => {

    const isUS = ls('country') === 'US';
    const { t } = useTranslation(['classes', 'common']);
    const [showEdit, setShowEdit] = useState(false)
    const [removeClass, setRemoveClass] = useState(null)
    const [showSaveConfirm, setShowSaveConfirm] = useState(false)
    const [formData, setFormData] = useState({
        firstName: student.firstName,
        lastName: student.lastName,
        upn: student.upn,
    })

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        yearGroup: Yup.number().typeError(isUS ? 'Please select grade' : 'Please select year group')
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = values => {
        const { firstName, lastName, yearGroup, upn } = values;
        editStudent({
            variables: {
                studentId: student.id,
                schoolId: data && studentData.school?.id,
                firstName,
                lastName,
                displayName: `${firstName} ${lastName}`,
                email: `${firstName}_${lastName}@null.com`,
                yearGroup,
                upn
            }
        });
    }

    const { data, error, loading } = useQuery(GET_STUDENT, {
        variables: {
            studentId: student.id
        },
        fetchPolicy: 'network-only'
    });

    const studentData = data && data.studentById;

    const [regenerateStudentLoginCode, { loading: refreshLoading }] = useMutation(REGENERATE_LOGIN_CODE, {
        variables: {
            studentId: student.id
        },
        onCompleted: () => {

        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_STUDENT,
                variables: {
                    studentId: student.id
                }
            }
        ]
    });

    const getQueries = () => {
        if (currentYear === 'UPN') {
            return {
                schoolId: data && studentData.school?.id,
                withoutUpn: true,
            }
        } else  {
            return {
                schoolId: data && studentData.school?.id,
                yearGroup: currentYear ? currentYear.toString() : '0',
            }
        }
        return null
    }

    const [editStudent, { loading: editingStudent }] = useMutation(EDIT_STUDENT, {
        onCompleted: () => {
            setShowSaveConfirm(true)
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_STUDENT,
                variables: {
                    studentId: student.id
                },
            },
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: data && studentData.school?.id,
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId: data && studentData.school?.id,
                }
            }
        ]
    });

    const [removeStudentFromClass, { loading: removingStudent }] = useMutation(REMOVE_STUDENT_FROM_CLASS, {
        onCompleted: () => {
            setRemoveClass(null)
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_STUDENT,
                variables: {
                    studentId: student.id
                },
            },
            {
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: data && studentData.school?.id,
                }
            },
            {
                query: GET_FILTERED_STUDENTS,
                variables: getQueries(),
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId: data && studentData.school?.id,
                }
            }
        ]
    });

    return (
        <div className={styles.container}>
        {loading && <img src={loader} />}
        {data && data.studentById && 
            <>
            {removeClass ? <>
                <p>Are you sure you want to remove {studentData.firstName} from the following class?</p>
                <p className="u-m-base-2">Class: {removeClass.name}</p>
                <Button disabled={removingStudent} className='u-m-right-1' onClick={() => removeStudentFromClass({variables: {
                    studentId: student.id,
                    studentsClassId: removeClass.id,
                }})}>{removingStudent ? 'Removing' : 'Yes'}</Button>
                <Button outline onClick={() => setRemoveClass(null)}>No</Button>
            </>
            :
            showEdit ?
                    <> 
                        <div className={styles.studentEdit}>
                        <button className={styles.close} onClick={() => setShowEdit(false)}><XCircle size="34"/></button>
                        <h3>Edit Student</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='basic-form__group'>
                                <label htmlFor='firstName'>{t('common:first_name')}</label>
                                <div className='basic-form__group'>
                                    <input
                                        name='firstName'
                                        className='basic-form__text-box'
                                        type='text'
                                        placeholder={t('common:first_name')}
                                        ref={register}
                                        defaultValue={studentData.firstName}
                                    />
                                </div>
                            </div>
                            {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                            <div className='basic-form__group'>
                                <label htmlFor='lastName'>{t('common:last_name')}</label>
                                <div className='basic-form__group'>
                                    <input
                                        name='lastName'
                                        className='basic-form__text-box'
                                        type='text'
                                        placeholder={t('common:last_name')}
                                        ref={register}
                                        defaultValue={studentData.lastName}
                                    />
                                </div>
                            </div>
                            {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}
                            <div className='basic-form__group'>
                                <label htmlFor='financeOfficerName'>{isUS ? 'Select grade' : 'Select year group'}</label>
                                <div className='basic-form__group'>
                                    <select defaultValue={studentData.yearGroup ? studentData.yearGroup : 'blank'} ref={register} name="yearGroup" className="basic-form__text-select">
                                        <option value='blank'>{isUS ? 'Select grade' : 'Select year group'}</option>
                                        {YEAR_GROUPS.map(yearGroup => (
                                            <option key={yearGroup.val} value={yearGroup.val}>{getYearGroupLabel(yearGroup.val)}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {errors.yearGroup && <p className='basic-form__hint'>{errors.yearGroup.message}</p>}
                            <div className='basic-form__group'>
                                <label htmlFor='upn'>UPN</label>
                                <div className='basic-form__group'>
                                <input
                                    name='upn'
                                    id='upn'
                                    placeholder='UPN'
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='text'
                                    defaultValue={studentData.upn}
                                />
                                </div>
                            </div>
                            <Button disabled={editingStudent} className='u-m-right-1'>{editingStudent ? 'Updating...' : 'Update'}</Button>
                            <Button outline onClick={() => setShowEdit(false)}>Cancel</Button>
                            {showSaveConfirm && <SaveConfirm message={'Student updated'} hideConfirm={setShowSaveConfirm} />}
                        </form>
                        </div>
                    </>
                :
                <>
            <div className={styles.studentHeader}>
                    <>
                        <img src={student.boomerAvatarUrl ? `${student.boomerAvatarUrl}?v=${student.updatedAt.replace(/\s/g, '' )}` : boomer} className={styles.studentAva} />
                        <h1 className={styles.heading}>{studentData ? studentData.firstName : student.firstName} {studentData ? studentData.lastName : student.lastName} <span className={styles.headingUpn}>{studentData.upn ? studentData.upn : 'No UPN number'}</span></h1>
                        <Edit onClick={() => setShowEdit(true)} className={styles.editBtn} />
                    </>
                <button className={styles.close} onClick={() => close(null)}><XCircle size="34"/></button>
            </div>
            <div className={styles.studentLayout}>
                <div className={styles.studentClasses}>
                    {studentData.classes.filter(x => x.typeId === 1).length > 0 &&
                    <>
                        <h3 className="u-bold">Registration Classes</h3>
                        <ul className="u-m-base-4">
                            {studentData.classes.filter(x => x.typeId === 1).map(c => {
                                return <li key={`c${c.id}`} className={styles.classLink}><Link to={`/classes/${c.id}`} className={styles.classLinkInner}><span>{c.name}</span><span>{c.teachers.length > 0 && c.teachers[0].displayName}</span></Link><Delete className={styles.removeFromClass} onClick={() => setRemoveClass(c)}/></li>
                            })}
                        </ul>
                    </>
                    }
                    {studentData.classes.filter(x => x.typeId !== 1).length > 0 &&
                    <>
                        <h3 className="u-bold">Other Classes</h3>
                        <ul>
                            {studentData.classes.filter(x => x.typeId !== 1).map(c => {
                                return <li key={`c${c.id}`} className={styles.classLink}><Link to={`/classes/${c.id}`} className={styles.classLinkInner}><span>{c.name}</span><span>{c.teachers.length > 0 && c.teachers[0].displayName}</span></Link><Delete className={styles.removeFromClass} onClick={() => setRemoveClass(c)}/></li>
                            })}
                        </ul>
                    </>
                    }
                </div>
                <div className={styles.studentInfo}>
                    {studentData.school?.canAccessGoReadApp &&
                        <div className="u-m-base-2">
                        <Button outline onClick={() => window.open(`${getUrl(APPS.go_read_teacher)}/student/${student.id}`, '_blank').focus()}>Reading Activity</Button>
                        </div>
                    }
                    <div>
                        {studentData.yearGroup && 
                            <span className={styles.yearTab}>{getYearGroupLabel(studentData.yearGroup)}</span>
                        }
                    </div>
                    <p className="u-m-top-2">School Code: <span className="u-bold">{studentData.school?.schoolCode}</span></p>
                    <p className={styles.loginCode}><span>Login Code: <span className="u-bold"> {studentData.loginCode}</span></span><span onClick={() => regenerateStudentLoginCode()}><RefreshCw className="u-m-left-1" size="16"/></span></p>
                </div>    
            </div>
            </>
            }
            </>
        }
    </div>
    )
}

export default Student